import { useState, useRef, useEffect } from 'react';

import '@kitware/vtk.js/Rendering/Profiles/Geometry';
import vtkGenericRenderWindow from '@kitware/vtk.js/Rendering/Misc/GenericRenderWindow';
import vtkLight from '@kitware/vtk.js/Rendering/Core/Light';

import { promise_createActor, createActor } from './vtkPipeline.js';
import { toRad, getGeometryAxis } from './reactorGeometry.js';
import ToggleActorsButton from './ToggleActorsButton';
import axesIcon from './assets/axes.png';
import orthoProjIcon from './assets/orthogonal.png';
import cameraIcon from './assets/camera.png';
import bgcolorIcon from './assets/bgcolor.png';
import levelIcon from './assets/liquid-level.png';

function getCameraSettings(view, camera, bounds) {
  const { viewAngle } = camera.get('viewAngle');
  const lengths = [];
  for (let i = 0; i < 6; i += 2) {
    lengths.push(bounds[i + 1] - bounds[i]);
  }
  let viewUp = [0, 0, 1];
  let position = [0, 0, 1];
  const distanceToFit =
    Math.max(...lengths) / 2 / Math.tan(toRad(viewAngle / 2));
  switch (view) {
    case '+X':
      viewUp = [0, 0, 1];
      position = [bounds[1] + distanceToFit, 0, lengths[2] / 2];
      break;
    case '-X':
      viewUp = [0, 0, 1];
      position = [bounds[0] - distanceToFit, 0, lengths[2] / 2];
      break;
    case '+Y':
      viewUp = [0, 0, 1];
      position = [0, bounds[3] + distanceToFit, lengths[2] / 2];
      break;
    case '-Y':
      viewUp = [0, 0, 1];
      position = [0, bounds[2] - distanceToFit, lengths[2] / 2];
      break;
    case '+Z':
      viewUp = [1, 0, 0];
      position = [0, 0, bounds[5] + distanceToFit];
      break;
    case '-Z':
      viewUp = [1, 0, 0];
      position = [0, 0, bounds[4] - distanceToFit];
      break;
    case 'iso':
      viewUp = [0, -1, 0];
      position = [distanceToFit, distanceToFit, distanceToFit];
      break;
    default:
      viewUp = [0, 0, 1];
      position = [
        (distanceToFit + bounds[1]) * Math.cos(toRad(15)),
        (distanceToFit + bounds[3]) * Math.sin(toRad(15)),
        lengths[2] + distanceToFit * Math.sin(toRad(15)),
      ];
      break;
  }
  return { viewUp, position, focalPoint: [0, 0, bounds[5] / 3] };
}

function ModelViewer({ transforms }) {
  const vtkContainerRef = useRef(null);
  const context = useRef(null);
  const [orthProj, setOrthProj] = useState(false);
  const [view, setView] = useState(null);
  const [toggleActors, setToggleActors] = useState([]);
  const [actors, setActors] = useState([]);
  const [transformActorMap, setTransformActorMap] = useState(new Map());
  const [capture, setCapture] = useState(false);
  const [darkBgMode, setDarkBgMode] = useState(true);

  // Checking for updates in transforms.
  useEffect(() => {
    if (transforms.length === 0 && transformActorMap.size > 0) {
      setActors([]);
      setTransformActorMap(new Map());
    }
    if (transforms.length > 0) {
      const newTransformStrings = transforms.map(JSON.stringify);
      const newMapEntries = newTransformStrings.map(async (transformString) => {
        let actor = transformActorMap.get(transformString);
        if (actor === undefined) {
          try {
            actor = await promise_createActor(JSON.parse(transformString));
          } catch (error) {
            console.error(error);
          }
        }
        return [transformString, actor];
      });
      Promise.all(newMapEntries).then((mapEntries) => {
        const newTransformActorMap = new Map(
          mapEntries.filter(([t, a]) => {
            return a === undefined ? false : newTransformStrings.includes(t);
          })
        );
        setTransformActorMap(newTransformActorMap);
        setActors(Array.from(newTransformActorMap.values()));
        // console.table(
        //   newTransformActorMap.forEach((actor, transformString) => [
        //     JSON.parse(transformString),
        //     actor,
        //   ])
        // );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transforms]);

  // set Toggle Actors.
  useEffect(() => {
    if (actors.length > 0 && actors.length === transforms.length) {
      setToggleActors(
        transforms
          .map((t, i) =>
            t.label && t.label !== '' ? [t.label, [actors[i]], undefined] : []
          )
          .map((t) => (t[0] === 'Liquid Level' ? [t[0], t[1], levelIcon] : t))
          .filter((x) => x.length > 0)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actors]);

  // Create render window.
  useEffect(() => {
    if (!context.current) {
      const genericRenderWindow = vtkGenericRenderWindow.newInstance();
      genericRenderWindow.setContainer(vtkContainerRef.current);
      genericRenderWindow.resize();

      const renderWindow = genericRenderWindow.getRenderWindow();
      const renderer = genericRenderWindow.getRenderer();
      const top_light = vtkLight.newInstance({
        lightType: 'CameraLight',
        position: [0, 0, 10],
        focalPoint: [0, 0, 0],
        direction: [0, 0, -1],
      });
      const bottom_light = vtkLight.newInstance({
        lightType: 'CameraLight',
        position: [0, 0, -10],
        focalPoint: [0, 0, 0],
        direction: [0, 0, 1],
      });
      const front_light = vtkLight.newInstance({
        lightType: 'CameraLight',
        position: [10, 0, 0],
        focalPoint: [0, 0, 0],
        direction: [-1, 0, 0],
      });
      const back_light = vtkLight.newInstance({
        lightType: 'CameraLight',
        position: [-10, 0, 0],
        focalPoint: [0, 0, 0],
        direction: [1, 0, 0],
      });
      renderer.removeAllLights();
      renderer.addLight(top_light);
      renderer.addLight(bottom_light);
      renderer.addLight(front_light);
      renderer.addLight(back_light);
      renderer.getLights().forEach((light) => light.setIntensity(0.675));
      renderer.setBackground([0, 0, 0]);
      renderWindow.render();

      context.current = {
        genericRenderWindow,
        renderWindow,
        renderer,
      };
    }

    return () => {
      if (context.current) {
        const { genericRenderWindow, renderWindow, renderer } = context.current;
        renderer.delete();
        renderWindow.delete();
        genericRenderWindow.delete();
        context.current = null;
      }
    };
  }, [vtkContainerRef]);

  // Adding actors to renderer.
  useEffect(() => {
    if (context.current && actors.length > 0) {
      const { renderer, renderWindow } = context.current;
      if (renderer.getActors().length === 0) {
        setView('R');
      }

      actors.forEach((actor) => renderer.addActor(actor));
      if (actors.length > 1) {
        renderer.resetCamera();
      }
      renderer.resetCameraClippingRange();
      renderWindow.render();

      const camera = renderer.getActiveCamera();
      context.current.camera = camera;

      window.vtk = {
        renderer: renderer,
        actors: actors,
        camera: camera,
      };
    }
    return () => {
      if (context.current && actors.length > 0) {
        actors.forEach((actor) => {
          context.current.renderer.removeActor(actor);
        });
        context.current.camera = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actors]);

  // Toggle othogonal projection.
  useEffect(() => {
    if (context.current.camera) {
      context.current.camera.setParallelProjection(orthProj);
      context.current.renderer.resetCameraClippingRange();
      context.current.renderWindow.render();
    }
  }, [orthProj]);

  // Set camera view
  useEffect(() => {
    if (actors.length > 0 && context.current.camera && view) {
      const actorsBounds =
        actors.length > 0
          ? actors.map((actor) => actor.getBounds())
          : context.current.axisActors.map((actor) => actor.getBounds());
      const bounds = Array(6)
        .fill(0)
        .map((val, i) => {
          const sign = i % 2 ? 1 : -1;
          const value = Math.max(...actorsBounds.map((arr) => arr[i] * sign));
          return value * sign;
        });
      const camera = context.current.camera;
      const cameraSettings = getCameraSettings(view, camera, bounds);
      camera.set(cameraSettings);
      if (actors.length > 1) {
        context.current.renderer.resetCamera();
      }
      context.current.renderer.resetCameraClippingRange();
      context.current.renderWindow.render();
    }
    setView(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  // Handle axis actor scaling and rendering.
  useEffect(() => {
    if (actors.length > 0) {
      if (!context.current?.axisActors) {
        context.current.axisActors = getGeometryAxis(0.5).map(createActor);
      }
      const actorsBounds =
        actors.length > 0
          ? actors.map((actor) => actor.getBounds())
          : context.current.axisActors.map((actor) => actor.getBounds());
      const bounds = Array(6)
        .fill(0)
        .map((val, i) => {
          const sign = i % 2 ? 1 : -1;
          const value = Math.max(...actorsBounds.map((arr) => arr[i] * sign));
          return value * sign;
        });
      let range = [];
      for (let i = 0; i < 6; i += 2) {
        range.push(Math.abs(bounds[i + 1] - bounds[i]));
      }
      let axisScale = Math.min(...range) / 2;
      if (actors.length === 1) {
        axisScale = Math.max(...range) / 2;
      }
      const newAxisActors = getGeometryAxis(axisScale).map(createActor);
      context.current.axisActors.forEach(context.current.renderer.removeActor);
      newAxisActors.forEach(context.current.renderer.addActor);
      context.current.axisActors = newAxisActors;
      if (actors.length > 1) {
        context.current.renderer.resetCamera();
      }
      context.current.renderer.resetCameraClippingRange();
      context.current.renderWindow.render();
    } else {
      if (context.current?.axisActors) {
        context.current.axisActors.forEach(
          context.current.renderer.removeActor
        );
        if (actors.length > 1) {
          context.current.renderer.resetCamera();
        }
        context.current.renderer.resetCameraClippingRange();
        context.current.renderWindow.render();
      }
    }
  }, [actors]);

  // Save image
  useEffect(() => {
    if (capture && context.current) {
      const { renderWindow } = context.current;
      if (renderWindow) {
        Promise.all(renderWindow.captureImages()).then((data) => {
          let link = document.createElement('a');
          link.download = 'screenshot';
          link.href = data[0];
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      }
    }
    setCapture(false);
  }, [capture]);

  // Switch between dark and light mode.
  useEffect(() => {
    if (context.current) {
      const { renderer, renderWindow } = context.current;
      if (renderer && renderWindow) {
        if (darkBgMode) {
          renderer.setBackground([0, 0, 0]);
          renderWindow.render();
        } else {
          renderer.setBackground([1, 1, 1]);
          renderWindow.render();
        }
      }
    }
  }, [darkBgMode]);

  return (
    <div
      className="w-100 h-100 position-relative"
      style={{ backgroundColor: 'black' }}
    >
      <div ref={vtkContainerRef} className="w-100 h-100"></div>
      <div
        className="position-absolute d-flex flex-wrap"
        style={{ top: '0.25rem', left: '0.25rem' }}
      >
        {['R', '+X', '-X', '+Y', '-Y', '+Z', '-Z'].map((k) => {
          return (
            <button
              key={k}
              className="ml-1 btn btn-secondary"
              title={'Set the camera view to ' + k}
              onClick={() => setView(k)}
            >
              {k}
            </button>
          );
        })}
        <button
          type="button"
          className="ml-1 btn btn-secondary"
          title="Set the camera view to ISO"
          onClick={() => setView('iso')}
        >
          ISO
        </button>
        <button
          className="ml-1 btn btn-secondary"
          title="Orthographic Projection On/Off"
          onClick={() => setOrthProj((flag) => !flag)}
        >
          <img src={orthoProjIcon} alt="Ortho" width={20} />
        </button>
        {context.current?.axisActors && context.current?.renderer ? (
          <ToggleActorsButton
            key={'Toggle Axes'}
            label={'Toggle Axes'}
            renderer={context.current.renderer}
            actors={context.current.axisActors}
            icon={axesIcon}
          />
        ) : (
          <button
            key={'Toggle Axes'}
            type="button"
            className="ml-1 btn btn-secondary"
            title="Toggle Axes"
          >
            <img src={axesIcon} alt="Toggle Axes" width={20} />
          </button>
        )}
        <button
          className="ml-1 btn btn-secondary"
          title="Toggle background color"
          onClick={() => setDarkBgMode((flag) => !flag)}
        >
          <img
            src={bgcolorIcon}
            alt={`${darkBgMode ? 'light' : 'dark'} mode`}
            width={20}
          />
        </button>
        <button
          className="ml-1 btn btn-secondary"
          title="Save Image"
          onClick={() => setCapture(true)}
        >
          <img src={cameraIcon} alt="Save_Image" width={20} />
        </button>
        {toggleActors.map(([label, actors, icon]) => {
          return actors && context.current?.renderer ? (
            <ToggleActorsButton
              key={label}
              label={label}
              renderer={context.current.renderer}
              actors={actors}
              icon={icon}
            />
          ) : (
            <button
              key={label}
              type="button"
              title={label}
              className="ml-1 btn btn-secondary"
            >
              {label}
            </button>
          );
        })}
      </div>
    </div>
  );
}

export default ModelViewer;
