import RSDropdown from './../../common/RSDropdown';
import { Form } from 'react-bootstrap';
import { GetProjectCompareDetailsData } from '../services/business-logic/compare.businesslogic';
import ToolbarButton from '../../common/ToolbarButton';
import { CompareCase } from './CompareCase';
import { pillTabs } from '../../utils/constants';
import { ArrowUpCircle } from 'react-feather';

const ComparePanel = ({projectId}) => {
  
  const { allSimCasesData, allSimCasesDocumentsDictionary, columnGroupsDictionary, 
    compareActionOptions, simCasesOptions, 
    compareCases, topOfPage, bottomOfPage, scrollToTop, addNewCaseToCompareAndScrollToBottom, removeCompareCase, imageModal, setImageModal,
    compareCombination, setCompareCombination, saveCombination
  } = GetProjectCompareDetailsData(projectId)
  const compareRawData = { allSimCasesData, allSimCasesDocumentsDictionary, columnGroupsDictionary, imageModal, setImageModal }
  
  return <>
    <div className='position-fixed' style={{bottom: 5, marginLeft: 'calc(100% - 50px)', opacity: 0.5}}>
      <ArrowUpCircle size='40' onClick={scrollToTop}/>
    </div>
    <div className="px-2" ref={topOfPage} >
      <Form.Group className='mb-3 d-flex flex-wrap justify-content-center' style={{rowGap: '0.5em'}}>
        <Form.Label className='align-self-center'> Compare Action</Form.Label>
        <RSDropdown
          className="ms-2"
          placeholder="Choose Compare Action"
          value={compareCombination?.selectedCompareAction}
          extraOption={false}
          handleChange={(selectedValue) => {
            setCompareCombination({
              type: "CompareAction",
              index: 0,
              value: selectedValue
            })
          }}
          options={compareActionOptions}
          style={{ width: '15em'}}
        />
        {
          Object.keys(compareCombination?.selectedCompareAction ?? {}).length > 0 && <>
            <ToolbarButton 
              className="ms-2"
              title="Add new sim to compare"
              onClickAction={addNewCaseToCompareAndScrollToBottom}
              iconType='add'
            />
            <ToolbarButton
              className="ms-2"
              title="Save combination"
              iconType='save'
              onClickAction={saveCombination}
            />
          </>
        }
      </Form.Group>
      {
        compareCombination?.selectedCompareAction?.value && 
        <div className='d-flex justify-content-around flex-wrap'>
          {
            compareCases.map((compareCase, index) => <div key={index}>
                <CompareCase 
                  compareCombination={compareCombination} 
                  setCompareCombination={setCompareCombination}
                  simCasesOptions={simCasesOptions} 
                  compareCase={compareCase} 
                  removeCompareCase={removeCompareCase} 
                  compareRawData={compareRawData}
                />
              </div>
            )
          }
        </div>
      }
    </div>
    <div className='mt-5' ref={bottomOfPage} />
  </>
}

const Compare = (projectId) =>  ({
  label: pillTabs.Compare,
  Panel: <ComparePanel projectId={projectId}/>,
  tabEnabled: true
})

export { Compare }
