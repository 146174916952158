import { useContext, useEffect, useMemo, useState } from "react";
import ToastContext from "../../../context";
import { getCookie } from "../../../utils";
import { useQuery } from "react-query";
import axios from "axios";
import { DataMapping } from "../../../data-mapping/SimDataMapping";
import * as Yup from 'yup';
import { toast } from "react-toastify";
import { AddNewSimDataActionMode } from "../../../utils/constants";

const GetAddNewSimDataDetails = ({
    caseData,
    onHide,
    onApply,
    selectAssetId,
    selectedAssetName,
    mode
}) => {

      const setToast = useContext(ToastContext)
      const [mappedData, setMappedData] = useState();
      const [addNewSimDataAction, setAddNewSimDataAction] = useState(AddNewSimDataActionMode.Single) 
      const [title, setTitle] = useState('')
      const [initialValues, setInitialValues] = useState({
        caseName: '',
        dirPath: ''
      })
    
      const config = {
        headers: {
          'X-CSRF-TOKEN': getCookie('csrf_token')
        },
      };
    
      const getAssetListQuery = useQuery('assetList', async () => {
        const { data } = await axios.get(
          '/api/v3/assets/lists/names/',
          config
        );
        const assetList = [{ _id: '', asset_name: 'Select' }];
        assetList.push(...data);
        return { data: assetList };
      });
    
      useEffect(() => {
        if (mode === 'create') {
          setTitle('Add New Case')
          setInitialValues((values) => ({
            ...values,
            addNewSimDataAction: AddNewSimDataActionMode.Single,
            caseName: ''
          }))
        }
        else {
          setTitle('Rename Case');
          setInitialValues((values) => ({
            ...values,
            caseName: caseData?.case_name
          }))
        }
      }, [mode])

      
      const getXMLtoJSON = async (fd) => {
        const parsedXMLdata = await axios.put('/api/data-parsing/', fd, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-CSRF-TOKEN': getCookie('csrf_token')
          },
        });
        setMappedData(DataMapping(parsedXMLdata));
      };

      const parseXML = (fileData) => {
        let fd = new FormData();
        fd.append('file', fileData);
        getXMLtoJSON(fd);
      };
    
      const validationSchema = useMemo(() => {
        let validationSchemaShape = {
          caseName: Yup.string().required('Required'),
          xmlFile: mode === 'create' ? Yup.mixed().required('Required') : null,
        }
        if(addNewSimDataAction === AddNewSimDataActionMode.Multiple) {
          validationSchemaShape = {
            dirPath: Yup.string().required('Required')
          }
        }
        return Yup.object().shape(validationSchemaShape)
      }, [addNewSimDataAction])
    
      
      const submitCaseData = async (values) => {
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-CSRF-TOKEN': getCookie('csrf_token')
          },
        };
    
        let toastLoadingMsg = addNewSimDataAction === AddNewSimDataActionMode.Multiple ? 
        'Adding simulation cases is in under process'
        : `${values.caseName} is in under ${mode === 'create' ? 'creation' : 'modification'}`
        let toastId = toast.loading(toastLoadingMsg)
    
        if (mode === 'create') {
          let fd = new FormData();
          if(addNewSimDataAction === AddNewSimDataActionMode.Multiple) {
            fd.append('dir_path', values.dirPath);
            fd.append('asset_name', selectedAssetName);
          }
          else {
            // Creating form data for adding new simulation case
            mappedData['Project_Name'] = values.caseName;
            const json_map_data = JSON.stringify(mappedData);
            fd.append('case_data', json_map_data);
      
            fd.append('xml_file', values.xmlFile);
            [...values.resultDir].forEach((i) => {
              fd.append('file', i);
            });
            if (selectAssetId) {
              fd.append('asset_id', selectAssetId);
            } else {
              if (values.assetName !== '') {
                fd.append('asset_id', values.assetName);
              }
            }
          }
    
          try {
            let requestEndpoint = addNewSimDataAction === AddNewSimDataActionMode.Multiple ?
            '/api/v3/multi-cases/' : '/api/v3/cases/'
    
            const case_resp = await axios.put(requestEndpoint, fd, config);
    
            if (case_resp.data.status_code === 401 || case_resp.data.status_code === 200){
              setToast({
                id: toastId,
                statusCode: case_resp.data.status_code,
                message: case_resp.data.msg
              }) 
              if(case_resp.data.status_code === 200) {
                onApply()
                onHide()
              }
            }
            else {
              setToast({
                id: toastId,
                statusCode: case_resp.data.status_code,
                message: case_resp.data.msg
              })
            }
          } catch (err) {
            setToast({
              id: toastId,
              statusCode: 500,
              message: "Whoops, Something went wrong!"
            })
          }
        } else if (mode === 'edit') {
          try {
            // Creating form data for edit simulation case name
            const editData = values.caseName;
            const json_map_data = JSON.stringify(editData);
            let fd = new FormData();
            fd.append('case_name', json_map_data);
            const case_rename_resp = await axios.put(`api/v3/cases/${caseData.id}/`, fd, config);
            if (case_rename_resp.data.status_code === 401){
              setToast({
                id: toastId,
                statusCode: case_rename_resp.data.status_code,
                message: case_rename_resp.data.msg
              })
            }
            else {
              setToast({
                id: toastId,
                statusCode: case_rename_resp.data.status_code,
                message: case_rename_resp.data.msg
              })
              onApply();
              onHide();
            }
          } catch (error) {
            setToast({
              id: toastId,
              statusCode: 500,
              message: "Whoops, Something went wrong!"
            })
          }
        }
      };

    return {
        title, initialValues, validationSchema, submitCaseData, parseXML, getAssetListQuery, addNewSimDataAction, setAddNewSimDataAction
    }
}

export { GetAddNewSimDataDetails }