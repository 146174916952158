import { useState, useEffect } from 'react';

import ModelViewer from './ModelViewer.js';

function StlViewer({ url }) {
  const [transforms, setTransforms] = useState([]);

  useEffect(() => {
    const t = {
      geometry: [
        {
          shape: 'STL',
          dim: { url: url },
          origin: [0, 0, 0],
          pos: [0, 0, 0],
          rotate: { x: 0, y: 0, z: 0 },
          scale: [1, 1, 1],
          opacity: 1,
          color: [1, 1, 1],
        },
      ],
      clip: [],
      cut: [],
      closedClip: [],
      color: [1, 1, 1],
      opacity: 0.50,
      label: '',
    };
    setTransforms([t]);
  }, [url]);

  return <ModelViewer transforms={transforms} />;
}

export default StlViewer;
