import { ButtonWithSpinner, UserContext } from '@tri/ui-shared';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Plus, Trash2, Edit2 } from 'react-feather';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { Table } from '../../common/SimsightTable';
import NewSimDataDialog from '../../sim-data-explorer/NewSimData';
import { ToScientificValue } from '@tri/ui-shared';
import { getCookie, sortTableColumn } from '../../utils';
import ToastContext from '../../context';

const LinkCell = ({ value, onClickLink, row }) => {
  return (
    <span
      onClick={(e) => {
        onClickLink(row.values);
      }}
      className="text-primary text-link"
    >
      {value}
    </span>
  );
};

// const checkIfTypeIsFloat = (x) => {
//   // check if the passed value is a number
//   // !isNaN() checks for numeric string
//   if (typeof x == 'number' && !isNaN(x) && x !== undefined) {
//     // check if it is integer
//     if (Number.isInteger(x)) {
//       return false;
//     } else {
//       // x is float
//       if (x <= 0.001) {
//         return false;
//       } else {
//         return true;
//       }
//     }
//   } else {
//     return false;
//   }
// };

const ConvertToFixed = (simCase) => {
  for (const key in simCase) {
    simCase[key] = ToScientificValue(simCase[key]);
  }
};

const SimulationCases = ({ selectedAssetData, cfdSimData }) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { user } = useContext(UserContext);
  const setToast = useContext(ToastContext)
  const [openAddNewCaseDialogFlag, setOpenAddNewCaseDialogFlag] =
    useState(false);
  const [editMode, setEditMode] = useState('create');
  const [selectedCases, setSelectedCases] = useState([]);
  const [tData, setTData] = useState([]);
  const [cData, setCDats] = useState([]);
  
  useEffect(() => {
    const ctableData = [];
    const cheaderData = [];

    for (const key in cfdSimData.data) {
      ConvertToFixed(cfdSimData.data[key]);
      ctableData.push(cfdSimData.data[key]);
    }
    setTData(ctableData);
    cheaderData.push({
      Header: 'Case Name',
      accessor: 'case_name',
      sortType: sortTableColumn,
      Cell: LinkCell,
      style: {
        cursor: 'pointer',
      },
      className: 'cursor-pointer',
    });
    for (const key in cfdSimData.header_map) {
      if (cfdSimData.header_map[key] !== 'Project_Name') {
        cheaderData.push({
          Header: key,
          accessor: cfdSimData.header_map[key],
        });
      }
    }
    setCDats(cheaderData);
  }, [cfdSimData]);

  // delete cases from asset
  const deleteSelectedCases = useMutation(
    async () => {
      const case_ids = [];
      selectedCases.map((caseData) => {
        case_ids.push(caseData.original.case_id);
      });
      const confirm = window.confirm(
        'Please confirm to proceed with deletion..'
      );

      //const newMixitIdList = selectedAssetData.mixit_tank.filter(
      const newCaseIdList = selectedAssetData.sim_cases.filter(
        (val) => !case_ids.includes(val)
      );
      const paramData = {
        asset_name: selectedAssetData.asset_name,
        case_id: newCaseIdList,
        id: selectedAssetData._id,
        is_stl_update: false,
        is_design_saved: false,
      };
      const config = {
        headers: {
          'X-CSRF-TOKEN': getCookie('csrf_token')
        },
      };

      if (confirm) {
        const case_del_resp = await axios.patch(
          `/api/v3/assets/${selectedAssetData._id}/${case_ids}/`,
          paramData,
          config
        );
        if (case_del_resp.data.status_code === 200){
          setToast({
            statusCode: case_del_resp.data.status_code,
            message: case_del_resp.data.msg
          }) 
        }
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['asset', selectedAssetData._id]);
        queryClient.invalidateQueries(`assetDetails-${selectedAssetData._id}`);
        setSelectedCases([])
      },
    }
  );

  return (
    <>
      {(user.is_superuser || user.is_poweruser) && (
        <div style={{ marginBottom: '17px', height: '47px' }}>
          { 
            selectedCases.length === 0 && <Button
              size="sm"
              variant="outline-primary"
              className="mr-1"
              title="Add new case"
              onClick={() => {
                setEditMode('create');
                setOpenAddNewCaseDialogFlag(true);
              }}
            >
              <Plus size={20} />
            </Button>
          }
          {selectedCases.length === 1 && (
            <ButtonWithSpinner
              size="sm"
              variant="outline-primary"
              title="Edit selected case"
              onClick={() => {
                setEditMode('edit');
                setOpenAddNewCaseDialogFlag(true);
              }}
              content={<Edit2 size={20} />}
              buttonClass="mr-1"
            ></ButtonWithSpinner>
          )}
          {selectedCases.length > 0 && (
            <ButtonWithSpinner
              size="sm"
              variant="outline-primary"
              title="Delete selected case(s)"
              onClick={deleteSelectedCases.mutateAsync}
              content={<Trash2 size={20} />}
              buttonClass="mr-1"
            ></ButtonWithSpinner>
          )}
        </div>
      )}

      {tData.length > 0 ? (
        <Table
          data={tData}
          columns={cData}
          initialState={{ hiddenColumns: ['id'] }}
          selectionEnabled={!user.is_staff}
          onSelect={setSelectedCases}
          onClickLink={(simData) => {
            history.push(`/app/asset/${selectedAssetData._id}/${simData.id}`);
          }}
          tableClass={`table skip-table-scroll skip-table-scroll-col1 ${user.is_staff ? '' : 'skip-table-scroll-col2'} `}
          searchBoxPlaceholder="Search in Simulation Table"
          disableSortBy={false}
        />
      ): <h5> No data found </h5>}

      {openAddNewCaseDialogFlag && (
        <NewSimDataDialog
          caseData={selectedCases.length > 0 ? selectedCases[0].original : null}
          onHide={() => {
            setOpenAddNewCaseDialogFlag(false);
          }}
          onApply={() => {
            queryClient.invalidateQueries(['asset', selectedAssetData._id]);
            queryClient.invalidateQueries(`assetDetails-${selectedAssetData._id}`);
          }}
          selectedAssetName={selectedAssetData.asset_name}
          selectAssetId={selectedAssetData._id}
          mode={editMode}
        ></NewSimDataDialog>
      )}
    </>
  );
};

export default SimulationCases;
