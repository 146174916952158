import { useMemo } from 'react';
import { useGetAssetStlURL } from '../data-service/projectasset.dataservice';
import { useGetProjectDetails } from '../data-service/simulation.dataservice';
import { useGetAllAssetDetails } from '../../../asset-explorer/services/data-service/asset.dataservice';

const GetAssetStlUrl = (mapped_asset_details) => useGetAssetStlURL(mapped_asset_details).data

const GetProjectAssetDetailsData = (projectId) => {
  const projectDetailsData = useGetProjectDetails(projectId)
  const allAssetsData = useGetAllAssetDetails()

  const projectDetails = useMemo(() => {
    if(projectDetailsData.status === 'success') {
      return projectDetailsData.data
    }
    return []
  }, [projectDetailsData])

  const mapped_asset_details = useMemo(() => {
    if(allAssetsData.status === 'success') {
      let mapped_asset_data = {}
      allAssetsData.data?.data?.forEach(asset => {
        let mapped_asset = projectDetails?.mapped_asset
        if(mapped_asset?.asset_id === asset._id) {
          mapped_asset_data["assetId"] = asset._id
          mapped_asset_data["assetImageUrl"] = asset.Additional_Image_File_Name
          mapped_asset_data['assetName'] = asset.asset_name
      }
      })
      return mapped_asset_data
    }
    return {}
  }, [allAssetsData, projectDetails])

  return { mapped_asset_details }
}

export { GetAssetStlUrl, GetProjectAssetDetailsData }