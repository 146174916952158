import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { UserContext } from '@tri/ui-shared';
import { logoutUser } from '../services/auth.dataservice';
import ToastContext from '../context';

const SimSightSignoutPage = () => {
  const { setUser } = useContext(UserContext);
  const setToast = useContext(ToastContext)

  const history = useHistory();

  return (
    <div
      color="primary"
      size="sm"
      onClick={() => {
        try {
          const logoutResponse = logoutUser();
          logoutResponse.then(res => {
            if(res.statusCode === 200) {
              setUser(undefined)
              history.push('/');
              localStorage.removeItem('simsight_breadcrumbs');
            }
          })
        } catch (err) {
          setToast({
            statusCode: 500,
            message: err.response.data
          }) 
        }
      }}
    >
      Sign out
    </div>
  );
};

export default SimSightSignoutPage;
