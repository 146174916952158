// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Dialog } from '@tri/ui-shared';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useContext } from 'react';
import { Col, Collapse, Form, Row } from 'react-bootstrap';
import { useState } from 'react';
import _ from 'lodash';
import { getCookie } from '../utils';
import ToastContext from './../context/index';
import MandatorySymbol from '../common/MandatorySymbol';
import { RBOffcanvas } from '../common/RBOffcanvas';

const NewAppDialog = ({ onHide, onApply, editAppFlag, selectedAppData }) => {
  const [isCreateAppInstructionOpen, setIsCreateAppInstructionOpen] = useState(false)

  const schema = Yup.object().shape({
    appName: Yup.string().required('Required'),
    app_files: editAppFlag ? Yup.mixed() : Yup.mixed().required('Required')
  });

  let initialValues = {};
  let title = 'Add New App';
  if (editAppFlag) {
    initialValues = {
      appName: selectedAppData[0].name,
      appDescription: selectedAppData[0].description,
      appFiles: { name: selectedAppData[0].appFiles }
    };
    title = 'Edit App';
  }
  const setToast = useContext(ToastContext)

  const [selectedAppFileNames, setSelectedAppFileNames] = useState('')
  
  const submitAppData = async (values) => {
    let fd = new FormData();
    fd.append(
      'data',
      JSON.stringify({
        name: values.appName,
        app_dir_name: values.appName.split(' ').join('_'),
        description: values.appDescription,
        app_files_names: selectedAppFileNames,
      })
    )

    if (values.app_files) {
      for (let i = 0; i < values.app_files.length; i++) {
        fd.append('app_files', values.app_files[i]);
      }
    }

    let config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-CSRF-TOKEN': getCookie('csrf_token')
      }
    }
    
    try {
      if (editAppFlag) {
        const app_patch_resp = await axios.patch(`/api/v3/app/${selectedAppData[0].id}/`, fd, config);
        if (app_patch_resp.data.status_code === 401) {
          setToast({
            statusCode: app_patch_resp.data.status_code,
            message: app_patch_resp.data.msg
          })
        }
      } else {
        const create_app_resp = await axios.post('/api/v3/app/', fd, config);
        if(create_app_resp.data.status_code === 401)
        {
          setToast({
            statusCode: create_app_resp.data.status_code,
            message: create_app_resp.data.msg
          })
        }
      }
      onApply();
      onHide();
    } catch (err) {
      setToast({
        statusCode: 500,
        message: err
      })
    }
  };

  return (
    <Formik
      onSubmit={submitAppData}
      initialValues={initialValues}
      validationSchema={schema}
    >
      {({ handleSubmit, handleChange, values, setFieldValue, errors, isSubmitting }) => (
        <RBOffcanvas 
          show={true} 
          title={title} 
          onHide={onHide} 
          onOk={handleSubmit}
          isOkBtnDisabled={_.isEqual(values, initialValues) || isSubmitting}
        >
          <Form>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={3}>
                Name <MandatorySymbol/>
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="text"
                  name="appName"
                  value={values.appName}
                  onChange={handleChange}
                  isInvalid={errors.appName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.appName}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={3}>
                Description
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="appDescription"
                  value={values.appDescription}
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" >
              <Form.Label column sm={3}>
                App Files {!editAppFlag && <MandatorySymbol />}
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="file"
                  multiple={true}
                  custom
                  name="app_files"
                  accept=".ipynb,.py,.pyc,.svg"
                  title={selectedAppFileNames || 'Select .ipynb, .py, .pyc, .svg files'}
                  onChange={(e) => {
                    let files = e.currentTarget.files
                    let allFileNames = ''
                    for (let i = 0; i < files.length; i++) {
                      allFileNames += `"${files[i].name}" `;
                    }
                    setSelectedAppFileNames(allFileNames)
                    setFieldValue('app_files', e.currentTarget.files);
                  }}
                  isInvalid={errors.app_files}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.app_files}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" >
                        <Col>
                            <Form.Text style={{fontSize: '87%'}}>
                            <span style={{color: '#e93535cc'}}> Instructions: </span>
                            <span className='text-link' style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => setIsCreateAppInstructionOpen(!isCreateAppInstructionOpen)} aria-controls="create-project-instruction" aria-expanded={isCreateAppInstructionOpen}>
                                Please check the following things before you upload the files.
                            </span>
                            <Collapse in={isCreateAppInstructionOpen}>
                                <div id="create-project-instruction">
                                    <Form.Text as="p" muted>
                                      <li>Make sure uploaded files includes .ipynb and .svg file</li> 
                                      <li>Make sure .svg (app icon file) filename should be same as that of app name</li> 
                                    </Form.Text>
                                </div>
                            </Collapse>   
                            </Form.Text>
                        </Col>
                    </Form.Group>
          </Form>
        </RBOffcanvas>
      )}
    </Formik>
  );
};

export default NewAppDialog;
