import { useState, useContext } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Row,
  Table,
  Modal,
} from 'react-bootstrap';
import { Formik } from 'formik';
import { Edit2 } from 'react-feather';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { UserContext, ToScientificValue } from '@tri/ui-shared';
import { getCookie } from '../../utils';

const ResultsPanel = ({ data, onSimulationQueryChanges }) => {
  const { user } = useContext(UserContext);
  const hasEditPermission = user.is_superuser || user.is_poweruser;
  const { pathname } = useLocation();
  const [, , , assetId, caseId] = pathname.split('/');
  const [expandedImageUrl, setExpandedImageUrl] = useState();
  const [expandedImageTitle, setExpandedImageTitle] = useState();
  const headerData = ['Parameter', 'Unit'];
  for (var i = 1; i <= data.data.config.geometry.impellers.length; i++) {
    headerData.push('Impeller - ' + i);
  }

  const c0Width = { width: '32rem' };
  const c1Width = { width: '8rem' };
  return (
    <div className="p-3">
      <h5>Performance Parameters</h5>
      <Table className="table">
        <thead>
          <tr>
            {headerData.map((h) => (
              <th key={h.index}>{h}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.results_performance_param_tbl1_list.map((data, index) => (
            <tr key={index}>
              <td className="p-1" style={c0Width}>
                {data.Parameter}
              </td>
              <td className="p-1" style={c1Width}>
                {data.Unit}
              </td>
              {data.Value.map((i) => (
                <td className="p-1">{ToScientificValue(i)}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>

      <Table className="table">
        <thead>
          <tr>
            <th>Parameter</th>
            <th>Unit</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {data.results_performance_param_tbl2_list.map((data, index) => (
            <tr key={index}>
              <td className="p-1" style={c0Width}>
                {data.Parameter}
              </td>
              <td className="p-1" style={c1Width}>
                {data.Unit}
              </td>
              {data.Value.map((i) => (
                <td className=" p-1">{ToScientificValue(i)}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>

      <h5>Key Images</h5>

      <Row xs={2} md={2} lg={3} xl={3} className="g-4">
        {data.image_list.map((imageFileName) => {
          //const imageUrl = `/api/asset-image/?case_name=${data.case_dir_name}&file_name=${imageFileName}`;
          const imageUrl = `/api/v3/assets/images/cases/${data.project_name}/${imageFileName}/`;
          const imageName = imageFileName.split('.').slice(0, -1).join('.');
          const imageDescData = data.results_image_description_data.find(
            (item) => item.image_file_name === imageFileName
          );
          // console.log(imageDescData);
          return (
            <Col>
              <Card
                style={{
                  boxShadow: '1px 3px 6px #00000029',
                  margin: '10px 0px',
                }}
              >
                <Card.Body className="d-flex flex-column p-1">
                  <Image
                    src={imageUrl}
                    thumbnail
                    style={{
                      height: '250px',
                      border: 'none',
                      textAlign: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setExpandedImageUrl(imageUrl);
                      setExpandedImageTitle(imageName);
                    }}
                    title="Click to open expanded view"
                  ></Image>
                  <div className="m-auto">{imageName}</div>
                  <Formik
                    initialValues={{
                      description: imageDescData?.description_data,
                    }}
                    enableReinitialize={true}
                    onSubmit={async (values) => {
                      let fd = new FormData();
                      fd.append('description', values.description);
                      const config = {
                        headers: {
                          'Content-Type': 'multipart/form-data',
                          'X-CSRF-TOKEN': getCookie('csrf_token')
                        },
                      };
                      console.log('values---', values);
                      if (imageDescData) {
                        await axios.patch(
                          `/api/v3/cases/results/images/${imageDescData._id}/`,
                          fd,
                          config
                        );
                        onSimulationQueryChanges();
                      } else {
                        values.description &&
                          (await axios.put(
                            `/api/v3/cases/${caseId}/images/${imageFileName}/`,
                            fd,
                            config
                          ));
                        onSimulationQueryChanges();
                      }
                    }}
                  >
                    {({ values, handleChange, handleSubmit }) => {
                      const [edit, setEdit] = useState(false);
                      return (
                        <Form>
                          {(hasEditPermission) &&(
                            <div className="d-flex">
                              Description
                              <Edit2
                                className="ml-1"
                                size={16}
                                onClick={() => {
                                  setEdit((prevValue) => !prevValue);
                                }}
                              ></Edit2>
                            </div>
                          )}
                          {(edit || values.description) && (
                            <Form.Group as={Row} className="mb-3">
                              <Col className="m-2">
                                <Form.Control
                                  as="textarea"
                                  name="description"
                                  value={values.description}
                                  onChange={handleChange}
                                  disabled={!edit}
                                />
                                {(hasEditPermission) && edit && (
                                  <Button
                                    className="mt-1"
                                    onClick={() => {
                                      setEdit((prevValue) => !prevValue);
                                      handleSubmit();
                                    }}
                                  >
                                    Save
                                  </Button>
                                )}
                              </Col>
                            </Form.Group>
                          )}
                        </Form>
                      );
                    }}
                  </Formik>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
      {expandedImageUrl && (
        <Modal
          show={true}
          onHide={() => {
            setExpandedImageUrl(null);
          }}
          dialogClassName="modal-w-90"
        >
          <Modal.Header closeButton>
            <Modal.Title>{expandedImageTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Image src={expandedImageUrl} fluid></Image>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

const Results = {
  id: 'results',
  label: 'Results',
  container: 'config',
  Renderer: ResultsPanel,
  tabEnabled: (activeNodeType) =>
    activeNodeType === 'config' || activeNodeType === 'process',
};

export default Results;
