// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { StlViewer } from "@tri/ui-shared";
import { Card, Image, InputGroup, ListGroup } from "react-bootstrap";
import { ImageModalView } from "../../common/ImageCardView";
import { dateFormats, parameterTableType } from "../../utils/constants";
import { compareDataType } from "../../utils/constants"
import { ParameterTable } from "../ParameterTable";
import { GetImageOrAnimationURL } from "../services/business-logic/compare.businesslogic";
import { GetSimCaseGeometry } from "../services/business-logic/geometry.businesslogic";
import STLViewerSkeleton from './../../common/STLViewerSkeleton';
import loadingGif from '../../../assets/loading.gif'
import { format } from "date-fns";
import { isNumber } from "lodash";

const CompareResultData = ({ compareAction, caseId, plotTypeValue, simCasesComparisionMetaData }) => {

    const { allSimCasesDocumentsDictionary, columnGroupsDictionary, simCaseRowData, columnUnits, columnTypes, imageModal, setImageModal } = simCasesComparisionMetaData

    const result = () => {
        switch(compareAction) {
            case compareDataType.Geometry:
                // eslint-disable-next-line no-case-declarations
                let stlUrl = GetSimCaseGeometry(caseId)
                return caseId && <>
                    <div className="mb-5" style={{width: '480px'}}>
                    {
                        stlUrl ? <StlViewer url={stlUrl} /> : 
                        stlUrl === '' ? <h5 className='mt-5'>Geometry not found or failed to load</h5> : <STLViewerSkeleton />
                    }
                    </div>
                    <ParameterTable Id={caseId} type={parameterTableType.geometry_param} readOnly={true} />
                </>
            case compareDataType.Animations:
            case compareDataType.Images:
                // eslint-disable-next-line no-case-declarations
                let url = GetImageOrAnimationURL(compareAction, caseId, plotTypeValue)
                return  caseId && plotTypeValue && <>
                    <ImageModalView imageModal={imageModal} setImageModal={setImageModal} />
                    <center className="mt-2 mb-2">
                    {
                        url ? <Card className='mt-3'>
                            <Card.Body>
                                <Image 
                                    src={url}
                                    thumbnail
                                    style={{
                                        height: '250px',
                                        border: 'none',
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                    }}
                                    title="Click to open expanded view"
                                    onClick={() => {
                                        setImageModal({"show": true, "url": url})
                                    }}
                                />
                            </Card.Body>
                        </Card> : 
                        <img alt="Loading..." src={loadingGif} height="50px"/>
                    }
                    </center>
                </>
            case compareDataType.Groups: 
                return plotTypeValue && <Card className='mt-3' style={{backgroundColor: columnGroupsDictionary[plotTypeValue]?.color}}>
                    <Card.Body>
                        <ListGroup variant="flush">
                            <InputGroup className="mb-3">
                                <InputGroup.displayName className='w-50 fw-bold'>Column Name</InputGroup.displayName>
                                <InputGroup.displayName className='fw-bold' style={{width: '30%'}}>Value</InputGroup.displayName>
                                <InputGroup.displayName className='fw-bold' style={{width: '15%'}}>Unit</InputGroup.displayName>
                            </InputGroup>
                            {
                                columnGroupsDictionary[plotTypeValue]?.columns.split(',').map(columnName => <InputGroup className="mb-3">
                                    <InputGroup.Text className='w-50 text-wrap'>{columnName}</InputGroup.Text>
                                    <td className='border p-1' style={{width: '30%'}}>
                                    {
                                        columnTypes?.[columnName]?.toLowerCase() === 'date' ?
                                        (isNumber(simCaseRowData?.[columnName]) ? format(new Date(simCaseRowData?.[columnName]), dateFormats[columnUnits?.[columnName]]) : 'Inproper Format')
                                        : simCaseRowData?.[columnName]
                                    }
                                    </td>
                                    <td className='border p-1' style={{width: '15%'}}>{(columnUnits && columnUnits[columnName]) ?? '-'}</td>
                                </InputGroup>)
                            }
                        </ListGroup>
                    </Card.Body>
                </Card>
            case compareDataType.Documents: 
                // eslint-disable-next-line no-case-declarations, no-prototype-builtins
                let simCaseDocument = allSimCasesDocumentsDictionary.hasOwnProperty(caseId) ? allSimCasesDocumentsDictionary[caseId] : {}
                // eslint-disable-next-line no-prototype-builtins
                if(simCaseDocument.hasOwnProperty(plotTypeValue)) {
                    return <Card className='mt-3'>
                        <Card.Header><strong>{plotTypeValue?.toUpperCase()} </strong></Card.Header>
                        <ListGroup variant="flush">
                        { 
                            simCaseDocument[plotTypeValue].map(file => <ListGroup.Item>{file}.{plotTypeValue?.toLowerCase()}</ListGroup.Item>)
                        }
                        </ListGroup>
                    </Card>    
                }
        }
    }
    return <div className="mt-2">{result()}</div>
}

export { CompareResultData }