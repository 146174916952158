import { useHeaderConfiguration } from "../../../utils";
import { useQuery } from 'react-query';
import axios from 'axios';

//get list of all users
const useUserList = () => {
    const config = useHeaderConfiguration();
    return useQuery('userList', async () => {
        const { data } = await axios.get('/api/users/', config);
        return data.data
    })
}

export { useUserList }