import { Table } from 'react-bootstrap';
import { ToScientificValue } from '@tri/ui-shared';
import { isArray } from 'lodash';

const ProcessMetrics = ({ processMetricsData, processMetricsHeaderData }) => {
  const headerData = [
    'Parameters',
    'Type',
    'Unit',
    ...processMetricsHeaderData,
  ];

  return processMetricsData?.length > 0 ?
      <Table className="table skip-table-scroll skip-table-scroll-col1">
        <thead>
          <tr>
            {headerData.map((h) => (
              <th className="p-1" key={h.index}>{h}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {processMetricsData.map((data, index) => (
            <tr key={index}>
              <td className="p-1" style={{ width: '24rem' }}>
                {data.Parameter}
              </td>
              <td className="p-1">{data.Type}</td>
              <td className="p-1">{data.Unit}</td>
              {data.Value.map((i) => {
                let dataValue = data.Parameter === "Operating Volume" ? Number(i).toExponential(3) : ToScientificValue(i);
                isArray(dataValue) &&
                  (dataValue = dataValue
                    .map((item) => {
                      return ' ' + item;
                    })
                    .toString());
                return <td className="p-1">{dataValue}</td>;
              })}
            </tr>
          ))}
        </tbody>
      </Table> :
      <h5>No data found</h5>
};

export default ProcessMetrics;
