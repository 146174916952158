import { Button } from "react-bootstrap"
import { Edit2, Eye, Grid, Lock, Plus, Save, Settings, Trash2, Unlock, Upload } from "react-feather"
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import ButtonWithSpinner from '../../../../../libs/ui-shared/src/lib/ButtonWithSpinner';

function ToolbarButton({ iconType, title, onClickAction, buttomWithSpinner = false, ...rest}) {
    const iconTypeList = {
        'add': <Plus size='20' />,
        'edit': <Edit2 size='20' />,
        'delete': <Trash2 size='20' />,
        'setting': <Settings size='20' />,
        'lock': <Lock size='20' />,
        'unlock': <Unlock size='20' />,
        'show': <Eye size='20' />,
        'group': <Grid size='20' />,
        'export': <Upload size='20' />,
        'save': <Save size='20' />
    }

    return (
        buttomWithSpinner ? 
            <ButtonWithSpinner
                size="sm"
                variant="outline-primary"
                title={title}
                onClick={onClickAction}
                content={iconTypeList[iconType]}
                buttonClass="mr-1"
                {...rest}
            ></ButtonWithSpinner> :
            <Button
                active={iconType === "lock"}
                size="sm"
                variant="outline-primary"
                className="mr-1"
                title={title}
                onClick={onClickAction}
                {...rest}
            >
            {iconTypeList[iconType]}
            </Button>
    )
}

export default ToolbarButton