import { Nav, Navbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Home, Info } from 'react-feather';
import { useHistory } from 'react-router-dom';
import UserDropdownMenu from './UserDropdownMenu';
import appLogo from '../../assets/simsight_logo.svg';
import { useState } from 'react';
import AboutMenu from './AboutMenu';

const Header = () => {
  const history = useHistory();
  const [isAboutMenuVisible, setAboutMenuVisibility] = useState(false);

  const goToHomePage = () => {
    history.push(`/app/home`);
  };

  return <>
      <Navbar collapseOnSelect bg='primary' variant="dark" className='p-2 fixed-top'>
        <Navbar.Brand>
          <img alt='SimSight' src={appLogo} height={32} onClick={goToHomePage} style={{ cursor: 'pointer'}}/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className='me-auto'/>
          <Nav className="justify-content-end" >
            <Nav.Link too>
              <OverlayTrigger 
                placement='bottom-start'
                overlay={<Tooltip id="tooltip-disabled">About</Tooltip>}>
                <div>
                  <Info color={'#fff'} onClick={() => setAboutMenuVisibility(true)}/>
                </div>
              </OverlayTrigger>
            </Nav.Link>
            <Nav.Link>
              <OverlayTrigger 
                placement='bottom-start'
                overlay={<Tooltip id="tooltip-disabled">Home</Tooltip>}>
                <div>
                  <Home color={'#fff'} onClick={goToHomePage} />
                </div>
              </OverlayTrigger>
            </Nav.Link>
            <UserDropdownMenu />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {
        isAboutMenuVisible && <AboutMenu onHide={() => setAboutMenuVisibility(false)} />
      }
  </>
};

export default Header;
