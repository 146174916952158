import { Card } from 'react-bootstrap';
import { QuantityControl, SelectControl, Unit } from '../common/FormControls';
import { HorizontalFormField } from '../common/FormFields';

const materialOptions = [
  'Carbon Steel',
  'Stainless 304',
  'Stainless 304L',
  'Stainless 316',
  'Stainless 316L',
  'Hastelloy C',
  'Hastelloy B',
  'Monel 400',
  'Inconel 600',
  'Nickel 200',
  'Carpenter 20',
];

const sealOptions = [
  'Open Tank',
  'Stuffing Box',
  'Single Seal',
  'Double Seal',
  'None',
];

const MechanicalPanel = ({ disabled }) => {
  const layoutProps = { w1: 5, w2: 5, rowClass: 'mb-1' };
  return (
    <Card>
      <Card.Body>
        <Card.Title className="h6">Shaft Design</Card.Title>
        <div className="pl-0 width-32">
          <HorizontalFormField label="Motor Power" {...layoutProps}>
            <QuantityControl
              name="config.mechanical.motorPower"
              qty="power"
              disabled={disabled}
            />
            <Unit qty="power" />
          </HorizontalFormField>

          <HorizontalFormField label="Motor Load %" {...layoutProps}>
            <QuantityControl
              name="config.mechanical.motorLoad"
              disabled={disabled}
            />
          </HorizontalFormField>

          <HorizontalFormField label="Shaft Material" {...layoutProps}>
            <SelectControl
              name="config.mechanical.shaftMaterial"
              options={materialOptions}
              disabled={disabled}
            />
          </HorizontalFormField>

          <HorizontalFormField label="Seal Type" {...layoutProps}>
            <SelectControl
              name="config.mechanical.sealType"
              options={sealOptions}
              disabled={disabled}
            />
          </HorizontalFormField>

          <HorizontalFormField label="Mounting Height" {...layoutProps}>
            <QuantityControl
              name="config.mechanical.mountingHeight"
              qty="length"
              disabled={disabled}
            />
            <Unit qty="length" />
          </HorizontalFormField>

          <HorizontalFormField label="Service Factor" {...layoutProps}>
            <QuantityControl
              name="config.mechanical.serviceFactor"
              disabled={disabled}
            />
          </HorizontalFormField>

          <HorizontalFormField label="Operating Range Min" {...layoutProps}>
            <QuantityControl
              name="config.mechanical.minOpRange"
              qty="angularSpeed"
              disabled={disabled}
            />
            <Unit qty="angularSpeed" />
          </HorizontalFormField>

          <HorizontalFormField label="Operating Range Max" {...layoutProps}>
            <QuantityControl
              name="config.mechanical.maxOpRange"
              qty="angularSpeed"
              disabled={disabled}
            />
            <Unit qty="angularSpeed" />
          </HorizontalFormField>
        </div>
      </Card.Body>
    </Card>
  );
};

const Mechanical = {
  id: 'mechanical',
  label: 'Mechanical',
  container: 'config',
  Renderer: MechanicalPanel,
  tabEnabled: (activeNodeType) =>
    activeNodeType === 'config' || activeNodeType === 'process',
};

export default Mechanical;
