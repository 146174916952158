import { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Clipboard } from 'react-feather';

const ClipboardCopy = ({ copyText }) => {
  const [isCopied, setIsCopied] = useState(false);
  const copyToClipboard = async (text) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  };
  const handleCopyClick = () => {
    copyToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
      })
      .catch((err) => {
        alert(err);
      });
  };
  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip>{isCopied ? 'Copied' : 'Copy'}</Tooltip>}
    >
      <Clipboard
        onClick={handleCopyClick}
        onMouseLeave={() => setIsCopied(false)}
        size={20}
        className="ml-2 cursor-pointer"
      />
    </OverlayTrigger>
  );
};

export default ClipboardCopy;
