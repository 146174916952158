import { useEffect, useState, useContext } from 'react';
import Geometry from '../../../../mixit/src/app/database/Geometry';
import Internals from '../../../../mixit/src/app/database/Internals';
import Mechanical from '../../../../mixit/src/app/database/Mechanical';
import OperatingConditions from '../../../../mixit/src/app/database/OperatingConditions';
import HeatTransferConditions from '../../../../mixit/src/app/database/HeatTransferConditions';
import DispersedPhaseConditions from '../../../../mixit/src/app/database/DispersedPhaseConditions';
import useUnitSystem from '../../../../mixit/src/app/hooks/useUnitSystem';
import {
  CenteredSpinner,
  DefContext,
  UnitSystemContext,
} from '@tri/ui-shared';
import { Form, Nav } from 'react-bootstrap';
import { Formik } from 'formik';
import Results from './sim-data-view/Results';
import { useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import { getCookie } from '../utils';

const tabs = [
  Geometry,
  Internals,
  Mechanical,
  OperatingConditions,
  HeatTransferConditions,
  DispersedPhaseConditions,
  Results,
];

const ViewSimData = ({ simId, onLoad }) => {
  const queryClient = useQueryClient();
  const [activeTabId, setActiveTabId] = useState(Geometry.id);
  const unitSystem = useUnitSystem();
  const [def] = useState({});
  const config = {
    headers: { 
      'X-CSRF-TOKEN': getCookie('csrf_token') 
    },
  };

  const activeTab = tabs.find((t) => t.id === activeTabId);
  const TabRenderer = activeTab.Renderer;

  const simulationDetailsQuery = useQuery(['simulationDetails', simId], async () => {
    const { data } = await axios.get(`/api/v3/case-details/${simId}/`, config);
    return data;
  });

  useEffect(() => {
    if (simulationDetailsQuery.data) {
      onLoad(simulationDetailsQuery.data);
    }
  }, [simulationDetailsQuery.data]);

  return (
    <DefContext.Provider value={def}>
      <UnitSystemContext.Provider value={unitSystem}>
        <div className="h-100 p-2 overflow-auto">
          <Nav
            variant="pills"
            activeKey={activeTabId}
            onSelect={setActiveTabId}
          >
            {tabs.map((T) => {
              return (
                <Nav.Item key={T.id}>
                  <Nav.Link eventKey={T.id}>{T.label}</Nav.Link>
                </Nav.Item>
              );
            })}
          </Nav>

          <div className="py-3 pr-3">
            {simulationDetailsQuery.status === 'loading' && <CenteredSpinner />}
            {simulationDetailsQuery.status === 'error' && (
              <h5>Something went wrong ...</h5>
            )}
            {simulationDetailsQuery.status === 'success' &&
              activeTab.id !== 'results' && (
                <Formik
                  initialValues={simulationDetailsQuery.data.data}
                  enableReinitialize={true}
                >
                  {({ values, setFieldValue, initialValues }) => {
                    return (
                      <Form className="p-3" style={{ width: '70rem' }}>
                        <TabRenderer
                          data={values}
                          disabled={true}
                          setFieldValue={setFieldValue}
                        />
                      </Form>
                    );
                  }}
                </Formik>
              )}

            {simulationDetailsQuery.status === 'success' &&
              activeTab.id === 'results' && (
                <TabRenderer
                  data={simulationDetailsQuery.data}
                  disabled={true}
                  onSimulationQueryChanges={() => {
                    queryClient.invalidateQueries('simulationDetails');
                  }}
                />
              )}
          </div>
        </div>
      </UnitSystemContext.Provider>
    </DefContext.Provider>
  );
};

export default ViewSimData;
