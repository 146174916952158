import { CompareResultData } from './CompareResultData';
import ToolbarButton from '../../common/ToolbarButton';
import RSDropdown from './../../common/RSDropdown';
import { GetSimcaseRowMetaDataForGroupsComparision } from '../services/business-logic/compare.businesslogic';

const CompareCase = ({compareCombination, setCompareCombination, simCasesOptions, compareCase, compareRawData, removeCompareCase}) => {

    const { simcaseGroupComparisionData } = GetSimcaseRowMetaDataForGroupsComparision(compareRawData["allSimCasesData"], compareCase.value?.value)
    const simCasesComparisionMetaData = { ...compareRawData, ...simcaseGroupComparisionData }

    return (
        <div className='d-flex flex-column mb-3'>
            <div className='d-flex flex-wrap' style={{rowGap: '0.5em'}}>
                <div className='me-2 d-flex' style={{width: '17em'}}>
                    <ToolbarButton 
                        title="Delete"
                        onClickAction={() => removeCompareCase(compareCase.index)}
                        iconType='delete'
                        buttomWithSpinner={true}
                    />
                    <RSDropdown
                        title={compareCase?.value?.label}
                        placeholder="Search or select case"
                        value={compareCombination.compareCases?.[compareCase.index]?.caseId}
                        extraOption={false}
                        handleChange={(selectedValue) => {
                            setCompareCombination({
                                type: "caseId",
                                value: selectedValue,
                                index: compareCase.index
                            })
                            compareCase.handleChange(selectedValue)
                        }}
                        options={simCasesOptions}
                        hideSelectedOptions={false}
                        containerStyle= {{ width: '-webkit-fill-available' }}
                    />
                </div>
                {
                    !compareCase.isPlotTypeDisabled && <RSDropdown
                        placeholder={`Search or select ${compareCombination.selectedCompareAction?.value}`}
                        value={compareCombination.compareCases[compareCase.index]?.plotType}
                        extraOption={false}
                        isDisabled={compareCase.isPlotTypeDisabled}
                        handleChange={(selectedValue) => {
                            setCompareCombination({
                                type: "plotType",
                                value: selectedValue,
                                index: compareCase.index
                            })
                            compareCase.handlePlotTypeChange(selectedValue)
                        }}
                        options={compareCase?.plotTypeOptions}
                        style={{ width: '17em'}}
                        className="text-break"
                    />
                }
            </div>
            <CompareResultData 
                compareAction={compareCombination.selectedCompareAction?.value} 
                caseId={compareCase.value?.value} 
                plotTypeValue={compareCase.plotTypeValue?.value} 
                simCasesComparisionMetaData={simCasesComparisionMetaData}
            />
        </div>
    )
}

export { CompareCase }