import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import * as yup from 'yup';
import axios from 'axios';
import AuthLayout from './AuthLayout';
import { Formik } from 'formik';

const requiredSchema = yup.string().required('Required');

const schema = yup.object().shape({
  name: requiredSchema,
  company: requiredSchema,
  jobTitle: requiredSchema,
  country: requiredSchema,
  email: requiredSchema.email('Please provide a valid email address.'),
  password: requiredSchema.matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
    'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
  ),
  password2: requiredSchema.oneOf(
    [yup.ref('password'), null],
    'Passwords must match'
  ),
});

const SignupPage = ({ onSignup = () => {} }) => {
  const [isSigningUp, setSigningUp] = useState(false);
  const history = useHistory();

  const inputRef = useRef();
  useEffect(() => inputRef.current && inputRef.current.focus());

  const initialValues = {
    name: '',
    company: '',
    jobTitle: '',
    country: 'United States of America (the)',
    email: '',
    password: '',
    password2: '',
  };

  return (
    <AuthLayout>
      <div className="text-center mt-5">
        <h1 className="h2">Welcome to MixIT!</h1>
        <p className="lead">Please sign up for your MixIT account</p>
      </div>

      <Card
        style={{
          backgroundColor: '#e9e9e9',
          boxShadow: '0 25px 50px -12px rgb(0 0 0 / 0.25)',
          border: 'none',
          padding: '20px',
        }}
      >
        <Card.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={async (values, actions) => {
              setSigningUp(true);
              try {
                const { data } = await axios.post('/api/auth/signup', values);
                onSignup(data);
                history.push('/verify-account');
              } catch (err) {
                if (err.response.data === 'Email in use') {
                  actions.setFieldError('email', 'Email in use');
                }
                setSigningUp(false);
              }
            }}
          >
            {({ handleSubmit, handleChange, touched, errors, values }) => (
              <Form>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    size="sm"
                    name="name"
                    value={values.name}
                    placeholder="Enter your name"
                    onChange={handleChange}
                    ref={inputRef}
                    isInvalid={touched.name && errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Company</Form.Label>
                  <Form.Control
                    size="sm"
                    name="company"
                    value={values.company}
                    placeholder="Enter your company"
                    onChange={handleChange}
                    isInvalid={touched.company && errors.company}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.company}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Job Title</Form.Label>
                  <Form.Control
                    size="sm"
                    name="jobTitle"
                    value={values.jobTitle}
                    placeholder="Enter your job title"
                    onChange={handleChange}
                    isInvalid={touched.jobTitle && errors.jobTitle}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.jobTitle}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    as="select"
                    size="sm"
                    name="country"
                    value={values.country}
                    placeholder="Enter your country"
                    onChange={handleChange}
                    isInvalid={errors.country}
                  >
                    {countryList.map((c) => (
                      <option key={c}>{c}</option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.country}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    size="sm"
                    type="email"
                    name="email"
                    value={values.email}
                    placeholder="Enter your email"
                    onChange={handleChange}
                    isInvalid={touched.email && errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    size="sm"
                    type="password"
                    name="password"
                    value={values.password}
                    placeholder="Enter your password"
                    onChange={handleChange}
                    isInvalid={touched.password && errors.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Confirm password</Form.Label>
                  <Form.Control
                    size="sm"
                    type="password"
                    name="password2"
                    value={values.password2}
                    placeholder="Confirm your password"
                    onChange={handleChange}
                    isInvalid={touched.password2 && errors.password2}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password2}
                  </Form.Control.Feedback>
                </Form.Group>

                <div className="text-center mt-3">
                  <Button color="primary" size="sm" onClick={handleSubmit}>
                    {isSigningUp && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        variant="light"
                        className="mr-2"
                      />
                    )}
                    Sign up
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </AuthLayout>
  );
};

const countryList = [
  'Afghanistan',
  'Åland Islands',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas (the)',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia (Plurinational State of)',
  'Bonaire, Sint Eustatius and Saba',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory (the)',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cayman Islands (the)',
  'Central African Republic (the)',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands (the)',
  'Colombia',
  'Comoros (the)',
  'Congo (the Democratic Republic of the)',
  'Congo (the)',
  'Cook Islands (the)',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Cyprus',
  'Czechia',
  "Côte d'Ivoire",
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic (the)',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Falkland Islands (the) [Malvinas]',
  'Faroe Islands (the)',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories (the)',
  'Gabon',
  'Gambia (the)',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard Island and McDonald Islands',
  'Holy See (the)',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran (Islamic Republic of)',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  "Korea (the Democratic People's Republic of)",
  'Korea (the Republic of)',
  'Kuwait',
  'Kyrgyzstan',
  "Lao People's Democratic Republic (the)",
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands (the)',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia (Federated States of)',
  'Moldova (the Republic of)',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands (the)',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger (the)',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands (the)',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine, State of',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines (the)',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Republic of North Macedonia',
  'Romania',
  'Russian Federation (the)',
  'Rwanda',
  'Réunion',
  'Saint Barthélemy',
  'Saint Helena, Ascension and Tristan da Cunha',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin (French part)',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten (Dutch part)',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan (the)',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan (Province of China)',
  'Tajikistan',
  'Tanzania, United Republic of',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands (the)',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates (the)',
  'United Kingdom of Great Britain and Northern Ireland (the)',
  'United States Minor Outlying Islands (the)',
  'United States of America (the)',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela (Bolivarian Republic of)',
  'Viet Nam',
  'Virgin Islands (British)',
  'Virgin Islands (U.S.)',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];

export default SignupPage;
