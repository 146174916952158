import { useEffect, useState } from 'react';

function ToggleActorsButton({ label, renderer, actors, icon }) {
  const [toggle, setToggle] = useState(true);

  useEffect(() => {
    if (renderer && !renderer.isDeleted() && actors) {
      if (toggle) {
        actors.forEach((actor) => renderer.addActor(actor));
      } else {
        actors.forEach((actor) => renderer.removeActor(actor));
      }
      renderer.getRenderWindow().render();
    }
  }, [toggle, actors, renderer]);

  return icon ? (
    <button
      className="ml-1 btn btn-secondary"
      title={label}
      onClick={() => setToggle((toggle) => !toggle)}
    >
      <img src={icon} alt={label} width={20} />
    </button>
  ) : (
    <button
      className="ml-1 btn btn-secondary"
      title={label}
      onClick={() => setToggle((toggle) => !toggle)}
    >
      {label}
    </button>
  );
}

export default ToggleActorsButton;
