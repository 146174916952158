import { QueryClient } from 'react-query';
import axios from 'axios';

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

//react-select custom theme
const reactSelectCustomTheme = (theme) => { 
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary: '#febb81',
      }
    }
}

const useHeaderConfiguration = ({contentType = 'multipart/form-data'} = {}) => ({
  headers: {
      'Content-Type': contentType,
      'X-CSRF-TOKEN': getCookie('csrf_token')
  }
})

function addEventAndGetActualDataFromBlob(blob, item) {
  return new Promise((resolve) => {
    item.addEventListener('loadend', (e) => {
      let response;
      try {
        const apiResponse = JSON.parse(e.srcElement.result)
        response = {
          data: window.URL.createObjectURL(blob),
          statusCode: apiResponse?.status_code,
          message: apiResponse?.msg
        }
      }
      catch (error) {
        response = {
          data: window.URL.createObjectURL(blob),
          statusCode: 200,
          message: "file downloaded successfully"
        }
      }
      resolve(response)
    });
  })
}

const executeApiCall = (requestType, apiEndPoint, data, config, downloadFile = false) => {
  let response = null
  try {
    if(downloadFile) {
      config = {responseType: 'blob', ...config}
    }

    switch(requestType) {
      case 'get':
        response = axios.get(apiEndPoint, config)
        break 
      case 'post':
        response = axios.post(apiEndPoint, data, config)
        break 
      case 'patch':
        response = axios.patch(apiEndPoint, data, config)
        break 
      case 'put':
        response = axios.put(apiEndPoint, data, config)
        break 
      case 'delete':
        response = axios.delete(apiEndPoint, {data: data, ...config })
        break 
    }

    return response.then(async res => {
      if(downloadFile) {
        let blob = new Blob([res.data], {type: res.data.type})
        const reader = new FileReader();
        // Start reading the blob as text.
        reader.readAsText(blob);
        return await addEventAndGetActualDataFromBlob(blob, reader)
      }
      return {
        data: res.data?.data || {},
        statusCode: res.data?.status_code,
        message: res.data?.msg
      }
    })
    .catch(error => ({
        statusCode: 500,
        message: error.message
    }))
  }
  catch (error) {
    return {
      statusCode: 500,
      message: error
    }
  }
}

const sortTableColumn = (rowA, rowB, columnId, desc) => {
  if(columnId === 'is_project_shared') {
    let sharedCount1 = rowA.original?.shared_with.length
    let sharedCount2 = rowB.original?.shared_with.length
    return sharedCount1 > sharedCount2 ? 1 : -1
  }

  let rowAValues = rowA.values[columnId]?.trim()
  let rowBValues = rowB.values[columnId]?.trim()

  let date1_milisec = rowA.original[columnId + "_milisec"]
  let date2_milisec = rowB.original[columnId + "_milisec"]
  
  if(date1_milisec && date2_milisec) {
    return new Date(date1_milisec) > new Date(date2_milisec) ? 1 : -1;
  }
  else if(['date', 'date_added'].includes(columnId)) {
      let dateSepartor = rowAValues.includes('-') ? '-' : '/'
      let rowDate1 = rowAValues.split(dateSepartor)
      let rowDate2 = rowBValues.split(dateSepartor)
      let date1 = new Date(rowDate1[2], rowDate1[0]-1, rowDate1[1])
      let date2 = new Date(rowDate2[2], rowDate2[0]-1, rowDate2[1])
      return date1 > date2 ? 1 : -1;
  }
  else {
      rowAValues = rowAValues ?? ''
      rowBValues = rowBValues ?? ''
      return typeof rowAValues === 'string' && typeof rowBValues === 'string' && rowAValues.toLowerCase() > rowBValues.toLowerCase() ? 1: -1;
  }
}

const removeRestBreadcrumbsOrAppendNew = (title, newBreadcrumbs, setBreadCrumbItmes, replaceWith = '', history) => {
  setBreadCrumbItmes(breadcrumbs => {
    let matchedIndex = -1
    breadcrumbs.every((breadcrumbitem, index) => {
      if (breadcrumbitem.title === title || breadcrumbitem.title === replaceWith) {
        matchedIndex = index
        return false
      }
      return true
    })

    if(replaceWith !== '' && matchedIndex !== -1) {
      breadcrumbs.splice(matchedIndex)
      matchedIndex = -1
    }

    if(matchedIndex === -1)
    {
      let breadcrumbsToBeAppend = []
      newBreadcrumbs.forEach(breadcrumb => {
        if (breadcrumb?.redirectTo) {
            breadcrumb['onClick'] = () => {
            history.push(breadcrumb.redirectTo)
          }
        }
        breadcrumbsToBeAppend.push(breadcrumb)
      })
      breadcrumbs = [...breadcrumbs, ...breadcrumbsToBeAppend]
    } 
    else {
      breadcrumbs.splice(matchedIndex + 1)
    }
    return [...breadcrumbs]
  })
}

const getActiveTabIndex = (tabs, tabLabel = undefined) => {
  let activeTabIndex = 0
  tabLabel && tabs.forEach((tab, index) => {
    if(tab.label === tabLabel) {
      activeTabIndex = index
    }
  })
  return activeTabIndex
}

const replaceConsecutiveSpacesBySingleSpace = (str) => {
  return str.replace(/[ ]{2,}/g, ' ')
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 5*(60*1000), // 5 mins
      cacheTime: 10*(60*1000), // 10 mins
    },
  },
});


export  { getCookie, reactSelectCustomTheme, useHeaderConfiguration, queryClient, executeApiCall, sortTableColumn, removeRestBreadcrumbsOrAppendNew, getActiveTabIndex, replaceConsecutiveSpacesBySingleSpace }