import { useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { UnitSystemContext } from '@tri/ui-shared';
import { SelectOptions } from './FormFields';
import { ToScientificValue } from '@tri/ui-shared';

export const SelectControl = ({
  name,
  options,
  onChange = () => {},
  ...rest
}) => {
  const { values, handleChange } = useFormikContext();
  const initialValue = _.get(values, name);

  return (
    <Form.Control
      as="select"
      name={name}
      onChange={(e) => {
        handleChange(e);
        onChange(e);
      }}
      value={initialValue}
      {...rest}
    >
      <SelectOptions options={options} />
    </Form.Control>
  );
};

export const TextControl = ({ name, initialValue, onBlur, ...rest }) => {
  const { values, errors, setFieldValue } = useFormikContext();
  const [value, setValue] = useState(
    initialValue === undefined ? _.get(values, name) : initialValue
  );

  useEffect(() => {
    if (name) setValue(_.get(values, name));
  }, [name, values]);

  useEffect(() => {
    if (!name) setValue(initialValue);
  }, [name, initialValue]);

  const err = _.get(errors, name);
  return (
    <>
      <Form.Control
        value={value}
        title={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={
          onBlur ||
          (() => {
            setFieldValue(name, value);
          })
        }
        isInvalid={err}
        {...rest}
      />
      <Form.Control.Feedback type="invalid">{err}</Form.Control.Feedback>
    </>
  );
};

export const QuantityControl = ({ name, qty, onBlur = () => {}, ...rest }) => {
  const { values, setFieldValue } = useFormikContext();
  const { displayUnitSystemId, convert } = useContext(UnitSystemContext);

  const initialValue = _.get(values, name);
  let displayValue = convert(qty, initialValue, 'SI', displayUnitSystemId);
  if (checkIfUnitTypeIsFloat(displayValue)) {
    displayValue = displayValue.toFixed(3);
  }
  if (displayValue) {
    displayValue = ToScientificValue(displayValue);
  }

  const convertToSIAndUpdateFormValue = (e) => {
    const displayValue = e.target.valueAsNumber;
    const storageValue = convert(qty, displayValue, displayUnitSystemId, 'SI');
    setFieldValue(name, storageValue);
  };

  return (
    <TextControl
      initialValue={displayValue}
      onBlur={(e) => {
        convertToSIAndUpdateFormValue(e);
        onBlur(e);
      }}
      type="number"
      className="w-100"
      {...rest}
    />
  );
};

export const Unit = ({ qty }) => {
  const { getDisplayUnit } = useContext(UnitSystemContext);
  const displayUnit = getDisplayUnit(qty);
  return <span>{displayUnit}</span>;
};

export const CheckboxControl = ({ name, onChange = () => {}, ...rest }) => {
  const { values, handleChange } = useFormikContext();
  const initialValue = _.get(values, name);
  return (
    <Form.Check
      custom
      type="switch"
      id={`cb-${name}`}
      name={name}
      checked={initialValue}
      onChange={(e) => {
        handleChange(e);
        onChange(e);
      }}
      {...rest}
    />
  );
};

export const checkIfUnitTypeIsFloat = (x) => {
  // check if the passed value is a number
  if (typeof x == 'number' && !isNaN(x) && x !== undefined) {
    // check if it is integer
    if (Number.isInteger(x)) {
      return false;
    } else {
      // x is float
      if (x <= 0.001) {
        return false;
      } else {
        return true;
      }
    }
  } else {
    return false;
  }
};
