// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Col, Form, Row } from 'react-bootstrap';
import { getCookie } from '../utils';
import { useContext } from 'react';
import ToastContext from './../context/index';
import { toast } from 'react-toastify';
import _ from 'lodash';
import MandatorySymbol from '../common/MandatorySymbol';
import { RBOffcanvas } from '../common/RBOffcanvas';

const requiredSchema = Yup.string().required('Required');
const validationSchema = Yup.object().shape({
  assetName: requiredSchema,
  location: requiredSchema,
  modelName: requiredSchema,
  scale: requiredSchema,
  vendor: requiredSchema, 
  stlFile: Yup.mixed().required('Required'),
});

const assetTypes = [
  {
    value: 't1',
    label: 'Type 1',
  },
  {
    value: 't2',
    label: 'Type 2',
  },
  {
    value: 't3',
    label: 'Type 3',
  },
];

const NewAssetDialog = ({ assetData, onHide, onApply, linkAsset = false }) => {
  const isEditMode = assetData !== null;
  const setToast = useContext(ToastContext)  

  const submitAssetData = async (values) => {

    let toastId = toast.loading(`${isEditMode ? 'Modifying' : 'Creating'} asset: ${values.assetName}`)

    let fd = new FormData();
    const data = {
      asset_name: values.assetName,
      mixit_tank: values.mixit_tank,
      geometry: {
        tank_diameter: 0,
        bottom_shape: '',
        liquid_level: 0,
        total_volume: 0,
        no_of_impeller: 1,
        no_of_baffles: 1,
        impeller_diameter: 0,
        location: '',
        manufacturer: '',
        model: '',
      },
      is_mixit_data: false,
      Additional_Image_File_Name: values.stlFile.name,
      Master_Calculation_File_Name: 'Blank file',
      model_name: values.modelName,
      type: values.type,
      location: values.location,
      scale: values.scale,
      vendor: values.vendor,
      simulation_path: values.simulation_path,
      comments: values.comments,
    };
    const finalData = JSON.stringify(data);
    fd.append('data', finalData);

    if (values.stlFile) fd.append('file', values.stlFile);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-CSRF-TOKEN': getCookie('csrf_token')
      },
    };

    let statusCode = 200, msg = `Asset ${values.assetName} is ${isEditMode ? 'edited' : 'created'} successfully`
    let newAssetData = {}

    try {
      if (isEditMode) {
        //await axios.put(`/api/v3/assets/${assetData._id}/`, fd, config);
        let response = await axios.patch(`/api/v3/assets/${assetData._id}/`, fd, config);
        statusCode = response.data?.status_code
        if(statusCode !== 200) {
          msg = response.data?.msg ?? `Asset ${values.assetName} is failed to edit'}`
        }
      } else {
        const asset_post_resp = await axios.post('/api/v3/assets/', fd, config)
        statusCode = asset_post_resp.data?.status_code

        if(statusCode === 200) {
          newAssetData = {
            label: values.assetName, 
            value: asset_post_resp.data?.data._id
          }
        }
        msg = asset_post_resp.data?.msg;
      }
    } catch (error) {
      statusCode = 500
      msg = error
    }

    setToast({
      id: toastId,
      statusCode: statusCode,
      message: msg
    }) 

    if(statusCode === 200) { 
      onApply(newAssetData) 
    }    
  };

  let title = 'Add New Asset';
  let initValues = { type: assetTypes[0].value };
  if (isEditMode) {
    title = 'Edit Asset';
    initValues.assetName = assetData.asset_name;
    initValues.modelName = assetData.model_name;
    if (assetData.type) initValues.type = assetData.type;
    initValues.location = assetData.location;
    initValues.scale = assetData.scale;
    initValues.vendor = assetData.vendor;
    initValues.simulation_path = assetData.simulation_path
    initValues.comments = assetData.comments;
    initValues.stlFile = { name: assetData.Additiona_Image_File_Name };
  }

  return (
    <Formik
      initialValues={initValues}
      validationSchema={validationSchema}
      onSubmit={submitAssetData}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ handleSubmit, values, setFieldValue, handleChange, errors, isSubmitting }) => (
        <RBOffcanvas 
          show={true} 
          title={linkAsset ? 'Link asset to project' : title} 
          onHide={onHide} 
          onOk={handleSubmit}
          isOkBtnDisabled={_.isEqual(values, initValues) || isSubmitting}
          okLabel={linkAsset ? 'Link' : 'Apply'}
        >
          <Form>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="3">
                Asset Name <MandatorySymbol />
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  name="assetName"
                  value={values.assetName}
                  onChange={handleChange}
                  isInvalid={errors.assetName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.assetName}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="3">
                Model Name <MandatorySymbol />
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  name="modelName"
                  value={values.modelName}
                  onChange={handleChange}
                  isInvalid={errors.modelName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.modelName}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="3">
                Type
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  as="select"
                  name="type"
                  value={values.type}
                  onChange={handleChange}
                >
                  {assetTypes.map(({ value, label }) => (
                    <option value={value}>{label}</option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="3">
                Location <MandatorySymbol />
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  name="location"
                  value={values.location}
                  onChange={handleChange}
                  isInvalid={errors.location}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.location}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="3">
                Scale <MandatorySymbol />
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  name="scale"
                  value={values.scale}
                  onChange={handleChange}
                  isInvalid={errors.scale}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.scale}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="3">
                Vendor <MandatorySymbol />
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  name="vendor"
                  value={values.vendor}
                  onChange={handleChange}
                  isInvalid={errors.vendor}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.vendor}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="3">
                Simulation Path
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  name="simulation_path"
                  value={values.simulation_path}
                  onChange={handleChange}
                  isInvalid={errors.simulation_path}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.simulation_path}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="3">
                Comments/Notes
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="comments"
                  value={values.comments}
                  onChange={handleChange}
                  isInvalid={errors.comments}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.comments}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="3">
                3D File {!isEditMode && <MandatorySymbol />}
              </Form.Label>
              <Col cm="9">
                <Form.Control
                  type="file"
                  custom
                  accept=".stl"
                  title={values.stlFile?.name || 'Select .STL File'}
                  onChange={(e) => {
                    setFieldValue('stlFile', e.currentTarget.files[0]);
                  }}
                  isInvalid={errors.stlFile}
                  feedback={errors.stlFile}
                />
              </Col>
            </Form.Group>
          </Form>
        </RBOffcanvas>
      )}
    </Formik>
  );
};

export default NewAssetDialog;
