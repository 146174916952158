import { useState, useMemo, useEffect } from 'react';
import { useGetSimTableColumnGroupDetails } from "../data-service/simulation.dataservice";

const useProjectGroupingDetailsData = (projectId, simCase) => {
    const [columnUnits, setColumnUnits] = useState()
    const columnGroupData = useGetSimTableColumnGroupDetails(projectId)
    const [ungroupColumns, setUngroupColumns] = useState([])
    const existingColumnGroups = useMemo(() => {
        if(columnGroupData.status === 'success') {
            return columnGroupData.data
        }
        return []
    }, [columnGroupData])

    useEffect(() => {
        let groupColumns = []
        let restColumns = []
        existingColumnGroups?.forEach((groupDetails) => {
            groupColumns.push(...groupDetails.column_names_list.split(','))
        })
        let allColumns = Object.keys(simCase?.original ?? {})
        allColumns.forEach(column => {
            if(!groupColumns.includes(column) && column !== '_id' && !column.includes("_milisec") ) {
                restColumns.push(column) 
            }
        })
        setUngroupColumns([...restColumns])
    }, [existingColumnGroups])

    useEffect(() => {
        let units = []
        simCase?.cells.forEach(cell => { 
            units[cell.column.id] = cell.column?.unit ?? '-' 
        })
        setColumnUnits(units)
    }, [])

    return { ungroupColumns, columnUnits, existingColumnGroups }
}

export { useProjectGroupingDetailsData }