// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { CenteredSpinner } from '@tri/ui-shared';
import axios from 'axios';
import { useState } from 'react';
import { Container, Nav } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { getCookie } from '../utils';
import AppsOrScripts from './asset-view/AppsOrScripts';
import FlowPatterns from './asset-view/FlowPatterns';
import Geometry from './asset-view/Geometry';
import ProcessMetrics from './asset-view/ProcessMetrics';
import SimulationCases from './asset-view/SimulationCases';

const ViewAsset = ({ assetData, onProcessParamsChange }) => {
  const [activeSubViewId, setActiveSubViewId] = useState('geometry');
  const config = {
    headers: { 
      'X-CSRF-TOKEN': getCookie('csrf_token')
    },
  };

  var params = new URLSearchParams();
  for (var i in assetData.mixit_tank) {
    params.append('tank_ids', assetData.mixit_tank[i]);
  }
  params.append('asset_group', true);
  params.append('assets_id', assetData._id);

  const assetDetailsQuery = useQuery(`assetDetails-${assetData._id}`, async () => {
    const { data } = await axios.get(
      `/api/v3/asset-details/${assetData._id}/`,
      config,
      {
        params,
      }
    );
    return { data: data };
  });
  return (
    <Container fluid>
      {assetDetailsQuery.status === 'loading' && <CenteredSpinner />}
      {assetDetailsQuery.status === 'error' && (
        <h5>Something went wrong ...</h5>
      )}
      {assetDetailsQuery.status === 'success' && (
        <>
          <Nav
            variant="pills"
            activeKey={activeSubViewId}
            onSelect={setActiveSubViewId}
            className="position-relative flex-grow-0 flex-shrink-0"
            style={{ zIndex: 100 }}
          >
            <Nav.Item>
              <Nav.Link eventKey="geometry">Geometry</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="simulationCases">Simulation Cases</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="processMetrics">Process Metrics</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="flowPatterns">Flow Patterns</Nav.Link>
            </Nav.Item>
          </Nav>

          <div
            className="mt-3"
            style={{ height: `calc(100vh - 200px)`, overflow: 'auto' }}
          >
            {activeSubViewId === 'geometry' && (
              <Geometry
                geometryData={assetDetailsQuery.data.data.geometry_data}
                stlPath={assetDetailsQuery.data.data.additional_image_path}
                selectedAssetId={assetData._id}
                processParams={assetData.geometry}
                onProcessParamsChange={onProcessParamsChange}
              />
            )}
            {activeSubViewId === 'simulationCases' && (
              <SimulationCases
                selectedAssetData={assetData}
                cfdSimData={assetDetailsQuery.data.data.cfd_sim_data}
              />
            )}
            {activeSubViewId === 'processMetrics' && (
              <ProcessMetrics
                processMetricsData={assetDetailsQuery.data.data.process_metrics}
                processMetricsHeaderData={
                  assetDetailsQuery.data.data.project_name_data
                }
              />
            )}
            {activeSubViewId === 'flowPatterns' && (
              <FlowPatterns
                selectedAssetData={assetData}
                flowPatternsData={assetDetailsQuery.data.data.flow_patterns}
              />
            )}
            {activeSubViewId === 'appsScripts' && (
              <AppsOrScripts
                selectedAssetId={assetData._id}
                geometryId={assetData.geometry._id}
              />
            )}
          </div>
        </>
      )}
    </Container>
  );
};

export default ViewAsset;
