import { Alert } from 'react-bootstrap';
import CenteredSpinner from './CenteredSpinner';

const Query = ({ state, children }) => {
  const { isLoading, isError, error } = state;
  if (isError)
    return <Alert variant="dark">Error: {error.response.data}</Alert>;
  else if (isLoading) return <CenteredSpinner />;
  else return children;
};

export default Query;
