import { useMemo, useState } from 'react';
import { useContext } from 'react';
import ToastContext from '../../../context';
import { useGetSimcaseImageURL, useSaveImageData, useSimcaseImagesList } from '../data-service/images.dataservice';
import { useQueryClient } from 'react-query';

const GetSimcaseImage = (simCaseId, fileId) => useGetSimcaseImageURL(simCaseId, fileId).data

const useSimCaseImagesData = (simcaseId) => {
    const setToast = useContext(ToastContext)
    const queryClient = useQueryClient()
    const simCaseImagesData = useSimcaseImagesList([simcaseId])
    const [isGettingImages, setIsGettingImages] = useState(true)
    const [images, setImages] = useState([])
    const [imageModal, setImageModal] = useState({
        show: false,
        title: "",
        url: ""
    })

    useMemo(() => {
        if(simCaseImagesData.status === 'success') {
            let allSimCaseImagesData = simCaseImagesData.data || {}
            // eslint-disable-next-line no-prototype-builtins
            let simcaseImageData = allSimCaseImagesData.hasOwnProperty(simcaseId) ? allSimCaseImagesData[simcaseId] : []
            let images = []
            simcaseImageData[1]?.file_info.forEach((image, index) => images.push({...image, isDescDisabled: true, isTitleDisabled: true, isButtonDisabled: true }))
            setImages(images)
            setIsGettingImages(false)
        }
        return []
    }, [simCaseImagesData.status])

    const handleDataChange = (e, id, type = 'filename') => {
        setImages((images) => 
            images.map((image, index) => {
                if (image.id === id) {
                    if(type === 'description') {
                        return { ...image, description: e.target.value, isButtonDisabled: false }
                    } 
                    else {
                        return { ...image, filename: e.target.value, isButtonDisabled: false }
                    }
                }
                return image
            })      
        )
    }

    const handleIsDisabled = (id) => {
        setImages((images) => 
            images.map((image, index) => {
                if (image.id === id) {
                    return { ...image, isDescDisabled: !image.isDescDisabled, isTitleDisabled: !image.isTitleDisabled }
                }
                return image
            })      
        )
    }

    const SaveImageData = async (id) => {
        let image = images.find(image => image.id === id)

        let allSimCaseImagesData = simCaseImagesData.data || {}
        // eslint-disable-next-line no-prototype-builtins
        let simcaseImageData = allSimCaseImagesData.hasOwnProperty(simcaseId) ? allSimCaseImagesData[simcaseId] : []
        let origianlImage = simcaseImageData[1]?.file_info.find(image => image.id === id)
        if(origianlImage.description === image.description && origianlImage.filename === image.filename) {
            return
        } 

        setImages((images) => 
            images.map((image, index) => {
                if (image.id === id) {
                    return { ...image, isButtonDisabled: true }
                }
                return image
            })      
        )

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const saveImageDataResponse = await useSaveImageData(simcaseId, image)
        if([200, 201].includes(saveImageDataResponse.statusCode)) {
            queryClient.invalidateQueries(`simcaseImages${simcaseId}`)
            handleIsDisabled(id)
            return;
        }
        setToast({
            statusCode: saveImageDataResponse.statusCode,
            message: saveImageDataResponse.message
        })
    } 

    return { imageModal, setImageModal, isGettingImages, images, handleDataChange, handleIsDisabled, SaveImageData }
}

export { GetSimcaseImage, useSimCaseImagesData }