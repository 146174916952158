import { Row, Col } from 'react-bootstrap';
import { pillTabs } from '../../utils/constants';
import { useSimCaseAnimationsData } from '../services/business-logic/animations.businesslogic';
import { ImageCardView, ImageModalView } from '../../common/ImageCardView';
import loadingGif from '../../../assets/loading.gif'

const AnimationsPanel = ({simcaseId}) => {

  const { animationModal, setAnimationModal, isGettingAnimations, animations, SaveAnimationData, handleDataChange, handleIsDisabled } = useSimCaseAnimationsData(simcaseId)

  return isGettingAnimations ? <center className="mt-5"><img alt="Loading..." src={loadingGif} height="50px"/></center> :
  <Row xs={1} md={4} className="g-4">
    {
      animations?.length > 0 ? animations.map((animation, index) => <Col id={index}>
            <ImageCardView image={animation} simCaseId={simcaseId} handleDataChange={handleDataChange} handleIsDisabled={handleIsDisabled} SaveImageData={SaveAnimationData} setImageModal={setAnimationModal} type="animation" />
        </Col>
      ) : <Col><h5> Animations not Found</h5></Col>
    }
    <ImageModalView imageModal={animationModal} setImageModal={setAnimationModal} />
  </Row>
}
  
const Animations = (simcaseId) =>  ({
  label: pillTabs.Animations,
  Panel: <AnimationsPanel simcaseId={simcaseId}/>
})
  
export { Animations }  