import { useHeaderConfiguration } from "../../../utils";
import { useQuery } from 'react-query';
import axios from 'axios';

//get list of all assets
const useAssetList = () => {
    const config = useHeaderConfiguration();
    return useQuery('allAssetsList', async () => {
        const { data } = await axios.get(
            '/api/v3/assets/lists/names/',
            config
        );
        return data
    })
}

//get asset details
const useGetAllAssetDetails = () => {
    const config = useHeaderConfiguration();
    return useQuery('allAssetDetails', async () => {
        const { data } = await axios.get(
            '/api/v3/assets/',
            config
        );
        return data
    })
}


const useGetAssetDetails = (assetId) => {
    const config = useHeaderConfiguration();
    return useQuery(['asset-details', assetId], async () => {
        const { data } = await axios.get(`/api/v3/assets/${assetId}/`, config);
        return data;
    })   
}

export { useAssetList, useGetAllAssetDetails, useGetAssetDetails }