import { Fragment } from 'react';
import { Jumbotron } from 'react-bootstrap';
import logo from './assets/mixit-logo.png';
import landingPageImg from './assets/landingPage-image.jpg';

const AuthLayout = ({ children }) => (
  <Fragment>
    <div className="vh-100">
      <nav
        className="bg-primary w-100 d-flex align-items-center"
        style={{ height: '6.5%' }}
      >
        <img src={logo} alt="" className="ml-3" />
      </nav>
      <Jumbotron style={{ backgroundColor: 'white', height: '93.5%' }}>
        <div className="h-100 d-flex align-items-center justify-content-between">
          <div className="p-5">
            <img src={landingPageImg} alt="" width="800" />
          </div>
          <div className="w-100 d-flex flex-column align-items-center py-3">
            {children}
          </div>
        </div>
      </Jumbotron>
    </div>
  </Fragment>
);

export default AuthLayout;
