import { useEffect } from 'react';
import { Card, Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { HorizontalFormField } from '../common/FormFields';
import {
  QuantityControl,
  CheckboxControl,
  SelectControl,
  Unit,
} from '../common/FormControls';
import { Table, TableHeader, TextCell, QuantityCell } from '../common/Table';

const jacketOptions = ['Conventional', 'Limpet 120', 'Limpet 180'];

const Jacket = ({ location, disabled }) => {
  const { values } = useFormikContext();

  const path = `config.internals.${location}Jacket`;
  const jacketData = _.get(values, path);
  const enabled = jacketData.enabled;

  const layoutProps = { w1: 4, w2: 6, rowClass: 'mb-1' };
  return (
    <>
      <p>
        <CheckboxControl
          name={`${path}.enabled`}
          label={`Enable ${location} jacket`}
          disabled={disabled}
        />
      </p>

      {enabled && (
        <div className="width-24">
          <HorizontalFormField label="Type" {...layoutProps}>
            <SelectControl
              name={`${path}.type`}
              options={jacketOptions}
              disabled={disabled}
            />
          </HorizontalFormField>

          <HorizontalFormField label="Diameter" {...layoutProps}>
            <QuantityControl
              name={`${path}.diameter`}
              qty="length"
              disabled={disabled}
            />
            <Unit qty="length" />
          </HorizontalFormField>

          <HorizontalFormField label="Pipe ID" {...layoutProps}>
            <QuantityControl
              name={`${path}.pipeID`}
              qty="length"
              disabled={disabled || jacketData.type === 'Conventional'}
            />
            <Unit qty="length" />
          </HorizontalFormField>

          <HorizontalFormField label="Pitch" {...layoutProps}>
            <QuantityControl
              name={`${path}.pitch`}
              qty="length"
              disabled={disabled || jacketData.type === 'Conventional'}
            />
            <Unit qty="length" />
          </HorizontalFormField>

          <HorizontalFormField label="No. of Turns" {...layoutProps}>
            <QuantityControl
              name={`${path}.numberOfTurns`}
              disabled={disabled || jacketData.type === 'Conventional'}
            />
          </HorizontalFormField>
        </div>
      )}
    </>
  );
};

const InternalsPanel = ({
  data,
  disabled,
  setFieldValue,
  onGeometryChange = () => {},
}) => {
  const internalsData = data.config.internals;
  const helicalCoilData = internalsData.helicalCoils || [];
  const ringSpargerData = internalsData.ringSpargers || [];
  const dipTubeData = internalsData.dipTubes || [];
  const helicalCoilCount = helicalCoilData.length;
  const ringSpargerCount = ringSpargerData.length;
  const dipTubeCount = dipTubeData.length;

  useEffect(() => {
    onGeometryChange(data);
  }, [data, helicalCoilData, ringSpargerData, dipTubeData]);

  const helicalCoilsTableColumns = [
    {
      title: 'Sr. No.',
      accessor: 'id',
      dataType: 'number',
      disabled: true,
      style: {
        width: '5%',
      },
    },
    {
      title: 'Coil Diameter',
      accessor: 'coilDiameter',
      qty: 'length',
      dataType: 'number',
      style: {
        width: '15%',
      },
    },
    {
      title: 'Coil Pitch',
      accessor: 'coilPitch',
      qty: 'length',
      dataType: 'number',
      style: {
        width: '15%',
      },
    },
    {
      title: 'No. of Turns',
      accessor: 'numberOfTurns',
      dataType: 'number',
      style: {
        width: '15%',
      },
    },
    {
      title: 'Tube ID',
      accessor: 'tubeID',
      qty: 'length',
      dataType: 'number',
      style: {
        width: '15%',
      },
    },
    {
      title: 'Tube OD',
      accessor: 'tubeOD',
      qty: 'length',
      dataType: 'number',
      style: {
        width: '15%',
      },
    },
    {
      title: 'Bottom Clearance',
      accessor: 'bottomClearance',
      qty: 'length',
      dataType: 'number',
      style: {
        width: '20%',
      },
    },
  ];

  const spargerTableColumns = [
    {
      title: 'Sr. No.',
      accessor: 'id',
      dataType: 'number',
      disabled: true,
      style: {
        width: '10%',
      },
    },
    {
      title: 'Sparger Diameter',
      accessor: 'spargerDiameter',
      qty: 'length',
      dataType: 'number',
      style: {
        width: '30%',
      },
    },
    {
      title: 'Tube Diameter',
      accessor: 'tubeDiameter',
      qty: 'length',
      dataType: 'number',
      style: {
        width: '30%',
      },
    },
    {
      title: 'Bottom Clearance',
      accessor: 'bottomClearance',
      qty: 'length',
      dataType: 'number',
      style: {
        width: '30%',
      },
    },
  ];

  const dipTubeTableColumns = [
    {
      title: 'Sr. No.',
      accessor: 'id',
      dataType: 'number',
      disabled: true,
      style: {
        width: '8%',
      },
    },
    {
      title: 'Name',
      accessor: 'name',
      dataType: 'string',
      Cell: TextCell,
      style: {
        width: '12%',
      },
    },
    {
      title: 'Tube Diameter',
      accessor: 'tubeDiameter',
      qty: 'length',
      dataType: 'number',
      style: {
        width: '8%',
      },
    },
    {
      title: 'Length-1',
      accessor: 'length1',
      qty: 'length',
      dataType: 'number',
      style: {
        width: '8%',
      },
    },
    {
      title: 'Angle-1',
      accessor: 'angle1',
      qty: 'angle',
      dataType: 'number',
      style: {
        width: '8%',
      },
    },
    {
      title: 'Length-2',
      accessor: 'length2',
      qty: 'length',
      dataType: 'number',
      style: {
        width: '8%',
      },
    },
    {
      title: 'Distance From Axis X',
      accessor: 'distanceFromAxisX',
      qty: 'length',
      dataType: 'number',
      style: {
        width: '8%',
      },
    },
    {
      title: 'Distance From Axis Y',
      accessor: 'distanceFromAxisY',
      qty: 'length',
      dataType: 'number',
      style: {
        width: '8%',
      },
    },
    {
      title: 'Distance From Bottom',
      accessor: 'distanceFromBottom',
      qty: 'length',
      dataType: 'number',
      style: {
        width: '8%',
      },
    },
    {
      title: 'Angle-2',
      accessor: 'angle2',
      qty: 'angle',
      dataType: 'number',
      style: {
        width: '8%',
      },
    },
    {
      title: 'Angle-3',
      accessor: 'angle3',
      qty: 'angle',
      dataType: 'number',
      style: {
        width: '8%',
      },
    },
    {
      title: 'Angle-4',
      accessor: 'angle4',
      qty: 'angle',
      dataType: 'number',
      style: {
        width: '8%',
      },
    },
  ];

  const layoutProps = { w1: 4, w2: 6, rowClass: 'mb-1' };
  return (
    <div>
      <Card>
        <Card.Body>
          <Card.Title className="h6">Jackets</Card.Title>
          <div className="row">
            <div className="col-sm">
              <Jacket location="side" disabled={disabled} />
            </div>

            <div className="col-sm">
              <Jacket location="bottom" disabled={disabled} />
            </div>
          </div>
        </Card.Body>
      </Card>

      <Card className="mt-3">
        <Card.Body>
          <Card.Title className="h6">Helical Coils</Card.Title>
          <div className="width-24">
            <HorizontalFormField label="Number" {...layoutProps}>
              <Form.Control
                type="number"
                value={helicalCoilCount}
                disabled={disabled}
                onChange={(e) => {
                  const newValue = e.target.value;
                  const noOfRows = helicalCoilData.length;
                  const addedRowCount = newValue - noOfRows;
                  if (addedRowCount > 0) {
                    for (let i = 0; i < addedRowCount; i++) {
                      helicalCoilData.push({
                        id: noOfRows + i + 1,
                        coilDiameter: 0.75,
                        coilPitch: 0.05,
                        numberOfTurns: 15,
                        tubeID: 0.0254,
                        tubeOD: 0.03,
                        bottomClearance: 0.3,
                      });
                    }
                    setFieldValue(
                      'config.internals.helicalCoils',
                      [...helicalCoilData] // new array to update 3D model
                    );
                  } else {
                    const updatedData = helicalCoilData.slice(0, addedRowCount);
                    setFieldValue('config.internals.helicalCoils', updatedData);
                  }
                }}
              />
            </HorizontalFormField>
          </div>
          {helicalCoilCount > 0 && (
            <Table
              name="config.internals.helicalCoils"
              columns={helicalCoilsTableColumns}
              data={helicalCoilData}
              defaultColumn={{
                Header: TableHeader,
                Cell: QuantityCell,
                disabled: disabled,
              }}
              tableClass="table-borderless"
            />
          )}
        </Card.Body>
      </Card>

      <Card className="mt-3">
        <Card.Body>
          <Card.Title className="h6">Ring Spargers</Card.Title>
          <p className="text-muted mt-3"></p>
          <div className="width-24">
            <HorizontalFormField label="Number" {...layoutProps}>
              <Form.Control
                type="number"
                w1={5}
                value={ringSpargerCount}
                disabled={disabled}
                onChange={(e) => {
                  const newValue = e.target.value;
                  const noOfRows = ringSpargerData.length;
                  const addedRowCount = newValue - noOfRows;
                  if (addedRowCount > 0) {
                    for (let i = 0; i < addedRowCount; i++) {
                      ringSpargerData.push({
                        id: noOfRows + i + 1,
                        spargerDiameter: 0.36,
                        tubeDiameter: 0.0254,
                        bottomClearance: 0.1,
                      });
                    }
                    setFieldValue(
                      'config.internals.ringSpargers',
                      [...ringSpargerData] // new array to update 3D model
                    );
                  } else {
                    const updatedData = ringSpargerData.slice(0, addedRowCount);
                    setFieldValue('config.internals.ringSpargers', updatedData);
                  }
                }}
              />
            </HorizontalFormField>
          </div>
          {ringSpargerCount > 0 && (
            <div className="w-60">
              <Table
                name="config.internals.ringSpargers"
                columns={spargerTableColumns}
                data={ringSpargerData}
                defaultColumn={{
                  Header: TableHeader,
                  Cell: QuantityCell,
                  disabled: disabled,
                }}
                tableClass="table-borderless"
              />
            </div>
          )}
        </Card.Body>
      </Card>

      <Card className="mt-3">
        <Card.Body>
          <Card.Title className="h6">Dip Tubes / Inlets / Outlets</Card.Title>
          <div className="width-24">
            <HorizontalFormField label="Number" {...layoutProps}>
              <Form.Control
                type="number"
                value={dipTubeCount}
                disabled={disabled}
                onChange={(e) => {
                  const newValue = e.target.value;
                  const noOfRows = dipTubeData.length;
                  const addedRowCount = newValue - noOfRows;
                  if (addedRowCount > 0) {
                    for (let i = 0; i < addedRowCount; i++) {
                      dipTubeData.push({
                        id: noOfRows + i + 1,
                        name: 'DipTube-' + parseInt(noOfRows + i + 1),
                        tubeDiameter: 0.05,
                        length1: 1,
                        length2: 0,
                        distanceFromAxisX: 0.3,
                        distanceFromAxisY: 0.3,
                        distanceFromBottom: 0.25,
                        angle1: 0,
                        angle2: 0,
                        angle3: 0,
                        angle4: 0,
                      });
                    }
                    setFieldValue('config.internals.dipTubes', [
                      ...dipTubeData,
                    ]); // new array to update 3D model
                  } else {
                    const updatedData = dipTubeData.slice(0, addedRowCount);
                    setFieldValue('config.internals.dipTubes', updatedData);
                  }
                }}
              />
            </HorizontalFormField>
            {dipTubeCount > 0 && (
              <Table
                name="config.internals.dipTubes"
                columns={dipTubeTableColumns}
                data={dipTubeData}
                defaultColumn={{
                  Header: TableHeader,
                  Cell: QuantityCell,
                  disabled: disabled,
                }}
                tableClass="table-borderless"
              />
            )}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

const Internals = {
  id: 'internals',
  label: 'Internals',
  container: 'config',
  Renderer: InternalsPanel,
  tabEnabled: (activeNodeType) =>
    activeNodeType === 'config' || activeNodeType === 'process',
};

export default Internals;
