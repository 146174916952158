import { executeApiCall, useHeaderConfiguration } from "../../../utils";
import { useQuery } from 'react-query';
import axios from 'axios';

//Get project details
const useGetProjectDetails = (projectId) => {
    const config = useHeaderConfiguration();
    // return await executeApiCall('get', `api/v3/project-details/${projectId}/`, {} ,config)
    return useQuery(`projectDetail_${projectId}`, async () => {
        const { data } = await axios.get(`/api/v3/project-details/${projectId}/`, config);
        return data;
    })
}

//Get sim table column groups details
const useGetSimTableColumnGroupDetails = (projectId) => {
    const config = useHeaderConfiguration();
    return useQuery(`simTableGroups_${projectId}`, async () => {
        const { data } = await executeApiCall('get', `/api/v3/project-details/groups/${projectId}/`, {}, config)
        return data
    })
}

//Delete project sim case groups 
const useDeleteSimCasesColumnGroup = (projectId, selectedSimCasesGroupIds) => {
    const config = useHeaderConfiguration({contentType: 'application/json'});
    let bodyData = {
        "selected_group_ids":  selectedSimCasesGroupIds
    }
    return executeApiCall('delete', `/api/v3/project-details/groups/`, bodyData, config)
}

//Delete project cases 
const useDeleteSelectedProjectCases = (projectId, selectedProjectSimCasesIds) => {
    const config = useHeaderConfiguration({contentType: 'application/json'});
    let bodyData = {
        "case_id_lists": selectedProjectSimCasesIds
    }
    return executeApiCall('delete', `/api/v3/project-details/${projectId}/`, bodyData, config)
}

//Update project case cell
const useUpdateProjectCaseCell = (edited_sim_data) => {
    const config = useHeaderConfiguration({contentType: 'application/json'})
    let bodyData = {
        "edited_sim_data": edited_sim_data
    }
    return executeApiCall('patch', 'api/v3/project-details/', bodyData, config)
}

//Create a group in sim-table
const useCreateGroupInSimDataTable = (projectId, values) => {
    const config = useHeaderConfiguration({contentType: 'application/json'})
    let formData = new FormData();
    formData.append('group_name', values.groupName)
    formData.append('color_label', values.fillColor)
    formData.append('selected_columns', values.selectedColumns)
    formData.append('mapped_project_id', projectId)
    return executeApiCall('post', `api/v3/project-details/groups/`, formData, config)
}

const useEditColumnGroupFromSimDataTable = (projectId, group_id, values) => {
    const config = useHeaderConfiguration()
    let formData = new FormData();
    formData.append('group_id', group_id)
    formData.append('group_name', values.groupName)
    formData.append('color_label', values.fillColor)
    formData.append('selected_columns', values.selectedColumns)
    formData.append('mapped_project_id', projectId)

    return executeApiCall('patch', `/api/v3/project-details/groups/`, formData, config)
}

//Add multiple rows to sim-table
const useAddNewSimcasesToSimDataTable = (projectData, values) => {
    const config = useHeaderConfiguration()
    let formData = new FormData();
    formData.append('project_name', projectData.project_name);
    formData.append('Mapped_Asset', projectData.mapped_asset);
    formData.append('shared_with', JSON.stringify(projectData.shared_with));
    formData.append('comments', projectData.comments);
    values.inputFile && formData.append('input_data_files', values.inputFile);
    formData.append('overwrite_data', false);

    return executeApiCall('patch', `api/v3/projects/${projectData._id}/`, formData, config)
}

//Add a New single column to table
const useAddNewColumnHeaderToSimDataTable = (projectId, values) => {
    const config = useHeaderConfiguration()
    let formData = new FormData();
    formData.append('project_id', projectId);
    formData.append('column_name', values.columnName);
    formData.append('column_type', values.columnType)
    formData.append('column_unit', values.columnUnit)

    if(values.columnType?.toLowerCase() === 'expression') {
        formData.append('formula', values.formula)
    }

    return executeApiCall('patch', `/api/v3/project-details/`, formData, config)
}

//Add a New multiple columns to table
const useAddMultipleColumnsToSimDataTable = (projectId, values) => {
    const config = useHeaderConfiguration()
    let formData = new FormData();
    formData.append('project_id', projectId)
    values.inputFile && formData.append('column_data_file', values.inputFile);
    return executeApiCall('patch', `/api/v3/project-details/`, formData, config)
}

//Delete columns from sim data table 
const useDeleteColumnFromSimDataTable = (projectId, selectedColumns, groupIdAlongWithColumns) => {
    const config = useHeaderConfiguration({contentType: 'application/json'});
    let bodyData = {
        "delete_sim_data": {
            "project_id": projectId,
            "column_names": selectedColumns,
            "column_names_tobe_deleted_from_group": groupIdAlongWithColumns
        }
    }

    return executeApiCall('patch', `/api/v3/project-details/`, bodyData, config)
}

const useEditRowFromSimDataTable = (values, selectedSimCasesId) => {
    const config = useHeaderConfiguration()
    let formData = new FormData();
    values.inputFile && formData.append('row_data_file', values.inputFile)
    formData.append('case_ids', selectedSimCasesId)

    return executeApiCall('patch', `/api/v3/project-details/`, formData, config)
}

const useEditColumnHeaderFromSimDataTable = (projectId, oldColumnName, group_id, values) => {
    const config = useHeaderConfiguration()
    let formData = new FormData();
    formData.append('project_id', projectId);
    formData.append('column_name', values.columnName);
    formData.append('column_type', values.columnType)
    formData.append('column_unit', values.columnUnit);
    if(values.columnType?.toLowerCase() === 'expression') {
        formData.append('formula', values.formula)
    }
    formData.append('old_column_name', oldColumnName);
    formData.append('group_id', group_id);

    return executeApiCall('patch', `/api/v3/project-details/`, formData, config)
}

const useEditColumnsDataFromSimDataTable = (projectId, values, groupIdAlongWithColumns) => {
    const config = useHeaderConfiguration()
    let formData = new FormData();
    formData.append('project_id', projectId);
    formData.append('old_column_names', values.selectedColumns);
    formData.append('column_names_tobe_deleted_from_group', JSON.stringify(groupIdAlongWithColumns));
    values.inputFile && formData.append('column_data_file', values.inputFile);

    return executeApiCall('patch', `/api/v3/project-details/`, formData, config)
}


const useExportSimTableData = (projectId, selectedProjectSimCases, values) => {
    const config = useHeaderConfiguration({contentType: 'application/json'});
    
    let exportSimCaseIds = []
    let exportSimCaseColumns = values?.selectedColumnsForExport

    selectedProjectSimCases.forEach(simcase => {
        simcase.values._id && exportSimCaseIds.push(simcase.values._id)
    })

    let bodyData = {
        "selected_project_id": projectId,
        "exported_sim_case_ids": exportSimCaseIds,
        "exported_column_names": exportSimCaseColumns,
        "exported_filename": values.fileName,
        "selected_file_extension": `.${values.exportFileType ?? 'csv'}`
    }

    return executeApiCall('post', `/api/v3/project-details/export/`, bodyData, config, true)
}

export { useGetProjectDetails, useDeleteSelectedProjectCases, useUpdateProjectCaseCell, useAddNewSimcasesToSimDataTable, useEditColumnsDataFromSimDataTable, useDeleteColumnFromSimDataTable, useAddNewColumnHeaderToSimDataTable, useAddMultipleColumnsToSimDataTable, useEditRowFromSimDataTable, useEditColumnHeaderFromSimDataTable, useCreateGroupInSimDataTable, useGetSimTableColumnGroupDetails, useDeleteSimCasesColumnGroup, useEditColumnGroupFromSimDataTable, useExportSimTableData }
