import { useQuery } from "react-query";
import { executeApiCall, useHeaderConfiguration } from "../../../utils"

const useGetGeometryURL = (simCaseId) => {
    if(!simCaseId) {
        return {
            data: ''
        }
    }
    
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const config = useHeaderConfiguration();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useQuery(`simcaseGeomtry${simCaseId}`, async () => {
        const res = await executeApiCall('get', `/api/v3/projects/sim_cases/results/geometry/${simCaseId}/`, {}, config, true)
        if(res?.statusCode !== 200) {
            return ''
        }
        return res.data        
    })
}
export { useGetGeometryURL }