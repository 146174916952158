import { useContext } from 'react'
import { UserContext } from '@tri/ui-shared';
import { Table } from '../../common/SimsightTable';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import CenteredSpinner from '../../../../../../libs/ui-shared/src/lib/CenteredSpinner';
import { useSharedProjectDetailsData } from '../services/business-logic/project.businesslogic';
import { pillTabs } from '../../utils/constants';
import { AddOrUpdateSimulationProject } from './AddOrUpdateSimulationProject';

function SharedProjectPanel() {
  const { user } = useContext(UserContext);
  const { selectedProject, setSelectedProject, projectList, allSharedProjectTableData, listOfAllProjectTableColumn, onClickOnProject } = useSharedProjectDetailsData(user)

  return (
    <div className="px-2">
      <AddOrUpdateSimulationProject user={user} selectedProject={selectedProject} isSharedProject={true} />
      {projectList.status === 'loading' && <CenteredSpinner />}
      {projectList.status === 'error' && <h5>Something went wrong ...</h5>}
      {projectList.status === 'success' && (allSharedProjectTableData?.length > 0 ?
        <Table
          data={allSharedProjectTableData}
          columns={listOfAllProjectTableColumn}
          initialState={{ hiddenColumns: ['_id'] }}
          selectionEnabled={!user.is_staff}
          onSelect={setSelectedProject}
          onClickLink={onClickOnProject}
          tableClass={`table skip-table-scroll skip-table-scroll-col1 ${user.is_staff ? '' : 'skip-table-scroll-col2'} `}
          searchBoxPlaceholder="Search in Project Table"
          disableSortBy={false}
        /> :  <h5>No data found...</h5>)
      }
    </div>
  )
}

const SharedProjects = () => {
  return {
    label: pillTabs.SharedProjects,
    Panel: <SharedProjectPanel/>,
    tabEnabled: false
  }
}

export { SharedProjects }
