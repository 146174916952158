import { useHistory } from 'react-router-dom';
import SimDataList from '../sim-data-explorer/SimDataList';
import SimData from './SimData';
import { useEffect, useContext } from 'react';
import { BreadcrumbContext } from '../context';
import { removeRestBreadcrumbsOrAppendNew } from '../utils';
import { pillTabs } from '../utils/constants';

const SimDataExplorerPanel = ({ activeSubView, activeSubSubView, type }) => {
  
  const { isBreadcrumbsFromCache, setBreadCrumbItmes } = useContext(BreadcrumbContext)
  const history = useHistory();
  
  //creating breadcrumbs
  useEffect(() => {
    !isBreadcrumbsFromCache && removeRestBreadcrumbsOrAppendNew(pillTabs.SimulationDetails, [{ 
      title: "Simulation Explorer",
      redirectTo: `/app/simulationData/?type=${pillTabs.MyProjects}`
    }], setBreadCrumbItmes, '', history)
  }, [])

  return (
    <div>
      {
        activeSubView ? 
          <SimData 
            simDataId={activeSubView} 
            activeSubSubView={activeSubSubView}
            projectActiveTabName={type}
          /> : 
          <SimDataList projectType={type} />
      }
    </div>
  );
};

export default SimDataExplorerPanel;
