// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { StlViewer, UserContext, ToScientificValue } from '@tri/ui-shared';
import axios from 'axios';
import { Formik } from 'formik';
import { useContext } from 'react';
import { Button, Card, Form, Table } from 'react-bootstrap';
import STLViewerSkeleton from '../../common/STLViewerSkeleton';
import { GetAssetStlUrl } from '../../sim-data-explorer/services/business-logic/projectasset.businesslogic';
import { getCookie } from '../../utils';
import * as Yup from 'yup';
import ToastContext from './../../context/index';
import _ from 'lodash';

const NumberControl = ({ name, ...rest }) => <>
  <Form.Control
    type="number"
    min={0}
    name={name}
    {...rest}
  />
  <Form.Control.Feedback type="invalid">
      {rest.isInvalid}
  </Form.Control.Feedback>
</>

const Geometry = ({
  geometryData,
  stlPath,
  processParams,
  onProcessParamsChange,
  selectedAssetId,
}) => {
  const { user } = useContext(UserContext);
  const setToast = useContext(ToastContext)
  const hasEditPermission = user.is_superuser || user.is_poweruser;
  const stlUrl = GetAssetStlUrl({
    "assetId": selectedAssetId,
    "assetImageUrl": stlPath
  })

  const numberValidation = Yup.number()
    .required('Enter Some Value')
    .test('positive', 'Enter Positive Value', value => value === 0 || value > 0)

  const validationSchema = Yup.object().shape({
    minTankWorkingVolume: numberValidation,
    maxTankWorkingVolume: numberValidation,
    minDensity: numberValidation,
    maxDensity: numberValidation,
    minViscosity: numberValidation,
    maxViscosity: numberValidation,
    minAgitatorSpeed: numberValidation,
    maxAgitatorSpeed: numberValidation,
  })

  const initValues = {
    minTankWorkingVolume:
      processParams.process_parameter_ranges.Min_Tank_Working_Volume,
    maxTankWorkingVolume:
      processParams.process_parameter_ranges.Max_Tank_Working_Volume,
    minDensity: processParams.process_parameter_ranges.Min_Liquid_Density,
    maxDensity: processParams.process_parameter_ranges.Max_Liquid_Density,
    minViscosity: processParams.process_parameter_ranges.Min_Liquid_Viscosity,
    maxViscosity: processParams.process_parameter_ranges.Max_Liquid_Viscosity,
    minAgitatorSpeed: processParams.process_parameter_ranges.Min_Agitator_Speed,
    maxAgitatorSpeed: processParams.process_parameter_ranges.Max_Agitator_Speed,
  };

  return (
    <div className="d-flex h-100 flex-wrap">
      <Card className="m-3 table-responsive" style={{minWidth: '320px'}}>
        <Card.Body>
          <Card.Title className="h6">Geometry</Card.Title>
          {
            geometryData.length > 0 ? <Table className="table">
              <thead>
                <tr>
                  <th scope="col">Parameter</th>
                  <th scope="col">Value</th>
                  <th scope="col">Unit</th>
                </tr>
              </thead>
              <tbody>
                {geometryData.map((gData) => (
                  <tr key={gData.index}>
                    <td className="p-1">{gData.geometry_field}</td>
                    <td className="p-1">
                      {gData.geometry_field === "Volume" ? Number(gData.geometry_value).toExponential(3) : ToScientificValue(gData.geometry_value)}
                    </td>
                    <td className="p-1">{gData.unit}</td>
                  </tr>
                ))}
              </tbody>
            </Table>:<span>No data found</span>
          }
        </Card.Body>
      </Card>
      <Card className="m-3 table-responsive">
        <Card.Body>
          <Card.Title className="h6">Process Parameter Ranges</Card.Title>
          <Formik
            initialValues={initValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              try {
                const config = {
                  headers: {
                    'X-CSRF-TOKEN': getCookie('csrf_token')
                  },
                };
                let process_para_update_result = await axios.put(
                  `/api/v3/assets/geometry/${selectedAssetId}/`,
                  {
                    geometry: {
                      Min_Tank_Working_Volume: values.minTankWorkingVolume,
                      Max_Tank_Working_Volume: values.maxTankWorkingVolume,
                      Min_Liquid_Density: values.minDensity,
                      Max_Liquid_Density: values.maxDensity,
                      Min_Liquid_Viscosity: values.minViscosity,
                      Max_Liquid_Viscosity: values.maxViscosity,
                      Min_Agitator_Speed: values.minAgitatorSpeed,
                      Max_Agitator_Speed: values.maxAgitatorSpeed,
                    },
                  },
                  config
                );
                onProcessParamsChange();
                if (process_para_update_result.data.status_code === 200) {
                  setToast({
                    statusCode: process_para_update_result.data.status_code,
                    message: process_para_update_result.data.msg
                  })   
                }
              } catch (err) {
                setToast({
                  statusCode: 500,
                  message: err.response.data.detail
                }) 
              }
            }}
          >
            {({ handleChange, handleSubmit, values, errors, isSubmitting }) => (
              <>
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Unit</th>
                      <th scope="col">Min</th>
                      <th scope="col">Max</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Tank Working Volume</td>
                      <td>liter</td>
                      <td>
                      <NumberControl
                          name="minTankWorkingVolume"
                          value={values.minTankWorkingVolume}
                          disabled={!hasEditPermission}
                          isInvalid={errors.minTankWorkingVolume}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <NumberControl
                          name="maxTankWorkingVolume"
                          value={values.maxTankWorkingVolume}
                          disabled={!hasEditPermission}
                          isInvalid={errors.maxTankWorkingVolume}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Agitator Speed</td>
                      <td>rpm</td>
                      <td>
                        <NumberControl
                          name="minAgitatorSpeed"
                          value={values.minAgitatorSpeed}
                          disabled={!hasEditPermission}
                          isInvalid={errors.minAgitatorSpeed}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <NumberControl
                          name="maxAgitatorSpeed"
                          value={values.maxAgitatorSpeed}
                          disabled={!hasEditPermission}
                          isInvalid={errors.maxAgitatorSpeed}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Liquid Viscosity</td>
                      <td>cP</td>
                      <td>
                        <NumberControl
                          name="minViscosity"
                          value={values.minViscosity}
                          disabled={!hasEditPermission}
                          isInvalid={errors.minViscosity}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <NumberControl
                          name="maxViscosity"
                          value={values.maxViscosity}
                          disabled={!hasEditPermission}
                          isInvalid={errors.maxViscosity}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Liquid Density</td>
                      <td>kg/m^3</td>
                      <td>
                        <NumberControl
                          name="minDensity"
                          value={values.minDensity}
                          disabled={!hasEditPermission}
                          isInvalid={errors.minDensity}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <NumberControl
                          name="maxDensity"
                          value={values.maxDensity}
                          disabled={!hasEditPermission}
                          isInvalid={errors.maxDensity}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                {hasEditPermission && (
                  <Button disabled={isSubmitting || _.isEqual(initValues, values)} variant="primary outline-primary" onClick={handleSubmit}>
                    Save
                  </Button>
                )}
              </>
            )}
          </Formik>
        </Card.Body>
      </Card>
      <div className="m-3">
        <div style={{ width: '480px' }}>
          {
            stlUrl? <StlViewer url={stlUrl} /> 
              : stlUrl === '' ? <h5 className='mt-5'>STL file not found or failed to load</h5> : <STLViewerSkeleton />
          }
        </div>
      </div>
    </div>
  );
};

export default Geometry;
