import { executeApiCall, useHeaderConfiguration } from "../../../utils";
import { useQuery } from 'react-query';
import { parameterTableType, parameterTableKey } from "../../../utils/constants";

//Get parameters
const useGetParams = (Id, type = parameterTableType.asset_param) => {
    const config = useHeaderConfiguration();
    const useQueryKey = parameterTableKey(Id)
    return useQuery(useQueryKey, async () => {
        const { data } = type === parameterTableType.asset_param ? 
            await executeApiCall('get', `api/v3/projects/sim_cases/results/geometry/parameters/?p_id=${Id}`, {}, config)
            : await executeApiCall('get', `api/v3/projects/sim_cases/results/geometry/parameters/?c_id=${Id}`, {}, config)
        return data
    })
}

//Add new parameter
const useAddNewParameter = (Id, param_data, type = parameterTableType.asset_param) => {
    const config = useHeaderConfiguration({contentType: 'application/json'})
    let formData = new FormData();
    Object.keys(param_data).forEach(key => {
        formData.append(key, param_data[key])
    })
    return  type === parameterTableType.asset_param ? 
        executeApiCall('post', `/api/v3/projects/sim_cases/results/geometry/parameters/?p_id=${Id}`, formData, config)
        : executeApiCall('post', `/api/v3/projects/sim_cases/results/geometry/parameters/?c_id=${Id}`, formData, config)
}

//Update parameter
const useUpdateParameter = (paramId, key, value, type = parameterTableType.asset_param) => {
    const config = useHeaderConfiguration()
    let formData = new FormData();
    formData.append(key, value)
    return  type === parameterTableType.asset_param ? 
        executeApiCall('patch', `/api/v3/projects/sim_cases/results/geometry/parameters/?p_para_id=${paramId}`, formData, config)
        : executeApiCall('patch', `/api/v3/projects/sim_cases/results/geometry/parameters/?c_para_id=${paramId}`, formData, config)
}

//Delete parameters
const useDeleteParams = (paramIds, type = parameterTableType.asset_param, Id) => {
    const config = useHeaderConfiguration({contentType: 'application/json'});
    paramIds = paramIds.map(param => `g_p_id=${param}`).join('&');
    return type === parameterTableType.asset_param ? 
        executeApiCall('delete', `/api/v3/projects/sim_cases/results/geometry/parameters/?p_id=${Id}&${paramIds}`, {}, config)
        : executeApiCall('delete', `/api/v3/projects/sim_cases/results/geometry/parameters/?c_id=${Id}&${paramIds}`, {}, config)
}

export { useGetParams, useAddNewParameter, useDeleteParams, useUpdateParameter }