import { useFormikContext } from 'formik';
import { useState } from 'react';
import { Form, ListGroup } from 'react-bootstrap';
import { functionExamplesDictionary } from '../utils/constants';

const MathExpressionInput = ({name, suggestionInputs = []}) => {
  const {values, setFieldValue, errors, handleChange} = useFormikContext()
  const [tempSuggestion, setTempSuggestion] = useState([])

  function getSubstringFromLastNonAlphabetic(str) {
    const match = str.match(/[^a-zA-Z0-9 _]*([a-zA-Z0-9 _]*)$/);
    if (match && match[1]) {
      return match[1];
    }
    return '';
  }

  //Set formula based on the suggestion item selection
  const onSuggestionSelect = (suggestion) => {
    let initialValue = values[name] ?? ""
    let selectedValue = suggestion.includes("()") ? suggestion.substring(0, suggestion.length - 1) : suggestion
    selectedValue = initialValue ? " " + selectedValue + " " : selectedValue + " " 
    let valueFromLastNonAplphabet = initialValue ? getSubstringFromLastNonAlphabetic(initialValue) : ""
    let newValue = valueFromLastNonAplphabet ? 
        initialValue.replace(new RegExp(valueFromLastNonAplphabet + '$'), selectedValue) : 
        initialValue + selectedValue        
        
    setFieldValue(name, newValue)
    setTempSuggestion([])
  }

  //to handle suggestion list based on current input
  const handleInputChange = (event) => {
    if (suggestionInputs.length > 0) {
        let initialValue = event.target.value ?? ""
        let valueFromLastNonAplphabet = initialValue ? getSubstringFromLastNonAlphabetic(initialValue) : ""
        
        setTempSuggestion([
          ...suggestionInputs.filter(suggestionInput => suggestionInput.toLowerCase().includes(valueFromLastNonAplphabet.toLowerCase().trim()))
        ])
    }    
    handleChange(event)
  };

  return <>
    <Form.Control
        as="textarea"
        rows={3}
        name={name}
        value={values[name]}
        onChange={handleInputChange}
        isInvalid={errors[name]}
    />
    <Form.Control.Feedback type="invalid">
        {errors[name]}
    </Form.Control.Feedback>
    {
        tempSuggestion.length > 0 && <ListGroup style={{maxHeight: '30vh', overflowY: 'scroll'}}>
        {
            tempSuggestion.map((suggestion, index) => 
                <ListGroup.Item onClick={() => onSuggestionSelect(suggestion)}>
                    <div className='d-flex justify-content-between'>
                      <div>{suggestion}</div>
                      <div>
                        {
                          suggestion.includes("()") || suggestion === "root" || suggestion === "Mod" ? 
                            functionExamplesDictionary.hasOwnProperty(suggestion) ? 
                            functionExamplesDictionary[suggestion] : '#function': 
                          '#column' 
                        }
                      </div>
                    </div>
                </ListGroup.Item>
            )
        }
        </ListGroup>
    }
  </>
}

export default MathExpressionInput