import { executeApiCall, useHeaderConfiguration } from "../../../utils";
import { useQuery } from 'react-query';
import { projectAssetDetailsKey } from "../../../utils/constants";

const useGetAssetStlURL = (mapped_asset_details) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const config = useHeaderConfiguration(); 
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useQuery(`mapped_asset_image_${mapped_asset_details?.assetId}`, async () => {
        const res = await executeApiCall('get', `/api/v3/assets/images/${mapped_asset_details?.assetId}/${mapped_asset_details?.assetImageUrl}/`, {}, config, true)
        if(res?.statusCode !== 200) {
            return ''
        }
        return res.data        
    })
}

//Get project asset details
const useGetAssetDetails = (assetId) => {
    const config = useHeaderConfiguration();
    return useQuery(projectAssetDetailsKey(assetId), async () => {
        const { data } = await executeApiCall('get', `/api/v3/asset-details/${assetId}/`, {}, config)
        return data
    })
}

export { useGetAssetStlURL, useGetAssetDetails }