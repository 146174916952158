import { useState, useCallback } from 'react';
import Qty from 'js-quantities';
import { unitSystems as builtinSystems } from '../common/Units';

const useUnitSystem = (
  userDefinedUnitSystems = [],
  initDisplayUnitSystemId = 'SI'
) => {
  const [displayUnitSystemId, setDisplayUnitSystemId] = useState(
    initDisplayUnitSystemId
  );

  const [allSystems, setAllSystems] = useState(() => {
    const customSystems = userDefinedUnitSystems.reduce((acc, sys) => {
      acc[sys.id] = sys;
      return acc;
    }, {});
    return { ...builtinSystems, ...customSystems };
  });

  const convert = useCallback(
    (qty, value, srcUnitSystemId, targetUnitSystemId) => {
      if (value === undefined || qty === undefined) return value;
      const srcUnitSystem = allSystems[srcUnitSystemId];
      const srcUnit = srcUnitSystem.units[qty];
      const targetUnitSystem = allSystems[targetUnitSystemId];
      const targetUnit = targetUnitSystem.units[qty];
      const convertedValue = new Qty(value, srcUnit).to(targetUnit).scalar;
      return convertedValue;
    },
    [allSystems]
  );

  const getDisplayUnit = useCallback(
    (qty) => allSystems[displayUnitSystemId].units[qty],
    [allSystems, displayUnitSystemId]
  );

  return {
    displayUnitSystemId,
    setDisplayUnitSystemId,
    allSystems,
    setAllSystems,
    convert,
    getDisplayUnit,
  };
};

export default useUnitSystem;
