// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import StlViewer from './../../../../../../libs/ui-shared/src/lib/3d-viewer/StlViewer';
import { GetAssetStlUrl, GetProjectAssetDetailsData } from '../services/business-logic/projectasset.businesslogic';
import { ParameterTable } from '../ParameterTable';
import { pillTabs } from '../../utils/constants';
import STLViewerSkeleton from './../../common/STLViewerSkeleton';

const AssetDetailsPanel = ({ projectId }) => {
  const { mapped_asset_details } = GetProjectAssetDetailsData(projectId);
  const assetStlUrl = GetAssetStlUrl(mapped_asset_details)

  return (
    <div className="d-flex flex-wrap justify-content-between">
      {
        mapped_asset_details?.assetName ? <>
          <div>
            <h5 className='mb-3'>Mapped Asset:  
              <a className='ms-1' href={`/app/asset/${mapped_asset_details.assetId}`}>{mapped_asset_details.assetName}</a></h5>
            <ParameterTable Id={projectId} />
          </div>
        
          <div style={{ width: '480px' }}>
            {
              assetStlUrl? <StlViewer url={assetStlUrl} /> 
                : assetStlUrl === '' ? <h5 className='mt-5'> Failed to load</h5> : <STLViewerSkeleton />
            }
          </div>
        </> : <h5 className='mb-3'> No mapped asset found </h5>
      }
    </div>
  )
};

const AssetDetails = (projectId) => ({
  label: pillTabs.AssetDetails,
  Panel: <AssetDetailsPanel projectId={projectId} />,
  tabEnabled: true,
})

export { AssetDetails };
