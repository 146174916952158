import { cloneDeep } from 'lodash';


const geometryObj_default = {
  shape: "",
  dim: {},
  res: {theta: 20, phi: 20},
  options: {capping: true, base: true},
  origin: [0, 0, 0],
  pos: [0, 0, 0],
  rotate: {x: 0, y:0, z:0},
  scale: [1, 1, 1],
  opacity: 1,
  color: [1, 1, 1],
};

const transform_default = {
  geometry: [],
  clip: [],
  cut: [],
  closedClip: [],
  color: [1, 1, 1],
  opacity: 1,
  label: '',
}

const colors = {
  tank: [1, 1, 1],
  baffles: [1, 0, 0],
  impellers: [1, 0, 0],
  dipTubes: [0, 1, 0],
  helicalCoils: [1, 1, 0],
  ringSparger: [1, 1, 0],
  liquidLevel: [0, 0, 1],
  tracer: [1, 0, 0],
  monitors: [0, 1, 0],
  heatTransferMonitors: [1, 0, 0],
  X: [1, 0, 0],
  Y: [0, 1, 0],
  Z: [0, 0, 1],
}

function toRad(degrees) {return degrees * Math.PI / 180}
function toDeg(radians) {return radians * 180 / Math.PI}

function getGeometryTankHead(reactor) {
  const geometryObj = cloneDeep(geometryObj_default);
  const tank = reactor.config.geometry.tank;
  let headStyle = tank.headStyle
  if (tank.type === "Rectangular")
    headStyle = "None";
  geometryObj.opacity = 0.5;
  geometryObj.color = colors.tank;
  switch (headStyle) {
    case "2:1Elliptical":
      geometryObj.shape = "STL";
      geometryObj.dim.url = "/api/files/system/dished-ends/21elliptical_top.stl";
      geometryObj.scale = geometryObj.scale.map(val => val*tank.diameter);
      geometryObj.rotate = {x:0, y:0, z:0};
      geometryObj.pos = [0, 0, tank.straightSide];
      break;
    case "Hemispherical":
      geometryObj.shape = "STL";
      geometryObj.dim.url = "/api/files/system/dished-ends/hemispherical_top.stl";
      geometryObj.scale = geometryObj.scale.map(val => val*tank.diameter);
      geometryObj.rotate = {x:0, y:0, z:0};
      geometryObj.pos = [0, 0, tank.straightSide];
      break;
    case "10%Torispherical":
      geometryObj.shape = "STL";
      geometryObj.dim.url = "/api/files/system/dished-ends/10ptorispherical_top.stl";
      geometryObj.scale = geometryObj.scale.map(val => val*tank.diameter);
      geometryObj.rotate = {x:0, y:0, z:0};
      geometryObj.pos = [0, 0, tank.straightSide];
      break;
    case "6%Torispherical":
      geometryObj.shape = "STL";
      geometryObj.dim.url = "/api/files/system/dished-ends/6ptorispherical_top.stl";
      geometryObj.scale = geometryObj.scale.map(val => val*tank.diameter);
      geometryObj.rotate = {x:0, y:0, z:0};
      geometryObj.pos = [0, 0, tank.straightSide];
      break;
    case "Conical":
      geometryObj.shape = "Cone";
      geometryObj.dim.radius = tank.diameter / 2;
      geometryObj.dim.height = tank.headDepth;
      geometryObj.pos = [0, 0, tank.straightSide + tank.headDepth / 2];
      geometryObj.res.theta = 50;
      geometryObj.rotate = {x:0, y:0, z:0};
      geometryObj.options.base = false
      break;
    case "Flat":
      geometryObj.shape = "Cone";
      geometryObj.dim.radius = tank.diameter / 2;
      geometryObj.dim.height = 0;
      geometryObj.pos = [0, 0, tank.straightSide];
      geometryObj.res.theta = 50;
      geometryObj.rotate = {x:0, y:0, z:0};
      break;
    case "None":
      geometryObj.shape = "Cone";
      geometryObj.dim.radius = 0;
      geometryObj.dim.height = 0;
      break;
    default:
      console.error('Cannot render the given Tank Head Style : ', tank.headStyle)
      break;
  }
  return geometryObj;
}

function getGeometryTankBottom(reactor) {
  const geometryObj = cloneDeep(geometryObj_default);
  const tank = reactor.config.geometry.tank;
  let bottomStyle = tank.bottomStyle
  geometryObj.opacity = 0.5;
  geometryObj.color = colors.tank;
  if (tank.type === "Rectangular")
    bottomStyle = "None";
  switch (bottomStyle) {
    case "2:1Elliptical":
      geometryObj.shape = "STL";
      geometryObj.dim.url = "/api/files/system/dished-ends/21elliptical_bot.stl";
      geometryObj.scale = geometryObj.scale.map(val => val*tank.diameter);
      geometryObj.rotate = {x:0, y:0, z:0};
      geometryObj.pos = [0, 0, 0];
      break;
    case "Hemispherical":
      geometryObj.shape = "STL";
      geometryObj.dim.url = "/api/files/system/dished-ends/hemispherical_bot.stl";
      geometryObj.scale = geometryObj.scale.map(val => val*tank.diameter);
      geometryObj.rotate = {x:0, y:0, z:0};
      geometryObj.pos = [0, 0, 0];
      break;
    case "10%Torispherical":
      geometryObj.shape = "STL";
      geometryObj.dim.url = "/api/files/system/dished-ends/10ptorispherical_bot.stl";
      geometryObj.scale = geometryObj.scale.map(val => val*tank.diameter);
      geometryObj.rotate = {x:0, y:0, z:0};
      geometryObj.pos = [0, 0, 0];
      break;
    case "6%Torispherical":
      geometryObj.shape = "STL";
      geometryObj.dim.url = "/api/files/system/dished-ends/6ptorispherical_bot.stl";
      geometryObj.scale = geometryObj.scale.map(val => val*tank.diameter);
      geometryObj.rotate = {x:0, y:0, z:0};
      geometryObj.pos = [0, 0, 0];
      break;
    case "Conical":
      geometryObj.shape = "Cone";
      geometryObj.dim.radius = tank.diameter / 2;
      geometryObj.dim.height = tank.bottomDepth;
      geometryObj.pos = [0, 0, -tank.bottomDepth / 2];
      geometryObj.res.theta = 50;
      geometryObj.rotate = {x:0, y:180, z:0};
      geometryObj.options.base = false;
      break;
    case "Flat":
      geometryObj.shape = "Cone";
      geometryObj.dim.radius = tank.diameter / 2;
      geometryObj.dim.height = 0;
      geometryObj.pos = [0, 0, 0];
      geometryObj.res.theta = 50;
      geometryObj.rotate = {x:0, y:180, z:0};
      break;
    case "None":
      geometryObj.shape = "Cone";
      geometryObj.dim.radius = 0;
      geometryObj.dim.height = 0;
      break;
    default:
      console.error('Cannot render the given Tank Bottom Style : ', tank.bottomStyle)
      break;
  }
  return geometryObj;
}

function getGeometryTankMiddle(reactor) {
  const geometryObj = cloneDeep(geometryObj_default);
  const tank = reactor.config.geometry.tank;
  geometryObj.opacity = 0.5;
  geometryObj.rotate = {x:0, y:0, z:0};
  geometryObj.color = colors.tank;
  geometryObj.pos = [0, 0, (tank.straightSide / 2)];
  switch (tank.type) {
    case "Cylindrical":
      geometryObj.shape = "Cylinder";
      geometryObj.dim.radius = tank.diameter / 2;
      geometryObj.dim.height = tank.straightSide;
      geometryObj.res.theta = 50;
      geometryObj.options.capping = false;
      
      break;
    case "Rectangular":
      geometryObj.shape = "Cube";
      geometryObj.dim.z = tank.straightSide;
      geometryObj.dim.x = tank.width;
      geometryObj.dim.y = tank.length;
      geometryObj.res.xRes = 20;
      geometryObj.res.yRes = 20;
      break;
    default:
      console.error('Cannot render the given Tank Type : ', tank.type)
      break;
  }
  return geometryObj;
}

function getGeometryBaffles(reactor) {
  const tank = reactor.config.geometry.tank;
  const tankRadius = tank.diameter / 2;
  const arr_geometryObj = reactor.config.geometry.baffles.map(baffle => {
    const geometryObj = cloneDeep(geometryObj_default);
    const baffleCenterWallDistance = 0;
    // const baffleSTLFileName = [
    //   reactor.reactor.name,
    //   reactor.config.name,
    //   baffle.type.replace(" ", ""),
    //   baffle.id
    // ].join("-");
    switch (baffle.type) {
      case "Regular Flat":
        geometryObj.shape = "Cube";
        geometryObj.dim.x = baffle.width;
        geometryObj.dim.y = 0;
        geometryObj.dim.z = (tank.straightSide
                            + tank.headDepth
                            + tank.bottomDepth
                            - baffle.offsetTop
                            - baffle.offsetBot);
        geometryObj.pos = [
          (((tankRadius
              - baffleCenterWallDistance
              - baffle.offsetWall
              - (baffle.width
                / 2
                * Math.cos(toRad(baffle.angle2))))
            * Math.cos(toRad(baffle.angle1)))
          + (baffle.width
            / 2
            * Math.sin(toRad(baffle.angle2))
            * Math.sin(toRad(baffle.angle1)))),
          (((tankRadius
              - baffleCenterWallDistance
              - baffle.offsetWall
              - (baffle.width
                / 2
                * Math.cos(toRad(baffle.angle2))))
            * Math.sin(toRad(baffle.angle1)))
          - (baffle.width
            / 2
            * Math.sin(toRad(baffle.angle2))
            * Math.cos(toRad(baffle.angle1)))),
          ((tank.headDepth
            + baffle.offsetBot
            - tank.bottomDepth
            - baffle.offsetTop)
            / 2
          + (tank.straightSide / 2)),
        ];
        break;
      // case "Fin Baffle":
      //   geometryObj.shape = "STL";
      //   geometryObj.dim.url = "/api/files/system/baffles/"
      //     + baffleSTLFileName
      //     + ".stl";
      //   geometryObj.scale = geometryObj.scale.map(val => val*baffle.width);
      //   geometryObj.pos = [
      //     (((tankRadius
      //         - baffleCenterWallDistance
      //         - baffle.offsetWall
      //         - (baffle.width
      //           / 2
      //           * Math.cos(toRad(baffle.angle2))))
      //       * Math.cos(toRad(baffle.angle1)))
      //     + (baffle.width
      //       / 2
      //       * Math.sin(toRad(baffle.angle2))
      //       * Math.sin(toRad(baffle.angle1)))),
      //     (((tankRadius
      //         - baffleCenterWallDistance
      //         - baffle.offsetWall
      //         - (baffle.width
      //           / 2
      //           * Math.cos(toRad(baffle.angle2))))
      //       * Math.sin(toRad(baffle.angle1)))
      //     - (baffle.width
      //       / 2
      //       * Math.sin(toRad(baffle.angle2))
      //       * Math.cos(toRad(baffle.angle1)))),
      //     ((tank.headDepth
      //       + baffle.offsetBot
      //       - tank.bottomDepth
      //       - baffle.offsetTop)
      //       / 2
      //     + (tank.straightSide / 2)),
      //   ];
      //   break;
      // case "Finger":
      //   geometryObj.shape = "STL";
      //   geometryObj.dim.url = "/api/files/system/baffles/"
      //     + baffleSTLFileName
      //     + ".stl";
      //   geometryObj.scale = geometryObj.scale.map(val => val*baffle.width);
      //   geometryObj.pos = [
      //     (((tankRadius
      //         - baffleCenterWallDistance
      //         - baffle.offsetWall
      //         - (baffle.width
      //           / 2
      //           * Math.cos(toRad(baffle.angle2))))
      //       * Math.cos(toRad(baffle.angle1)))
      //     + (baffle.width
      //       / 2
      //       * Math.sin(toRad(baffle.angle2))
      //       * Math.sin(toRad(baffle.angle1)))),
      //     (((tankRadius
      //         - baffleCenterWallDistance
      //         - baffle.offsetWall
      //         - (baffle.width
      //           / 2
      //           * Math.cos(toRad(baffle.angle2))))
      //       * Math.sin(toRad(baffle.angle1)))
      //     - (baffle.width
      //       / 2
      //       * Math.sin(toRad(baffle.angle2))
      //       * Math.cos(toRad(baffle.angle1)))),
      //     ((tank.headDepth
      //       + baffle.offsetBot
      //       - tank.bottomDepth
      //       - baffle.offsetTop)
      //       / 2
      //     + (tank.straightSide / 2)),
      //   ];
      //   break;
      // case "Flattened Pipe (Beavertail)":
      //   geometryObj.shape = "STL";
      //   geometryObj.dim.url = "/api/files/system/baffles/"
      //     + baffleSTLFileName
      //     + ".stl";
      //   geometryObj.scale = geometryObj.scale.map(val => val*baffle.width);
      //   geometryObj.pos = [
      //     (((tankRadius
      //         - baffleCenterWallDistance
      //         - baffle.offsetWall
      //         - (baffle.width
      //           / 2
      //           * Math.cos(toRad(baffle.angle2))))
      //       * Math.cos(toRad(baffle.angle1)))
      //     + (baffle.width
      //       / 2
      //       * Math.sin(toRad(baffle.angle2))
      //       * Math.sin(toRad(baffle.angle1)))),
      //     (((tankRadius
      //         - baffleCenterWallDistance
      //         - baffle.offsetWall
      //         - (baffle.width
      //           / 2
      //           * Math.cos(toRad(baffle.angle2))))
      //       * Math.sin(toRad(baffle.angle1)))
      //     - (baffle.width
      //       / 2
      //       * Math.sin(toRad(baffle.angle2))
      //       * Math.cos(toRad(baffle.angle1)))),
      //     ((tank.headDepth
      //       + baffle.offsetBot
      //       - tank.bottomDepth
      //       - baffle.offsetTop)
      //       / 2
      //     + (tank.straightSide / 2)),
      //   ];
      //   break;
      // case "h-style":
      //   geometryObj.shape = "STL";
      //   geometryObj.dim.url = "/api/files/system/baffles/"
      //     + baffleSTLFileName
      //     + ".stl";
      //   geometryObj.scale = geometryObj.scale.map(val => val*baffle.width);
      //   geometryObj.pos = [
      //     (((tankRadius
      //         - baffleCenterWallDistance
      //         - baffle.offsetWall
      //         - (baffle.width
      //           / 2
      //           * Math.cos(toRad(baffle.angle2))))
      //       * Math.cos(toRad(baffle.angle1)))
      //     + (baffle.width
      //       / 2
      //       * Math.sin(toRad(baffle.angle2))
      //       * Math.sin(toRad(baffle.angle1)))),
      //     (((tankRadius
      //         - baffleCenterWallDistance
      //         - baffle.offsetWall
      //         - (baffle.width
      //           / 2
      //           * Math.cos(toRad(baffle.angle2))))
      //       * Math.sin(toRad(baffle.angle1)))
      //     - (baffle.width
      //       / 2
      //       * Math.sin(toRad(baffle.angle2))
      //       * Math.cos(toRad(baffle.angle1)))),
      //     ((tank.headDepth
      //       + baffle.offsetBot
      //       - tank.bottomDepth
      //       - baffle.offsetTop)
      //       / 2
      //     + (tank.straightSide / 2)),
      //   ];
      //   break;
      case "Custom":
        if (!baffle.file) return null; // when users changes type to 'Custom' but has not selected file yet
        geometryObj.shape = "STL";
        geometryObj.dim.url = "/api/files/system/baffles/"
          + baffle.file;
        // geometryObj.scale = geometryObj.scale.map(val => val*baffle.width);
        // geometryObj.pos = [
        //   (((tankRadius
        //       - baffleCenterWallDistance
        //       - baffle.offsetWall
        //       - (baffle.width
        //         / 2
        //         * Math.cos(toRad(baffle.angle2))))
        //     * Math.cos(toRad(baffle.angle1)))
        //   + (baffle.width
        //     / 2
        //     * Math.sin(toRad(baffle.angle2))
        //     * Math.sin(toRad(baffle.angle1)))),
        //   (((tankRadius
        //       - baffleCenterWallDistance
        //       - baffle.offsetWall
        //       - (baffle.width
        //         / 2
        //         * Math.cos(toRad(baffle.angle2))))
        //     * Math.sin(toRad(baffle.angle1)))
        //   - (baffle.width
        //     / 2
        //     * Math.sin(toRad(baffle.angle2))
        //     * Math.cos(toRad(baffle.angle1)))),
        //   ((tank.headDepth
        //     + baffle.offsetBot
        //     - tank.bottomDepth
        //     - baffle.offsetTop)
        //     / 2
        //   + (tank.straightSide / 2)),
        // ];
        break;
      default:
        console.error('Cannot render the given Baffle Type : ', baffle.type);
        break;
    }
    geometryObj.rotate = {
      x:  0,
      y:  0,
      z:  (baffle.angle1
          + baffle.angle2),
    }
    geometryObj.color = colors.baffles;
    return geometryObj;
  });
  return arr_geometryObj.filter(b => b);
}

function getGeometryImpellers(reactor) {
  const tank = reactor.config.geometry.tank;
  const arr_geometryObj = reactor.config.geometry.impellers.map(impeller => {
    const geometryObj = cloneDeep(geometryObj_default);
    geometryObj.shape = "STL";
    geometryObj.dim.url = '/api/files/system/impellers/' + impeller.type + '.stl';
    geometryObj.scale = geometryObj.scale.map(val => val * impeller.diameter);
    let mounting = impeller.mounting;
    if (mounting === "Top") {
      geometryObj.pos = [
        impeller.offsetX,
        impeller.offsetY,
        (impeller.clearance
        - tank.bottomDepth),
      ];
      geometryObj.rotate = {
        x: impeller.angle3,
        y: impeller.angle1,
        z: impeller.angle2,
      }
    } else if (mounting === "Bottom") {
      geometryObj.pos = [
        impeller.offsetX,
        impeller.offsetY,
        (impeller.clearance
        - tank.bottomDepth),
      ];
      geometryObj.rotate = {
        x: impeller.angle3,
        y: impeller.angle1,
        z: impeller.angle2,
      }
    } else if (mounting === "Side") {
      const tankRadius = tank.diameter * 0.5;
      let shaftLength = tankRadius;
      if (tank.type === "Cylindrical") {
        const offset = Math.sqrt(impeller.offsetX**2
                                + impeller.offsetY**2)
        const offsetAngle = toDeg(Math.acos(impeller.offsetX / offset))
        const angle2 = impeller.angle2 % 360
        let px = tankRadius;
        let py = 0;
        if (angle2 <= 180) {
          px =  (tankRadius
                * Math.cos(toRad(angle2)));
          const f = x => {
            return  ((Math.tan(toRad(angle2))
                      * x)
                    - Math.sqrt(tankRadius**2 - x**2)
                    - (Math.tan(toRad(angle2))
                      * impeller.offsetX)
                    + impeller.offsetY)
          }
          const fprime = x => {
            return  ((x
                      / Math.sqrt(tankRadius**2 - x**2))
                    + Math.tan(toRad(angle2)))
          }
          const delta_px = x => {
            return  (- f(x) / fprime(x))
          }
          let delta = delta_px(px)
          let iter = 0;
          while (Math.abs(delta) > 1e-4 && iter < 10) {
            px += delta
            delta = delta_px(px)
            iter++;
          }
          py =  ((px
                  * Math.tan(toRad(angle2)))
                - (impeller.offsetX
                  * Math.tan(toRad(angle2)))
                + impeller.offsetY);
          shaftLength = Math.sqrt((px - impeller.offsetX)**2
                                  + (py - impeller.offsetY)**2)
        } else {
          const angle2_ = angle2 - 180
          px =  (tankRadius
                * Math.cos(toRad(angle2_)));
          const f = x => {
            return  ((Math.tan(toRad(angle2_))
                      * x)
                    - Math.sqrt(tankRadius**2 - x**2)
                    - (Math.tan(toRad(angle2_))
                      * impeller.offsetX)
                    + impeller.offsetY)
          }
          const fprime = x => {
            return  ((x
                      / Math.sqrt(tankRadius**2 - x**2))
                    + Math.tan(toRad(angle2_)))
          }
          const delta_px = x => {
            return  (- f(x) / fprime(x))
          }
          let delta = delta_px(px)
          let iter = 0;
          while (Math.abs(delta) > 1e-4 && iter < 10) {
            px += delta
            delta = delta_px(px)
            iter++;
          }
          py =  ((px
                  * Math.tan(toRad(angle2_)))
                - (impeller.offsetX
                  * Math.tan(toRad(angle2_)))
                + impeller.offsetY);
          let segDis =  (offset
                          * Math.cos(toRad(90 + angle2_ - offsetAngle)))
          if (offset === 0) segDis = 0;
          const segMidPointX =  - (segDis
                                  * Math.sin(toRad(angle2_)))
          const segMidPointY =  (segDis
                                * Math.cos(toRad(angle2_)))
          px = segMidPointX - (px - segMidPointX)
          py = segMidPointY - (py - segMidPointY)
          shaftLength = Math.sqrt((px - impeller.offsetX)**2
                                  + (py - impeller.offsetY)**2)
        }
        let ox =  (px
                  - (shaftLength
                    * Math.cos(toRad(impeller.angle2 + impeller.angle4))))
        let oy =  (py
                  - (shaftLength
                    * Math.sin(toRad(impeller.angle2 + impeller.angle4))))
        geometryObj.pos = [
          (ox
          + (shaftLength
            * (1 - Math.cos(toRad(impeller.angle3)))
            * Math.cos(toRad(impeller.angle2 + impeller.angle4)))),
          (oy
          + (shaftLength
            * (1 - Math.cos(toRad(impeller.angle3)))
            * Math.sin(toRad(impeller.angle2 + impeller.angle4)))),
          (impeller.clearance
          - tank.bottomDepth
          + (shaftLength
            * Math.sin(toRad(impeller.angle3)))),
        ]
      } else if (tank.type === "Rectangular") {
        const impellerCenterOnXY = {
          x: impeller.offsetX,
          y: impeller.offsetY
        }
        let bounds = {};
        if (impeller.angle2 > 0) {
          bounds = {
            x: ((impeller.angle2 + 90) % 360)  > 180 ? -tank.width/2 : tank.width/2,
            y: (impeller.angle2 % 360) > 180 ? -tank.length/2 : tank.length/2
          }
        } else if (impeller.angle2 < 0) {
          bounds = {
            x: (360 - ((impeller.angle2 + 90) % 360))  > 180 ? -tank.width/2 : tank.width/2,
            y: (360 - (impeller.angle2 % 360)) > 180 ? -tank.length/2 : tank.length/2
          }
        } else {
          bounds = {
            x: tank.width/2,
            y: tank.length/2
          }
        }
        const mountPoints = [
          {
            x:  bounds.x,
            y:  (impellerCenterOnXY.y
                - (impellerCenterOnXY.x
                  * Math.tan(toRad(impeller.angle2)))
                + (bounds.x
                  * Math.tan(toRad(impeller.angle2))))
          },
          {
            x:  (impellerCenterOnXY.x
                - (impellerCenterOnXY.y
                  / Math.tan(toRad(impeller.angle2)))
                + (bounds.y
                  / Math.tan(toRad(impeller.angle2)))),
            y: bounds.y
          }
        ]
        const distance = (x, y) => Math.sqrt(x**2 + y**2);
        const lengths = mountPoints.map(mount => {
          return distance(
            mount.x - impellerCenterOnXY.x,
            mount.y - impellerCenterOnXY.y)})
        let index = lengths[0] < lengths[1] ? 0 : 1
        index = isNaN(lengths[index]) ? 1 - index : index
        const mountPoint = mountPoints[index]
        shaftLength = lengths[index] / Math.cos(toRad(impeller.angle3))
        geometryObj.pos = [
          (mountPoint.x
          - (shaftLength
            * Math.cos(toRad(impeller.angle2 + impeller.angle4))
            * Math.cos(toRad(impeller.angle3)))),
          (mountPoint.y
            - (shaftLength
              * Math.sin(toRad(impeller.angle2 + impeller.angle4))
              * Math.cos(toRad(impeller.angle3)))),
          (impeller.clearance
          - tank.bottomDepth
          + (shaftLength
            * Math.sin(toRad(impeller.angle3))))
        ]
      }
      geometryObj.rotate = {
        x: 0,
        y: impeller.angle1 + impeller.angle3,
        z: impeller.angle2 + impeller.angle4,
      }
    }
    geometryObj.color = colors.impellers;
    return geometryObj;});
  return arr_geometryObj;
}

function getGeometryShafts(reactor) {
  const tank = reactor.config.geometry.tank;
  const impellers = reactor.config.geometry.impellers;
  const arr_geometryObj = impellers.map(impeller => {
    const geometryObj = cloneDeep(geometryObj_default);
    geometryObj.shape = "Cylinder";
    geometryObj.dim.radius = 0.035 * impeller.diameter;
    geometryObj.options.capping = true;
    let mounting = impeller.mounting;
    if (mounting === "Top") {
      const tankLength =  (tank.straightSide
                          + tank.headDepth
                          + tank.bottomDepth)
      const shaftLength = (tankLength
                          - impeller.clearance)
      const shaftZ =  (shaftLength
                      * Math.cos(toRad(impeller.angle3))
                      * Math.cos(toRad(impeller.angle1)));
        const shaftX =  (0
                        + (shaftLength
                          * Math.sin(toRad(impeller.angle3))
                          * Math.sin(toRad(impeller.angle2)))
                        + (shaftLength
                          * Math.cos(toRad(impeller.angle3))
                          * Math.cos(toRad(impeller.angle2))
                          * Math.sin(toRad(impeller.angle1))));
        const shaftY =  (0
                        - (shaftLength
                          * Math.sin(toRad(impeller.angle3))
                          * Math.cos(toRad(impeller.angle2)))
                        + (shaftLength
                          * Math.cos(toRad(impeller.angle3))
                          * Math.sin(toRad(impeller.angle2))
                          * Math.sin(toRad(impeller.angle1))));
      const k1 =  Math.abs(shaftLength
                          / shaftZ)
      const k2 =  (tank.diameter
                  / 2
                  / Math.sqrt(shaftX**2 + shaftY**2))
      const k = k2 < k1 ? k2 : k1
      geometryObj.pos = [
        (impeller.offsetX
        + (shaftX
          * k
          / 2)),
        (impeller.offsetY
        + (shaftY
          * k
          / 2)),
        ((impeller.clearance
          - tank.bottomDepth)
        + (shaftZ
          * k
          / 2)),
      ];
      geometryObj.rotate = {
        x: impeller.angle3,
        y: impeller.angle1,
        z: impeller.angle2,
      }
      geometryObj.dim.height = Math.abs(k * shaftLength);
    } else if (mounting === "Bottom") {
      const tankLength = (tank.straightSide
                          + tank.headDepth
                          + tank.bottomDepth)
      const shaftLength = tankLength
      const shaftZ =  (shaftLength
                      * Math.cos(toRad(impeller.angle3))
                      * Math.cos(toRad(impeller.angle1)));
      const shaftX =  (0
                      + (shaftLength
                        * Math.sin(toRad(impeller.angle3))
                        * Math.sin(toRad(impeller.angle2)))
                      + (shaftLength
                        * Math.cos(toRad(impeller.angle3))
                        * Math.cos(toRad(impeller.angle2))
                        * Math.sin(toRad(impeller.angle1))));
      const shaftY =  (0
                      - (shaftLength
                        * Math.sin(toRad(impeller.angle3))
                        * Math.cos(toRad(impeller.angle2)))
                      + (shaftLength
                        * Math.cos(toRad(impeller.angle3))
                        * Math.sin(toRad(impeller.angle2))
                        * Math.sin(toRad(impeller.angle1))));
      const k1 =  Math.abs(impeller.clearance
                          / shaftZ)
      const k2 =  (tank.diameter
                  / 2
                  / Math.sqrt(shaftX**2 + shaftY**2))
      const k = k2 < k1 ? k2 : k1
      geometryObj.pos = [
        (impeller.offsetX
        + (shaftX
          * k
          / 2)),
        (impeller.offsetY
        + (shaftY
          * k
          / 2)),
        ((impeller.clearance
        - tank.bottomDepth)
        + (shaftZ
          * k
          / 2)),
      ];
      geometryObj.rotate = {
        x: impeller.angle3,
        y: impeller.angle1,
        z: impeller.angle2,
      }
      geometryObj.dim.height = Math.abs(k * shaftLength);
    } else if (mounting === "Side") {
      const tankRadius = tank.diameter * 0.5;
      let shaftLength = tankRadius - 0.05;
      if (tank.type === "Cylindrical") {
        const offset = Math.sqrt(impeller.offsetX**2
                                + impeller.offsetY**2)
        const offsetAngle = toDeg(Math.acos(impeller.offsetX / offset))
        let angle2 = impeller.angle2 % 360
        /*******To avoid Infinities of tan(PI/2) and tan(3*PI/2)***/
        if (angle2 === 90 || angle2 === 270) angle2 += 0.0001
        /**********************************************************/
        let px = tankRadius;
        let py = 0;
        if (angle2 <= 180) {
          px =  (tankRadius
                * Math.cos(toRad(angle2)));
          const f = x => {
            return  ((Math.tan(toRad(angle2))
                      * x)
                    - Math.sqrt(tankRadius**2 - x**2)
                    - (Math.tan(toRad(angle2))
                      * impeller.offsetX)
                    + impeller.offsetY)
          }
          const fprime = x => {
            return  ((x
                      / Math.sqrt(tankRadius**2 - x**2))
                    + Math.tan(toRad(angle2)))
          }
          const delta_px = x => {
            return  (- f(x) / fprime(x))
          }
          let delta = delta_px(px)
          let iter = 0;
          while (Math.abs(delta) > 1e-4 && iter < 10) {
            px += delta
            delta = delta_px(px)
            iter++;
          }
          py =  ((px
                  * Math.tan(toRad(angle2)))
                - (impeller.offsetX
                  * Math.tan(toRad(angle2)))
                + impeller.offsetY);
          shaftLength = Math.sqrt((px - impeller.offsetX)**2
                                  + (py - impeller.offsetY)**2)
        } else {
          const angle2_ = angle2 - 180
          px =  (tankRadius
                * Math.cos(toRad(angle2_)));
          const f = x => {
            return  ((Math.tan(toRad(angle2_))
                      * x)
                    - Math.sqrt(tankRadius**2 - x**2)
                    - (Math.tan(toRad(angle2_))
                      * impeller.offsetX)
                    + impeller.offsetY)
          }
          const fprime = x => {
            return  ((x
                      / Math.sqrt(tankRadius**2 - x**2))
                    + Math.tan(toRad(angle2_)))
          }
          const delta_px = x => {
            return  (- f(x) / fprime(x))
          }
          let delta = delta_px(px)
          let iter = 0;
          while (Math.abs(delta) > 1e-4 && iter < 10) {
            px += delta
            delta = delta_px(px)
            iter++;
          }
          py =  ((px
                  * Math.tan(toRad(angle2_)))
                - (impeller.offsetX
                  * Math.tan(toRad(angle2_)))
                + impeller.offsetY);
          let segDis =  (offset
                          * Math.cos(toRad(90 + angle2_ - offsetAngle)))
          if (offset === 0) segDis = 0;
          const segMidPointX =  - (segDis
                                  * Math.sin(toRad(angle2_)))
          const segMidPointY =  (segDis
                                * Math.cos(toRad(angle2_)))
          px = segMidPointX - (px - segMidPointX)
          py = segMidPointY - (py - segMidPointY)
          shaftLength = Math.sqrt((px - impeller.offsetX)**2
                                  + (py - impeller.offsetY)**2)
        }
        let ox =  (px
                  - (shaftLength
                    * Math.cos(toRad(impeller.angle2 + impeller.angle4))))
        let oy =  (py
                  - (shaftLength
                    * Math.sin(toRad(impeller.angle2 + impeller.angle4))))
        geometryObj.pos = [
          (((px + ox)
            / 2)
          + (shaftLength
            * (1 - Math.cos(toRad(impeller.angle3)))
            * Math.cos(toRad(impeller.angle2 + impeller.angle4))
            / 2)),
          (((py + oy)
            / 2)
          + (shaftLength
            * (1 - Math.cos(toRad(impeller.angle3)))
            * Math.sin(toRad(impeller.angle2 + impeller.angle4))
            / 2)),
          (impeller.clearance
          - tank.bottomDepth
          + (shaftLength
            * Math.sin(toRad(impeller.angle3))
            / 2)),
        ]
      } else if (tank.type === "Rectangular") {
        const impellerCenterOnXY = {
          x: impeller.offsetX,
          y: impeller.offsetY
        }
        let bounds = {};
        if (impeller.angle2 > 0) {
          bounds = {
            x: ((impeller.angle2 + 90) % 360)  > 180 ? -tank.width/2 : tank.width/2,
            y: (impeller.angle2 % 360) > 180 ? -tank.length/2 : tank.length/2
          }
        } else if (impeller.angle2 < 0) {
          bounds = {
            x: (360 - ((impeller.angle2 + 90) % 360))  > 180 ? -tank.width/2 : tank.width/2,
            y: (360 - (impeller.angle2 % 360)) > 180 ? -tank.length/2 : tank.length/2
          }
        } else {
          bounds = {
            x: tank.width/2,
            y: tank.length/2
          }
        }
        const mountPoints = [
          {
            x:  bounds.x,
            y:  (impellerCenterOnXY.y
                - (impellerCenterOnXY.x
                  * Math.tan(toRad(impeller.angle2)))
                + (bounds.x
                  * Math.tan(toRad(impeller.angle2))))
          },
          {
            x:  (impellerCenterOnXY.x
                - (impellerCenterOnXY.y
                  / Math.tan(toRad(impeller.angle2)))
                + (bounds.y
                  / Math.tan(toRad(impeller.angle2)))),
            y: bounds.y
          }
        ]
        const distance = (x, y) => {return Math.sqrt(x**2 + y**2)};
        const lengths = mountPoints.map(mount =>{
          return distance(
            mount.x - impellerCenterOnXY.x,
            mount.y - impellerCenterOnXY.y)})
        let index = lengths[0] < lengths[1] ? 0 : 1
        index = isNaN(lengths[index]) ? 1 - index : index
        const mountPoint = mountPoints[index]
        shaftLength = lengths[index] / Math.cos(toRad(impeller.angle3))
        geometryObj.pos = [
          (mountPoint.x
          - (shaftLength
            * Math.cos(toRad(impeller.angle2 + impeller.angle4))
            * Math.cos(toRad(impeller.angle3))
            / 2)),
          (mountPoint.y
            - (shaftLength
              * Math.sin(toRad(impeller.angle2 + impeller.angle4))
              * Math.cos(toRad(impeller.angle3))
              / 2)),
          (impeller.clearance
          - tank.bottomDepth
          + (shaftLength
            * Math.sin(toRad(impeller.angle3))
            / 2))
        ]
      }
      geometryObj.rotate = {
        x: 0,
        y: (impeller.angle3 - 90),
        z: (impeller.angle2 + impeller.angle4),
      }
      geometryObj.dim.height = Math.abs(shaftLength);
    }
    geometryObj.color = colors.impellers;
    return geometryObj;
  });
  if (reactor.config.geometry.useShaft){
    return arr_geometryObj;
  } else {
    return [];
  }
}

function getGeometryRingSpargers(reactor) {
  const tank = reactor.config.geometry.tank;
  const arr_geometryObj = reactor.config.internals.ringSpargers.map(sparger => {
    const geometryObj = cloneDeep(geometryObj_default);
    geometryObj.shape = "Torus";
    geometryObj.res.theta = 50;
    geometryObj.res.phi = 50;
    geometryObj.dim.majorRadius = sparger.spargerDiameter / 2;
    geometryObj.dim.minorRadius = sparger.tubeDiameter / 2;
    geometryObj.rotate = {
      x: 0,
      y: 90,
      z: 0,
    };
    geometryObj.pos = [
      0,
      0,
      (sparger.bottomClearance
      - tank.bottomDepth)
    ]
    geometryObj.color = colors.ringSparger;
    geometryObj.options.capping = false;
    return geometryObj
  });
  return arr_geometryObj;
}

function getGeometryDipTubes(reactor) {
  const tank = reactor.config.geometry.tank;
  const arr_geometryObj = reactor.config.internals.dipTubes.map(dipTube => {
    const geometryObj = cloneDeep(geometryObj_default);
    
    const points = [];
    points.push(0, 0, 0);
    points.push(0, 0, dipTube.length1);
    points.push(
      - dipTube.length2 * Math.sin(toRad(dipTube.angle1)),
      0,
      dipTube.length2 * Math.cos(toRad(dipTube.angle1))
    );

    const lines = [];
    if (dipTube.length1 !== 0 && dipTube.length2 !== 0) {
      lines.push(3, 1, 0, 2);
    } else if (dipTube.length2 !== 0) {
      lines.push(2, 0, 2);
    } else if (dipTube.length1 !== 0) {
      lines.push(2, 1, 0);
    }

    geometryObj.shape = "Tube";
    geometryObj.dim.tubeRadius = dipTube.tubeDiameter / 2
    geometryObj.dim.points = points;
    geometryObj.dim.lines = lines;
    geometryObj.pos = [
      dipTube.distanceFromAxisX,
      dipTube.distanceFromAxisY,
      (dipTube.distanceFromBottom
      - tank.bottomDepth)
    ]
    geometryObj.rotate = {
      x: - dipTube.angle4,
      y: dipTube.angle2,
      z: dipTube.angle3,
    }
    geometryObj.color = colors.dipTubes;
    geometryObj.options.capping = true;
    return geometryObj;
  });
  return arr_geometryObj;
}

function getGeometryHelicalCoils(reactor) {
  const tank = reactor.config.geometry.tank;
  const arr_geometryObj = reactor.config.internals.helicalCoils.map(coil => {
    const geometryObj = cloneDeep(geometryObj_default);
    const points = [];
    const numberOfSidesPerTurn = 50;
    const numberOfPoints =  (coil.numberOfTurns
                            * numberOfSidesPerTurn);
    const lines = [numberOfPoints+1];
    const unitArc = 360 / numberOfSidesPerTurn;
    for (let i=0; i<=numberOfPoints; i++) {
      const angle = i * unitArc;
      points.push(
        (coil.coilDiameter
        / 2
        * Math.cos(toRad(angle))),
        (coil.coilDiameter
        / 2
        * Math.sin(toRad(angle))),
        (coil.coilPitch
        * angle
        / 360)
      );
      lines.push(i);
    }
    geometryObj.shape = "Tube";
    geometryObj.dim.tubeRadius = coil.tubeOD / 2;
    geometryObj.dim.points = points;
    geometryObj.dim.lines = lines;
    geometryObj.res.phi = 50;
    geometryObj.pos = [
      0,
      0,
      (coil.bottomClearance
      - tank.bottomDepth),
    ];
    geometryObj.color = colors.helicalCoils;
    geometryObj.options.capping = true;
    return geometryObj;
  })
  return arr_geometryObj;
}

function getGeometryTracer(reactor) {
  const geometryObj = cloneDeep(geometryObj_default);
  const tracer = reactor?.simulationControls?.species?.tracer
  const opVolume = reactor?.process?.operatingConditions?.operatingVolume;
  if (tracer && opVolume) {
    const { percentVolume, x, y, z } = tracer;
    const radius = Math.pow(
      3/4/Math.PI * percentVolume/100 * opVolume,
      1/3
    )
    geometryObj.shape = 'Sphere';
    geometryObj.dim = { radius: radius };
    geometryObj.pos = [x, y, z];
    geometryObj.color = colors.tracer;
    geometryObj.opacity = 1;
    return geometryObj
  }
  geometryObj.shape = 'Sphere';
  geometryObj.dim = { radius: 0 };
  return geometryObj;
}

function getGeometrySpeciesMonitors(reactor) {
  let arr_geometryObj = [];
  const tank = reactor.config.geometry.tank;
  const monitors = reactor?.simulationControls?.species?.monitors
  if (monitors) {
    arr_geometryObj = monitors.map((monitor) => {
      const geometryObj = cloneDeep(geometryObj_default);
      const { x, y, z } = monitor
      const ratio = 0.02
      let radius = tank.diameter * ratio;
      if (tank.type === 'Rectangular') {
        const { length, width, straightSide } = tank;
        radius = Math.min(length, width, straightSide) * ratio;
      }
      geometryObj.shape = 'Sphere';
      geometryObj.dim = { radius: radius };
      geometryObj.pos = [x, y, z];
      geometryObj.color = colors.monitors;
      geometryObj.opacity = 1;
      return geometryObj
    });
  }
  return arr_geometryObj;
}

function getGeometryHeatTransferMonitors(reactor) {
  let arr_geometryObj = [];
  const tank = reactor.config.geometry.tank;
  const monitors = reactor?.simulationControls?.heatTransfer?.monitors
  if (monitors) {
    arr_geometryObj = monitors.map((monitor) => {
      const geometryObj = cloneDeep(geometryObj_default);
      const { x, y, z } = monitor
      const ratio = 0.02
      let radius = tank.diameter * ratio;
      if (tank.type === 'Rectangular') {
        const { length, width, straightSide } = tank;
        radius = Math.min(length, width, straightSide) * ratio;
      }
      geometryObj.shape = 'Sphere';
      geometryObj.dim = { radius: radius };
      geometryObj.pos = [x, y, z];
      geometryObj.color = colors.heatTransferMonitors;
      geometryObj.opacity = 1;
      return geometryObj
    });
  }
  return arr_geometryObj;
}

function getTransformTankHead(reactor) {
  let transform = cloneDeep(transform_default)
  transform.geometry = [getGeometryTankHead(reactor)]
  transform.color = colors.tank
  transform.opacity = 0.5
  // transform.label = 'Tank Head' // Adding toggle button
  return transform
}

function getTransformTankMiddle(reactor) {
  let transform = cloneDeep(transform_default)
  transform.geometry = [getGeometryTankMiddle(reactor)]
  transform.color = colors.tank
  transform.opacity = 0.5
  // transform.label = 'Tank Middle' // Adding toggle button
  return transform
}

function getTransformTankBottom(reactor) {
  let transform = cloneDeep(transform_default)
  transform.geometry = [getGeometryTankBottom(reactor)]
  transform.color = colors.tank
  transform.opacity = 0.5
  // transform.label = 'Tank Bottom' // Adding toggle button
  return transform
}

function getTransformTank(reactor) {
  let transform = cloneDeep(transform_default)
  const tankType = reactor.config.geometry.tank.type;
  let geometryObjTank = []
  if (tankType === 'Cylindrical') {
    geometryObjTank = [
      getGeometryTankHead(reactor),
      getGeometryTankMiddle(reactor),
      getGeometryTankBottom(reactor),
    ]
  } else if (tankType === 'Rectangular') {
    geometryObjTank = [
      getGeometryTankMiddle(reactor)
    ]
  }
  transform.geometry = geometryObjTank
  transform.color = colors.tank
  transform.opacity = 0.5
  // transform.label = 'Tank' // Adding toggle button
  return transform
}

function getTransformBaffles(reactor) {
  let transform = cloneDeep(transform_default)
  transform.geometry = getGeometryBaffles(reactor)
  transform.color = colors.baffles
  // transform.label = 'Baffles' // Adding toggle button
  return transform
}

function getTransformImpellers(reactor) {
  let transform = cloneDeep(transform_default)
  transform.geometry = getGeometryImpellers(reactor)
  transform.color = colors.impellers
  // transform.label = 'Impellers' // Adding toggle button
  return transform
}

function getTransformShafts(reactor) {
  let transform = cloneDeep(transform_default)
  transform.geometry = getGeometryShafts(reactor)
  transform.color = colors.impellers
  // transform.label = 'Shafts' // Adding toggle button
  return transform
}

function getTransformRingSpargers(reactor) {
  let transform = cloneDeep(transform_default)
  transform.geometry = getGeometryRingSpargers(reactor)
  transform.color = colors.ringSparger
  // transform.label = 'Spargers' // Adding toggle button
  return transform
}

function getTransformDipTubes(reactor) {
  let transform = cloneDeep(transform_default)
  transform.geometry = getGeometryDipTubes(reactor)
  transform.color = colors.dipTubes
  // transform.label = 'DipTubes' // Adding toggle button
  return transform
}

function getTransformHelicalCoils(reactor) {
  let transform = cloneDeep(transform_default)
  transform.geometry = getGeometryHelicalCoils(reactor)
  transform.color = colors.helicalCoils
  // transform.label = 'HelicalCoils' // Adding toggle button
  return transform
}

function getTransformLiquidLevel(reactor) {
  if (!reactor.process || !reactor.process.operatingConditions) {
    return cloneDeep(transform_default)
  }
  const tankType = reactor.config.geometry.tank.type;
  const liquidLevel = reactor.process.operatingConditions.liquidLevel;
  const bottomDepth = reactor.config.geometry.tank.bottomDepth;
  const normal = [0, 0, 1]
  let origin = [0, 0, 0]
  if (liquidLevel > 0) {
    origin = [
      0,
      0,
      (liquidLevel
      - bottomDepth)
    ];
  } else {
    origin = [
      0, 
      0, 
      -bottomDepth
    ];
  }
  let geometryObjTank = []
  if (tankType === 'Cylindrical') {
    geometryObjTank = [
      getGeometryTankHead(reactor),
      getGeometryTankMiddle(reactor),
      getGeometryTankBottom(reactor),
    ]
  } else if (tankType === 'Rectangular') {
    geometryObjTank = [
      getGeometryTankMiddle(reactor)
    ]
  }

  return {
      geometry: geometryObjTank,
      clip: [],
      cut: [],
      closedClip: [{origin, normal}],
      color: colors.liquidLevel,
      opacity: 0.25,
      label: 'Liquid Level' // Adding toggle button
  }
}

function getTransformTracer(reactor) {
  const geometry = getGeometryTracer(reactor);
  const transform = {
    geometry: [geometry],
    clip: [],
    cut: [],
    closedClip: [],
    color: colors.tracer,
    opacity: 1,
    // label: 'Tracer' // Adding toggle button
  };
  return transform;
}

function getTransformSpeciesMonitors(reactor) {
  const arr_geometry = getGeometrySpeciesMonitors(reactor);
  const transform = {
    geometry: arr_geometry,
    clip: [],
    cut: [],
    closedClip: [],
    color: colors.monitors,
    opacity: 1,
    // label: 'Monitors' // Adding toggle button
  };
  return transform;
}

function getTransformHeatTransferMonitors(reactor) {
  const arr_geometry = getGeometryHeatTransferMonitors(reactor);
  const transform = {
    geometry: arr_geometry,
    clip: [],
    cut: [],
    closedClip: [],
    color: colors.heatTransferMonitors,
    opacity: 1,
    // label: 'Monitors' // Adding toggle button
  };
  return transform;
}

function getGeometryAxis(scale) {
  const directions = {
    "X": [1.0, 0.0, 0.0],
    "Y": [0.0, 1.0, 0.0],
    "Z": [0.0, 0.0, 1.0],
  }
  const arr_geometryObj = [];
  for (let axis in directions) {
    const geometryObj = cloneDeep(geometryObj_default);
    geometryObj.shape = "Arrow";
    geometryObj.dim.tipRadius = 0.02;
    geometryObj.dim.tipLength = 0.1;
    geometryObj.dim.shaftRadius = 0.01;
    geometryObj.dim.arrowLength = 1;
    geometryObj.res.theta = 50;
    geometryObj.res.phi = 6;
    geometryObj.dim.direction = directions[axis];
    geometryObj.color = colors[axis];
    geometryObj.opacity = 1
    geometryObj.scale = geometryObj.scale.map(val => val * scale)
    arr_geometryObj.push(geometryObj);
  }
  return arr_geometryObj;
}

export {
  toRad,
  toDeg,
  getTransformTankHead,
  getTransformTankMiddle,
  getTransformTankBottom,
  getTransformTank,
  getTransformBaffles,
  getTransformImpellers,
  getTransformShafts,
  getTransformRingSpargers,
  getTransformDipTubes,
  getTransformHelicalCoils,
  getTransformLiquidLevel,
  getTransformTracer,
  getTransformSpeciesMonitors,
  getTransformHeatTransferMonitors,
  getGeometryAxis,
}