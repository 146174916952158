import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import axios from 'axios';
import AuthLayout from './AuthLayout';
import { Formik } from 'formik';

const ResetPasswordPage = ({ token, onSuccess = () => {} }) => {
  const [isResetting, setResetting] = useState(false);
  const history = useHistory();

  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  return (
    <AuthLayout>
      <div className="text-center mt-4 text-light">
        <h1 className="h2">Reset Password</h1>
        <p className="lead">Enter your new password</p>
      </div>

      <Card>
        <Card.Body>
          <Formik
            initialValues={initialValues}
            onSubmit={async (values) => {
              setResetting(true);
              try {
                const { data } = await axios.post('/api/auth/reset-password', {
                  ...values,
                  token,
                });
                onSuccess(data);
                history.push('/auth/signin');
              } catch (err) {
                alert(err.response.data);
                setResetting(false);
              }
            }}
          >
            {({ handleSubmit, handleChange, values }) => (
              <Form>
                <Form.Group>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    size="sm"
                    name="password"
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    size="sm"
                    name="confirmPassword"
                    type="password"
                    value={values.confirmPassword}
                    onChange={handleChange}
                  />
                </Form.Group>

                <div className="text-center mt-3">
                  <Button color="primary" size="sm" onClick={handleSubmit}>
                    {isResetting && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        variant="light"
                        className="mr-2"
                      />
                    )}
                    Reset Password
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </AuthLayout>
  );
};

export default ResetPasswordPage;
