import { Breadcrumb } from 'react-bootstrap';

function BreadCrumb({breadCrumbItmes, ...rest}) {
  return (
    <Breadcrumb {...rest}>
    {
        breadCrumbItmes.map((item, index) => <Breadcrumb.Item 
            key={index}
            onClick={item.onClick}
            active={breadCrumbItmes.length - 1 === index ? true : false} 
        > {item.title} </Breadcrumb.Item>)
    }
    </Breadcrumb>
  )
}

export default BreadCrumb