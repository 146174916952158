// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { UserContext } from '@tri/ui-shared';
import { useContext } from 'react'
import { Table } from '../../common/SimsightTable';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import CenteredSpinner from '../../../../../../libs/ui-shared/src/lib/CenteredSpinner';
import { useMyProjectDetailsData } from '../services/business-logic/project.businesslogic';
import { pillTabs } from '../../utils/constants';
import { AddOrUpdateSimulationProject } from './AddOrUpdateSimulationProject';

function MyProjectPanel() {
  const { user } = useContext(UserContext);
  const { selectedProject, setSelectedProject, projectList, allMyProjectTableData, listOfAllProjectTableColumn, onClickOnProject } = useMyProjectDetailsData(user)

  return (
    <div className="px-2">
      <AddOrUpdateSimulationProject user={user} selectedProject={selectedProject} />
      {projectList.status === 'loading' && <CenteredSpinner />}
      {projectList.status === 'error' && <h5>Something went wrong ...</h5>}
      {projectList.status === 'success' && (allMyProjectTableData?.length > 0 ?
          <Table
            data={allMyProjectTableData}
            columns={listOfAllProjectTableColumn}
            initialState={{ hiddenColumns: ['_id'] }}
            selectionEnabled={true}
            onSelect={setSelectedProject}
            tableClass="table skip-table-scroll skip-table-scroll-col1 skip-table-scroll-col2"
            onClickLink={onClickOnProject}
            searchBoxPlaceholder="Search in Project Table"
            disableSortBy={false}
          /> :  <h5>No data found...</h5>)
      }
    </div>
  )
}

const MyProjects = () => {
  return {
    label: pillTabs.MyProjects,
    Panel: <MyProjectPanel/>,
    tabEnabled: true
  }
}

export { MyProjects }
