import { useMemo, useState } from 'react';
import { useGetSimcaseDocumentURL, useSimCaseDocumentsList } from '../data-service/documents.dataservice';

const GetSimcaseDocument = (simcaseId, fileName) => useGetSimcaseDocumentURL(simcaseId, fileName).data

const useSimCaseDocumentsData = (simcaseId) => {
    const simcaseDocuments = useSimCaseDocumentsList([simcaseId])
    const [isGettingDocuments, setIsGettingDocuments] = useState(true)
    const [documents, setdocuments] = useState({})

    useMemo(() => {
        if(simcaseDocuments.status === 'success') {
            let allSimCaseDocumentsData = simcaseDocuments.data || {}
            // eslint-disable-next-line no-prototype-builtins
            let simcaseDocumentData = allSimCaseDocumentsData.hasOwnProperty(simcaseId) ? allSimCaseDocumentsData[simcaseId] : []

            let allDocuments = {}
            simcaseDocumentData[1]?.file_info.forEach(file => {
                let lastIndexOfDotFromFileName = file.lastIndexOf('.')
                let fileName = file.substring(0, lastIndexOfDotFromFileName)
                let extension = file.substring(lastIndexOfDotFromFileName + 1)
                
                allDocuments[extension] = allDocuments[extension] ? 
                    [...allDocuments[extension], fileName ]
                    : [fileName]
            })
            setdocuments({...allDocuments})
            setIsGettingDocuments(false)
        }
    }, [simcaseDocuments.status])

    return { isGettingDocuments, documents }
}

export { GetSimcaseDocument, useSimCaseDocumentsData }