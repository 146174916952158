import { useState, useCallback } from 'react';
import { Button, Spinner } from 'react-bootstrap';

const ButtonWithSpinner = ({
  content,
  onClick = () => {},
  initiallySpinning = false,
  buttonClass,
  spinnerClass,
  ...rest
}) => {
  const [isSpinning, setSpinning] = useState(initiallySpinning);
  const handleClickCallback = useCallback(async () => {
    try {
      setSpinning(true);
      await onClick();
      setSpinning(false);
    } catch (error) {
      alert(error.response?.data ?? 'Something went wrong');
      setSpinning(false);
    }
  }, [onClick]);

  return (
    <Button className={buttonClass} onClick={handleClickCallback} {...rest}>
      {isSpinning && (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          variant="light"
          className={spinnerClass}
        />
      )}
      {content}
    </Button>
  );
};

export default ButtonWithSpinner;
