import { isArray, isObject, isString } from 'lodash';

export const ToScientificValue = (value) => {
  // if value type is number
  if (typeof value === 'number') {
    const data = convertValue(value);
    return data;
  }

  // if value type is a numeric string
  else if (!isNaN(value)) {
    let val = parseFloat(value);

    const data = convertValue(val);
    return data;
  }

  // if the value is of array type
  // iterate over array and convert the values accordingly
  else if (isArray(value)) {
    const valx = value.map((item) => {
      const res = item.split(',').map((x) => {
        let temp = x.trim();
        let some = !isNaN(temp) ? parseFloat(temp) : temp;
        const data = convertValue(some);
        // added extra ' ' for more redability
        return data;
      });

      return res;
    });

    return valx;
  } else if (isObject(value)) {
    let obj = value;
    for (const key in value) {
      let some = !isNaN(obj[key]) ? parseFloat(obj[key]) : obj[key];
      obj[key] = convertValue(some);
    }
    return obj;
  }

  // else return the value as it is (for cases like string etc.)
  else {
    return value;
  }
};

const convertValue = (value) => {
  // if value is greater than 9999 or smaller than 0.009 and not equals to 0 then covert it to scientific notation
  if (value > 9999 || value <= 0.009) {
    if (value === 0) {
      return value;
    }
    // if the value lies between 0 and 0.001 fix upto 3 decimals.
    else if (value < 0 || (value <= 0.009 && value >= 0.001)) {
      return value.toFixed(3);
    } else {
      return value.toExponential(3);
    }
  }

  // if numstring is perfect integer then return it as it is OR if value is string then return it as it is.
  else if (Number.isInteger(value) || isString(value)) {
    return value;
  }

  // else fix it upto 3 decimals.
  else {
    return value.toFixed(3);
  }
};
