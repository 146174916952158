// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { CenteredSpinner, UserContext } from '@tri/ui-shared';
import { useContext, useState } from 'react';
import { Button, Form, Row, Col, Breadcrumb } from 'react-bootstrap';
import { Edit2, Save } from 'react-feather';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import { getCookie } from '../utils';
import ToastContext from '../context';
import MandatorySymbol from '../common/MandatorySymbol';
import _ from 'lodash';

const UserProfile = () => {
  const [readlonlyFlag, setReadonlyFlag] = useState(true);
  const { user } = useContext(UserContext);
  const setToast = useContext(ToastContext)
  
  const config = {
    headers: {
      'X-CSRF-TOKEN': getCookie('csrf_token')
    },
  };

  const queryClient = useQueryClient();

  //Get user details
  const userProfileData = useQuery(['userDetails', user._id], async () => {
    const { data, status } = await axios.get(`/api/users/${user._id}/`, config);
    return {
      data: data.data[0],
    };
  });

  let initialValues = {
    userName: '',
    firstName: '',
    lastName: '',
    emailId: '',
    userType: '',
  };

  if (userProfileData?.data?.data) {
    initialValues = {
      userName: userProfileData?.data?.data.username,
      firstName: userProfileData?.data?.data.first_name ?? '',
      lastName: userProfileData?.data?.data.last_name ?? '',
      emailId: userProfileData?.data?.data.email,
      userType: userProfileData?.data?.data.user_type,
    };
  } else if (userProfileData?.data) {
    initialValues = {
      userName: userProfileData?.data.username,
      firstName: userProfileData?.data.first_name ?? '',
      lastName: userProfileData?.data.last_name ?? '',
      emailId: userProfileData?.data.email,
      userType: userProfileData?.data.user_type,
    };
  }

  const editProfile = async (values) => {
    let fd = new FormData();

    queryClient.setQueryData('user-profileData', {
      ...values,
    });

    const editUserProfileData = {
      user_id: user._id,
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.emailId,
    };
    fd.append('data', JSON.stringify(editUserProfileData));
    try {
      await axios.patch('/api/user/', fd, config);
    } catch (err) {
      setToast({
        statusCode: 500,
        message: err
      })
    }
    setReadonlyFlag(true);
  };

  const ValidationSchema = Yup.object().shape({
    emailId: Yup.string().email().required('Email is Required'),
  });

  return (
    <div style={{marginTop: '3.3rem'}}>
      <Breadcrumb>
        <Breadcrumb.Item active={true}>Profile</Breadcrumb.Item>
      </Breadcrumb>
      {userProfileData.status === 'loading' && <CenteredSpinner />}
      {userProfileData.status === 'error' && <h5>Something went wrong ...</h5>}
      {userProfileData.status === 'success' && (
        <div className="px-3" style={{ width: '32em' }}>
          <Formik
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            onSubmit={editProfile}
            enableReinitialize={true}
          >
            {({
              handleSubmit,
              values,
              errors,
              setFieldValue,
              handleChange,
              touched
            }) =>{ 

              console.log(initialValues, values);
              console.log(_.isEqual(initialValues, values));
              return (
              <Form>
                <div style={{ height: '3em' }}>
                  {readlonlyFlag && (
                    <Button
                      size="sm"
                      variant="outline-primary"
                      className="mr-1 mb-1"
                      title="Edit profile"
                      onClick={() => {
                        setReadonlyFlag(false);
                      }}
                    >
                      <Edit2 size={20} />
                    </Button>
                  )}
                  {!readlonlyFlag && 
                      <Button
                        size="sm"
                        variant="outline-primary"
                        className="mr-1 mb-1"
                        title="Update profile"
                        onClick={handleSubmit}
                        disabled={_.isEqual(initialValues, values)}
                      >
                        <Save size={20} />
                      </Button>
                  }
                </div>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="4">
                    Username
                  </Form.Label>
                  <Col sm="8">
                    <Field
                      type="text"
                      name="userName"
                      placeholder="Enter Username"
                      value={values.userName}
                      onChange={handleChange}
                      className="form-control"
                      disabled
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="4">
                    First Name
                  </Form.Label>
                  <Col sm="8">
                    <Field
                      type="text"
                      name="firstName"
                      placeholder="Enter First Name"
                      value={values.firstName}
                      onChange={handleChange}
                      className="form-control"
                      disabled={readlonlyFlag}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="4">
                    Last Name
                  </Form.Label>
                  <Col sm="8">
                    <Field
                      type="text"
                      name="lastName"
                      placeholder="Enter Last Name"
                      value={values.lastName}
                      onChange={handleChange}
                      className="form-control"
                      disabled={readlonlyFlag}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="4">
                    Email {!readlonlyFlag && <MandatorySymbol />}
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="email"
                      name="emailId"
                      placeholder="Enter Email"
                      value={values.emailId}
                      onChange={handleChange}
                      className="form-control"
                      disabled={readlonlyFlag}
                      isInvalid={errors.emailId}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.emailId}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="4">
                    User Type
                  </Form.Label>
                  <Col sm="8">
                    <Field
                      type="text"
                      name="userType"
                      placeholder="Enter User Type"
                      value={values.userType}
                      onChange={handleChange}
                      className="form-control"
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Form>
            )}}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default UserProfile;
