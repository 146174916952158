import axios from 'axios';
import { useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import ViewSimData from '../sim-data-explorer/ViewSimData';
import ViewAsset from './ViewAsset';
import { getCookie } from '../utils';

const AssetData = ({ assetId, activeSubSubView }) => {
  const history = useHistory();
  const [activeCaseName, setActiveCaseName] = useState();
  const queryClient = useQueryClient();
  const config = {
    headers: {
      'X-CSRF-TOKEN': getCookie('csrf_token')
    },
  };

  let assetTitle = '';
  const assetQuery = useQuery(['assets', assetId], async () => {
    if (assetId) {
      const { data } = await axios.get(`/api/v3/assets/${assetId}/`, config);
      return data;
    }
  });
  if (assetQuery.data) {
    const isNewAsset = assetId === 'new';
    assetTitle = isNewAsset
      ? 'New'
      : assetQuery.data.data
      ? `${assetQuery.data.data.asset_name}`
      : undefined;
  }
  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item
          onClick={() => {
            history.push('/app/home');
          }}
          active={false}
        >
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => {
            history.push('/app/asset');
          }}
          active={false}
        >
          Asset Explorer
        </Breadcrumb.Item>

        <Breadcrumb.Item
          active={!activeSubSubView}
          onClick={() => {
            history.push(`/app/asset/${assetId}`);
          }}
        >
          {assetTitle}
        </Breadcrumb.Item>
        {activeSubSubView && (
          <Breadcrumb.Item active>{activeCaseName}</Breadcrumb.Item>
        )}
      </Breadcrumb>

      {assetId !== 'new' && assetQuery.data && !activeSubSubView && (
        <ViewAsset
          assetData={assetQuery.data.data}
          onProcessParamsChange={() => {
            queryClient.invalidateQueries(['assets', assetId]);
          }}
        />
      )}
      {activeSubSubView && (
        <ViewSimData
          simId={activeSubSubView}
          onLoad={(assetData) => {
            setActiveCaseName(assetData.case_name);
          }}
        />
      )}
    </>
  );
};

export default AssetData;
