import { useContext, useEffect, useMemo, useRef } from 'react';
import { useFormikContext } from 'formik';
import { Card, Form } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import axios from 'axios';
import { DefContext } from '@tri/ui-shared';
import {
  RadioGroupField2,
  HorizontalFormField,
  SelectOptions,
} from '../common/FormFields';
import {
  CheckboxControl,
  QuantityControl,
  SelectControl,
  Unit,
} from '../common/FormControls';
import {
  Table,
  TableHeader,
  SelectCell,
  QuantityCell,
  TextCell,
} from '../common/Table';

const END_STYLES = [
  { label: 'Conical', value: 'Conical' },
  { label: '2:1 Elliptical', value: '2:1Elliptical' },
  { label: 'Flat', value: 'Flat' },
  { label: 'Hemispherical', value: 'Hemispherical' },
  { label: '10% Torispherical', value: '10%Torispherical' },
  { label: '6% Torispherical', value: '6%Torispherical' },
];

const mountingOptions = ['', 'Top', 'Side', 'Bottom'];

const calculateDishedEndDepth = (dishedEndStyle, diameter) => {
  if (dishedEndStyle === '2:1Elliptical') return diameter / 4;
  else if (dishedEndStyle === 'Conical') return diameter / 2;
  else if (dishedEndStyle === 'Flat') return 0;
  else if (dishedEndStyle === 'Hemispherical') return diameter / 2;
  else if (dishedEndStyle === '10%Torispherical')
    return calculateTorisphericalEndDepth(diameter, 0.1);
  else if (dishedEndStyle === '6%Torispherical')
    return calculateTorisphericalEndDepth(diameter, 0.06);
};

const calculateTorisphericalEndDepth = (diameter, knuckRadFact) => {
  const dishRadFact = 1.0;
  const fact = (1 - 2 * knuckRadFact) / (2 * (dishRadFact - knuckRadFact));
  const toriAlpha = Math.asin(fact); //angle in radians
  const toriA1 = dishRadFact * diameter * (1 - Math.cos(toriAlpha));
  const toriA2 = knuckRadFact * diameter * Math.cos(toriAlpha);
  return toriA1 + toriA2;
};

const calculateStandardBaffles = (diameter, botDepth, headDepth) => {
  const standardBafflesData = [];
  let prev = 0;
  for (let i = 1; i <= 4; i++) {
    standardBafflesData.push({
      id: i,
      isStandard: true,
      type: 'Regular Flat',
      width: diameter / 12,
      offsetWall: diameter / 72,
      offsetTop: headDepth,
      offsetBot: botDepth,
      angle1: prev,
      angle2: 0,
    });
    prev += 90;
  }
  return standardBafflesData;
};

const BaffleQuantityCell = (props) => {
  return props.row.original.type === 'Custom' ? (
    <TextCell {...props} disabled={true} />
  ) : (
    <QuantityCell {...props} />
  );
};

const BaffleTypeCell = (props) => {
  const fileInputEl = useRef(null);
  const { setFieldValue } = useFormikContext();
  const {
    row: { index },
    column,
    value,
    tableName,
  } = props;
  const { id, options, disabled } = column;
  const name = `${tableName}.${index}.${id}`;

  return (
    <>
      <Form.Control
        as="select"
        value={value}
        onChange={(e) => {
          const newValue = e.target.value;
          if (newValue === 'Custom') {
            // MOW-191: Don't change value right away. Change only if user supplies a file.
            fileInputEl.current.click();
          } else {
            setFieldValue(name, newValue);
            setFieldValue(`${tableName}.${index}.width`, 0);
            setFieldValue(`${tableName}.${index}.offsetWall`, 0);
            setFieldValue(`${tableName}.${index}.offsetTop`, 0);
            setFieldValue(`${tableName}.${index}.offsetBot`, 0);
            setFieldValue(`${tableName}.${index}.angle1`, 0);
            setFieldValue(`${tableName}.${index}.angle2`, 0);
          }
        }}
        disabled={disabled}
      >
        <SelectOptions options={options} />
      </Form.Control>
      <input
        type="file"
        ref={fileInputEl}
        className="d-none"
        accept=".stl"
        onChange={async (e) => {
          const file = e.currentTarget.files[0];
          const formData = new FormData();
          formData.append('custom-baffle-file', file);
          const { data: fileName } = await axios.post(
            '/api/files/custom/baffles',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );
          setFieldValue(`${tableName}.${index}.file`, fileName);
          // MOW-191: Change value now as user has supplied a file.
          setFieldValue(name, 'Custom');
          setFieldValue(`${tableName}.${index}.width`, 0);
          setFieldValue(`${tableName}.${index}.offsetWall`, 0);
          setFieldValue(`${tableName}.${index}.offsetTop`, 0);
          setFieldValue(`${tableName}.${index}.offsetBot`, 0);
          setFieldValue(`${tableName}.${index}.angle1`, 0);
          setFieldValue(`${tableName}.${index}.angle2`, 0);
        }}
      />
    </>
  );
};

const MountingCell = (props) => {
  const { setFieldValue } = useFormikContext();
  const {
    row: { index },
    tableName,
  } = props;
  return (
    <SelectCell
      {...props}
      onChange={(newValue) => {
        const angle1 = `${tableName}.${index}.angle1`;
        const angle4 = `${tableName}.${index}.angle4`;
        if (newValue === 'Side') setFieldValue(angle1, 90);
        else if (newValue === 'Bottom') {
          setFieldValue(angle1, 180);
          setFieldValue(angle4, 0);
        } else {
          setFieldValue(angle1, 0);
          setFieldValue(angle4, 0);
        }
      }}
    />
  );
};

const ClearanceCell = (props) => {
  const {
    data,
    row: { index },
  } = props;
  return (
    <QuantityCell
      {...props}
      onBlur={(e) => {
        if (index > 0) {
          const currentClearance = e.target.valueAsNumber;
          const clearanceOfPrevImpeller = data[index - 1].clearance;
          if (currentClearance <= clearanceOfPrevImpeller)
            alert(
              `Clearance of impeller-${
                index + 1
              } must be greater than clearance of impeller-${index}`
            );
        }
      }}
    />
  );
};

const Angle1Cell = (props) => {
  const { row } = props;
  return <QuantityCell {...props} disabled={row.original.mounting !== 'Top'} />;
};

const Angle4Cell = (props) => {
  const { row } = props;
  return (
    <QuantityCell
      {...props}
      disabled={
        row.original.mounting === 'Top' || row.original.mounting === 'Bottom'
      }
    />
  );
};

const GeometryPanel = ({
  data,
  disabled,
  setFieldValue = () => {},
  onGeometryChange = () => {},
}) => {
  const { impellers: builtinImpellers = [] } = useContext(DefContext);
  const queryClient = useQueryClient();
  const userImpellers = queryClient.getQueryData('user-impellers') || [];

  const geometryData = data.config.geometry;
  const baffleData = geometryData.baffles || [];
  const baffleCount = baffleData.length;
  const baffleType =
    baffleCount < 1
      ? 'none'
      : geometryData.baffles[0].isStandard
      ? 'standard'
      : 'non-standard';
  const impellerData = geometryData.impellers;
  const impellerCount = impellerData.length;

  const impellerOptions = useMemo(
    () =>
      data.config.geometry.impellers.map(({ type, name, fileName }) => {
        const label = `${type}-${name}`;
        const value = fileName?.split('.')[0]; // strips .stl from file name
        return { value, label };
      }),
    [data.config.geometry.impellers]
  );

  const baffleTableColumns = useMemo(
    () => [
      {
        title: 'Sr. No.',
        accessor: 'id',
        dataType: 'number',
        disabled: true,
        style: {
          width: '8%',
        },
      },
      {
        title: 'Type',
        accessor: 'type',
        Cell: BaffleTypeCell,
        options: ['Regular Flat', 'Custom'],
        style: {
          width: '20%',
        },
      },
      {
        title: 'Width',
        accessor: 'width',
        Cell: BaffleQuantityCell,
        qty: 'length',
        dataType: 'number',
        style: {
          width: '12%',
        },
      },
      {
        title: 'Off Wall',
        accessor: 'offsetWall',
        Cell: BaffleQuantityCell,
        qty: 'length',
        dataType: 'number',
        style: {
          width: '12%',
        },
      },
      {
        title: 'Off Bot',
        accessor: 'offsetBot',
        Cell: BaffleQuantityCell,
        qty: 'length',
        dataType: 'number',
        style: {
          width: '12%',
        },
      },
      {
        title: 'Off Top',
        accessor: 'offsetTop',
        Cell: BaffleQuantityCell,
        qty: 'length',
        dataType: 'number',
        style: {
          width: '12%',
        },
      },
      {
        title: 'Angle 1',
        accessor: 'angle1',
        Cell: BaffleQuantityCell,
        qty: 'angle',
        dataType: 'number',
        style: {
          width: '12%',
        },
      },
      {
        title: 'Angle 2',
        accessor: 'angle2',
        Cell: BaffleQuantityCell,
        qty: 'angle',
        dataType: 'number',
        style: {
          width: '12%',
        },
      },
    ],
    [baffleType]
  );

  const impellerTableColumns = useMemo(
    () => [
      {
        title: 'Sr. No.',
        accessor: 'id',
        dataType: 'number',
        disabled: true,
        style: {
          width: '8%',
        },
      },
      {
        title: 'Type',
        accessor: 'type',
        options: impellerOptions,
        Cell: TextCell,
        style: {
          width: '20%',
        },
      },
      {
        title: 'Mounting',
        accessor: 'mounting',
        options: mountingOptions,
        Cell: MountingCell,
        style: {
          width: '8%',
        },
      },
      {
        title: 'Diameter',
        accessor: 'diameter',
        dataType: 'number',
        qty: 'length',
        style: {
          width: '8%',
        },
      },
      {
        title: 'Clearance',
        accessor: 'clearance',
        dataType: 'number',
        qty: 'length',
        Cell: ClearanceCell,
        style: {
          width: '8%',
        },
      },
      {
        title: 'Offset X',
        accessor: 'offsetX',
        dataType: 'number',
        qty: 'length',
        style: {
          width: '8%',
        },
      },
      {
        title: 'Offset Y',
        accessor: 'offsetY',
        dataType: 'number',
        qty: 'length',
        style: {
          width: '8%',
        },
      },
      {
        title: 'Angle 1',
        accessor: 'angle1',
        Cell: Angle1Cell,
        dataType: 'number',
        qty: 'angle',
        style: {
          width: '8%',
        },
      },
      {
        title: 'Angle 2',
        accessor: 'angle2',
        dataType: 'number',
        qty: 'angle',
        style: {
          width: '8%',
        },
      },
      {
        title: 'Angle 3',
        accessor: 'angle3',
        dataType: 'number',
        qty: 'angle',
        style: {
          width: '8%',
        },
      },
      {
        title: 'Angle 4',
        accessor: 'angle4',
        Cell: Angle4Cell,
        dataType: 'number',
        qty: 'angle',
        style: {
          width: '8%',
        },
      },
    ],
    [impellerOptions]
  );

  const headStyleDisabled =
    disabled || geometryData.tank.type === 'Rectangular';
  const headDepthDisabled =
    disabled || geometryData.tank.headStyle !== 'Conical';
  const bottomStyleDisabled =
    disabled || geometryData.tank.type === 'Rectangular';
  const bottomDepthDisabled =
    disabled || geometryData.tank.bottomStyle !== 'Conical';

  useEffect(() => {
    if (geometryData.tank.type === 'Rectangular') {
      setFieldValue('config.geometry.tank.headStyle', 'Flat');
      setFieldValue('config.geometry.tank.bottomStyle', 'Flat');
    }
  }, [geometryData.tank.type]);

  useEffect(() => {
    const headDepth = calculateDishedEndDepth(
      geometryData.tank.headStyle,
      geometryData.tank.diameter
    );
    setFieldValue('config.geometry.tank.headDepth', headDepth);
  }, [geometryData.tank.headStyle, geometryData.tank.diameter]);

  useEffect(() => {
    const bottomDepth = calculateDishedEndDepth(
      geometryData.tank.bottomStyle,
      geometryData.tank.diameter
    );
    setFieldValue('config.geometry.tank.bottomDepth', bottomDepth);
  }, [geometryData.tank.bottomStyle, geometryData.tank.diameter]);

  useEffect(() => {
    if (geometryData.tank.type === 'Rectangular') {
      setFieldValue('config.geometry.baffles', []);
    } else if (
      geometryData.tank.type === 'Cylindrical' &&
      baffleType === 'standard'
    ) {
      const standardBafflesData = calculateStandardBaffles(
        geometryData.tank.diameter,
        geometryData.tank.bottomDepth,
        geometryData.tank.headDepth
      );
      setFieldValue('config.geometry.baffles', standardBafflesData);
    }
  }, [
    geometryData.tank.type,
    geometryData.tank.diameter,
    geometryData.tank.bottomDepth,
    geometryData.tank.headDepth,
  ]);

  useEffect(() => {
    onGeometryChange(data);
  }, [
    data,
    geometryData.tank.type,
    geometryData.tank.length,
    geometryData.tank.width,
    geometryData.tank.diameter,
    geometryData.tank.straightSide,
    geometryData.tank.headStyle,
    geometryData.tank.headDepth,
    geometryData.tank.bottomStyle,
    geometryData.tank.bottomDepth,
    baffleData,
    geometryData.useShaft,
    impellerData,
  ]);

  const layoutProps = { w1: 4, w2: 6, rowClass: 'mb-1' };
  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title className="h6">Tank</Card.Title>
          <div className="row">
            <div className="col-sm">
              <HorizontalFormField label="Type" {...layoutProps}>
                <SelectControl
                  name="config.geometry.tank.type"
                  options={['Rectangular', 'Cylindrical']}
                  disabled={disabled}
                />
              </HorizontalFormField>

              {geometryData.tank.type === 'Rectangular' && (
                <HorizontalFormField label="Length" {...layoutProps}>
                  <QuantityControl
                    name="config.geometry.tank.length"
                    qty="length"
                    disabled={disabled}
                  />
                  <Unit qty="length" />
                </HorizontalFormField>
              )}

              {geometryData.tank.type === 'Rectangular' && (
                <HorizontalFormField label="Width" {...layoutProps}>
                  <QuantityControl
                    name="config.geometry.tank.width"
                    qty="length"
                    disabled={disabled}
                  />
                  <Unit qty="length" />
                </HorizontalFormField>
              )}

              {geometryData.tank.type === 'Cylindrical' && (
                <HorizontalFormField label="Diameter" {...layoutProps}>
                  <QuantityControl
                    name="config.geometry.tank.diameter"
                    qty="length"
                    disabled={disabled}
                  />
                  <Unit qty="length" />
                </HorizontalFormField>
              )}

              <HorizontalFormField label="Straight Side" {...layoutProps}>
                <QuantityControl
                  name="config.geometry.tank.straightSide"
                  qty="length"
                  disabled={disabled}
                />
                <Unit qty="length" />
              </HorizontalFormField>
            </div>

            <div className="col-sm">
              <HorizontalFormField label="Head Style" {...layoutProps}>
                <SelectControl
                  name="config.geometry.tank.headStyle"
                  options={END_STYLES}
                  disabled={headStyleDisabled}
                />
              </HorizontalFormField>

              <HorizontalFormField label="Head Depth" {...layoutProps}>
                <QuantityControl
                  name="config.geometry.tank.headDepth"
                  qty="length"
                  disabled={headDepthDisabled}
                />
                <Unit qty="length" />
              </HorizontalFormField>

              <HorizontalFormField label="Bottom Style" {...layoutProps}>
                <SelectControl
                  name="config.geometry.tank.bottomStyle"
                  options={END_STYLES}
                  disabled={bottomStyleDisabled}
                />
              </HorizontalFormField>

              <HorizontalFormField label="Bottom Depth" {...layoutProps}>
                <QuantityControl
                  name="config.geometry.tank.bottomDepth"
                  qty="length"
                  disabled={bottomDepthDisabled}
                />
                <Unit qty="length" />
              </HorizontalFormField>
            </div>
          </div>
        </Card.Body>
      </Card>

      <Card className="mt-3">
        <Card.Body>
          <Card.Title className="h6">Baffles</Card.Title>
          <RadioGroupField2
            name="config.geometry.baffles.type"
            options={[
              { name: 'No Baffles', value: 'none', disabled: disabled },
              {
                name: 'Standard Baffles',
                value: 'standard',
                disabled: disabled || geometryData.tank.type === 'Rectangular',
              },
              {
                name: 'Non-standard Baffles',
                value: 'non-standard',
                disabled: disabled || geometryData.tank.type === 'Rectangular',
              },
            ]}
            value={baffleType}
            onChange={(newValue) => {
              if (newValue === 'none') {
                setFieldValue('config.geometry.baffles', []);
              } else if (newValue === 'standard') {
                const stdBafflesData = calculateStandardBaffles(
                  geometryData.tank.diameter,
                  geometryData.tank.bottomDepth,
                  geometryData.tank.headDepth
                );
                setFieldValue('config.geometry.baffles', stdBafflesData);
              } else if (newValue === 'non-standard') {
                const stdBafflesData = calculateStandardBaffles(
                  geometryData.tank.diameter,
                  geometryData.tank.bottomDepth,
                  geometryData.tank.headDepth
                );
                const nonStdBafflesData = stdBafflesData.map((d) => {
                  return { ...d, isStandard: false };
                });
                setFieldValue('config.geometry.baffles', nonStdBafflesData);
              }
            }}
            className="mb-2"
          />
          {baffleType !== 'none' && (
            <>
              <div className="width-24">
                <HorizontalFormField w1={6} w2={6} label="Number of Baffles">
                  <Form.Control
                    name="baffleCount"
                    type="number"
                    value={baffleCount}
                    disabled={disabled || baffleType === 'standard'}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      const addedRowCount = newValue - baffleCount;
                      if (addedRowCount > 0) {
                        for (let i = 0; i < addedRowCount; i++) {
                          baffleData.push({
                            id: baffleCount + i + 1,
                            isStandard: false,
                            type: 'Regular Flat',
                          });
                        }
                        setFieldValue('config.geometry.baffles', [
                          ...baffleData,
                        ]);
                      } else {
                        const updatedData = baffleData.slice(0, addedRowCount);
                        setFieldValue('config.geometry.baffles', [
                          ...updatedData,
                        ]);
                      }
                    }}
                  />
                </HorizontalFormField>
              </div>
              <Table
                name="config.geometry.baffles"
                columns={baffleTableColumns}
                data={baffleData}
                defaultColumn={{
                  Header: TableHeader,
                  Cell: QuantityCell,
                  disabled: disabled || baffleType === 'standard',
                }}
                tableClass="table-borderless"
              />
            </>
          )}
        </Card.Body>
      </Card>

      <Card className="mt-3">
        <Card.Body>
          <Card.Title className="h6">Impellers</Card.Title>

          <CheckboxControl
            name="config.geometry.useShaft"
            label="Use Shaft"
            disabled={disabled}
          />

          <div className="width-24 mt-3">
            <HorizontalFormField label="Number of Impellers" w1={6} w2={6}>
              <Form.Control
                type="number"
                name="impellerCount"
                value={impellerCount}
                disabled={disabled}
                onChange={(e) => {
                  const newValue = e.target.value;
                  const addedRowCount = newValue - impellerCount;
                  if (addedRowCount > 0) {
                    for (let i = 0; i < addedRowCount; i++) {
                      let diameter = 0.45;
                      let clearance = 0.5;
                      if (impellerData.length > 0) {
                        const prevImpellerData =
                          impellerData[impellerData.length - 1];
                        diameter = prevImpellerData.diameter;
                        clearance = 1.1 * prevImpellerData.clearance;
                      }
                      impellerData.push({
                        id: impellerCount + i + 1,
                        type: 'Axial-Propeller',
                        mounting: 'Top',
                        diameter,
                        clearance,
                        offsetX: 0,
                        offsetY: 0,
                        angle1: 0,
                        angle2: 0,
                        angle3: 0,
                        angle4: 0,
                      });
                    }
                    setFieldValue('config.geometry.impellers', [
                      ...impellerData,
                    ]);
                  } else {
                    const updatedData = impellerData.slice(0, addedRowCount);
                    setFieldValue('config.geometry.impellers', [
                      ...updatedData,
                    ]);
                  }
                }}
              />
            </HorizontalFormField>
          </div>
          {impellerCount > 0 && (
            <Table
              name="config.geometry.impellers"
              columns={impellerTableColumns}
              data={impellerData}
              defaultColumn={{
                Header: TableHeader,
                Cell: QuantityCell,
                disabled,
              }}
              tableClass="table-borderless"
            />
          )}
        </Card.Body>
      </Card>
    </>
  );
};

const Geometry = {
  id: 'geometry',
  label: 'Geometry',
  container: 'config',
  Renderer: GeometryPanel,
  tabEnabled: (activeNodeType) =>
    activeNodeType === 'config' || activeNodeType === 'process',
};

export default Geometry;
