import { Spinner } from 'react-bootstrap';

const CenteredSpinner = () => {
  return (
    <div className="vh-100 d-flex justify-content-center align-items-center">
      <Spinner animation="border" variant="secondary" />
    </div>
  );
};

export default CenteredSpinner;
