import { useHistory } from 'react-router-dom';
import AssetData from './AssetData';
import AssetList from './AssetList';

const AssetExplorerPanel = ({ activeSubView, activeSubSubView }) => {
  console.log('activesubview', activeSubView);
  const history = useHistory();
  return (
    <div style={{marginTop: '3.3rem'}}>
      {activeSubView ? (
        <AssetData
          assetId={activeSubView}
          activeSubSubView={activeSubSubView}
        />
      ) : (
        <AssetList
          onClickLink={(assetData) => {
            history.push(`/app/asset/${assetData._id}`);
          }}
        />
      )}
    </div>
  );
};

export default AssetExplorerPanel;
