export const DataMapping = (jsonData) => {
  //site
  let site;
  let site_name =
    jsonData.data.parsed_json_data.MixIT.reactorInfo.site['@value'];
  site_name === '' ? (site = 'site-1') : (site = site_name);
  let std_baff_value, Baffle_Type, baffles;
  //Baffles
  let bafflesCustoms =
    jsonData.data.parsed_json_data.MixIT.geometry.bafflesCustoms;
  let Number_of_Baffles = parseInt(
    bafflesCustoms.numberOfBafflesCustom['@value']
  );
  if (Number_of_Baffles === 0) {
    Baffle_Type = 'No Baffles';
  } else {
    Number_of_Baffles === 1
      ? (std_baff_value = bafflesCustoms.bafflesCustom.standardBaffle['@value'])
      : (std_baff_value =
          bafflesCustoms.bafflesCustom[0].standardBaffle['@value']);
    std_baff_value === 'True'
      ? (Baffle_Type = 'Standard Baffles')
      : (Baffle_Type = 'Non-Standard Baffles'); // shorthand for if else statement.
  }
  let baffle_data = [];
  Number_of_Baffles === 1
    ? (baffles = [bafflesCustoms.bafflesCustom])
    : (baffles = bafflesCustoms.bafflesCustom);

  for (var i = 0; i < Number_of_Baffles; i++) {
    let baff_dict = {
      Baffle_Style: baffles[i].baffleStyle['@value'],
      Baffle_Width: baffles[i].baffleWidth['@value'],
      Baffle_Off_Wall: baffles[i].baffleOffWall['@value'],
      Baffle_Off_Bottom: baffles[i].baffleOffBottom['@value'],
      Baffle_Off_Top: baffles[i].baffleOffTop['@value'],
      Baffle_Angle_1: baffles[i].baffleAngle1['@value'],
      Baffle_Angle_2: baffles[i].baffleAngle2['@value'],
    };
    baffle_data.push(baff_dict);
  }
  //Impellers
  let Is_Use_Shaft,
    impeller,
    process_impeller,
    Impeller_Speed_Is_Same_Speed_For_All_Impellers,
    dispersed_phase;
  let Number_of_Impellers = parseInt(
    jsonData.data.parsed_json_data.MixIT.geometry.impellers.numberOfImpellers[
      '@value'
    ]
  );
  let impellers = jsonData.data.parsed_json_data.MixIT.geometry.impellers;
  impellers.useShaft['@value'] === 'True'
    ? (Is_Use_Shaft = true)
    : (Is_Use_Shaft = false);

  let impeller_data = [];
  if (Number_of_Impellers === 1) {
    impeller = [impellers.impeller];
    process_impeller = [
      jsonData.data.parsed_json_data.MixIT.processData.impellerSpeeds
        .processImpeller,
    ];
  } else {
    impeller = impellers.impeller;
    process_impeller =
      jsonData.data.parsed_json_data.MixIT.processData.impellerSpeeds
        .processImpeller;
  }
  for (let i = 0; i < Number_of_Impellers; i++) {
    let impeller_dict = {
      Impeller_Type: impeller[i].type['@value'],
      Impeller_Mounting: impeller[i].mounting['@value'],
      Impeller_Diameter: impeller[i].diameter['@value'],
      Impeller_Clearance: impeller[i].clearance['@value'],
      Impeller_Offset_X: impeller[i].offCenter['@value'],
      Impeller_Offset_Y: impeller[i].offCenterY['@value'],
      Impeller_Angle_1: impeller[i].angle1['@value'],
      Impeller_Angle_2: impeller[i].angle2['@value'],
      Impeller_Angle_3: impeller[i].angle3['@value'],
      Impeller_Angle_4: impeller[i].angle4['@value'],
      Op_Speed_Impeller_Type: impeller[i].type['@value'],
      Impeller_Speed: process_impeller[i].speed['@value'],
      Impeller_Direction: process_impeller[i].direction['@value'],
    };
    impeller_data.push(impeller_dict);
  }

  //Impeller Speed
  let useCommonSpeedForAllImpellers_check =
    jsonData.data.parsed_json_data.MixIT.processData.operatingCondition
      .useCommonSpeedForAllImpellers['@value'];
  useCommonSpeedForAllImpellers_check === 'True'
    ? (Impeller_Speed_Is_Same_Speed_For_All_Impellers = true)
    : (Impeller_Speed_Is_Same_Speed_For_All_Impellers = false);

  dispersed_phase =
    jsonData.data.parsed_json_data.MixIT.processData.dispersedPhase;

  //Gas
  let Is_Enable_Gas, Is_Enable_Solids, Is_Enable_Liquids, Is_Heat_Transfer_Used;
  let gasStatus_check = dispersed_phase.gasStatus['@value'];
  gasStatus_check === 'True' ? (Is_Enable_Gas = true) : (Is_Enable_Gas = false);
  //Solid
  let solidStatus_check = dispersed_phase.solidStatus['@value'];
  solidStatus_check === 'True'
    ? (Is_Enable_Solids = true)
    : (Is_Enable_Solids = false);
  //liquids
  let liquidStatus_check = dispersed_phase.liquidStatus['@value'];
  liquidStatus_check === 'True'
    ? (Is_Enable_Liquids = true)
    : (Is_Enable_Liquids = false);
  //Heat Transfer Conditions
  let heatTransfer_check =
    jsonData.data.parsed_json_data.MixIT.heatTransfer.enabled['@value'];
  heatTransfer_check === 'True'
    ? (Is_Heat_Transfer_Used = true)
    : (Is_Heat_Transfer_Used = false);

  //Jackets
  //Side Jacket
  let Is_Side_Jacket_Enable, Is_Bottom_Jacket_Enable;
  let internals = jsonData.data.parsed_json_data.MixIT.internals;
  let side_jacket_status = internals.jacketStatus;
  let bottom_jacket_status = internals.bottomJacketStatus;
  let jacket_data = [
    {
      Tank: '1',
      Jacket_Type: 'Conventional',
      Jacket_Diameter: 0,
      Pipe_ID: 0,
      Pitch: 0,
      Number_Of_Turns: 0,
      Jacket_Type_Tag: 'Side',
    },
    {
      Tank: '1',
      Jacket_Type: 'Conventional',
      Jacket_Diameter: 0,
      Pipe_ID: 0,
      Pitch: 0,
      Number_Of_Turns: 0,
      Jacket_Type_Tag: 'Bottom',
    },
  ];
  if (typeof side_jacket_status != 'undefined') {
    if (side_jacket_status['@value']) {
      Is_Side_Jacket_Enable = true;
    }
    jacket_data[0].Jacket_Type = internals.jacket.jacketType['@value'];
    jacket_data[0].Jacket_Diameter = parseFloat(
      internals.jacket.jacketDiameter['@value']
    );
    jacket_data[0].Pipe_ID = parseFloat(internals.jacket.pipeID['@value']);
    jacket_data[0].Pitch = parseFloat(internals.jacket.pitch['@value']);
    jacket_data[0].Number_Of_Turns = parseInt(
      internals.jacket.numberOfTurns['@value']
    );
  } else {
    Is_Side_Jacket_Enable = false;
  }
  //Bottom Jacket
  if (typeof bottom_jacket_status != 'undefined') {
    if (bottom_jacket_status['@value']) {
      Is_Bottom_Jacket_Enable = true;
    }
    jacket_data[1].Jacket_Type =
      internals.bottomJacket.bottomJacketType['@value'];
    jacket_data[1].Jacket_Diameter = parseFloat(
      internals.bottomJacket.bottomJacketDiameter['@value']
    );
    jacket_data[1].Pipe_ID = parseFloat(
      internals.bottomJacket.bottomJacketPipeID['@value']
    );
    jacket_data[1].Pitch = parseFloat(
      internals.bottomJacket.bottomJacketPitch['@value']
    );
    jacket_data[1].Number_Of_Turns = parseInt(
      internals.bottomJacket.bottomJacketNumberOfTurns['@value']
    );
  } else {
    Is_Bottom_Jacket_Enable = false;
  }

  //Helical Coil &  Heat Transfer Fluid Coil
  let helical_coil, ht_coil_fluid;
  let Number_of_Helical_Coils = parseInt(
    jsonData.data.parsed_json_data.MixIT.internals.helicalCoils
      .numberOfHelicalCoils['@value']
  );
  let helical_coil_data = [];
  if (Number_of_Helical_Coils === 1) {
    helical_coil = [
      jsonData.data.parsed_json_data.MixIT.internals.helicalCoils.helicalCoil,
    ];
    ht_coil_fluid = [
      jsonData.data.parsed_json_data.MixIT.heatTransfer.heatTransferCoilFluids
        .heatTransferCoilFluid,
    ];
  } else {
    helical_coil =
      jsonData.data.parsed_json_data.MixIT.internals.helicalCoils.helicalCoil;
    ht_coil_fluid =
      jsonData.data.parsed_json_data.MixIT.heatTransfer.heatTransferCoilFluids
        .heatTransferCoilFluid;
  }
  for (let i = 0; i < Number_of_Helical_Coils; i++) {
    let helical_coil_dict = {
      Helical_Coil_Diameter: helical_coil[i].diameter['@value'],
      Helical_Coil_Pitch: helical_coil[i].pitch['@value'],
      Helical_Coil_Number_Of_Turns: helical_coil[i].numofturns['@value'],
      Helical_Coil_Tube_Id: helical_coil[i].tubeid['@value'],
      Helical_Coil_Tube_Od: helical_coil[i].tubeod['@value'],
      Helical_Coil_Bottom_Clearance: helical_coil[i].bottomclearance['@value'],

      HT_Coil_Number: 'Coil' + [i + 1],
      HT_Coil_Inlet_Temperature: ht_coil_fluid[i].inletTemperature['@value'],
      HT_Coil_Outlet_Temperature: ht_coil_fluid[i].outletTemperature['@value'],
      HT_Coil_Viscosity: ht_coil_fluid[i].viscosity['@value'],
      HT_Coil_Density: ht_coil_fluid[i].density['@value'],
      HT_Coil_Sp_Heat_Capacity: ht_coil_fluid[i].spHeatCapacity['@value'],
      HT_Coil_Thermal_Conductivity:
        ht_coil_fluid[i].thermalConductivity['@value'],
      HT_Coil_Flow_Rate: ht_coil_fluid[i].flowRate['@value'],
      HT_Coil_Thermal_Conductivity_Coil_wall:
        ht_coil_fluid[i].thermalConductivityCoil['@value'],
    };
    helical_coil_data.push(helical_coil_dict);
  }

  //Ring Spargers
  let ring_sparger;
  let ringSpargers =
    jsonData.data.parsed_json_data.MixIT.internals.ringSpargers;
  let Number_of_Ring_Spargers = parseInt(
    ringSpargers.numberOfRingSparger['@value']
  );
  let ring_spargers_data = [];
  Number_of_Ring_Spargers === 1
    ? (ring_sparger = [ringSpargers.ringSparger])
    : (ring_sparger = ringSpargers.ringSparger);

  for (let i = 0; i < Number_of_Ring_Spargers; i++) {
    let ring_sparger_dict = {
      Ring_Sparger_Diameter: ring_sparger[i].spargerdiameter['@value'],
      Ring_Sparger_Tube_Diameter: ring_sparger[i].tubediameter['@value'],
      Ring_Sparger_Bottom_Clearance: ring_sparger[i].bottomclearance['@value'],
    };
    ring_spargers_data.push(ring_sparger_dict);
  }

  //Diptubes
  let dip_tube, inlet_outlet;
  let Number_of_Diptubes = parseInt(
    jsonData.data.parsed_json_data.MixIT.internals.dipTubes.numberOfDipTubes[
      '@value'
    ]
  );
  let dip_tubes_data = [];
  if (Number_of_Diptubes !== 0) {
    if (Number_of_Diptubes === 1) {
      dip_tube = [
        jsonData.data.parsed_json_data.MixIT.internals.dipTubes.dipTube,
      ];
      inlet_outlet = [
        jsonData.data.parsed_json_data.MixIT.processData.inletOutletConditions
          .inletOutlet,
      ];
    } else {
      dip_tube =
        jsonData.data.parsed_json_data.MixIT.internals.dipTubes.dipTube;
      inlet_outlet =
        jsonData.data.parsed_json_data.MixIT.processData.inletOutletConditions
          .inletOutlet;
    }
    for (let i = 0; i < Number_of_Diptubes; i++) {
      let dip_tube_dict = {
        Diptubes_Tube_Diameter: dip_tube[i].diameter['@value'],
        Diptubes_Length_1: dip_tube[i].length1['@value'],
        Diptubes_Angle_1: dip_tube[i].angle1['@value'],
        Diptubes_Length_2: dip_tube[i].length2['@value'],
        Diptubes_Disatance_From_X: dip_tube[i].distanceFromAxis['@value'],
        Diptubes_Distance_From_Y: dip_tube[i].distanceFromAxisY['@value'],
        Diptubes_Distance_From_Bottom: dip_tube[i].param2Length['@value'],
        Diptubes_Angle_2: dip_tube[i].angle2['@value'],
        Diptubes_Angle_3: dip_tube[i].param1Angle['@value'],
        Diptubes_Angle_4: dip_tube[i].param2Angle['@value'],
        Diptubes_Type: inlet_outlet[i].type['@value'],
        Diptubes_Flow_Rate: inlet_outlet[i].flowRate['@value'],
      };
      dip_tubes_data.push(dip_tube_dict);
    }
  }
  //Shaft
  let mechanical = jsonData.data.parsed_json_data.MixIT.mechanical;

  //Operating Conditions & Fluid Properties
  let operating_condition =
    jsonData.data.parsed_json_data.MixIT.processData.operatingCondition;
  let fluid_properties =
    jsonData.data.parsed_json_data.MixIT.processData.fluidProperties;

  //Heat Transfer
  // Process Fluid & Vessel Wall
  let process_ht_fluid =
    jsonData.data.parsed_json_data.MixIT.heatTransfer.processHeatTranferFluid;
  let vessel_wall =
    jsonData.data.parsed_json_data.MixIT.heatTransfer.vesselWall;

  //HT Jacket
  let ht_side_jacket_data,
    ht_side_jacket,
    ht_bottom_jacket_data,
    ht_bottom_jacket;
  let ht_fluid_jacket_data = [];
  if (
    typeof side_jacket_status != 'undefined' &&
    typeof bottom_jacket_status != 'undefined'
  ) {
    ht_side_jacket_data =
      jsonData.data.parsed_json_data.MixIT.heatTransfer.heatTransferJacketFluids
        .heatTransferSideJacketFluid;
    ht_side_jacket = {
      HT_Jacket_Inlet_Temperature:
        ht_side_jacket_data.inletTemperature['@value'],
      HT_Jacket_Outlet_Temperature:
        ht_side_jacket_data.outletTemperature['@value'],
      HT_Jacket_Viscosity: ht_side_jacket_data.viscosity['@value'],
      HT_Jacket_Density: ht_side_jacket_data.density['@value'],
      HT_Jacket_Sp_Heat_Capacity: ht_side_jacket_data.spHeatCapacity['@value'],
      HT_Jacket_Thermal_Conductivity:
        ht_side_jacket_data.thermalConductivity['@value'],
      HT_Jacket_Flow_Rate: ht_side_jacket_data.flowRate['@value'],
      Jacket_Type_Tag: 'Side',
    };
    ht_bottom_jacket_data =
      jsonData.data.parsed_json_data.MixIT.heatTransfer.heatTransferJacketFluids
        .heatTransferBottomJacketFluid;
    ht_bottom_jacket = {
      HT_Jacket_Inlet_Temperature:
        ht_bottom_jacket_data.inletTemperature['@value'],
      HT_Jacket_Outlet_Temperature:
        ht_bottom_jacket_data.outletTemperature['@value'],
      HT_Jacket_Viscosity: ht_bottom_jacket_data.viscosity['@value'],
      HT_Jacket_Density: ht_bottom_jacket_data.density['@value'],
      HT_Jacket_Sp_Heat_Capacity:
        ht_bottom_jacket_data.spHeatCapacity['@value'],
      HT_Jacket_Thermal_Conductivity:
        ht_bottom_jacket_data.thermalConductivity['@value'],
      HT_Jacket_Flow_Rate: ht_bottom_jacket_data.flowRate['@value'],
      Jacket_Type_Tag: 'Bottom',
    };
  } else if (
    typeof side_jacket_status != 'undefined' &&
    typeof bottom_jacket_status == 'undefined'
  ) {
    ht_side_jacket_data =
      jsonData.data.parsed_json_data.MixIT.heatTransfer.heatTransferJacketFluids
        .heatTransferSideJacketFluid;
    ht_side_jacket = {
      HT_Jacket_Inlet_Temperature:
        ht_side_jacket_data.inletTemperature['@value'],
      HT_Jacket_Outlet_Temperature:
        ht_side_jacket_data.outletTemperature['@value'],
      HT_Jacket_Viscosity: ht_side_jacket_data.viscosity['@value'],
      HT_Jacket_Density: ht_side_jacket_data.density['@value'],
      HT_Jacket_Sp_Heat_Capacity: ht_side_jacket_data.spHeatCapacity['@value'],
      HT_Jacket_Thermal_Conductivity:
        ht_side_jacket_data.thermalConductivity['@value'],
      HT_Jacket_Flow_Rate: ht_side_jacket_data.flowRate['@value'],
      Jacket_Type_Tag: 'Side',
    };
    ht_bottom_jacket = {
      HT_Jacket_Inlet_Temperature: 0,
      HT_Jacket_Outlet_Temperature: 0,
      HT_Jacket_Viscosity: 0,
      HT_Jacket_Density: 0,
      HT_Jacket_Sp_Heat_Capacity: 0,
      HT_Jacket_Thermal_Conductivity: 0,
      HT_Jacket_Flow_Rate: 0,
      Jacket_Type_Tag: 'Bottom',
    };
  } else if (
    typeof side_jacket_status == 'undefined' &&
    typeof bottom_jacket_status != 'undefined'
  ) {
    ht_side_jacket = {
      HT_Jacket_Inlet_Temperature: 0,
      HT_Jacket_Outlet_Temperature: 0,
      HT_Jacket_Viscosity: 0,
      HT_Jacket_Density: 0,
      HT_Jacket_Sp_Heat_Capacity: 0,
      HT_Jacket_Thermal_Conductivity: 0,
      HT_Jacket_Flow_Rate: 0,
      Jacket_Type_Tag: 'Side',
    };
    ht_bottom_jacket_data =
      jsonData.data.parsed_json_data.MixIT.heatTransfer.heatTransferJacketFluids
        .heatTransferBottomJacketFluid;
    ht_bottom_jacket = {
      HT_Jacket_Inlet_Temperature:
        ht_bottom_jacket_data.inletTemperature['@value'],
      HT_Jacket_Outlet_Temperature:
        ht_bottom_jacket_data.outletTemperature['@value'],
      HT_Jacket_Viscosity: ht_bottom_jacket_data.viscosity['@value'],
      HT_Jacket_Density: ht_bottom_jacket_data.density['@value'],
      HT_Jacket_Sp_Heat_Capacity:
        ht_bottom_jacket_data.spHeatCapacity['@value'],
      HT_Jacket_Thermal_Conductivity:
        ht_bottom_jacket_data.thermalConductivity['@value'],
      HT_Jacket_Flow_Rate: ht_bottom_jacket_data.flowRate['@value'],
      Jacket_Type_Tag: 'Bottom',
    };
  } else {
    ht_side_jacket = {
      HT_Jacket_Inlet_Temperature: 0,
      HT_Jacket_Outlet_Temperature: 0,
      HT_Jacket_Viscosity: 0,
      HT_Jacket_Density: 0,
      HT_Jacket_Sp_Heat_Capacity: 0,
      HT_Jacket_Thermal_Conductivity: 0,
      HT_Jacket_Flow_Rate: 0,
      Jacket_Type_Tag: 'Side',
    };
    ht_bottom_jacket = {
      HT_Jacket_Inlet_Temperature: 0,
      HT_Jacket_Outlet_Temperature: 0,
      HT_Jacket_Viscosity: 0,
      HT_Jacket_Density: 0,
      HT_Jacket_Sp_Heat_Capacity: 0,
      HT_Jacket_Thermal_Conductivity: 0,
      HT_Jacket_Flow_Rate: 0,
      Jacket_Type_Tag: 'Bottom',
    };
  }
  ht_fluid_jacket_data.push(ht_side_jacket);
  ht_fluid_jacket_data.push(ht_bottom_jacket);

  const data = {
    Scale: jsonData.data.parsed_json_data.MixIT.reactorInfo.scale['@value'],
    Site_Name: site,
    Results_in_pdf: 'Results.pdf',
    Tank_Type:
      jsonData.data.parsed_json_data.MixIT.geometry.tank.type['@value'],
    Tank_Length: parseFloat(
      jsonData.data.parsed_json_data.MixIT.geometry.tank.length['@value']
    ),
    Tank_Diameter: parseFloat(
      jsonData.data.parsed_json_data.MixIT.geometry.tank.diameter['@value']
    ),
    Tank_Height: parseFloat(
      jsonData.data.parsed_json_data.MixIT.geometry.tank.straightSide['@value']
    ),
    Tank_Bottom_Style:
      jsonData.data.parsed_json_data.MixIT.geometry.tank.bottomStyle['@value'],
    Tank_Head_Style:
      jsonData.data.parsed_json_data.MixIT.geometry.tank.headStyle['@value'],
    Tank_Head_Depth: parseFloat(
      jsonData.data.parsed_json_data.MixIT.geometry.tank.headDepth['@value']
    ),
    Tank_Bottom_Depth: parseFloat(
      jsonData.data.parsed_json_data.MixIT.geometry.tank.bottomDepth['@value']
    ),
    Number_of_Baffles: Number_of_Baffles,
    Baffle_Type: Baffle_Type,
    Number_of_Impellers: Number_of_Impellers,
    Number_of_Helical_Coils: Number_of_Helical_Coils,
    Number_of_Ring_Spargers: Number_of_Ring_Spargers,
    Number_of_Diptubes: Number_of_Diptubes,
    Impeller_Speed_Is_Same_Speed_For_All_Impellers:
      Impeller_Speed_Is_Same_Speed_For_All_Impellers,
    Is_Enable_Gas: Is_Enable_Gas,
    Is_Enable_Solids: Is_Enable_Solids,
    Is_Enable_Liquids: Is_Enable_Liquids,
    Is_Heat_Transfer_Used: Is_Heat_Transfer_Used,
    Asset_Name: jsonData.data.parsed_json_data.MixIT.reactorInfo.id['@value'],
    Configuration_Name:
      jsonData.data.parsed_json_data.MixIT.reactorInfo.configuration['@value'],
    Process_Name:
      jsonData.data.parsed_json_data.MixIT.reactorInfo.process['@value'],
    Date_Simulation: jsonData.data.parsed_json_data.MixIT.generalInfo.timeStamp[
      '@value'
    ].split(' ')[0],
    MixitVersion_And_Date: jsonData.data.parsed_json_data.MixIT.generalInfo.MixitVersionAndDate['@value'], 
    Is_Use_Shaft: Is_Use_Shaft,
    Is_Side_Jacket_Enable: Is_Side_Jacket_Enable,
    Is_Bottom_Jacket_Enable: Is_Bottom_Jacket_Enable,
    shaft_design: {
      Motor_Power: mechanical.motorPower['@value'],
      Motor_Load: mechanical.motorLoad['@value'],
      MOC: mechanical.MOC['@value'],
      Seal_Type: mechanical.sealType['@value'],
      Mounting_Height: mechanical.mountingHeight['@value'],
      Hyd_Service: mechanical.HSF['@value'],
      Operating_Range_Min: mechanical.minOpRange['@value'],
      Operating_Range_Max: mechanical.maxOpRange['@value'],
    },
    operating_conditions: {
      Operating_Temperature: operating_condition.temperature['@value'],
      Operating_Gauge_Pressure: operating_condition.gaugePressure['@value'],
      Operating_Liquid_Level: operating_condition.liquidLevel['@value'],
      Operating_Volume: operating_condition.operatingVolume['@value'],
      Fluid_Density: fluid_properties.density['@value'],
      Fluid_Type: fluid_properties.fluidType['@value'],
      Fluid_Viscosity: fluid_properties.viscosity['@value'],
      Fluid_Flow_Index: fluid_properties.flowIndex['@value'],
      Fluid_Yield_Stress: fluid_properties.yieldStress['@value'],
      Fluid_Mu0: fluid_properties.muZero['@value'],
      Fluid_Inf: fluid_properties.muInfinity['@value'],
      Fluid_Surface_Tension: fluid_properties.surfaceTension['@value'],
      Impeller_Speed_Operating_Speed:
        operating_condition.impellerSpeed['@value'],
    },
    baffles: baffle_data,
    impellers: impeller_data,
    helical_coil: helical_coil_data,
    ring_spargers: ring_spargers_data,
    dip_tubes: dip_tubes_data,
    ht_fluid_jacket: ht_fluid_jacket_data,
    heat_transfer: {
      Process_Initial_Temperature:
        process_ht_fluid.initialTemperature['@value'],
      Process_Final_Temperature: process_ht_fluid.finalTemperature['@value'],
      Process_Sp_Heat_Capacity: process_ht_fluid.spHeatCapacity['@value'],
      Process_Thermal_Conductivity:
        process_ht_fluid.thermalConductivity['@value'],
      Process_Additional_Heat_Load: process_ht_fluid.heatLoad['@value'],
      Vessel_MOC: vessel_wall.type['@value'],
      Vessel_Thermal_Conductivity: vessel_wall.thermalConductivity['@value'],
      Vessel_Thickness: vessel_wall.thickness['@value'],
    },
    jacket: jacket_data,
    dispersed_conditions: {
      Tank: '1',
      Gas_Type: dispersed_phase.gas.type['@value'],
      Gas_Molecular_Weight: dispersed_phase.gas.molecularWeight['@value'],
      Gas_Volume_Flow_Rate: dispersed_phase.gas.volFlowRate['@value'],
      Solids_Density: dispersed_phase.solid.density['@value'],
      Solids_Particle_Size: dispersed_phase.solid.particleSize['@value'],
      Solids_Loading: dispersed_phase.solid.solidsLoading['@value'],
      Liquid_Density: dispersed_phase.liquid.density['@value'],
      Liquid_Viscosity: dispersed_phase.liquid.viscosity['@value'],
      Liquid_Surface_Tension: dispersed_phase.liquid.surfaceTension['@value'],
    },
    image_description: [
      {
        image_name: '',
        description: '',
      },
    ],
  };
  return data;
};
