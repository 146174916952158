import { Row, Card, Col, ListGroup } from 'react-bootstrap';
import { GetSimcaseDocument, useSimCaseDocumentsData } from '../services/business-logic/documents.businesslogic';
import FileViewer from 'react-file-viewer';
import { Dialog } from '@tri/ui-shared';
import { useRef, useState } from 'react';
import { pillTabs } from '../../utils/constants';
import loadingGif from '../../../assets/loading.gif'
import { Download, Eye } from 'react-feather';

const DocumentListItemView = ({simcaseId, index, file, extension, setFileData, setIsDialogueOpen, downloadFileButtonRef}) => {
  const url = GetSimcaseDocument(simcaseId, `${file}.${extension}`)
  const supportedExtensions = ['csv', 'xls', 'docx', 'pdf', 'plt', 'txt', 'pptx', 'html']
  const supportedViewerExtensions = ['pdf', 'txt', 'html', 'json']
  
  return <ListGroup.Item 
      className='text-link' 
      key={index}
      // onClick={() => {
      //   //commented code will be needed for the future enhancement tasks
      //   // if(supportedExtensions.includes(extension)) {
      //     downloadFileButtonRef.current.setAttribute('href', url)
      //     !supportedViewerExtensions.includes(extension) ?
      //       downloadFileButtonRef.current.setAttribute('download', `${file}.${extension}`) :
      //       downloadFileButtonRef.current.removeAttribute('download')
      //     downloadFileButtonRef.current.click() 
      //   // }
      //   // else {
      //   //   setFileData({"extension": extension, "filename": file, "url": url});
      //   //   setIsDialogueOpen(true)          
      //   // }
      // }}
    >
      <div className='d-flex justify-content-between'>
        <div>{file}</div>
        <div>
          {
            supportedViewerExtensions.includes(extension) && 
            <Eye size='20' className='me-2' onClick={() => {
              downloadFileButtonRef.current.setAttribute('href', url)
              downloadFileButtonRef.current.removeAttribute('download')
              downloadFileButtonRef.current.click() 
            }}/> 
          }
          <Download size='20' onClick={() => {
            downloadFileButtonRef.current.setAttribute('href', url)
            downloadFileButtonRef.current.setAttribute('download', `${file}.${extension}`)
            downloadFileButtonRef.current.click() 
          }} />
        </div>
      </div>
  </ListGroup.Item>
}

const DocumentsPanel = ({simcaseId}) => {
  const { isGettingDocuments, documents} = useSimCaseDocumentsData(simcaseId)
  const [fileData, setFileData] = useState({})
  const [isDialogueOpen, setIsDialogueOpen] = useState(false)
  const downloadFileButtonRef = useRef(null)

  return isGettingDocuments ? <center className="mt-5"><img alt="Loading..." src={loadingGif} height="50px"/></center> :
  <>
    <Dialog 
        show={isDialogueOpen}
        title={`${fileData.filename}.${fileData.extension}`}
        onHide={() => setIsDialogueOpen(false)}
        size = 'xl'
        showOk={false}
    >
      <FileViewer
        fileType={fileData.extension}
        filePath={fileData.url}
      />
    </Dialog>
    <Row xs={1} md={4} className="g-4">
      {
        Object.keys(documents).length > 0 ? 
          Object.keys(documents).sort().map((extension, index) => {
            return <Col key={index}>
              <Card className='mt-3'>
                <Card.Header><strong>{extension.toUpperCase()} </strong></Card.Header>
                <ListGroup variant="flush" style={{height: '20em', overflowY: 'auto'}}>
                { 
                  documents[extension].map((file, index) => <DocumentListItemView 
                  simcaseId={simcaseId} index={index} file={file} extension={extension} setFileData={setFileData} setIsDialogueOpen={setIsDialogueOpen} downloadFileButtonRef={downloadFileButtonRef}/>)
                }
                </ListGroup>
              </Card>            
            </Col>
          }) : <Col><h5> Documents not found</h5></Col>
    }
    </Row>
    <a ref={downloadFileButtonRef} target="_blank"/>
  </>
}
  
const Documents = (simcaseId) =>  ({
  label: pillTabs.Documents,
  Panel: <DocumentsPanel simcaseId={simcaseId}/>
})
  
export { Documents }  
