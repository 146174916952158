import { Field, Formik } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { RadioGroupField2, SelectOptions } from 'apps/mixit/src/app/common/FormFields';
import MandatorySymbol from '../common/MandatorySymbol';
import { RBOffcanvas } from '../common/RBOffcanvas';
import { GetAddNewSimDataDetails } from '../asset-explorer/services/business-logic/newsimdata.businesslogice';
import { AddNewSimDataActionMode, AddNewSimDataActionOptions } from '../utils/constants';

const NewSimDataDialog = ({
  caseData,
  onHide,
  onApply,
  selectAssetId,
  selectedAssetName,
  showAssetNameFieldFlag,
  mode,
}) => {
  const {title, initialValues, validationSchema, submitCaseData, parseXML, getAssetListQuery, addNewSimDataAction, setAddNewSimDataAction} = GetAddNewSimDataDetails({
    caseData,
    onHide,
    onApply,
    selectAssetId,
    selectedAssetName,
    mode
  })

  return <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submitCaseData}
            enableReinitialize={true}
          >
            {({
              handleSubmit,
              values,
              setFieldValue,
              handleChange,
              errors,
              touched,
              isSubmitting
            }) => (
              <RBOffcanvas
                show={true}
                title={title}
                onHide={onHide}
                onOk={handleSubmit}
                isOkBtnDisabled={_.isEqual(values, initialValues) || isSubmitting}
              >
                <Form>

                {mode === 'create' ?
                  <Form.Group as={Row} className="mb-3" >
                    <Form.Label column sm="3">
                        Action Type
                    </Form.Label>
                    <Col sm="9">
                      <RadioGroupField2
                          w1={9}
                          w2={3}
                          name="addNewSimDataAction"
                          onChange={(actionType) => {
                            //reset casename and directory path field on action changed
                            if(actionType === AddNewSimDataActionMode.Single) {
                              setFieldValue('dirPath','');
                            }
                            else {
                              setFieldValue('caseName','')
                            } 
                            setAddNewSimDataAction(actionType)
                          }}
                          options={AddNewSimDataActionOptions}
                          value={addNewSimDataAction}
                          className="mb-2"
                      />
                    </Col>
                </Form.Group> : null
              }

                {
                  mode === 'create' && addNewSimDataAction === AddNewSimDataActionMode.Multiple ? <Form.Group as={Row} className="mb-3" >
                    <Form.Label column sm="3">
                        Directory Path <MandatorySymbol />
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                          name="dirPath"
                          value={values.dirPath}
                          onChange={handleChange}
                          isInvalid={errors.dirPath}
                      />
                      <Form.Control.Feedback type="invalid">
                          {errors.dirPath}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group> : <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        Case Name <MandatorySymbol />
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          name="caseName"
                          value={values.caseName}
                          onChange={handleChange}
                          isInvalid={errors.caseName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.caseName}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group> 
                }
                  {
                    mode === 'create' && addNewSimDataAction === AddNewSimDataActionMode.Single ?
                  <>
                     <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        XML File <MandatorySymbol />
                      </Form.Label>
                      <Col cm="9">
                        <Form.Control
                          type="file"
                          custom
                          accept=".xml"
                          title={values.xmlFile?.name || 'Select .xml file'}
                          onChange={(event) => {
                            const selectedFile = event.currentTarget.files[0];
                            setFieldValue('xmlFile', selectedFile);
                            parseXML(selectedFile);
                          }}
                          isInvalid={errors.xmlFile}
                          feedback={errors.xmlFile}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3" className="pr-0">
                        Results Directory
                      </Form.Label>
                      <Col cm="9">
                        <Field
                          type="file"
                          directory=""
                          webkitdirectory=""
                          name="resultsDirectory"
                          onChange={(event) => {
                            setFieldValue(
                              'resultDir',
                              event.currentTarget.files
                            );
                          }}
                        />
                        {errors.resultsDirectory && touched.resultsDirectory ? (
                          <div className="text-danger mb-3">
                            {errors.resultsDirectory}
                          </div>
                        ) : null}
                      </Col>
                    </Form.Group>
                   
                  </> : null
                  }

          {showAssetNameFieldFlag && (
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="3">
                Asset Name
              </Form.Label>
              <Col cm="9">
                <Form.Control
                  className="col-sm-9 form-control"
                  as="select"
                  name="assetName"
                  onChange={handleChange}
                >
                  {getAssetListQuery.status === 'success' && (
                    <SelectOptions
                      options={getAssetListQuery.data.data.map(
                        (cList) => {
                          return {
                            label: cList.asset_name,
                            value: cList.id,
                          };
                        }
                      )}
                    />
                  )}
                </Form.Control>
              </Col>
            </Form.Group>
          )}
        </Form>
      </RBOffcanvas>
    )}
  </Formik>
};

export default NewSimDataDialog;
