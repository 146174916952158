// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  ButtonWithSpinner,
  CenteredSpinner,
  UserContext,
} from '@tri/ui-shared';
import axios from 'axios';
import { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Edit2, Plus, Trash2 } from 'react-feather';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Table } from '../../common/SimsightTable';
import { getCookie } from '../../utils';
import NewScriptDialog from './NewScript';

const columns = [
  {
    Header: 'Id',
    accessor: 'id',
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Script File Name',
    accessor: 'file_name',
  },
];

const AppsOrScripts = ({ selectedAssetId, geometryId }) => {
  const [openAddScriptDialogFlag, setOpenAddScriptDialogFlag] = useState(false);
  const [editScriptFlag, setEditScriptFlag] = useState(false);
  const [selectedScript, setSelectedScript] = useState([]);
  const queryClient = useQueryClient();
  const { user } = useContext(UserContext);

  const scriptQuery = useQuery('scriptList', async () => {
    const { data } = await axios.get(`/api/script/${selectedAssetId}`);

    return { data: data };
  });

  //Delete Script
  const deleteSelectedAsset = useMutation(
    async () => {
      const confirm = window.confirm(
        'Please confirm to proceed with deletion..'
      );
      if (confirm) {
        await Promise.all(
          selectedScript.map((asset) => {
            return axios.delete(`/api/script/${asset.original.id}`, {'X-CSRF-TOKEN': getCookie('csrf_token') });
          })
        );
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('scriptList');
      },
    }
  );

  return (
    <div>
      {(user.is_superuser || user.is_poweruser ||user.is_staff) && (
        <div style={{ marginBottom: '16px' }}>
          <Button
            size="sm"
            variant="outline-primary"
            className="mr-1"
            title="Add new script"
            onClick={() => {
              setOpenAddScriptDialogFlag(true);
              setEditScriptFlag(false);
            }}
          >
            <Plus size={20} />
          </Button>
          {selectedScript.length === 1 && (
            <Button
              size="sm"
              variant="outline-primary"
              className="mr-1"
              title="Edit selected script"
              onClick={() => {
                setOpenAddScriptDialogFlag(true);
                setEditScriptFlag(true);
              }}
            >
              <Edit2 size={20} />
            </Button>
          )}

          {selectedScript.length > 0 && (
            <ButtonWithSpinner
              size="sm"
              variant="outline-primary"
              title="Delete selected script(s)"
              onClick={deleteSelectedAsset.mutateAsync}
              content={<Trash2 size={20} />}
              buttonClass="mr-1"
            ></ButtonWithSpinner>
          )}
        </div>
      )}
      {scriptQuery.status === 'loading' && <CenteredSpinner />}
      {scriptQuery.status === 'error' && <h5>Something went wrong ...</h5>}
      {scriptQuery.status === 'success' && scriptQuery.data.data.length > 0 && (
        <Table
          data={scriptQuery.data.data}
          columns={columns}
          initialState={{ hiddenColumns: ['id'] }}
          selectionEnabled={true}
          onSelect={setSelectedScript}
          // onClickLink={onClickLink}
          tableClass="table"
          showGlobalFilter={false}
          disableSortBy={false}
        />
      )}

      {openAddScriptDialogFlag && (
        <NewScriptDialog
          onHide={() => {
            setOpenAddScriptDialogFlag(false);
          }}
          onApply={() => {
            queryClient.invalidateQueries('scriptList');
          }}
          selectedAssetId={selectedAssetId}
          geometryId={geometryId}
          editScriptFlag={editScriptFlag}
          selectedScriptData={selectedScript}
        />
      )}
    </div>
  );
};

export default AppsOrScripts;
