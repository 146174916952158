import { Button } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';

export const RBOffcanvas = ({
  show,
  title,
  children,
  okLabel = 'Apply',
  cancelLabel = 'Cancel',
  showCancel = true,
  showOk = true,
  isOkBtnDisabled = false,
  onOk,
  onHide
}) => {

  return <Offcanvas show={show} onHide={onHide} placement="end" backdrop="static" style={{width: '480px'}}>
    <Offcanvas.Header closeButton>
      <Offcanvas.Title>{title}</Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body>
      {children}
    </Offcanvas.Body>
    <footer className='m-3 d-flex justify-content-end'>
        {showOk && (
          <Button disabled={isOkBtnDisabled} variant="primary" onClick={onOk} className='me-2' >
            {okLabel}
          </Button>
        )}
        {showCancel && (
          <Button variant="secondary" onClick={onHide}>
            {cancelLabel}
          </Button>
        )}
    </footer>
  </Offcanvas>
}