import { SelectOptions } from 'apps/mixit/src/app/common/FormFields';
import { useState, useEffect } from 'react';
import { Card, Form, Image, Row } from 'react-bootstrap';
import { ToScientificValue } from '@tri/ui-shared';

const stripExtension = (fileName) => fileName.split('.').slice(0, -1).join('.');

function getUniqueArr(arr, key) {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
}

const CaseInfo = ({ info }) => {
  if (info) {
    info.Volume = Number(info.Volume).toExponential(3)
  }
  return (
    <div className="p-2 m-1">
      <div className="d-flex">
        <div className="font-weight-bold">Case Information:</div>
        {info && (
          <div className="ml-1 flex-grow-1">
            Viscosity: {ToScientificValue(info.Viscosity * 1000)} cP
            <br />
            Density: {info.Density} kg/m^3
            <br />
            Volume: {info.Volume} m<sup>3</sup> <br />
            Speed: {info.Speed} RPM
          </div>
        )}
      </div>
    </div>
  );
};

const FlowPatterns = ({ flowPatternsData = [] }) => {
  const [plotType, setPlotType] = useState();
  const [firstCaseValue, setFirstCaseValue] = useState();
  const [secondCaseValue, setSecondCaseValue] = useState();

  if (flowPatternsData.length < 1) return <h5> No data found </h5>;

  let cases = [{ value: '', label: 'Select Case' }];
  let plotTypes = [{ value: null, label: 'Select Plot Type' }];

  flowPatternsData.forEach((fData) => {
    for (let key in fData) {
      cases.push({ value: key, label: key });
      fData[key]['flow_patterns_img'].forEach((imgName) => {
        plotTypes.push({ value: imgName, label: stripExtension(imgName) });
      });
    }
  });

  const filteredPlotTypes = getUniqueArr(plotTypes, 'label');

  useEffect(() => {
    if (filteredPlotTypes.length > 1) setPlotType(filteredPlotTypes[1].value); // first value is always null
  }, []);

  let firstCaseName = null;
  let firstFileName = null;
  let secondCaseName = null;
  let secondFileName = null;
  let firstCaseInfo = null;
  let secondCaseInfo = null;

  for (let i = 0; i < flowPatternsData.length; i++) {
    const fpData = flowPatternsData[i];
    for (let projectName in fpData) {
      for (let i in fpData[projectName]['flow_patterns_img']) {
        let plotFileName = fpData[projectName]['flow_patterns_img'][i];
        if (firstCaseValue === projectName) {
          firstCaseName = fpData[projectName]['dir_name'];
          if (plotType === plotFileName) {
            firstFileName = plotFileName;
            firstCaseInfo = {
              Viscosity: fpData[projectName]['viscosity'],
              Density: fpData[projectName]['density'],
              Volume: fpData[projectName]['volume'],
              Speed: fpData[projectName]['speed'],
            };
          }
        }
        if (secondCaseValue === projectName) {
          secondCaseName = fpData[projectName]['dir_name'];
          if (plotType === plotFileName) {
            secondFileName = plotFileName;
            secondCaseInfo = {
              Viscosity: fpData[projectName]['viscosity'],
              Density: fpData[projectName]['density'],
              Volume: fpData[projectName]['volume'],
              Speed: fpData[projectName]['speed'],
            };
          }
        }
      }
    }
  }
  const firstImageUrl = `/api/v3/assets/images/cases/${firstCaseName}/${firstFileName}/`;
  const secondImageUrl = `/api/v3/assets/images/cases/${secondCaseName}/${secondFileName}/`;

  return (
    <div>
      <div className="d-flex m-0" style={{ width: '24rem' }}>
        <label className="col-sm-3 col-form-label pl-0">Plot Type</label>
        <Form.Control
          className="col-sm-6 form-control"
          as="select"
          onChange={(e) => {
            setPlotType(e.target.value);
          }}
          value={plotType}
        >
          <SelectOptions options={filteredPlotTypes} />
        </Form.Control>
      </div>

      <div>
        <Row xs={1} md={1} lg={2} xl={2} className="mt-3">
          <Card className="border-0 mt-3">
            <div className="d-flex justify-content-center">
              <Form.Control
                className="w-25 form-control"
                as="select"
                onChange={(e) => {
                  setFirstCaseValue(e.target.value);
                }}
                value={firstCaseValue}
              >
                <SelectOptions options={cases} />
              </Form.Control>
            </div>

            {plotType && firstCaseValue && (
              <>
                <Image
                  src={firstImageUrl}
                  thumbnail
                  style={{
                    height: '350px',
                    border: 'none',
                    textAlign: 'center',
                  }}
                ></Image>
                <CaseInfo info={firstCaseInfo} />
              </>
            )}
          </Card>
          <Card className="border-0 mt-3">
            <div className="d-flex justify-content-center">
              <Form.Control
                className="w-25 form-control"
                as="select"
                onChange={(e) => {
                  setSecondCaseValue(e.target.value);
                }}
                value={secondCaseValue}
              >
                <SelectOptions options={cases} />
              </Form.Control>
            </div>
            {plotType && secondCaseValue && (
              <>
                <Image
                  src={secondImageUrl}
                  thumbnail
                  style={{
                    height: '350px',
                    border: 'none',
                    textAlign: 'center',
                  }}
                ></Image>
                <CaseInfo info={secondCaseInfo} />
              </>
            )}
          </Card>
        </Row>
      </div>
    </div>
  );
};

export default FlowPatterns;
