import { useAddMultipleColumnsToSimDataTable, useAddNewColumnHeaderToSimDataTable, useAddNewSimcasesToSimDataTable, useCreateGroupInSimDataTable, useDeleteColumnFromSimDataTable, useDeleteSelectedProjectCases, useDeleteSimCasesColumnGroup, useEditColumnHeaderFromSimDataTable, useEditColumnGroupFromSimDataTable, useEditRowFromSimDataTable, useGetProjectDetails, useGetSimTableColumnGroupDetails, useUpdateProjectCaseCell, useExportSimTableData, useEditColumnsDataFromSimDataTable } from "../data-service/simulation.dataservice"
import { useEffect, useMemo, useState, useContext, useRef, useCallback } from 'react';
import * as Yup from 'yup'
import { Form } from "react-bootstrap";
import ToastContext, { BreadcrumbContext, CompareCombinationContext } from './../../../context/index';
import { useMutation, useQueryClient } from 'react-query';
import { removeRestBreadcrumbsOrAppendNew, replaceConsecutiveSpacesBySingleSpace, sortTableColumn } from "../../../utils";
import { LinkCell } from "../../../common/LinkCell";
import { format } from 'date-fns'
import { isNumber } from "lodash";
import { useHistory } from 'react-router-dom';
import { dateFormats, operationTypes, pillTabs } from "../../../utils/constants";
import { toast } from 'react-toastify';
import Mexp from 'math-expression-evaluator';

const useExtendTableActionMode = () => ({
    AddRow: "Add Row",
    AddColumns: "Add Columns Data",
    AddColumnManually: "Add Column Header"
})

const useFormModes = () => ({
    Setting: "Setting",
    AddColumnOrRow: "Add Column Or Row",
    EditColumn: "Edit Column",
    EditRow: "Edit Row",
    ColumnGroups: "Groups",
    Export: "Export"
})

const useSelectedColumnActions = () => ({
    CreateGroup: "Create Group",
    EditGroup: "Edit Group",
    HideColumn: "Hide Column",
    DeleteColumn: "Delete Column",
    EditColumn: "Edit Column Details",
    EditColumnsData: "Edit Columns Data"
})

const nonEditableColumnTypes = ['casename', 'path', 'expression']

const GetSimulationDetailsData = (projectId, selectedGroups, setSelectedGroups, setSelectedSimcase) => {
    const formModes = useFormModes()
    const extendTableActionMode = useExtendTableActionMode()
    const setToast = useContext(ToastContext)
    const queryClient = useQueryClient()
    const [initialValues, setInitialValues] = useState()
    const selectedColumnActions = useSelectedColumnActions()
    const columnGroupData = useGetSimTableColumnGroupDetails(projectId)
    const columnActionOptions = [
        {
            label: selectedColumnActions.CreateGroup,
            value: selectedColumnActions.CreateGroup
        },
        {
            label: selectedColumnActions.HideColumn,
            value: selectedColumnActions.HideColumn
        },
        {
            label: selectedColumnActions.DeleteColumn,
            value: selectedColumnActions.DeleteColumn
        },
        {
            label: selectedColumnActions.EditColumn,
            value: selectedColumnActions.EditColumn
        },
        {
            label: selectedColumnActions.EditColumnsData,
            value: selectedColumnActions.EditColumnsData
        }
    ]

    const [isTableLock, setIsTableLock] = useState(true)
    const [activeFormMode, setActiveFormMode] = useState(formModes.AddColumnOrRow)
    const [dialogueFlag, setDialogueFlag] = useState(false)
    const [dialogueTitle, setDialogueTitle] = useState()
    const [selectedProjectSimCases, setSelectedProjectSimCases] = useState([])
    const selectedProjectSimCasesRef = useRef();
    const [currentProjectData, setCurrentProjectData] = useState({})
    const [selectedSimTableColumns, setSelectedSimTableColumns] = useState([])
    const [listOfSimTableColumnOptions, setListOfSimTableColumnOptions] = useState([])
    const [selectedColumnActionType, setSelectedColumnActionType] = useState()
    const [selectedColumnActionOptions, setSelectedColumnActionOptions] = useState(columnActionOptions)
    const [extendTableAction, setExtendTableAction] = useState(extendTableActionMode.AddRow) 
    const [addOrEditColumnType, setAddOrEditColumnType] = useState('')
    let hiddenColumnFromCache = []
    let allHiddenColumnsCacheData = localStorage.getItem('hiddenColumns')
    if(allHiddenColumnsCacheData) {
        hiddenColumnFromCache = JSON.parse(allHiddenColumnsCacheData)?.[projectId] || []
    }
    const [hiddenColumns, setHiddenColumns] = useState(hiddenColumnFromCache.length > 0 ? hiddenColumnFromCache : ['_id'])

    const [selectedColumnsForExport, setSelectedColumnsForExport] = useState([])
    const [casenameTypeColumn, setCasenameTypeColumn] = useState('')
    const exportAndDownloadFileRef = useRef(null)
    const downloadTemplateFileButtonRef = useRef(null)
    const [isExtentTableInstructionOpen, setIsExtendTableInstructionOpen] = useState(false)
    const lockUnlockToastId = 'simulation-details-table'
    const [isSettingDropDownOpen, setIsSettingDropDownOpen] = useState(false)

    const { isBreadcrumbsFromCache, setBreadCrumbItmes } = useContext(BreadcrumbContext)
    const projectName = currentProjectData?.project_name ?? ''
    const history = useHistory()

    //need to set selected sim cases into ref to get selected sim cases on component unmount
    useEffect(() => {
        selectedProjectSimCasesRef.current = selectedProjectSimCases;
    }, [selectedProjectSimCases]);

    const existingColumnGroups = useMemo(() => {
        if(columnGroupData.status === 'success') {
            return columnGroupData.data
        }
        return []
    }, [columnGroupData.data])

    //create group columns dictionary
    const allColumnsFromGroup = useMemo(() => {
        const groupColumnDictionary = []
        if(existingColumnGroups?.length > 0) {
            existingColumnGroups.forEach(group => {
                group.column_names_list.split(",").forEach(groupColumn => {
                    groupColumnDictionary.push(groupColumn)
                })
            })
        }
        return groupColumnDictionary       
    }, [existingColumnGroups])

    //Generate template file link for download
    const DownloadTemplateFile = async () => await useExportSimTableData(projectId, [], {
        selectedColumnsForExport: listOfSimTableColumnOptions.map(column => column.value),
        fileName: `${activeFormMode === formModes.EditRow ? 'EditSimCasesTemplate': 'AddSimCasesTemplate'}`,
        exportFileType: 'csv'
    })

    useMemo(async() => {
        if(dialogueFlag && ((activeFormMode === formModes.AddColumnOrRow && extendTableAction === extendTableActionMode.AddRow) || activeFormMode === formModes.EditRow)) {
            let exportDataResponse = await DownloadTemplateFile()
            if([200, 201].includes(exportDataResponse.statusCode)) {
                downloadTemplateFileButtonRef.current.setAttribute('href', exportDataResponse.data)
                downloadTemplateFileButtonRef.current.setAttribute('download', `${activeFormMode === formModes.EditRow ? 'EditSimCasesTemplate': 'AddSimCasesTemplate'}.csv`)
            }                
        }
    }, [dialogueFlag])
  
    // creating breadcrumbs
    useEffect(() => {
        if(projectName !== '' && !isBreadcrumbsFromCache) {
            removeRestBreadcrumbsOrAppendNew(projectName, [{ 
                title: projectName,
                redirectTo: `/app/simulationData/${projectId}/?type=${pillTabs.SimulationDetails}`
            }], setBreadCrumbItmes, '', history)
        }
    }, [projectName])

    const onClickOnSimse = (simcase) => {
        setSelectedSimcase(simcase)
        removeRestBreadcrumbsOrAppendNew(simcase.values[casenameTypeColumn], [{ 
            title: simcase.values[casenameTypeColumn]
        }], setBreadCrumbItmes, '', history)
        history.push(`/app/simulationData/${projectId}/${simcase.values._id}`);
    }

    const tableSettingsActionMenu = Object.values(selectedColumnActionOptions).map(option => ({
        title: option.value,
        onSelect: () => {
            setActiveFormMode(formModes.Setting); 
            setDialogueFlag(true); 
            setSelectedColumnActionType({label:option.value, value: option.value}) 
            setIsSettingDropDownOpen(false)
        }
    }))

    //close instruction div once dialogue is open
    useEffect(() => {
        dialogueFlag && setIsExtendTableInstructionOpen(false)
    }, [dialogueFlag])

    //filter column actions depends on column/row selection
    useEffect(() => {
        const filteredSelectedColumnActionOptions = columnActionOptions.filter(option => !(option.value === selectedColumnActions.Export && selectedProjectSimCases.length < 1))
        setSelectedColumnActionOptions(filteredSelectedColumnActionOptions)
        
    }, [selectedProjectSimCases])

    const { setSelectedSimCasesForComparision } = useContext(CompareCombinationContext)
    useEffect(() => {
        return () => {
            const selectedProjectSimCases = selectedProjectSimCasesRef.current
            let compareCases = []
            selectedProjectSimCases.forEach(simcase => {
                compareCases.push({
                    "caseId": {
                        "label": simcase.original.case_name,
                        "value": simcase.original._id
                    }
                })
            })
            let compareCombination = {
                [projectId]: {
                    "compareCases": compareCases
                }
            } 
            setSelectedSimCasesForComparision(compareCombination )           
        }
    }, [])

    //Set dialog title after changing form mode
    useEffect(() => {
        switch(activeFormMode) {
            case formModes.AddColumnOrRow:
                return setDialogueTitle("Add New Simulation Cases Or Columns")
            case formModes.EditColumn:
                return setDialogueTitle("Edit Column Details")
            case formModes.EditRow: 
                return setDialogueTitle("Edit Simulation Case")
            case formModes.ColumnGroups:
                return setDialogueTitle("Column Groups")
            case formModes.Export:
                return setDialogueTitle("Export Table")
            case formModes.Setting:
                switch(selectedColumnActionType?.value) {
                    case selectedColumnActions.DeleteColumn: 
                        return setDialogueTitle("Delete Column")
                    case selectedColumnActions.HideColumn:
                        return setDialogueTitle("Hide Columns")
                    case selectedColumnActions.CreateGroup:
                        return setDialogueTitle("Create Group")
                    case selectedColumnActions.EditGroup:
                        return setDialogueTitle("Edit Column Group")
                    case selectedColumnActions.EditColumnsData:
                        return setDialogueTitle("Edit Columns Data")
                    default:
                        return setDialogueTitle("Edit Column Details")
                }         
        }
    }, [activeFormMode, selectedColumnActionType])

    useEffect(() => {
        setInitialValues(values => ({ ...values, 
            "selectedColumns": undefined
        }))
        setSelectedSimTableColumns(...[])
        setSelectedGroups(...[])
    }, [dialogueFlag])

    useEffect(() => {
        if(selectedColumnActionType?.value === selectedColumnActions.EditColumn && selectedSimTableColumns?.value) {

            setActiveFormMode(formModes.EditColumn)
            let editColumn = {}

            listOfAllProjectTableColumn.forEach((columnHeader) => {
                // first we check whether column is belong to group or not
                // eslint-disable-next-line no-prototype-builtins
                if(columnHeader.hasOwnProperty("columns")) { 
                    columnHeader.columns.forEach((column) => {
                        if(column.Header === selectedSimTableColumns.value) {
                            editColumn= column
                        }
                    })
                }
                else {
                    if(columnHeader.Header === selectedSimTableColumns.value) {
                        editColumn= columnHeader
                    }
                }
            })

            if(editColumn) {
                setInitialValues(values => ({...values,
                    "columnName": editColumn.Header,
                    "columnUnit": editColumn.unit,
                    "columnType": editColumn.type,
                    "formula": editColumn?.formula,
                }))
                setAddOrEditColumnType(editColumn.type)
            }
        }
    }, [selectedSimTableColumns])

    useEffect(() => {
        if(selectedColumnActionType?.value === selectedColumnActions.EditColumn) {
            setSelectedSimTableColumns([])
            setInitialValues(values => ({...values,
                "selectedColumns": undefined
            }))
        }
        else if(selectedColumnActionType?.value === selectedColumnActions.EditGroup) {
            setSelectedSimTableColumns([])
            const selectedGroup = selectedGroups[0].values
            let selectedColunms = selectedGroup.column_names_list?.split(',') ?? []
            selectedColunms.forEach(column => {
                setSelectedSimTableColumns((selectedCols) => [...selectedCols, {"label": column, "value": column}])
            })

            setInitialValues(values => ({...values, 
                "groupName": selectedGroup.group_name,
                "fillColor": selectedGroup.color_label?.props.defaultValue,
                "selectedColumns": selectedColunms
            }))
        }
        else if(selectedColumnActionType?.value === selectedColumnActions.CreateGroup) {
            setInitialValues(values => ({...values, 'groupName': '', selectedColumns: undefined,  "fillColor": '#fd7702'}))
        } 
        else if(selectedColumnActionType?.value === selectedColumnActions.HideColumn) {
            const existingHiddenColumnOptions = []
            hiddenColumns.forEach(column => {
                column !== '_id' && existingHiddenColumnOptions.push({"label": column, "value": column})
            })
            setSelectedSimTableColumns(existingHiddenColumnOptions)
            setInitialValues(values => ({...values, 
                "selectedColumns": existingHiddenColumnOptions
            }))
        } 
    }, [selectedColumnActionType])

    useEffect(() => {
        if (activeFormMode === formModes.AddColumnOrRow) {
            setInitialValues(values => ({...values, "ExtendTableAction": extendTableActionMode.AddRow }))
        } 
    }, [activeFormMode])

    const [columnTypeOptions, setColumnTypeOptions] = useState([{
        'label': 'Expression',
        'value': 'Expression' 
    }])

    const [columnUnitOptions, setColumnUnitOptions] = useState([])

    const getProjectDetails = useGetProjectDetails(projectId)

    const allProjectTableData = useMemo(() => getProjectDetails.data, [getProjectDetails.data])

    const filterTableData = useMemo(() => {
        if(!allProjectTableData) return []

        let unitProjectCase = allProjectTableData?.project_case_data?.find((projectCase, index) => projectCase?.isColumnUnit)
        let typeProjectCase = allProjectTableData?.project_case_data?.find((projectCase, index) => projectCase?.isColumnType)

        //creating array of date type columns
        let dateTypeColumnNames = []
        let column_types = typeProjectCase?.column_type
        for(var key in column_types){
            column_types[key] && column_types[key].toLowerCase() === 'date' && dateTypeColumnNames.push(key)
        }
        const getFormatedDate = (date, unit) => {
            if(date) {
                return isNumber(date) ? format(new Date(date), dateFormats[unit]) : 'Improper Format'
            }
            else {
                return ''
            }
        }
        
        const projectColumnTypeCase = allProjectTableData?.project_case_data?.find((projectCase) => projectCase?.isColumnType)
        const columnNameWithCorrelationFormulae = projectColumnTypeCase?.correlations ?? {}

        let filterData = []
        allProjectTableData?.project_case_data?.forEach((projectCase, index) => {
            if(!(projectCase?.isColumnUnit || projectCase?.isColumnType)) {
                let dateTypeColumnsData = {}
                let formulaTypeColumnsData = {}
                //if column type is Date then we have to calculate required values
                dateTypeColumnNames.forEach(column => {
                    dateTypeColumnsData[column] = getFormatedDate(projectCase[column], unitProjectCase?.column_unit[column])
                    dateTypeColumnsData[`${column}_milisec`] = isNumber(projectCase[column]) ? projectCase[column] : 'Inproper Format'
                })

                //if column type is correlation then we have to calculate corresponding cell value
                Object.entries(columnNameWithCorrelationFormulae).forEach(columnWithFormula => {
                    try {
                        // Sort the array based on the length of the keys
                        const keyValueArrayForSimCase = Object.entries(projectCase);
                        keyValueArrayForSimCase.sort((a, b) => a[0].length - b[0].length).reverse()
                        
                        const mexp = new Mexp();
                        let formula = columnWithFormula[1]
                        
                        keyValueArrayForSimCase.forEach(simcase => {
                            formula = formula.includes(simcase[0]) ? formula.replaceAll(simcase[0], simcase[1]) : formula
                        })
                        formulaTypeColumnsData[columnWithFormula[0]] =  mexp.eval(formula)?.toFixed(4)
                    }
                    catch (error) {
                        formulaTypeColumnsData[columnWithFormula[0]] = "Invalid Formula"
                    }
                    
                })
                
                filterData.push({...projectCase, ...dateTypeColumnsData, ...formulaTypeColumnsData})
            }
        })                                                                                                                                                                                                                                                                                      
        return filterData
    }, [allProjectTableData])

    //Set current selected project data
    useEffect(() => {
        if(allProjectTableData) {
            let currentSelectedProjectData = {
                "_id": allProjectTableData._id,
                "comments": allProjectTableData.comments,
                "mapped_asset": allProjectTableData.mapped_asset?.asset_name,
                "project_name": allProjectTableData.project_info?.name,
                "project_owner": allProjectTableData.project_info?.owner,
                "shared_with": allProjectTableData.shared_with
            }
            setCurrentProjectData(currentSelectedProjectData)
        }
    }, [allProjectTableData])

    const capitalizeFirstWord = (inputString) => {
        if(!inputString) return ''
        inputString = inputString.trim()
        const words = inputString.includes('_') && inputString !== "_id" ? inputString.split('_') :  inputString.split(' ')
        const capitalizeWordsArray = words.map(word => word[0].toUpperCase() + word.substr(1).toLowerCase())
        return capitalizeWordsArray.join(' ')
    } 

    const getColumnGroupName = useCallback((columnName) => existingColumnGroups.filter(group => group.column_names_list.split(",").includes(columnName))[0] , [existingColumnGroups])


    const getTableColumnFormat = (projectTableMetaData, header, groupColor = '') => {
        let columnNameWithCorrelationFormulae = projectTableMetaData.columnNameWithCorrelationFormulae
        let columnNameWithTypes = projectTableMetaData.columnNameWithTypes
        let columnNameWithUnit = projectTableMetaData.columnNameWithUnit

        // eslint-disable-next-line no-prototype-builtins
        let correlationFormula = columnNameWithCorrelationFormulae?.hasOwnProperty(header) ? `${columnNameWithCorrelationFormulae[header]}` : ''
        let type = capitalizeFirstWord(columnNameWithTypes[header]) ?? ''
        let columnFormat = {
            Header: header,
            accessor: (originalRow) => {
                let originalRowString = replaceConsecutiveSpacesBySingleSpace(JSON.stringify(originalRow))
                let originalRowWithoutSpace = JSON.parse(originalRowString)
                return originalRowWithoutSpace[replaceConsecutiveSpacesBySingleSpace(header)]
            },
            // eslint-disable-next-line no-prototype-builtins
            unit: (columnNameWithUnit.hasOwnProperty(header) && columnNameWithUnit[header]) ?? '-',
            type: type,
            sortType: ['date', 'string'].includes(columnNameWithTypes[header]?.toLowerCase()) ? sortTableColumn : 'alphanumeric',
            style: {
                background: groupColor
            },                
            disableGlobalFilter: header === "_id"
        }
        if(correlationFormula) {
            columnFormat["formula"] = correlationFormula
        }
        if(columnNameWithTypes[header]?.toLowerCase() === 'casename') {
            columnFormat["Cell"] = LinkCell
            setCasenameTypeColumn(header)
        } 
        return columnFormat
    }

    const projectTableMetaData = useMemo(() => {

        let columnNameWithTypes = {"_id": "string"}, columnNameWithUnit = {"_id": "-"}
        let columnNameWithCorrelationFormulae = {}

        allProjectTableData?.project_case_data?.forEach((projectCase) => {
            if(projectCase?.isColumnType) {
                //Generating dictionary for column name along with its type
                Object.entries(projectCase.column_type).forEach(pair => {
                    columnNameWithTypes[pair[0]] = pair[1]
                    //set the column type options, if type is already there don't do anything, else add to the list
                    pair[1] && setColumnTypeOptions((options) => 
                        options.map(opt => opt.value).includes(pair[1].toLowerCase()) ?
                            options : 
                            [...options, {label: pair[1].toLowerCase(), value: pair[1].toLowerCase()}])
                })
                //Generating dictionary for column name along with formulae
                columnNameWithCorrelationFormulae = projectCase?.correlations ?? {}
            }
            if(projectCase?.isColumnUnit)
            {
                Object.entries(projectCase.column_unit).forEach(pair => {
                    columnNameWithUnit[pair[0]] = pair[1]
                    pair[1] && setColumnUnitOptions((options) => options.map(opt => opt.value).includes(pair[1].toLowerCase()) ? 
                                options :  [...options, {label: pair[1].toLowerCase(), value: pair[1].toLowerCase()}])
                })
            }
        })
        return {
            columnNameWithTypes, columnNameWithUnit, columnNameWithCorrelationFormulae
        }
    }, [allProjectTableData])

    const allColumnsFromTable = useMemo(() => {
        return projectTableMetaData.columnNameWithTypes ? Object.keys(projectTableMetaData.columnNameWithTypes) : {}
    }, [projectTableMetaData.columnNameWithTypes])

    //Build Sim table columns
    const listOfAllProjectTableColumn = useMemo(() => {
        let tableColumns = []
        allColumnsFromTable.forEach(header => {
            if(allColumnsFromGroup.includes(header)) {
                let group = getColumnGroupName(header)
                group && tableColumns.push({
                    Header: group.group_name,
                    columns: [getTableColumnFormat(projectTableMetaData, header, group.color_label ?? '')],
                    style: {
                        background: group.color_label ?? ''
                    },
                    // color: group.color_label ?? ''
                })
            }
            else {
                tableColumns.push(getTableColumnFormat(projectTableMetaData, header))
            }            
        })
        return tableColumns;
    }, [allColumnsFromTable, isTableLock, hiddenColumns, allColumnsFromGroup])
    
    useEffect(() => {
        let hiddenColumnsCacheData = localStorage.getItem('hiddenColumns')
        let hiddenColumnsData = hiddenColumnsCacheData ? JSON.parse(hiddenColumnsCacheData) : {}
        hiddenColumnsData[projectId] = [...new Set(hiddenColumns)]
        localStorage.setItem('hiddenColumns', JSON.stringify(hiddenColumnsData))
    }, [hiddenColumns])
    
    const addColumnOption = (columnOptions, column) => {
        ([selectedColumnActions.EditColumnsData, selectedColumnActions.EditColumn].includes(selectedColumnActionType?.value) && nonEditableColumnTypes.includes(column.type?.toLowerCase())) ||
        (selectedColumnActionType?.value === selectedColumnActions.DeleteColumn && ['casename', 'path'].includes(column.type?.toLowerCase())) || 
        (selectedColumnActionType?.value === selectedColumnActions.HideColumn && column.Header === casenameTypeColumn) ||
        (
            column.Header !== "_id" && columnOptions.push({ 
                value: column.Header, 
                label: column.Header,
            })
        )
    }

    //Generate sim table columns options
    useEffect(() => {
        if(!listOfAllProjectTableColumn) return;

        const columnOptions = []
        listOfAllProjectTableColumn.forEach(column => {   
            if(selectedColumnActionType?.value !== selectedColumnActions.CreateGroup || (!column.columns || column.Header === ' '))
            {
                if(column.columns) { 
                    column.columns.forEach(column => {
                        addColumnOption(columnOptions, column)
                    })
                }  
                else {
                    addColumnOption(columnOptions, column)
                } 
            }
        })
        setListOfSimTableColumnOptions(columnOptions)
    }, [listOfAllProjectTableColumn, selectedColumnActionType])

    // Integer, Float, String, Casename, Path, NA, Date
    const simCasesDataTypes = {
        'string' : 'string',
        'float': 'float',
        'integer': 'integer',
        'casename': 'string',
        'path': 'string',
        'date': 'date',
        'na': 'string',
        'custom': 'string'
    }
   
    const validateCellType = (cellValue, type) => {
        switch(type) {
            case 'float': {
                let pattern = /^-?\d*(\.\d*)?([eE][-+]?\d*)?$/
                return pattern.test(cellValue) || !cellValue
            }
            case 'integer': {
                let pattern = /^-?\d*([eE][+]?\d*)?$/
                return  pattern.test(cellValue) || !cellValue
            }
            case 'string':
            case 'date':
            default: 
                return true
        }
    }

    // Create an editable cell renderer
    const EditableCell = ({
        value: initialValue,
        row: { index, original },
        column: { id },
        updateMyData, // This is a custom function that we supplied to our table instance
    }) => {
        let projectCaseId =  original?._id
        // We need to keep and update the state of the cell normally
        const [value, setValue] = useState(initialValue ?? '')
        const [valueType, setValueType] = useState()
        const [dateInMilisec, setDateInMilisec] = useState()

        const valueMetaData = useMemo(()=> {
            let editColumnHeader = []
            listOfAllProjectTableColumn.forEach((columnHeader) => {
                // first we check whether column is belong to group or not
                // eslint-disable-next-line no-prototype-builtins
                if(columnHeader.hasOwnProperty("columns")) { 
                    columnHeader.columns.forEach((column) => {
                        if(column.Header === id) {
                            editColumnHeader.push(column)
                        }
                    })
                }
                else {
                    if(columnHeader.Header === id) {
                        editColumnHeader.push(columnHeader)
                    }
                }
            })

            // const editColumnHeader = listOfAllProjectTableColumn.filter((columnHeader) => columnHeader.accessor === id)
            return editColumnHeader.length > 0 ? editColumnHeader[0] : {}
        }, [id])

        useEffect(() => {
            if(Object.keys(valueMetaData).length > 0) {
                setValueType(valueMetaData.type?.toLowerCase())
            }
        }, [valueMetaData])

        useEffect(() => {
            if(valueType === 'date') {
                setDateInMilisec(original[`${id}_milisec`])
            }
        }, [valueType])
        
        const onChange = e => {
            let cellValue = e.target.value
            let cellValueType = simCasesDataTypes[valueType ?? 'custom']
            if(valueType && validateCellType(cellValue, cellValueType))
            {
                if(valueType === 'date') {
                    setValue(new Date(cellValue).getTime())
                    setDateInMilisec(new Date(cellValue).getTime())
                }
                else {
                    setValue(cellValue)
                }
            }
        }

        // We'll only update the external data when the input is blurred
        const onBlur = () => {
            let newValue = valueType === 'date' ? value : value?.trim()
            if(newValue && initialValue !== newValue) {
                if(valueType === 'float') {
                    if(/^-?\d+(\.\d+)?([eE][-+]?\d+)?$/.test(newValue)) {
                        //No need to append . to integers exponentials values
                        if(!/e|E/.test(newValue) && !newValue.includes(".")) {
                            newValue += '.0'
                        }
                        updateMyData(projectCaseId, original.case_name, id, newValue)
                    }
                    else
                        setToast({
                            id: id + projectCaseId,
                            removeDuplicate: true,
                            statusCode: 500,                            
                            message: `The ${id} of ${original.case_name} is invalid and will not be saved in the database.`
                        })
                }
                else if(valueType === 'integer') {
                    if(/^-?\d+([eE][+]?\d+)?$/.test(newValue))
                        updateMyData(projectCaseId, original.case_name, id, newValue)
                    else
                        setToast({
                            id: id + projectCaseId,
                            removeDuplicate: true,
                            statusCode: 500,
                            message: `The ${id} of ${original.case_name} is invalid and will not be saved in the database.`
                        })
                }
                else {
                    updateMyData(projectCaseId, original.case_name, id, newValue)
                }
            }
        }

        // If the initialValue is changed external, sync it up with our state
        useEffect(() => {
            setValue(initialValue)
        }, [initialValue])
        
        //We are considering column as primary when datatype is a casename. So, primary column should not be editable
        if(nonEditableColumnTypes.includes(valueType?.toLowerCase())) {
            return <span>{value}</span>
        }
        else if(valueType === 'date') {
            let date = new Date(dateInMilisec)
            let dateModified = date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2)
            return <input type='date' value={dateModified} onChange={onChange} onBlur={onBlur} style={{border: 0, width: '100%', margin: 0, padding: 0, background: 'transparent'}} />
        }
        else {
            return <input value={value ?? ''} onChange={onChange} onBlur={onBlur} style={{border: 0, width: '100%', margin: 0, padding: 0, background: 'transparent'}} />
        }
    }

    // Set our editable cell renderer as the default Cell renderer
    const defaultColumns = useMemo(() => {
        !isTableLock && setToast({
            id: lockUnlockToastId,
            removeDuplicate: true,
            statusCode: 300,
            message: "Any modifications after unlocking the Simulation Details table will be permanently saved into the database and cannot be undone."
        })
        return isTableLock ? {} : {
            Cell: EditableCell,
        }
    }, [isTableLock])
    
    // When our cell renderer calls updateMyData, we'll use
    // the rowIndex, columnId and new value to update the
    // original data
    const updateMyData = async (projectCaseId, casename, columnId, value) => {
        let edited_sim_data = [
            {
                "case_id": projectCaseId,
                "column_name": columnId,
                "new_value": value
            }
        ]
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const updateCellResponse = await useUpdateProjectCaseCell(edited_sim_data)
        if([200, 201].includes(updateCellResponse.statusCode)) {
            queryClient.invalidateQueries(`projectDetail_${projectId}`)
        }
        setToast({
            id: columnId + projectCaseId,
            statusCode: updateCellResponse.statusCode,
            message: `The ${columnId} of ${casename} saved successfully`
        })
    }

    const resetAllFlag = () => {
        setDialogueFlag(false)
        setSelectedColumnActionType()
        setSelectedSimTableColumns([])
        setExtendTableAction(extendTableActionMode.AddRow)
    }

    const getGroupAndHiddenColumns = (selectedColumns) => {
        //here we have to figureout columns are belong to the group or not, If yes then we have to show confirmation dailog
        let selectedSimCasesColumnFromGroups = '', selectedSimCasesHiddenColumns = '', groupColumns = [], groupIdAlongWithColumns = {}
        existingColumnGroups.forEach(group => {
            let groupColumnArray = group.column_names_list?.split(',')
            groupColumns = [...groupColumns, ...groupColumnArray]
            selectedColumns.forEach(selectedColumn => {
                if(groupColumnArray.includes(selectedColumn)) {
                    // eslint-disable-next-line no-prototype-builtins
                    groupIdAlongWithColumns[group._id] = groupIdAlongWithColumns.hasOwnProperty(group._id) ? [...groupIdAlongWithColumns[group._id], selectedColumn] : [selectedColumn]
                }
            })
        })

        selectedColumns.forEach(selectedColumn => {
            if(groupColumns.includes(selectedColumn)) {
                selectedSimCasesColumnFromGroups += selectedSimCasesColumnFromGroups === '' ? selectedColumn : ', ' + selectedColumn
            }
            if(hiddenColumns.includes(selectedColumn)) {
                selectedSimCasesHiddenColumns += selectedSimCasesHiddenColumns === '' ? selectedColumn : ', ' + selectedColumn
            }
        })
        return { selectedSimCasesColumnFromGroups, selectedSimCasesHiddenColumns, groupIdAlongWithColumns }
    }

    const getConfirmationMessageBasedOnGroupAndHiddenColumns = (selectedSimCasesColumnFromGroups, selectedSimCasesHiddenColumns, operationType= operationTypes.edit) => {
        let confirmationMessage = ''
        if(selectedSimCasesColumnFromGroups) {
            confirmationMessage = `- Following columns are belongs to the group:\n  ${selectedSimCasesColumnFromGroups}\n\n`
        }
        if(selectedSimCasesHiddenColumns) {
            confirmationMessage += `- Following columns are hidden:\n  ${selectedSimCasesHiddenColumns}\n\n`
        } 

        confirmationMessage += selectedSimCasesColumnFromGroups !== '' ? 
            `On confirming, those column will ${operationType === operationTypes.edit ? 'updated in' : 'removed from'} the groups.\n\n` : ""
        return confirmationMessage
    }

    const getEditOrDeleteColumnConfirmationMsg = (operationType, selectedSimCasesColumnFromGroups, selectedSimCasesHiddenColumns) => {
        let confirmationMessage = `Please confirm to proceed with ${operationType}`
        let confirmationMessageBasedOnGroupAndHiddenColumns = ''
        if(selectedSimCasesColumnFromGroups || selectedSimCasesHiddenColumns ) {
            confirmationMessageBasedOnGroupAndHiddenColumns = getConfirmationMessageBasedOnGroupAndHiddenColumns(selectedSimCasesColumnFromGroups, selectedSimCasesHiddenColumns)
        }
        if(confirmationMessageBasedOnGroupAndHiddenColumns)
        confirmationMessage = confirmationMessageBasedOnGroupAndHiddenColumns + confirmationMessage

        const confirm = window.confirm(confirmationMessage);
        if(!confirm) {
            setDialogueFlag(false)
            setSelectedSimTableColumns([])
            return false
        }
        return true
    }

    const HandleFormSubmit = async (values) => {
        switch(activeFormMode) {
            case formModes.AddColumnOrRow: {
                var response = null
                let toastId
                if(extendTableActionMode.AddRow === extendTableAction) {
                    toastId = toast.loading("Adding new simulation cases is in under process")
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    response = await useAddNewSimcasesToSimDataTable(currentProjectData, values)  
                }
                else if(extendTableActionMode.AddColumns === extendTableAction) {
                    toastId = toast.loading("Adding new columns is in under process")
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    response = await useAddMultipleColumnsToSimDataTable(projectId, values)  
                }
                else {
                    //Not allowing user to add duplicate casename and path type of column
                    let columnType = values.columnType?.toLowerCase()
                    if(['casename', 'path'].includes(columnType)) {
                        let allColumnTypes = Object.values(projectTableMetaData.columnNameWithTypes)
                        setToast({
                            statusCode: 409,
                            message: (columnType === 'path' && !allColumnTypes.toString().toLowerCase().includes(values.columnType))
                                ? "Existing project won't allow to add path type of column"
                                : `There should only be one ${columnType} type of column`
                        })
                        break;
                    }
                    toastId = toast.loading("Adding column header is in under process")
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    response = await useAddNewColumnHeaderToSimDataTable(projectId, values)
                }
                if([200, 201, 406].includes(response.statusCode)) {
                    queryClient.invalidateQueries(`projectDetail_${projectId}`)
                    setDialogueFlag(false)
                }
                setToast({
                    id: toastId,
                    statusCode: response.statusCode,
                    message: response.message
                })
                setExtendTableAction(extendTableActionMode.AddRow)
                break
            }
            case formModes.EditRow: {
                let selectedProjectSimCasesIds = []
                selectedProjectSimCases.forEach(simCase => {
                    selectedProjectSimCasesIds.push(simCase.original?._id)
                })
                let toastId = toast.loading("Edit row operation is in under process")
                setDialogueFlag(false)

                // eslint-disable-next-line react-hooks/rules-of-hooks, no-case-declarations
                const editSimCaseResponse = await useEditRowFromSimDataTable(values, selectedProjectSimCasesIds)
                if([200, 201].includes(editSimCaseResponse.statusCode)) {
                    queryClient.invalidateQueries(`projectDetail_${projectId}`)
                }
                setToast({
                    id: toastId,
                    statusCode: editSimCaseResponse.statusCode,
                    message: editSimCaseResponse.message
                })
                break
            }
            case formModes.Export: {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const exportTableResponse = await useExportSimTableData(projectId, selectedProjectSimCases, values)
                if(![200, 201].includes(exportTableResponse.statusCode)) {
                    setToast({
                        statusCode: exportTableResponse.statusCode,
                        message: exportTableResponse.message
                    })
                } 
                else {
                    exportAndDownloadFileRef.current.setAttribute('href', exportTableResponse.data)
                    exportAndDownloadFileRef.current.setAttribute('download', values.fileName + "." + (values.exportFileType ?? 'csv'))
                    exportAndDownloadFileRef.current.click()  
                }
                setSelectedColumnsForExport([])
                setDialogueFlag(false)
                break
            }
            case formModes.EditColumn: {
                const oldColumnName = initialValues.columnName
                let { selectedSimCasesColumnFromGroups, selectedSimCasesHiddenColumns, groupIdAlongWithColumns } = getGroupAndHiddenColumns([oldColumnName])
                if(!getEditOrDeleteColumnConfirmationMsg(operationTypes.edit, selectedSimCasesColumnFromGroups, selectedSimCasesHiddenColumns)) {
                    return
                }
                //groupIdAlongWithColumns object have edit column group id along with its column name
                let groupIdArray = Object.keys(groupIdAlongWithColumns)
                let group_id = groupIdArray.length === 1 ? groupIdArray[0] : ''
                // eslint-disable-next-line react-hooks/rules-of-hooks, no-case-declarations
                const editSimTableColumnResponse = await useEditColumnHeaderFromSimDataTable(projectId, oldColumnName, group_id, values)
                if([200, 201].includes(editSimTableColumnResponse.statusCode)) {
                    queryClient.invalidateQueries(`projectDetail_${projectId}`)
                    selectedSimCasesColumnFromGroups !== '' && queryClient.invalidateQueries(`simTableGroups_${projectId}`)
                }
                else {
                    setToast({
                        statusCode: editSimTableColumnResponse.statusCode,
                        message: editSimTableColumnResponse.message
                    })
                }
                setDialogueFlag(false)
                break
            }
            case formModes.Setting: {
                let resetColumnSelection = true;
                if(selectedColumnActions.DeleteColumn === selectedColumnActionType.value) {
                    let { selectedSimCasesColumnFromGroups, selectedSimCasesHiddenColumns, groupIdAlongWithColumns } = getGroupAndHiddenColumns(values.selectedColumns)
                    if(!getEditOrDeleteColumnConfirmationMsg(operationTypes.delete, selectedSimCasesColumnFromGroups, selectedSimCasesHiddenColumns)) {
                        return
                    }

                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    const deleteProjectColumnResponse = await useDeleteColumnFromSimDataTable(projectId, values.selectedColumns, groupIdAlongWithColumns)
                    if([200, 201].includes(deleteProjectColumnResponse.statusCode)) {
                        queryClient.invalidateQueries(`projectDetail_${projectId}`)
                        selectedSimCasesColumnFromGroups !== '' && queryClient.invalidateQueries(`simTableGroups_${projectId}`)
                        setHiddenColumns((hiddenColumns) => [...hiddenColumns.filter(column => !values.selectedColumns.includes(column))])
                    }
                    else {
                        setToast({
                            statusCode: deleteProjectColumnResponse.statusCode,
                            message: deleteProjectColumnResponse.message
                        })
                    }
                    setDialogueFlag(false)
                }
                else if(selectedColumnActions.EditGroup === selectedColumnActionType.value) {
                    let selectedColumnGroupId = selectedGroups[0].values?._id
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    const editColumnGroupResponse = await useEditColumnGroupFromSimDataTable(projectId, selectedColumnGroupId, values)
                    if([200, 201].includes(editColumnGroupResponse.statusCode)) {
                        queryClient.invalidateQueries(`simTableGroups_${projectId}`)
                    }
                    else {
                        setToast({
                            statusCode: editColumnGroupResponse.statusCode,
                            message: editColumnGroupResponse.message
                        })
                    }
                    if(editColumnGroupResponse.statusCode !== 409) {
                        setDialogueFlag(false)
                    }
                    else {
                        resetColumnSelection = false;
                    }
                    break
                }
                else if(selectedColumnActions.HideColumn === selectedColumnActionType.value) {
                    setHiddenColumns([...values.selectedColumns, "_id"])
                    setDialogueFlag(false)
                }
                else if(selectedColumnActions.CreateGroup === selectedColumnActionType.value) {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    const createColumnGroupResponse = await useCreateGroupInSimDataTable(projectId, values)
                    if([200, 201].includes(createColumnGroupResponse.statusCode)) {
                        queryClient.invalidateQueries(`simTableGroups_${projectId}`)
                    }
                    else {
                        setToast({
                            statusCode: createColumnGroupResponse.statusCode,
                            message: createColumnGroupResponse.message
                        })
                    }
                    if(createColumnGroupResponse.statusCode !== 409) {
                        setDialogueFlag(false)
                    }
                    else {
                        resetColumnSelection = false;
                    }
                }
                else if(selectedColumnActions.EditColumnsData === selectedColumnActionType.value) {
                    let { selectedSimCasesColumnFromGroups, selectedSimCasesHiddenColumns, groupIdAlongWithColumns } = getGroupAndHiddenColumns(values.selectedColumns)
                    if(!getEditOrDeleteColumnConfirmationMsg(operationTypes.edit, selectedSimCasesColumnFromGroups, selectedSimCasesHiddenColumns)) {
                        return
                    }

                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    const editColumnDataResponse = await useEditColumnsDataFromSimDataTable(projectId, values, groupIdAlongWithColumns)
                    if([200, 201].includes(editColumnDataResponse.statusCode)) {
                        queryClient.invalidateQueries(`projectDetail_${projectId}`)
                        selectedSimCasesColumnFromGroups !== '' && queryClient.invalidateQueries(`simTableGroups_${projectId}`)
                    }
                    else {
                        setToast({
                            statusCode: editColumnDataResponse.statusCode,
                            message: editColumnDataResponse.message
                        })
                    }
                    setDialogueFlag(false)
                }
                resetColumnSelection && setSelectedSimTableColumns([])
                break
            }
            default:
                break
        }
    }

    const deleteProjectSimCases = useMutation(
        async () => {
            const confirm = window.confirm('Please confirm to proceed with deletion..');
            if(!confirm) return

            const deleteProjectCasesResponse = await DeleteSelectedProjectCases(projectId, selectedProjectSimCases)
            if(![200, 201].includes(deleteProjectCasesResponse.statusCode)) {
                setToast({
                    statusCode: deleteProjectCasesResponse.statusCode,
                    message: deleteProjectCasesResponse.message
                })
            }
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(`projectDetail_${projectId}`);
            },
        }
    )

    const deleteSimCasesColumnGroup = useMutation(
        async () => {
            const confirm = window.confirm('Please confirm to proceed with deletion..');
            if(!confirm) return
            
            const deleteSimCasesColumnGroupResponse = await DeleteSimCasesColumnGroup(projectId, selectedGroups)
            if(![200, 201].includes(deleteSimCasesColumnGroupResponse.statusCode)) {
                setToast({
                    statusCode: deleteSimCasesColumnGroupResponse.statusCode,
                    message: deleteSimCasesColumnGroupResponse.message
                })
            }
            else if (selectedGroups.length === existingColumnGroups.length) {
                setDialogueFlag(false)
            }
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(`simTableGroups_${projectId}`)
                setSelectedGroups([])
            },
        }
    )

    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            inputFile: (selectedColumnActions.EditColumnsData === selectedColumnActionType?.value) || (![formModes.Setting, formModes.Export, formModes.EditColumn].includes(activeFormMode) && [extendTableActionMode.AddColumns, extendTableActionMode.AddRow].includes(extendTableAction)) ? Yup.mixed().required("Required") : Yup.mixed(),
            selectedColumnsForExport: activeFormMode === formModes.Export ? Yup.array().min(1).required("Required") : Yup.string(),
            fileName: activeFormMode === formModes.Export && selectedColumnsForExport.length > 0 && Yup.string().matches(/^[_a-zA-Z0-9]+$/, 'File name always have alphanemuric character only').required("File name is required"),
            selectedColumns: activeFormMode === formModes.Setting ? Yup.array().min(1).required("Required") : Yup.mixed(),
            groupName: [selectedColumnActions.CreateGroup, selectedColumnActions.EditGroup].includes(selectedColumnActionType?.value) ? Yup.string().required("Required") : Yup.string(),
            columnName: extendTableAction === extendTableActionMode.AddColumnManually || formModes.EditColumn === activeFormMode ? Yup.string().matches(/^[^+@!$#%^&*<>?/\\|~:,.]*$/, 'Column name should not contains one of +@!$#%^&*<>?/\\|~,. these characters').required("Required") : Yup.string(),
            columnType: extendTableAction === extendTableActionMode.AddColumnManually || formModes.EditColumn === activeFormMode ? Yup.string().required("Required") : Yup.string(),
            columnUnit: extendTableAction === extendTableActionMode.AddColumnManually || formModes.EditColumn === activeFormMode ? Yup.string().required("Required") : Yup.string(),
            formula: addOrEditColumnType.toLowerCase() === 'expression' && (extendTableAction === extendTableActionMode.AddColumnManually || formModes.EditColumn === activeFormMode) ? Yup.string().required("Required") : Yup.string(),
        })
    }, [activeFormMode, extendTableAction, selectedColumnsForExport, addOrEditColumnType, selectedColumnActionType])

    // const [simTableFileTemplateLink, setSimTableFileTemplateLink] = useState('')

    // useEffect(() => {
    //     (async () => {
    //         let exportDataResponse = await DownloadTemplateFile()
    //         if([200, 201].includes(exportDataResponse.statusCode)) {
    //             setSimTableFileTemplateLink(exportDataResponse.data)
    //         }  
    //     })()        
    // }, [])

    // useMemo(async () => {
    //     if(downloadTemplateFileButtonRef?.current) {
    //         let exportDataResponse = await DownloadTemplateFile()
    //         if([200, 201].includes(exportDataResponse.statusCode)) {
    //             downloadTemplateFileButtonRef.current?.setAttribute('href', exportDataResponse.data)
    //             downloadTemplateFileButtonRef.current?.setAttribute('download', "AddSimCasesTemplate.csv")
    //         }                
    //     }
    // }, [dialogueFlag, activeFormMode])


    const suggestionInputsForMathExpression = useMemo(() => {
        let suggestionInputs = []
        const addColumnToSUggestionList = (columnName, columnType) => {
            if(['integer', 'float'].includes(columnType?.toLowerCase())) {
                suggestionInputs.push(columnName)
            }
        }
        
        listOfAllProjectTableColumn.forEach(columnHeader => {
            //if columns belongs to the groups
            if(columnHeader.columns) { 
                columnHeader.columns.forEach(column => {
                    addColumnToSUggestionList(column.Header, column.type)
                })
            }
            else {
                addColumnToSUggestionList(columnHeader.Header, columnHeader.type)
            }
        })
        return [
            ...suggestionInputs.sort(), 
            ...[
                "pow()", "root", "Mod", "log()", 
                "sin()", "cos()", "tan()", 
                "asin()", "acos()", "atan()",
                "sinh()", "cosh()", "tanh()", 
                "asinh()", "acosh()", "atanh()"
            ]
        ]
    }, [listOfAllProjectTableColumn])    

    return { 
        isTableLock, setIsTableLock, activeFormMode, setActiveFormMode, formModes, currentProjectData, tableSettingsActionMenu,
        extendTableAction, setExtendTableAction, extendTableActionMode, dialogueFlag, setDialogueFlag, dialogueTitle, 
        resetAllFlag, selectedSimTableColumns, setSelectedSimTableColumns, listOfSimTableColumnOptions, selectedColumnActionOptions, selectedColumnActions,
        selectedColumnActionType, setSelectedColumnActionType, hiddenColumns, setHiddenColumns, selectedProjectSimCases, setSelectedProjectSimCases, 
        listOfAllProjectTableColumn, columnTypeOptions, columnUnitOptions, filterTableData, getProjectDetails, EditableCell, defaultColumns, 
        updateMyData, HandleFormSubmit, deleteProjectSimCases, deleteSimCasesColumnGroup, onClickOnSimse,
        initialValues, validationSchema, selectedColumnsForExport, setSelectedColumnsForExport, exportAndDownloadFileRef, downloadTemplateFileButtonRef, isExtentTableInstructionOpen, setIsExtendTableInstructionOpen,
        setToast, suggestionInputsForMathExpression, isSettingDropDownOpen, setIsSettingDropDownOpen, setAddOrEditColumnType
    }
}

const GetSimulationTableColumnGroupingData = (projectId) => {
    const [selectedGroups, setSelectedGroups] = useState([])
    const columnGroupData = useGetSimTableColumnGroupDetails(projectId)

    const existingColumnGroups = useMemo(() => {
        if(columnGroupData.status === 'success') {
            return columnGroupData.data
        }
        return []
    }, [columnGroupData.data])

    const columnGroupsTableData = useMemo(() => {
        let columnGroups = []
        existingColumnGroups?.forEach(group => {
            columnGroups.push({
                "column_names_list": group.column_names_list,
                "color_label": <Form.Control
                    type="color"
                    id="exampleColorInput"
                    defaultValue={group.color_label}
                    title="Choose your color"
                    disabled={true}
                />,
                "group_name": group.group_name,
                "_id": group._id
            })
        })
        return columnGroups
    }, [existingColumnGroups])

    const listOfAllColumnGroupsTableColumn = useMemo(() => {
        let tableColumns = []
        tableColumns.push({
            Header: "Id",
            accessor: "_id"
        },{
            Header: "Name",
            accessor: "group_name"
        },{
            Header: "Column List",
            accessor: "column_names_list"
        },{
            Header: "Color",
            accessor: "color_label"
        })
        return tableColumns;
    },[])

    return { selectedGroups, setSelectedGroups, columnGroupsTableData, listOfAllColumnGroupsTableColumn, existingColumnGroups }
}

const DeleteSelectedProjectCases = (projectId, selectedProjectSimCases) => {
    const selectedProjectSimCasesIds = []
    selectedProjectSimCases.forEach(simCase => {
        selectedProjectSimCasesIds.push(simCase.original?._id)
    })
    
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useDeleteSelectedProjectCases(projectId, selectedProjectSimCasesIds)
}

const DeleteSimCasesColumnGroup = (projectId, selectedGroups) => {
    const selectedSimCasesGroups = []
    selectedGroups?.forEach(simCase => {
        selectedSimCasesGroups.push(simCase.original?._id)
    })
    
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useDeleteSimCasesColumnGroup(projectId, selectedSimCasesGroups)
}

export { useExtendTableActionMode, useFormModes, useSelectedColumnActions, GetSimulationDetailsData, GetSimulationTableColumnGroupingData, DeleteSelectedProjectCases }