// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  ButtonWithSpinner,
  CenteredSpinner,
  UserContext,
} from '@tri/ui-shared';
import axios from 'axios';
import { useContext, useState } from 'react';
import { Breadcrumb, Button } from 'react-bootstrap';
import { Edit2, Trash2, UserPlus } from 'react-feather';
import { useMutation, useQueryClient } from 'react-query';
import { Table } from '../common/SimsightTable';
import { getCookie, sortTableColumn } from '../utils';
import CreateUserDialog from './CreateUserDialog';
import { useUserList } from './services/data-service/user.dataservice';
import ToastContext from '../context';
import { toast } from 'react-toastify';

const columns = [
  {
    Header: 'User Id',
    accessor: '_id',
  },
  {
    Header: 'User Name',
    accessor: 'username',
    sortType: sortTableColumn
  },
  {
    Header: 'User Type',
    accessor: 'user_type',
  },
  {
    Header: 'Email',
    accessor: 'email',
    sortType: sortTableColumn
  },
];

const Administration = () => {
  const queryClient = useQueryClient();
  const [selectedUser, setSelectedUser] = useState([]);
  const [openCreateUserDialogFlag, setOpenCreateUserDialogFlag] =
    useState(false);
  const [editUserFlag, setEditUserFlag] = useState(false);
  const { user } = useContext(UserContext);
  const setToast = useContext(ToastContext)

  const config = {
    headers: {
      'X-CSRF-TOKEN': getCookie('csrf_token')
    },
  };

  //Get User list
  const userListQuery = useUserList()

  //Delete Selected User
  const deleteSelectedUsers = useMutation(
    async () => {
      const confirm = window.confirm(
        'Please confirm to proceed with deletion..'
      );
      if (confirm) {
        let toastId = toast.loading(`Deleting users is in under process`)
        const logged_id = user._id
        try {
          let deletingLoggedInUser = false;
          const user_del_resp = await Promise.all(
            selectedUser.map(async (user) => {
              if (logged_id === user.original._id){
                let toastObj = selectedUser.length === 1 ?  {
                  id: toastId,  
                  statusCode: 304,
                  message: 'You cannot DELETE your profile!'
                } : {
                  statusCode: 304,
                  message: 'You cannot DELETE your profile!'
                }
                deletingLoggedInUser = true;
                setToast(toastObj)   
                return; 
              }
              else{
                let userDeleteResponse = await axios.delete(`/api/users/${user.original._id}/`, config)
                return {...userDeleteResponse, username: user.original.username}
              }
            })
          );

          if(deletingLoggedInUser && user_del_resp.length === 1) {
            return;
          }

          let failToDeleteUsers = ''
          user_del_resp.forEach((user) => {
            if(user && user.data.status_code !== 200) {
              failToDeleteUsers += failToDeleteUsers ? `, ${user.username}` : user.username
            }
          })

          if(!failToDeleteUsers){
            setToast({
              id: toastId,
              statusCode: 200,
              message: `All selected users are successfully deleted`
            })  
          }
          else {
            setToast({
              id: toastId,
              statusCode: 500,
              message: `These ${failToDeleteUsers} users are failed to delete`
            })
          }
        }
        catch (error) {
          setToast({
            id: toastId,
            statusCode: 500,
            message: `Failed to delete users`
          }) 
        }
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('userList');
      },
    }
  );

  return <div style={{marginTop: '3.3rem'}}>
      {
        user?.is_superuser ? <>
        <Breadcrumb>
          <Breadcrumb.Item active={true}>Administration</Breadcrumb.Item>
        </Breadcrumb>
        <div className="px-3">
          <div style={{ marginBottom: '16px' }}>
            {
              selectedUser.length === 0 &&
                <Button
                  size="sm"
                  variant="outline-primary"
                  className="mr-1"
                  title="Add new user"
                  onClick={() => {
                    setOpenCreateUserDialogFlag(true);
                    setEditUserFlag(false);
                  }}
                >
                  <UserPlus size={20} />
                </Button>
            }
            { 
                selectedUser.length === 1 && <Button
                  size="sm"
                  variant="outline-primary"
                  className="mr-1"
                  title="Edit selected user data"
                  onClick={() => {
                    setOpenCreateUserDialogFlag(true);
                    setEditUserFlag(true);
                  }}
                >
                <Edit2 size={20} />
              </Button>
            }
            {selectedUser.length > 0 && (
              <ButtonWithSpinner
                size="sm"
                variant="outline-primary"
                title="Delete selected user(s)"
                onClick={deleteSelectedUsers.mutateAsync}
                content={<Trash2 size={20} />}
                buttonClass="mr-1"
              ></ButtonWithSpinner>
            )}
          </div>
          {userListQuery.status === 'loading' && <CenteredSpinner />}
          {userListQuery.status === 'error' && <h5>Something went wrong ...</h5>}
          {userListQuery.status === 'success' && (
            <Table
              data={userListQuery.data}
              columns={columns}
              initialState={{ hiddenColumns: ['_id', 'email_id'] }}
              selectionEnabled={true}
              onSelect={setSelectedUser}
              tableClass="table skip-table-scroll skip-table-scroll-col1 skip-table-scroll-col2"
              showGlobalFilter={true}
              searchBoxPlaceholder="Search in User Table"
              disableSortBy={false}
            />
          )}

          {openCreateUserDialogFlag && (
            <CreateUserDialog
              onHide={() => {
                setOpenCreateUserDialogFlag(false);
              }}
              onApply={() => {
                queryClient.invalidateQueries('userList');
              }}
              editUserFlag={editUserFlag}
              selectedUserData={selectedUser}
            />
          )}
        </div>
      </> : <h5 className="px-3 pt-5 text-center">Whoops, you don't have access</h5>
      }
  </div>    
};

export default Administration;
