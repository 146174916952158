export const getCSSProperty = (name) => {
  const style = getComputedStyle(document.body);
  return style.getPropertyValue(`--${name}`).trim();
};

export const shadeHexColor = (color, percent) => {
  var f = parseInt(color.slice(1), 16),
    t = percent < 0 ? 0 : 255,
    p = percent < 0 ? percent * -1 : percent,
    R = f >> 16,
    G = (f >> 8) & 0x00ff,
    B = f & 0x0000ff;
  return (
    '#' +
    (
      0x1000000 +
      (Math.round((t - R) * p) + R) * 0x10000 +
      (Math.round((t - G) * p) + G) * 0x100 +
      (Math.round((t - B) * p) + B)
    )
      .toString(16)
      .slice(1)
  );
};

export const formatDate = (value) => {
  if (!value) return null;
  const date = new Date(value); // value is json representation of date
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};
