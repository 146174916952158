import { Fragment, Children } from 'react';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { Form, Row, Col } from 'react-bootstrap';

// 2-column horizontal layout

export const HorizontalFormField = ({
  w1 = 4, // column 1 width (1-12)
  w2 = 6, // column 2 width (1-12)
  label,
  rowClass,
  labelClass,
  children,
}) => {
  const childArray = Children.toArray(children);
  const ch1 = childArray[0];
  const ch2 = Children.count(children) > 1 ? childArray[1] : null;
  return (
    <Row className={rowClass}>
      {label && (
        <Form.Label column sm={w1} className={labelClass}>
          {label}
        </Form.Label>
      )}
      <Col sm={w2}>{ch1}</Col>
      <div style={{width: 'fit-content'}}>{ch2}</div>
    </Row>
  );
};

export const SelectOptions = ({ options }) => {
  const isString = _.isString(options[0]);

  return isString ? (
    <Fragment>
      {options.map((o) => (
        <option key={o}>{o}</option>
      ))}
    </Fragment>
  ) : (
    <Fragment>
      {options.map((o) => (
        <option key={o.value} value={o.value}>
          {o.label}
        </option>
      ))}
    </Fragment>
  );
};

// Form fields for 2-column horizontal layout
export const RadioGroupField = ({ name, ...rest }) => {
  const { values, setFieldValue } = useFormikContext();
  const value = _.get(values, name);
  return (
    <RadioGroupField2
      name={name}
      {...rest}
      value={value}
      onChange={(newValue) => {
        setFieldValue(name, newValue);
      }}
    />
  );
};

export const RadioGroupField2 = ({
  name,
  value,
  label,
  options,
  onChange,
  className,
}) => {
  return (
    <HorizontalFormField w1={12} w2={0} label={label} rowClass={className}>
      <>
        {options.map((o, index) => (
          <Form.Check
            custom
            inline
            id={`rbtn-${name}-${index}`}
            key={o.value}
            label={o.name}
            type="radio"
            checked={value === o.value}
            disabled={o.disabled}
            onChange={() => {
              onChange(o.value);
            }}
          />
        ))}
      </>
    </HorizontalFormField>
  );
};
