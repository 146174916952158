import { Fragment, useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { HorizontalFormField } from '../common/FormFields';
import {
  QuantityControl,
  CheckboxControl,
  SelectControl,
  Unit,
  TextControl,
} from '../common/FormControls';
import { Table, TableHeader, QuantityCell, TextCell } from '../common/Table';
import { ToScientificValue } from '@tri/ui-shared';

const materials = [
  { name: 'Carbon-Steel', thermalCond: 30 },
  { name: 'Copper', thermalCond: 218 },
  { name: 'Cupro-Nickel (90/10)', thermalCond: 30 },
  { name: 'Cupro-Nickel (70/30)', thermalCond: 20 },
  { name: 'Hastelloy (C276)', thermalCond: 7.5 },
  { name: 'Glass', thermalCond: 0.67 },
  { name: 'Incoloy (825)', thermalCond: 6.9 },
  { name: 'Inconel (600)', thermalCond: 9.2 },
  { name: 'Monel', thermalCond: 15 },
  { name: 'Nickel', thermalCond: 38 },
  { name: 'Stainless-Steel', thermalCond: 9.8 },
  { name: 'Tantalum', thermalCond: 32 },
  { name: 'Titanium', thermalCond: 11.5 },
];

const materialOptions = materials.map((o) => o.name);

const initJacketData = {
  inletTemperature: 350,
  outletTemperature: 320,
  viscosity: 0.001,
  density: 1000,
  specificHeatCapacity: 4200,
  thermalConductivity: 0.56,
  flowRate: 0.005,
};

const HeatTransferConditionsPanel = ({
  data,
  onChange,
  setFieldValue,
  disabled,
}) => {
  const htcData = data.heatTransfer;
  const isHeatTransferEnabled = htcData.enabled;

  const coilData = data.config.internals.helicalCoils || [];
  const coilFluidsData = htcData.coilFluids || [];
  if (isHeatTransferEnabled && coilFluidsData.length < coilData.length) {
    for (let i = coilFluidsData.length; i < coilData.length; i++) {
      coilFluidsData.push({
        inletTemperature: 350,
        outletTemperature: 320,
        viscosity: 0.001,
        density: 1000,
        specificHeatCapacity: 4200,
        thermalConductivity: 0.56,
        flowRate: 0.005,
        thermalConductivityOfCoil: 0.56,
      });
    }
  }
  coilFluidsData.forEach((d, index) => (d.id = index + 1));

  const coilFluidsTableColumns = useMemo(() => [
    {
      title: 'Coil No.',
      accessor: 'id',
      dataType: 'number',
      disabled: true,
    },
    {
      title: 'Inlet Temp',
      accessor: 'inletTemperature',
      qty: 'temperature',
      dataType: 'number',
    },
    {
      title: 'Outlet Temp',
      accessor: 'outletTemperature',
      qty: 'temperature',
      dataType: 'number',
    },
    {
      title: 'Viscosity',
      accessor: 'viscosity',
      qty: 'viscosity',
      dataType: 'number',
    },
    {
      title: 'Density',
      accessor: 'density',
      qty: 'density',
      dataType: 'number',
    },
    {
      title: 'Specific Heat Capacity',
      accessor: 'specificHeatCapacity',
      qty: 'specificHeatCapacity',
      dataType: 'number',
    },
    {
      title: 'Thermal Conductivity',
      accessor: 'thermalConductivity',
      qty: 'thermalConductivity',
      dataType: 'number',
    },
    {
      title: 'Flow Rate',
      accessor: 'flowRate',
      qty: 'volFlowRate',
      dataType: 'number',
    },
    {
      title: 'Thermal Conductivity Coil Wall',
      accessor: 'thermalConductivityOfCoil',
      qty: 'thermalConductivity',
      dataType: 'number',
    },
  ]);

  const isHelicalCoilPresent = data.config.internals.helicalCoils?.length > 0;
  const isSideJacketEnabled = data.config.internals.sideJacket.enabled;
  const isBottomJacketEnabled = data.config.internals.bottomJacket.enabled;
  let jacketFluidsData = htcData.jacketFluids || [];
  let jacketData = [];
  if (isSideJacketEnabled) {
    const jacketSideData = ToScientificValue(
      jacketFluidsData.heatTransferSideJacketFluid
    );
    jacketData[0] = jacketSideData;
    jacketData[0].type = 'side';
  }
  if (isBottomJacketEnabled) {
    const jacketBottomData = ToScientificValue(
      jacketFluidsData.heatTransferBottomJacketFluid
    );
    jacketData[1] = jacketBottomData;
    jacketData[1].type = 'bottom';
  }

  const jacketFluidsTableColumns = useMemo(() => [
    {
      title: 'Jacket Type',
      accessor: 'type',
      Cell: TextCell,
      disabled: true,
    },
    {
      title: 'Inlet Temp',
      accessor: 'inletTemperature',
      Cell: TextCell,
      qty: 'temperature',
      dataType: 'number',
    },
    {
      title: 'Outlet Temp',
      accessor: 'outletTemperature',
      Cell: TextCell,
      qty: 'temperature',
      dataType: 'number',
    },
    {
      title: 'Viscosity',
      accessor: 'viscosity',
      Cell: TextCell,
      qty: 'viscosity',
      dataType: 'number',
    },
    {
      title: 'Density',
      accessor: 'density',
      Cell: TextCell,
      qty: 'density',
      dataType: 'number',
    },
    {
      title: 'Specific Heat Capacity',
      accessor: 'spHeatCapacity',
      Cell: TextCell,
      qty: 'specificHeatCapacity',
      dataType: 'number',
    },
    {
      title: 'Thermal Conductivity',
      accessor: 'thermalConductivity',
      Cell: TextCell,
      qty: 'thermalConductivity',
      dataType: 'number',
    },
    {
      title: 'Flow Rate',
      accessor: 'flowRate',
      Cell: TextCell,
      qty: 'volFlowRate',
      dataType: 'number',
    },
  ]);

  const layoutProps = { w1: 5, w2: 4, rowClass: 'mb-1' };
  const layout2Props = { w1: 3, w2: 6, rowClass: 'mb-1' };
  return (
    <div>
      <p>
        <CheckboxControl
          name="heatTransfer.enabled"
          label="Heat Transfer Used"
          onChange={onChange}
          disabled={
            disabled ||
            (!isBottomJacketEnabled &&
              !isSideJacketEnabled &&
              !isHelicalCoilPresent)
          }
        />
      </p>

      {isHeatTransferEnabled && (
        <Fragment>
          <div className="row">
            <div className="col-sm">
              <Card>
                <Card.Body>
                  <Card.Title className="h6">Process Fluid</Card.Title>
                  <HorizontalFormField label="Initial Temp" {...layoutProps}>
                    <QuantityControl
                      name="heatTransfer.processFluid.initialTemp"
                      qty="temperature"
                      disabled={disabled}
                    />
                    <Unit qty="temperature" />
                  </HorizontalFormField>

                  <HorizontalFormField label="Final Temp" {...layoutProps}>
                    <QuantityControl
                      name="heatTransfer.processFluid.finalTemp"
                      qty="temperature"
                      disabled={disabled}
                    />
                    <Unit qty="temperature" />
                  </HorizontalFormField>

                  <HorizontalFormField
                    label="Sp. Heat Capacity"
                    {...layoutProps}
                  >
                    <QuantityControl
                      name="heatTransfer.processFluid.specificHeatCapacity"
                      qty="specificHeatCapacity"
                      disabled={disabled}
                    />
                    <Unit qty="specificHeatCapacity" />
                  </HorizontalFormField>

                  <HorizontalFormField label="Thermal Cond." {...layoutProps}>
                    <QuantityControl
                      name="heatTransfer.processFluid.thermalConductivity"
                      qty="thermalConductivity"
                      disabled={disabled}
                    />
                    <Unit qty="thermalConductivity" />
                  </HorizontalFormField>

                  <HorizontalFormField label="Add. Heat Load" {...layoutProps}>
                    <QuantityControl
                      name="heatTransfer.processFluid.additionalHeatLoad"
                      qty="energy"
                      disabled={disabled}
                    />
                    <Unit qty="energy" />
                  </HorizontalFormField>
                </Card.Body>
              </Card>
            </div>

            <div className="col-sm">
              <Card>
                <Card.Body>
                  <Card.Title className="h6">Vessel Wall</Card.Title>
                  <HorizontalFormField label="Material" {...layout2Props}>
                    <TextControl
                      name="heatTransfer.vesselWall.material"
                      options={materialOptions}
                      onChange={(e) => {
                        onChange(e);
                        const obj = materials.find(
                          (o) => o.name === e.target.value
                        );
                        setFieldValue(
                          'heatTransfer.vesselWall.thermalConductivity',
                          obj.thermalCond
                        );
                      }}
                      disabled={disabled}
                    />
                  </HorizontalFormField>

                  <HorizontalFormField label="Thermal Cond." {...layout2Props}>
                    <QuantityControl
                      name="heatTransfer.vesselWall.thermalConductivity"
                      qty="thermalConductivity"
                      disabled={disabled}
                    />
                    <Unit qty="thermalConductivity" />
                  </HorizontalFormField>

                  <HorizontalFormField label="Thickness" {...layout2Props}>
                    <QuantityControl
                      name="heatTransfer.vesselWall.thickness"
                      qty="length"
                      disabled={disabled}
                    />
                    <Unit qty="length" />
                  </HorizontalFormField>
                </Card.Body>
              </Card>
            </div>
          </div>

          {coilFluidsData.length > 0 && (
            <Card className="mt-3">
              <Card.Body>
                <Card.Title className="h6">Heat Transfer Fluid Coil</Card.Title>
                <Table
                  name="heatTransfer.coilFluids"
                  columns={coilFluidsTableColumns}
                  data={coilFluidsData}
                  defaultColumn={{
                    Header: TableHeader,
                    Cell: QuantityCell,
                    disabled: disabled,
                  }}
                  tableClass="table-borderless"
                />
              </Card.Body>
            </Card>
          )}

          {jacketData.length > 0 && (
            <Card className="mt-3">
              <Card.Body>
                <Card.Title className="h6">
                  Heat Transfer Fluid Jacket
                </Card.Title>
                <Table
                  name="jacketData"
                  columns={jacketFluidsTableColumns}
                  data={jacketData}
                  defaultColumn={{
                    Header: TableHeader,
                    Cell: QuantityCell,
                    disabled: disabled,
                  }}
                  tableClass="table-borderless"
                />
              </Card.Body>
            </Card>
          )}
        </Fragment>
      )}
    </div>
  );
};

const HeatTransferConditions = {
  id: 'heat-transfer-conditions',
  label: 'Heat Transfer',
  container: 'process',
  Renderer: HeatTransferConditionsPanel,
  tabEnabled: (activeNodeType) => activeNodeType === 'process',
};

export default HeatTransferConditions;
