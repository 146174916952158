import { useContext, useMemo, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import axios from 'axios';
import appLogo from '../../assets/simsight_logo.svg';
import bgImage from '../../assets/signin_bg.svg';
import ToastContext from '../context';
import * as Yup from 'yup'

const SigninPage = ({
  onSignin,
  activeView,
  activeSubView,
  activeSubSubView,
  type
}) => {
  const [isSigningIn, setSigningIn] = useState(false);
  const history = useHistory();
  const [hasError, setError] = useState(false);
  const setToast = useContext(ToastContext)

  const signInValidationSchema = useMemo(() => Yup.object().shape({
    username: Yup.string().required("Please enter username"),
    password: Yup.string().required("Please enter password")
  }), [])

  return (
    <div
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100vw',
        height: '100vh',
      }}
    >
      <div className="signInPage">
        <div
          style={{
            width: '16rem',
          }}
        >
          <div
            style={{
              backgroundColor: '#d76502',
              borderRadius: '0.25rem',
              padding: '0.5rem',
            }}
            className="d-flex justify-content-center"
          >
            <img src={appLogo} height={32} />
          </div>

          <Formik
            initialValues={{
              username: '',
              password: ''
            }}
            validationSchema={signInValidationSchema}
            onSubmit={async (values) => {
              setSigningIn(true);
              try {
                const user = await axios.post('/api/token/', values);
                if (user.data.msg !== '') {
                  setToast({
                    statusCode: 500,
                    message: user.data.msg
                  }) 
                  setSigningIn(false);
                  setError(true);
                  return;
                }
                onSignin(user.data);

                let openingRoute = '/app';
                if (activeView) {
                  openingRoute = openingRoute + `/${activeView}`;
                  if (activeSubView) {
                    openingRoute = openingRoute + `/${activeSubView}`;
                    if (activeSubSubView) {
                      openingRoute = openingRoute + `/${activeSubSubView}`;
                    }
                  }
                }
                else {
                  openingRoute += '/home'
                }
                if(type) {
                  openingRoute +=`/?type=${type}`;
                }
                history.push(openingRoute);
              } catch (err) {
                setToast({
                  statusCode: 500,
                  message: err
                }) 
                setSigningIn(false);
              }
              if (hasError) {
                return <p>Sorry, Sign up failed!</p>;
              }
              return <button onClick={handleClick}>Sign up</button>;
            }}
          >
            {({ handleSubmit, handleChange, values, errors, isValid }) => <Form noValidate >
                <Form.Group as={Row} className="mt-3 mb-3 position-relative">
                  <Col>
                      <Form.Control
                          name="username"
                          value={values.username}
                          placeholder='Username'
                          onChange={handleChange}
                          isInvalid={!!errors.username}
                      />
                      <Form.Control.Feedback type="invalid">
                          {errors.username}
                      </Form.Control.Feedback>
                  </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3 position-relative">
                  <Col>
                      <Form.Control
                          name="password"
                          type="password"
                          placeholder='Password'
                          value={values.password}
                          onChange={handleChange}
                          isInvalid={!!errors.password}
                      />
                      <Form.Control.Feedback type="invalid">
                          {errors.password}
                      </Form.Control.Feedback>
                  </Col>
              </Form.Group>
                <Button
                  disabled={!isValid}
                  onClick={handleSubmit}
                  className="w-100 d-block mx-auto mt-3 p-1 rounded-lg"
                  type='submit'
                >
                  {isSigningIn && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      variant="light"
                      className="mr-2"
                    />
                  )}
                  Sign in
                </Button>
              </Form>
            }
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default SigninPage;
