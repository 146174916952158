import { useContext } from 'react';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { UserContext } from '@tri/ui-shared';
 import { Table } from '../../common/SimsightTable';
import { Form, Row, Col, Button, InputGroup, Collapse } from 'react-bootstrap';
import { Formik } from 'formik';
/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { RadioGroupField2 } from 'apps/mixit/src/app/common/FormFields'
import CenteredSpinner from '../../../../../../libs/ui-shared/src/lib/CenteredSpinner';
import { SelectControl } from 'apps/mixit/src/app/common/FormControls';
/* eslint-enable @nrwl/nx/enforce-module-boundaries */
import { GetSimulationDetailsData, GetSimulationTableColumnGroupingData } from '../services/business-logic/simulation.businesslogic'
import ToolbarButton from '../../common/ToolbarButton';
import RSDropdown from '../../common/RSDropdown';
import DropdownMenu from '../../common/DropdownMenu';
import { Settings } from 'react-feather';
import { pillTabs, dateFormatOptions } from '../../utils/constants';
import _ from 'lodash';
import MandatorySymbol from '../../common/MandatorySymbol';
import { RBOffcanvas } from '../../common/RBOffcanvas';
import MathExpressionInput from '../../common/MathExpressionInput';

const SimulationDetailsPanel = ({projectId, setSelectedSimcase}) => {
    const { user } = useContext(UserContext);
    const { selectedGroups, setSelectedGroups, columnGroupsTableData, listOfAllColumnGroupsTableColumn, existingColumnGroups} = GetSimulationTableColumnGroupingData(projectId)
    const { isTableLock, setIsTableLock, activeFormMode, setActiveFormMode, extendTableAction, setExtendTableAction, 
        extendTableActionMode, dialogueFlag, setDialogueFlag, dialogueTitle, formModes, resetAllFlag,
        initialValues, validationSchema, tableSettingsActionMenu,
        selectedSimTableColumns, setSelectedSimTableColumns, listOfSimTableColumnOptions, selectedColumnActionOptions, selectedColumnActions, selectedColumnActionType, 
        setSelectedColumnActionType, hiddenColumns, setHiddenColumns, selectedProjectSimCases, setSelectedProjectSimCases, 
        listOfAllProjectTableColumn, columnTypeOptions, columnUnitOptions, filterTableData, getProjectDetails, 
        defaultColumns, updateMyData, HandleFormSubmit, deleteProjectSimCases, deleteSimCasesColumnGroup, onClickOnSimse,
        selectedColumnsForExport, setSelectedColumnsForExport, exportAndDownloadFileRef, downloadTemplateFileButtonRef, isExtentTableInstructionOpen, setIsExtendTableInstructionOpen,
        setToast, suggestionInputsForMathExpression, isSettingDropDownOpen, setIsSettingDropDownOpen, setAddOrEditColumnType
    } = GetSimulationDetailsData(projectId, selectedGroups, setSelectedGroups, setSelectedSimcase)

    return (
        <div className="px-2">
            {getProjectDetails.status === 'loading' && <CenteredSpinner />}
            {getProjectDetails.status === 'error' && <h5>Something went wrong ...</h5>}
            {getProjectDetails.status === 'success' && (filterTableData?.length > 0 ?
                <>
                    {
                        !user.is_staff && <>
                            <div style={{marginBottom: '1em'}}>
                                {
                                    hiddenColumns.length > 1 && 
                                    <ToolbarButton 
                                        title={`Show hidden columns (${
                                            hiddenColumns.filter(column => column !== "_id").join(", ")
                                        })`} 
                                        onClickAction={() => {setHiddenColumns(['_id'])}}
                                        iconType='show'
                                    />
                                }
                                { 
                                    existingColumnGroups?.length > 0 && <ToolbarButton 
                                        title="Show Groups"
                                        onClickAction={() => {setActiveFormMode(formModes.ColumnGroups); setDialogueFlag(true)}}
                                        iconType='group'
                                    />
                                }
                                { 
                                    selectedProjectSimCases.length > 0 &&
                                    <>                                       
                                        <ToolbarButton 
                                            title="Edit selected simulation cases"
                                            onClickAction={() => {setActiveFormMode(formModes.EditRow); setDialogueFlag(true)}}
                                            iconType='edit'
                                            buttomWithSpinner={true}
                                        />
                                        <ToolbarButton 
                                            title="Delete selected  simulation cases"
                                            onClickAction={deleteProjectSimCases.mutateAsync}
                                            iconType='delete'
                                            buttomWithSpinner={true}
                                        />
                                        <ToolbarButton 
                                            title="Export"
                                            onClickAction={() => {setActiveFormMode(formModes.Export); setDialogueFlag(true)}}
                                            iconType='export'
                                            buttomWithSpinner={true}
                                        />
                                    </>
                                }
                                <ToolbarButton 
                                    title="Add new simulation cases or columns"
                                    onClickAction={() => {setActiveFormMode(formModes.AddColumnOrRow); setDialogueFlag(true)}}
                                    iconType='add'
                                />
                                <span 
                                    onClick={(e) => {setIsSettingDropDownOpen(!isSettingDropDownOpen);e.preventDefault()}} 
                                    onBlur={(e) => setTimeout(()=>{setIsSettingDropDownOpen(false)}, 1000)} 
                                >
                                    <Button
                                        title="Settings"
                                        size="sm"
                                        variant="outline-primary"
                                        content={Settings}
                                    >
                                        <DropdownMenu 
                                            title={<Settings size='20' />}
                                            dropdownMenus={tableSettingsActionMenu} 
                                        />
                                    </Button>
                                </span>
                                <ToolbarButton 
                                    title={isTableLock ? "Unlock table" : "Lock table"}
                                    onClickAction={() => {setIsTableLock((isLock) => !isLock)}}
                                    iconType={isTableLock ? 'lock' : 'unlock' }
                                />
                            </div>

                            {
                                dialogueFlag && <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={HandleFormSubmit}
                                    enableReinitialize={true}
                                >
                                {({values, errors, handleChange, handleSubmit, setFieldValue, isSubmitting}) => (
                                    <RBOffcanvas 
                                        show={dialogueFlag}
                                        title={dialogueTitle}
                                        onOk={handleSubmit}
                                        onHide={() => resetAllFlag()}
                                        showOk={formModes.ColumnGroups !== activeFormMode && (formModes.Setting !== activeFormMode || selectedColumnActionType?.value !== selectedColumnActions.EditColumn)}
                                        isOkBtnDisabled={
                                            (activeFormMode === formModes.EditColumn ?
                                            _.isEqual({...values, selectedColumns: undefined}, initialValues) :
                                            _.isEqual(values, initialValues)) || isSubmitting
                                        }
                                    >
                                        <Form>
                                            {
                                                activeFormMode === formModes.ColumnGroups && 
                                                <>
                                                    <div className='mb-3'>
                                                        {
                                                            selectedGroups?.length === 1 && <ToolbarButton 
                                                                title="Edit selected group"
                                                                onClickAction={() => {setActiveFormMode(formModes.Setting); setSelectedColumnActionType({label:selectedColumnActions.EditGroup, value: selectedColumnActions.EditGroup}); setDialogueFlag(true);}}
                                                                iconType='edit'
                                                                buttomWithSpinner={true}
                                                            />
                                                        }
                                                        {   
                                                            selectedGroups?.length > 0 && <ToolbarButton 
                                                                title="Delete selected groups"
                                                                onClickAction={deleteSimCasesColumnGroup.mutateAsync}
                                                                iconType='delete'
                                                                buttomWithSpinner={true}
                                                            />
                                                        }
                                                    </div>
                                                    {
                                                        columnGroupsTableData.length > 0 &&
                                                        <Table
                                                            name="column-group"
                                                            data={columnGroupsTableData}
                                                            initialState={{ hiddenColumns: ["_id"] }}
                                                            columns={listOfAllColumnGroupsTableColumn}
                                                            selectionEnabled={true}
                                                            onSelect={setSelectedGroups}
                                                            tableClass="table skip-table-scroll skip-table-scroll-col1 skip-table-scroll-col2"
                                                            disableSortBy={false}
                                                            showGlobalFilter={false}
                                                        />
                                                    }
                                                </>
                                            }
                                            {
                                                activeFormMode === formModes.Setting &&
                                                <>
                                                    {
                                                        selectedColumnActionType?.value !== selectedColumnActions.EditGroup && <Form.Group as={Row} className="mb-3">
                                                        <Form.Label column sm="3"> Action</Form.Label>
                                                            <Col sm="9">
                                                                <RSDropdown
                                                                    value={selectedColumnActionType}
                                                                    extraOption={false}
                                                                    handleChange={
                                                                        (event) => {
                                                                            setFieldValue("selectedColumnAction", event.value)
                                                                            setSelectedColumnActionType(event)
                                                                        }
                                                                    }
                                                                    options={selectedColumnActionOptions}
                                                                    isDisabled={true}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                    }
                                                    { 
                                                        selectedColumnActionType && <Form.Group as={Row} className="mb-3">
                                                            <Form.Label column sm="3">
                                                                Select column<MandatorySymbol />
                                                            </Form.Label>
                                                            <Col sm="9">
                                                                <RSDropdown
                                                                    options={listOfSimTableColumnOptions}
                                                                    placeholder="Search or select column"
                                                                    handleChange ={(selectedCols) => {
                                                                        setSelectedSimTableColumns(selectedCols)
                                                                        let selectedSimTableColumnsName = [];
                                                                        [selectedColumnActions.EditColumn].includes(selectedColumnActionType.value) ?
                                                                            selectedSimTableColumnsName.push(selectedCols.value)
                                                                            : selectedCols.forEach(option => selectedSimTableColumnsName.push(option.value))
                                                                        setFieldValue('selectedColumns', selectedSimTableColumnsName);
                                                                    }}
                                                                    value={selectedSimTableColumns}
                                                                    isMulti={![selectedColumnActions.EditColumn].includes(selectedColumnActionType.value)}
                                                                />
                                                                <div style={{display: errors.selectedColumns ? 'block' : 'none', color: '#dc3545', fontSize: '80%'}}>
                                                                    {errors.selectedColumns}
                                                                </div> 
                                                            </Col>
                                                        </Form.Group>
                                                    }                                                    
                                                    {
                                                        ([selectedColumnActions.CreateGroup, selectedColumnActions.EditGroup].includes(selectedColumnActionType?.value)) &&
                                                        <>
                                                            <Form.Group as={Row} className="mb-3">
                                                                <Form.Label column sm="3"> Group Name<MandatorySymbol /> </Form.Label>
                                                                <Col sm="9">
                                                                    <Form.Control
                                                                        name="groupName"
                                                                        value={values.groupName}
                                                                        onChange={handleChange}
                                                                        isInvalid={errors.groupName}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {errors.groupName}
                                                                    </Form.Control.Feedback>
                                                                </Col>
                                                            </Form.Group>
                                                            <Form.Group as={Row} className="mb-3">
                                                                <Form.Label column sm="3"> Fill Color </Form.Label>
                                                                <Col sm="2">
                                                                    <Form.Control
                                                                        title="Choose color"
                                                                        type="color"
                                                                        name="fillColor"
                                                                        value={values.fillColor}
                                                                        onChange={handleChange}
                                                                        isInvalid={errors.fillColor}
                                                                    />
                                                                </Col>
                                                            </Form.Group>
                                                        </>
                                                    }
                                                </>
                                            }
                                            {
                                                activeFormMode === formModes.Export && <>
                                                <Form.Group as={Row} className="mb-3">
                                                    <Form.Label column sm="3">
                                                        Select column<MandatorySymbol />
                                                    </Form.Label>
                                                    <Col sm="9">
                                                        <RSDropdown
                                                            options={listOfSimTableColumnOptions}
                                                            placeholder="Search or select column"
                                                            handleChange ={(selectedCols) => {
                                                                setSelectedColumnsForExport(selectedCols)
                                                                let selectedSimTableColumnsName = []
                                                                selectedCols.forEach(option => selectedSimTableColumnsName.push(option.value))
                                                                setFieldValue('selectedColumnsForExport', selectedSimTableColumnsName);
                                                            }}
                                                            value={selectedColumnsForExport}
                                                            isMulti={true}
                                                        />   
                                                        <div style={{display: errors.selectedColumnsForExport ? 'block' : 'none', color: '#dc3545', fontSize: '80%'}}>
                                                            {errors.selectedColumnsForExport}
                                                        </div> 
                                                    </Col>
                                                </Form.Group>
                                                {
                                                    selectedColumnsForExport.length > 0 && <>
                                                        <Form.Group as={Row} className="mb-3">
                                                            <Form.Label column sm="3"> File Name <MandatorySymbol /> </Form.Label>
                                                            <Col sm="9">
                                                                <InputGroup>
                                                                    <Form.Control
                                                                        style={{
                                                                            width: '60%'
                                                                        }}
                                                                        name="fileName"
                                                                        value={values.fileName}
                                                                        onChange={handleChange}
                                                                        isInvalid={errors.fileName}
                                                                    />
                                                                    <SelectControl
                                                                        name="exportFileType"
                                                                        options={[
                                                                            {label: 'csv', value: "csv" },
                                                                            {label: 'xlsx', value: "xlsx" },
                                                                            {label: 'pdf', value: "pdf" }
                                                                        ]}
                                                                        onChange={(event) => {
                                                                            setFieldValue('exportFileType', event.target.value)
                                                                        }}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {errors.fileName}
                                                                    </Form.Control.Feedback>
                                                                </InputGroup>
                                                            </Col>
                                                        </Form.Group>
                                                        <a ref={exportAndDownloadFileRef} />
                                                    </>
                                                }
                                            </>
                                            }
                                            {
                                                ((formModes.Setting === activeFormMode && selectedColumnActions.EditColumnsData === selectedColumnActionType?.value) || [formModes.AddColumnOrRow, formModes.EditColumn, formModes.EditRow].includes(activeFormMode)) && 
                                                <>
                                                    {
                                                        formModes.AddColumnOrRow === activeFormMode && <Form.Group as={Row} className="mb-3" >
                                                            <Form.Label column sm="3">
                                                                Action Type
                                                            </Form.Label>
                                                            <Col sm="9">
                                                                <RadioGroupField2
                                                                    w1={9}
                                                                    w2={3}
                                                                    name="ExtendTableAction"
                                                                    onChange={(actionType) => {
                                                                        setExtendTableAction(actionType)
                                                                        setFieldValue("ExtendTableAction", actionType)
                                                                    }}
                                                                    options={[
                                                                        { name: 'Add new simulation cases', value: extendTableActionMode.AddRow },
                                                                        { name: 'Add new columns', value: extendTableActionMode.AddColumns },
                                                                        { name: 'Add column header', value: extendTableActionMode.AddColumnManually },
                                                                    ]}
                                                                    value={values.ExtendTableAction}
                                                                    className="mb-2"
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                    }
                                                    {
                                                        !(extendTableAction === extendTableActionMode.AddColumnManually || formModes.EditColumn === activeFormMode) ?
                                                            <>
                                                                <Form.Group as={Row} className="mb-3">
                                                                    <Form.Label column sm="3"> Input Data File <MandatorySymbol />
                                                                    </Form.Label>
                                                                    <Col sm="9" style={{zIndex: 0}}>
                                                                        <Form.Control
                                                                            type="file"
                                                                            custom
                                                                            accept=".csv"
                                                                            title={values.inputFile?.name || 'Select .csv file'}
                                                                            onChange={(event) => { 
                                                                                let file = event.currentTarget.files[0]
                                                                                if (file === undefined || file.name.match(/.csv$/) === null) {
                                                                                    return setToast({
                                                                                        statusCode: 500,
                                                                                        message: 'Please select .csv file only.'
                                                                                    })
                                                                                }
                                                                                setFieldValue('inputFile', file);
                                                                            }}
                                                                            isInvalid={errors.inputFile}
                                                                            aria-describedby="downloadTemplateFile"
                                                                        />
                                                                        <Form.Control.Feedback type="invalid">
                                                                            {errors.inputFile}
                                                                        </Form.Control.Feedback>
                                                                        <Form.Text id="downloadTemplateFile"> 
                                                                            {
                                                                                ((activeFormMode === formModes.AddColumnOrRow && extendTableAction === extendTableActionMode.AddRow) || activeFormMode === formModes.EditRow) ? 
                                                                                <a style={{color: 'blue'}} ref={downloadTemplateFileButtonRef}>Download a csv template</a> : null
                                                                            }                                                                                  
                                                                        </Form.Text>
                                                                    </Col>
                                                                </Form.Group>
                                                                {
                                                                    ([extendTableActionMode.AddRow, extendTableActionMode.AddColumns].includes(values.ExtendTableAction) || activeFormMode === formModes.EditRow || selectedColumnActions.EditColumnsData === selectedColumnActionType?.value) && <Form.Group as={Row} className="mb-3" >
                                                                        <Col>
                                                                            <Form.Text style={{fontSize: '87%'}}>
                                                                                <span style={{color: '#e93535cc'}}>Instructions: </span>
                                                                                <span className='text-link' style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => setIsExtendTableInstructionOpen(!isExtentTableInstructionOpen)} aria-controls="extend-table-instruction" aria-expanded={isExtentTableInstructionOpen}>
                                                                                    Please check the following things before you upload the files.
                                                                                </span>
                                                                                <Collapse in={isExtentTableInstructionOpen}>
                                                                                    <div id="extend-table-instruction">
                                                                                        <Form.Text as="p"muted>
                                                                                            {
                                                                                                selectedColumnActions.EditColumnsData === selectedColumnActionType?.value ? <>
                                                                                                    <li>Make sure the nos of rows in .csv should match with that in simulation details</li>
                                                                                                    <li>Nos of selected columns from simulation details should match with that in the .csv</li>
                                                                                                </> : <>
                                                                                                    {
                                                                                                        (extendTableActionMode.AddRow === values.ExtendTableAction || activeFormMode === formModes.EditRow) && <>
                                                                                                            <li>Make sure the no of columns in .csv and simulation details should be same</li>
                                                                                                            <li>Column header names in .csv should exactly match with that in simulation details</li>
                                                                                                        </>
                                                                                                    }
                                                                                                    { 
                                                                                                        extendTableActionMode.AddColumns === values.ExtendTableAction && activeFormMode !== formModes.EditRow &&
                                                                                                        <li>Make sure the nos of rows in .csv should match with that in simulation details</li> 
                                                                                                    }
                                                                                                </>

                                                                                            }
                                                                                        </Form.Text>
                                                                                    </div>
                                                                                </Collapse>   
                                                                            </Form.Text>
                                                                        </Col>
                                                                    </Form.Group>
                                                                }
                                                            </>
                                                        : <>
                                                            <Form.Group as={Row} className="mb-3">
                                                                <Form.Label column sm="3"> Column Name<MandatorySymbol /> </Form.Label>
                                                                <Col sm="9">
                                                                    <Form.Control
                                                                        name="columnName"
                                                                        value={values.columnName}
                                                                        onChange={handleChange}
                                                                        isInvalid={errors.columnName}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {errors.columnName}
                                                                    </Form.Control.Feedback>
                                                                </Col>
                                                            </Form.Group>
                                                            <Form.Group as={Row} className="mb-3">
                                                                <Form.Label column sm="3"> Column Type{formModes.EditColumn !== activeFormMode && <MandatorySymbol />}</Form.Label>
                                                                <Col sm="9">
                                                                    <Form.Control
                                                                        name="columnType"
                                                                        value={values.columnType}
                                                                        onChange={(e) => {
                                                                            setAddOrEditColumnType(e.target.value);
                                                                            handleChange(e)
                                                                        }}
                                                                        list='columnTypes'
                                                                        disabled={formModes.EditColumn === activeFormMode}
                                                                        isInvalid={errors.columnType}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {errors.columnType}
                                                                    </Form.Control.Feedback>
                                                                    <datalist id="columnTypes">
                                                                        {columnTypeOptions.map(({ value, label }) => (
                                                                            <option value={value}>{label}</option>
                                                                        ))}
                                                                    </datalist>
                                                                </Col>
                                                            </Form.Group>
                                                            {
                                                                values.columnType?.toLowerCase() === 'expression' && <Form.Group as={Row} className="mb-3">
                                                                    <Form.Label column sm="3"> Expression Value <MandatorySymbol /></Form.Label>
                                                                    <Col sm="9">
                                                                        <MathExpressionInput
                                                                            name="formula"
                                                                            suggestionInputs={suggestionInputsForMathExpression}
                                                                        />                                                             
                                                                    </Col>
                                                                </Form.Group>
                                                            }
                                                            <Form.Group as={Row} className="mb-3">
                                                                <Form.Label column sm="3"> Column Unit<MandatorySymbol /></Form.Label>
                                                                {
                                                                    values.columnType?.toLowerCase() === 'date' ?
                                                                    <Col sm="9">
                                                                        <RSDropdown
                                                                            value={({
                                                                                label: values.columnUnit,
                                                                                value: values.columnUnit
                                                                            })}
                                                                            extraOption={false}
                                                                            handleChange={
                                                                                (event) => {
                                                                                    setFieldValue("columnUnit", event.value)
                                                                                }
                                                                            }
                                                                            options={dateFormatOptions}
                                                                        />
                                                                    </Col> :
                                                                    <Col sm="9">
                                                                        <Form.Control
                                                                            name="columnUnit"
                                                                            value={values.columnUnit}
                                                                            onChange={handleChange}
                                                                            list='columnUnits'
                                                                            autoComplete={false}
                                                                            isInvalid={errors.columnUnit}
                                                                        />
                                                                        <Form.Control.Feedback type="invalid">
                                                                            {errors.columnUnit}
                                                                        </Form.Control.Feedback>
                                                                        <datalist id="columnUnits">
                                                                            {columnUnitOptions.map(({ value, label }) => (
                                                                                <option value={value}>{label}</option>
                                                                            ))}
                                                                        </datalist>
                                                                    </Col>
                                                                }
                                                            </Form.Group>
                                                            <Form.Group as={Row} className="mb-3" >
                                                                <Col>
                                                                    <Form.Text style={{fontSize: '87%'}}>
                                                                        <span style={{color: '#e93535cc'}}>Note: </span>
                                                                        <span>
                                                                            Unit given by user will be accepted as it is, validation checks are not applied. Please give relevant units to columns.
                                                                        </span>
                                                                    </Form.Text>
                                                                </Col>
                                                            </Form.Group>
                                                        </>
                                                    }
                                                </>
                                            }
                                        </Form>
                                    </RBOffcanvas>
                                )}
                                </Formik>
                            }
                        </>
                    }
                    <Table
                        data={filterTableData}
                        columns={listOfAllProjectTableColumn}
                        defaultColumn={defaultColumns}
                        initialState={{ hiddenColumns: hiddenColumns }}
                        selectionEnabled={!user.is_staff}
                        onSelect={setSelectedProjectSimCases}
                        tableClass={`table skip-table-scroll skip-table-scroll-col1 ${user.is_staff ? '' : 'skip-table-scroll-col2'} `}
                        searchBoxPlaceholder="Search in Project Table"
                        disableSortBy={false}
                        showColumnTypeOnHeaderHover={true}
                        onClickLink={onClickOnSimse}
                        updateMyData={updateMyData}
                    /> 
                </> : <h5>No data found...</h5>)
            }
        </div>
    )
}

const SimulationDetails = (projectId, setSelectedSimcase) => {
    return {
        label: pillTabs.SimulationDetails,
        Panel: <SimulationDetailsPanel projectId={projectId} setSelectedSimcase={setSelectedSimcase}/>,
        tabEnabled: true
    }
}

export { SimulationDetails }
