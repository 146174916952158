import { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import { User } from 'react-feather';
import * as yup from 'yup';
import axios from 'axios';
import AuthLayout from './AuthLayout';
import { Formik } from 'formik';

const requiredSchema = yup.string().required('Required');

const schema = yup.object().shape({
  email: requiredSchema.email('Please provide a valid email address.'),
  password: requiredSchema,
});

const SigninPage = ({
  title,
  onSignin,
  activeView,
  activeSubView,
  activeSubSubView,
}) => {
  const [isSigningIn, setSigningIn] = useState(false);
  const history = useHistory();

  const initialValues = {
    email: '',
    password: '',
  };
  const inputRef = useRef();
  useEffect(() => inputRef.current && inputRef.current.focus());
  return (
    <AuthLayout>
      <div className="text-center mt-4">
        <h2>{title}</h2>
        <p className="lead">Sign in to your account to continue</p>
      </div>

      <Card
        style={{
          backgroundColor: '#e9e9e9',
          boxShadow: '0 25px 50px -12px rgb(0 0 0 / 0.25)',
          border: 'none',
          width: '450px',
        }}
      >
        <Card.Body>
          <div className="m-sm-4">
            <div
              style={{
                width: 96,
                height: 96,
                margin: 'auto',
              }}
              className="bg-primary rounded-circle text-center"
            >
              <User color="#e9e9e9" size="96" />
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={async (values) => {
                setSigningIn(true);
                try {
                  const user = await axios.post('/api/auth/signin', values);
                  onSignin(user.data);
                  let openingRoute = '/app';
                  if (activeView) {
                    openingRoute = openingRoute + `/${activeView}`;
                    if (activeSubView) {
                      openingRoute = openingRoute + `/${activeSubView}`;
                      if (activeSubSubView) {
                        openingRoute = openingRoute + `/${activeSubSubView}`;
                      }
                    }
                  }
                  history.push(openingRoute);
                } catch (err) {
                  alert(err.response.data);
                  setSigningIn(false);
                }
              }}
            >
              {({
                setFieldError,
                handleSubmit,
                handleChange,
                touched,
                values,
                errors,
              }) => (
                <Form>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      size="sm"
                      type="email"
                      name="email"
                      value={values.email}
                      ref={inputRef}
                      placeholder="Enter your email"
                      onChange={handleChange}
                      isInvalid={errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      size="sm"
                      type="password"
                      name="password"
                      value={values.password}
                      placeholder="Enter your password"
                      onChange={handleChange}
                      isInvalid={touched.password && errors.password}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                    <small>
                      <p
                        className="cursor-pointer text-link"
                        onClick={async (e) => {
                          if (!values.email) {
                            setFieldError(
                              'email',
                              'Please provide a valid email address.'
                            );
                            return;
                          } else {
                            history.push('/auth/reset-password');
                            await axios.post(
                              '/api/auth/request-password-reset',
                              {
                                email: values.email,
                              }
                            );
                            history.push('/sent-reset-link');
                          }
                        }}
                      >
                        Forgot password?
                      </p>
                    </small>
                  </Form.Group>

                  <div className="text-center mt-3">
                    <Button onClick={handleSubmit} type="submit">
                      {isSigningIn && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          variant="light"
                          className="mr-2"
                        />
                      )}
                      Sign in
                    </Button>
                  </div>
                  <div className="text-left mt-3">
                    <Link to="/auth/signup">
                      Don't have an account? Sign up
                    </Link>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Card.Body>
      </Card>
    </AuthLayout>
  );
};

export default SigninPage;
