import { useMemo, useState } from 'react';
import { useContext } from 'react';
import ToastContext from '../../../context';
import { useGetSimcaseAnimationURL, useSaveAnimationData, useSimcaseAnimationsList } from '../data-service/animations.dataservice';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import UserContext from './../../../../../../../libs/ui-shared/src/lib/UserContext';
import { useQueryClient } from 'react-query';

const GetSimcaseAnimation = (simCaseId, fileId) => useGetSimcaseAnimationURL(simCaseId, fileId).data

const useSimCaseAnimationsData = (simcaseId) => {
    const setToast = useContext(ToastContext)
    const { user } = useContext(UserContext)
    const queryClient = useQueryClient();
    const simcaseAnimations = useSimcaseAnimationsList([simcaseId])
    const [isGettingAnimations, setIsGettingAnimations] = useState(true)
    const [animations, setAnimations] = useState([])
    const [animationModal, setAnimationModal] = useState({
        show: false,
        title: "",
        url: ""
    })

    useMemo(() => {
        if(simcaseAnimations.status === 'success') {
            let allSimCaseAnimationsData = simcaseAnimations.data || {}
            // eslint-disable-next-line no-prototype-builtins
            let simcaseAnimationData = allSimCaseAnimationsData.hasOwnProperty(simcaseId) ? allSimCaseAnimationsData[simcaseId] : []
            let animations = []
            simcaseAnimationData[1]?.file_info.forEach((animation, index) => animations.push({...animation, isDescDisabled: true, isTitleDisabled: true, isButtonDisabled: true }))
            setAnimations(animations)
            setIsGettingAnimations(false)
        }
        return []
    }, [simcaseAnimations.status])

    const handleDataChange = (e, id, type = 'filename') => {
        setAnimations((animations) => 
            animations.map((animation, index) => {
                if (animation.id === id) {
                    if(type === 'description') {
                        return { ...animation, description: e.target.value, isButtonDisabled: false }
                    } 
                    else {
                        return { ...animation, filename: e.target.value, isButtonDisabled: false }
                    }
                }
                return animation
            })      
        )
    }

    const handleIsDisabled = (id) => {
        setAnimations((animations) => 
        animations.map((animation, index) => {
                if (animation.id === id) {
                    return { ...animation, isDescDisabled: !animation.isDescDisabled, isTitleDisabled: !animation.isTitleDisabled }
                }
                return animation
            })      
        )
    }

    const SaveAnimationData = async (id) => {
        let animation = animations.find(animation => animation.id === id)
        
        let allSimCaseAnimationsData = simcaseAnimations.data || {}
        // eslint-disable-next-line no-prototype-builtins
        let simcaseAnimationData = allSimCaseAnimationsData.hasOwnProperty(simcaseId) ? allSimCaseAnimationsData[simcaseId] : []
        let origianAnimation = simcaseAnimationData[1]?.file_info.find(animation => animation.id === id)
        if(origianAnimation.description === animation.description && origianAnimation.filename === animation.filename) {
            return
        } 

        setAnimations((animations) => 
            animations.map((animation, index) => {
                if (animation.id === id) {
                    return { ...animation, isButtonDisabled: true }
                }
                return animation
            })      
        )

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const saveAnimationDataResponse = await useSaveAnimationData(simcaseId, animation, user.access)
        if([200, 201].includes(saveAnimationDataResponse.statusCode)) {
            queryClient.invalidateQueries(`simcaseAnimations${simcaseId}`)
            handleIsDisabled(id)
            return
        }
        setToast({
            statusCode: saveAnimationDataResponse.statusCode,
            message: saveAnimationDataResponse.message
        })
    } 
    
    return { animationModal, setAnimationModal, isGettingAnimations, animations, handleDataChange, handleIsDisabled, SaveAnimationData }
}

export { GetSimcaseAnimation, useSimCaseAnimationsData }