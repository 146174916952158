import { useState, useEffect } from 'react'
import { Nav } from 'react-bootstrap'

function Pills({tabs, activeTabIndex = 0}) {
    const [activeTabId, setActiveTabId] = useState()
    const [Panel, setActivePanel] = useState(null)

    useEffect(() => {
        setActiveTabId(activeTabIndex)
    }, [tabs])
    
    useEffect(() => {
        let activeTab = tabs[activeTabId]
        setActivePanel(activeTab?.Panel)
    }, [tabs, activeTabId])
    
    return (
        <div className='h-100 p-2'>
            <Nav 
                variant='pills'
                activeKey={activeTabId}
                onSelect={(id) => {
                    setActiveTabId(parseInt(id))
                    window.history.replaceState(null, null, `${window.location.origin}${window.location.pathname}?type=${tabs[id].label}`);
                }}
            >
                {
                    tabs.map((tab, index) => 
                        <Nav.Item key={index} menuVariant="primary">
                            <Nav.Link eventKey={index}>{tab.label}</Nav.Link>
                        </Nav.Item>
                    )
                }
            </Nav>
            <div className="py-3 pr-3">
                {Panel}
            </div>
        </div>
    )
}

export default Pills