import React, { useContext, useRef, useEffect, forwardRef } from 'react';
import { Form, Table as BTable, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import {
  useAsyncDebounce,
  useTable,
  useRowSelect,
  useSortBy,
  useFilters,
  useGlobalFilter,
} from 'react-table';
import { ArrowDown, ArrowUp, Check, X } from 'react-feather';
import { SelectOptions } from '../../../../mixit/src/app/common/FormFields';
import {
  QuantityControl,
  TextControl,
} from '../../../../mixit/src/app/common/FormControls';
import { UnitSystemContext, Utils } from '@tri/ui-shared';
const IndeterminateCheckbox = forwardRef((props, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  const { indeterminate, id, disableRowSelection, ...rest } = props;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <div className="custom-control custom-checkbox">
      <input
        type="checkbox"
        className="custom-control-input"
        ref={resolvedRef}
        id={`ccb-${id}`}
        disabled={disableRowSelection}
        {...rest}
        onClick={e => {
          e.stopPropagation()
        }}
      ></input>
      <label
        className="custom-control-label"
        ref={resolvedRef}
        {...rest}
        htmlFor={`ccb-${id}`}
      ></label>
    </div>
  );
});

const DownIcon = () => <ArrowDown size={12}></ArrowDown>;
const UpIcon = () => <ArrowUp size={12}></ArrowUp>;

export const FormattedDateCell = ({ value }) => {
  return Utils.formatDate(value);
};

export const BooleanCell = ({ value }) => {
  return value === true ? <Check size={16} /> : <X size={16} />;
};

export const QuantityCell = ({ row, column, tableName }) => {
  const { qty, id, disabled } = column;
  const { index } = row;
  const name = `${tableName}.${index}.${id}`;
  return (
    <QuantityControl
      name={name}
      qty={qty}
      showUnit={false}
      disabled={disabled}
    />
  );
};

export const TextCell = ({ row, column, value, tableName }) => {
  const { setFieldValue } = useFormikContext();
  const { id, disabled } = column;
  const { index } = row;
  const name = `${tableName}.${index}.${id}`;
  return (
    <TextControl
      initialValue={value}
      disabled={disabled}
      onBlur={(e) => {
        setFieldValue(name, e.target.value);
      }}
    />
  );
};

export const SelectCell = ({ row, column, value, tableName }) => {
  const { setFieldValue } = useFormikContext();
  const { index } = row;
  const { id, options, disabled } = column;
  const name = `${tableName}.${index}.${id}`;
  return (
    <Form.Control
      as="select"
      value={value}
      onChange={(e) => {
        setFieldValue(name, e.target.value);
      }}
      disabled={disabled}
    >
      <SelectOptions options={options} />
    </Form.Control>
  );
};

// Create a default prop getter
const defaultPropGetter = () => ({});

//Global Search
export const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  searchPlaceholderString,
}) => {
  // const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <center>
      <input
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={searchPlaceholderString}
        className="form-control "
        style={{
          width: '40vw',
          height: '29px',
          position: 'relative',
          zIndex: '2',
        }}
      />
    </center>
  );
};

export const Table = ({
  name: tableName,
  columns,
  data,
  defaultColumn,
  initialState = {},
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  tableClass = {},
  selectionEnabled = false,
  onSelect = () => {},
  disableSortBy = true,
  showGlobalFilter = true,
  searchBoxPlaceholder,
  updateMyData, 
  showColumnTypeOnHeaderHover = false,
  ...rest
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state,
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      tableName,
      initialState,
      disableSortBy,
      // updateMyData isn't part of the API, but anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our renderer!
      updateMyData,
      ...rest,
    },

    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect,
    (hooks) => {
      if (!selectionEnabled) return hooks;
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps, rows, toggleRowSelected, isAllRowsSelected, selectedFlatRows }) => {
            //count disable row selection rows
            let totalDisableRows = 0;
            rows.forEach(row => {
              !row.original?.disableRowSelection && totalDisableRows++;
            });

            const overridenOnChange = (event) => {
              // Toggle all checkboxes to selected/unselected depending on whether the header checkbox is checked or not. 
              rows.forEach(row => {
                !row.original?.disableRowSelection && toggleRowSelected(row.id, event.currentTarget.checked);
              });
            };

            const checked = isAllRowsSelected || (selectedFlatRows.length > 0 && selectedFlatRows.length === totalDisableRows); // header checkbox is only checked if all rows are checked
            const indeterminate = !isAllRowsSelected && selectedFlatRows.length > 0; // if some but not all rows are checked, header checkbox is indeterminate
            const newProps = { id: tableName, onChange: overridenOnChange, checked: checked, indeterminate: indeterminate };

            return <div>
              <IndeterminateCheckbox {...newProps} />
            </div>
          },
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({row }) => {
            return <div>
              <IndeterminateCheckbox
                disableRowSelection={row.original?.disableRowSelection}
                id={`${tableName}-${row.id}`}
                {...row.getToggleRowSelectedProps()}
              />
            </div>
          },
        },
        ...columns,
      ]);
    }
  );
  
  useEffect(() => {
    onSelect(selectedFlatRows);
  }, [selectedFlatRows.length]);

  return <div className="">
    {/* Global Search input */}
    {showGlobalFilter && <div style={{marginBottom: '5vh'}}>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
        searchPlaceholderString={searchBoxPlaceholder}
      />
    </div>}
    <div className='table-responsive'>
      <BTable {...getTableProps()} className={tableClass} size="sm">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps([
                    getColumnProps(column),
                    getHeaderProps(column),
                    column.getSortByToggleProps(),
                    {
                      className: column.className,
                      style: column.style,
                    }
                  ])}
                >
                  {
                    showColumnTypeOnHeaderHover ? <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={ 
                        column?.type ? 
                          <Tooltip>
                            {
                              `${column?.type} ${column?.formula ? `: ${column.formula}`: ""}`
                            }
                          </Tooltip> : 
                          <Tooltip> NA </Tooltip> }
                    >
                      <div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                          <div>{column.render('Header')}</div>
                        </div>
                        {
                          column?.unit && <div>{`[${column.unit}]`}</div>
                        }
                      </div>
                    </OverlayTrigger> : <div>{column.render('Header')}</div>
                  }
              
                                
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.canSort && column.isSorted ? (
                      column.isSortedDesc ? (
                        <DownIcon />
                      ) : (
                        <UpIcon />
                      )
                    ) : (
                      ''
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      // Based on: https://react-table.tanstack.com/docs/examples/data-driven-classes-and-styles
                      // Return an array of prop objects and react-table will merge them appropriately
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        getCellProps(cell),
                      ])}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
    </div>
  </div>
};

export const TableHeader = ({ column: { qty, title } }) => {
  const { displayUnitSystemId, allSystems } = useContext(UnitSystemContext);
  const displayUnit = qty
    ? allSystems[displayUnitSystemId].units[qty]
    : undefined;
  const unitLabel = displayUnit ? `[${displayUnit}]` : '';
  return (
    <div className="d-flex flex-column align-items-center">
      <div>{title}</div>
      <div>{unitLabel}</div>
    </div>
  );
};
