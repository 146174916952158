import { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Toast({toastObj}) {

  useEffect(() => {
      const statusCode = toastObj.statusCode ?? 400
      
      if(toastObj?.dismissAll) {
        toast.dismiss()
      }
      else if(toastObj?.id) {
        let msgType = 'error', autoClose = false
        if(statusCode >= 200 && statusCode < 300) {
          msgType = "success"
          autoClose = 3000
        } 
        else if(statusCode >= 300 && statusCode < 400) {
          msgType = "warning"
        }

        if(toastObj?.dismiss) {
          toast.dismiss(toastObj?.id)
        }
        else if(toastObj?.removeDuplicate) {
          toast(toastObj.message, {toastId: toastObj?.id, type: msgType, isLoading: false, autoClose: autoClose, closeButton: true})
        }
        else {
          toast.update(toastObj?.id, { render: toastObj.message, type: msgType, isLoading: false, autoClose: autoClose, closeButton: true });
        }
      }
      else if(statusCode >= 200 && statusCode < 300) {
        toast.success(toastObj.message);
      } 
      else if(statusCode >= 300 && statusCode < 400) {
        toast.warning(toastObj.message, {autoClose: false});
      }
      else {
        toast.error(toastObj.message, {autoClose: false});
      }
  }, [toastObj, toastObj.promise])

  return <ToastContainer
    position="top-right"
    autoClose={3000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick={false}
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover={false}
  />
}