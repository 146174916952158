import { useQuery } from "react-query";
import { executeApiCall, useHeaderConfiguration } from "../../../utils";

//get list of all existing projects
const useProjectList = () => {
    const config = useHeaderConfiguration();
    return useQuery('projects', async () => {
        const responseData = await executeApiCall('get', '/api/v3/projects/', {}, {config}) 
        return responseData.data
    })
}

const useDeleteProjects = (selectedProjectIds) => {
    const config = useHeaderConfiguration({contentType: 'application/json'});
    let bodyData = {
        "project_lists": selectedProjectIds
    }
    return executeApiCall('delete', `api/v3/projects/`, bodyData, config)
}

const useCreateProject = (projectValues) => {
    const config = useHeaderConfiguration();
        
    //Creating form data for adding new project
    let formData = new FormData();
    formData.append('project_name', projectValues.projectName);
    formData.append('Mapped_Asset', projectValues.mappedAssets);
    formData.append('shared_with', JSON.stringify(projectValues.sharedUsers));
    formData.append('comments', projectValues.comment);
    projectValues.projectFile && formData.append('input_data_files', projectValues.projectFile);

    return executeApiCall('post', 'api/v3/projects/', formData, config)
}

const useUpdateProject = (projectValues, isOverwrite) => {
    const config = useHeaderConfiguration();
        
    //Creating form data for updating project
    let formData = new FormData();
    formData.append('project_name', projectValues.projectName);
    formData.append('Mapped_Asset', projectValues.mappedAssets);
    formData.append('shared_with', JSON.stringify(projectValues.sharedUsers));
    formData.append('comments', projectValues.comment);
    projectValues.projectFile && formData.append('input_data_files', projectValues.projectFile);
    formData.append('overwrite_data', isOverwrite);

    return executeApiCall('patch', `api/v3/projects/${projectValues.projectId}/`, formData, config)
}

export { useProjectList, useDeleteProjects, useCreateProject, useUpdateProject}