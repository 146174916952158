import Header from './header/Header';
import AssetExplorer from './asset-explorer/AssetExplorerPanel';
import HomePage from './pages/HomePage';
import SimDataExplorerPanel from './sim-data-explorer/SimDataExplorerPanel';
import UserProfile from './user/UserProfile';
import ChangePassword from './user/ChangePassword';
import Administration from './user/Administration';
import './AppPage.css';
import { BreadcrumbContext } from './context';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import BreadCrumb from './common/BreadCrumb';
import { removeRestBreadcrumbsOrAppendNew } from './utils';

const viewComponentMap = {
  home: HomePage,
  asset: AssetExplorer,
  simulationData: SimDataExplorerPanel,
  userProfile: UserProfile,
  changePassword: ChangePassword,
  administration: Administration,
};

const AppPage = ({ activeView = 'home', activeSubView, activeSubSubView, type }) => {
  const ViewComponent = viewComponentMap[activeView];
  const history = useHistory();

  let simsight_breadcrumbs = JSON.parse(localStorage.getItem('simsight_breadcrumbs')) ?? []
  const [breadCrumbItmes, setBreadCrumbItmes] = useState(simsight_breadcrumbs)
  const [isBreadcrumbsFromCache, setIsBreadcrumbsFromCache] = useState(simsight_breadcrumbs.length > 0)

  useEffect(() => {
    localStorage.setItem('simsight_breadcrumbs', JSON.stringify(breadCrumbItmes));
  }, [breadCrumbItmes]);

  //creating breadcrumbs
  useEffect(() => {
    if(!isBreadcrumbsFromCache) {
      if(activeView === 'home') {
        removeRestBreadcrumbsOrAppendNew("Home", [{ 
          title: "Home",
          redirectTo: '/app/home'
        }], setBreadCrumbItmes, '', history)
      }
    }
    else {
      let breadcrumbsToBeAppend = []
      breadCrumbItmes.forEach(breadcrumb => {
        if (breadcrumb?.redirectTo) {
            breadcrumb['onClick'] = () => {
            history.push(breadcrumb.redirectTo)
          }
        }
        breadcrumbsToBeAppend.push(breadcrumb)
      })
      setBreadCrumbItmes([...breadcrumbsToBeAppend])
      setIsBreadcrumbsFromCache(false)
    }
  }, [activeView])

  return (
    <BreadcrumbContext.Provider value={{isBreadcrumbsFromCache, setBreadCrumbItmes}}>
      <Header activeView={activeView} />
      {
        (history.location.pathname?.includes('/app/home') || history.location.pathname?.includes('/app/simulationData')) && 
        <BreadCrumb breadCrumbItmes={breadCrumbItmes} style={{ marginTop: '3.3rem' }}/>
      }
      <ViewComponent
        activeView={activeView}
        activeSubView={activeSubView}
        activeSubSubView={activeSubSubView}
        type={type}
      />
    </BreadcrumbContext.Provider>
  );
};

export default AppPage;
