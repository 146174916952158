import { Row, Col, Card, ListGroup, InputGroup } from 'react-bootstrap'
import { pillTabs } from '../../utils/constants';
import { useProjectGroupingDetailsData } from '../services/business-logic/grouping.businesslogic';

const GroupingPanel = ({projectId, simCase}) => {
  const { ungroupColumns, columnUnits, existingColumnGroups } = useProjectGroupingDetailsData(projectId, simCase)
  
  return (
    <Row sm={1}>
      {
        ungroupColumns.length > 0 && <Col md={3} className='d-flex'>
          <Card className='mt-3 me-3' style={{width: '380px'}} >
            <Card.Body>
              <Card.Title>Other Columns</Card.Title>
                <ListGroup variant="flush">
                  {
                    ungroupColumns.map((column, index) => <InputGroup className="mb-3">
                          <InputGroup.Text className='w-50 text-wrap' style={{overflowWrap: 'anywhere'}}>{column}</InputGroup.Text>
                          <td className='border p-1' style={{width: '30%'}}>{simCase?.values[column] || ''}</td>
                          <td className='border p-1' style={{width: '15%'}}>{(columnUnits && columnUnits[column]) ?? '-'}</td>
                        </InputGroup>
                      )
                  }
                </ListGroup>
            </Card.Body>
          </Card>
          <div class="d-flex mt-3">
            <div class="vr"></div>
          </div>            
        </Col>
      }
      <Col md={ungroupColumns.length > 0 ? 9 : 12}>
        <Row sm={1} md={ungroupColumns.length > 0 ? 3 : 4}>
        {
          existingColumnGroups.length > 0 ? 
            existingColumnGroups?.map((group, index) => (
            <Col id={index}>
              <Card className='mt-3'>
                <Card.Body>
                  <Card.Title>{group.group_name}</Card.Title>
                    <ListGroup variant="flush">
                      {
                        group.column_names_list.split(',').map((column, index) => <InputGroup className="mb-3">
                              <InputGroup.Text className='w-50 text-wrap' style={{overflowWrap: 'anywhere'}}>{column}</InputGroup.Text>
                              <td className='border p-1' style={{width: '30%'}}>{simCase?.values[column] || ''}</td>
                              <td className='border p-1' style={{width: '15%'}}>{(columnUnits && columnUnits[column]) ?? '-'}</td>
                            </InputGroup>
                          )
                      }
                    </ListGroup>
                </Card.Body>
              </Card>
            </Col>
          )) : <Col><h5> Groups not found</h5></Col>
        }
        </Row>
      </Col>
    </Row>
  )
}
  
const Grouping = (projectId, simCase) =>  {
  return {
    label: pillTabs.GroupingDetails,
    Panel: <GroupingPanel projectId={projectId} simCase={simCase}/>
  }
}
  
export { Grouping }   