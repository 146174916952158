const compareDataType = {
    "Geometry": "Geometry",
    "Images": "Images",
    "Documents": "Documents",
    "Animations": "Animations",
    "Groups": "Groups"
}

const compareActions = [
    {
        label: compareDataType.Geometry,
        value: compareDataType.Geometry
    },
    {
        label: compareDataType.Groups,
        value: compareDataType.Groups
    },
    {
        label: compareDataType.Images,
        value: compareDataType.Images
    },
    {
        label: compareDataType.Animations,
        value: compareDataType.Animations
    },
    {
        label: compareDataType.Documents,
        value: compareDataType.Documents
    }
]

const dateFormats = {
    "d mon year": 'd MMM yyyy',
    "dd-mm-yy": 'dd-MM-yy',
    "mm-dd-yy": 'MM-dd-yy',
    "dd/mm/yy": 'dd/MM/yy',
    "mm/dd/yy": 'MM/dd/yy',
    "dd-mm-YYYY": 'dd-MM-yyyy',
    "mm-dd-YYYY": 'MM-dd-yyyy',
    "dd/mm/YYYY": 'dd/MM/yyyy',
    "mm/dd/YYYY": 'MM/dd/yyyy'
}

const dateFormatOptions = Object.keys(dateFormats).map(key => ({
    label: key,
    value: key
}));

const navbarHeight = '50.58px'

const parameterTableType = {
    "asset_param": "asset_param",
    "geometry_param": "geometry_param"
}

const pillTabs = {
    "MyProjects": "My Projects",
    "SharedProjects": "Shared Projects",
    "SimulationDetails": "Simulation Details",
    "AssetDetails": "Asset Details",
    "Compare": "Compare",
    "Geometry": "Geometry",
    "GroupingDetails": "Groups",
    "Images": "Images",
    "Animations": "Animations",
    "Documents": "Documents",
}

const operationTypes = {
    "edit": "Edit",
    "delete": "Delete"
}

const functionExamplesDictionary = {
    "pow()": "eg. pow(2,3) returns 8",
    "root": "eg. root 4 returns 2",
    "Mod": "eg. 3 Mod 2 results 1",
    "log()": "eg. log 1000 returns 3"
}

const AddNewSimDataActionMode = {
    "Single": "Single",
    "Multiple": "Multiple"
}

const AddNewSimDataActionOptions = [
    { name: 'Add single simulation case', value: AddNewSimDataActionMode.Single },
    { name: 'Add multiple simulation cases', value: AddNewSimDataActionMode.Multiple }
]

const parameterTableKey = (Id) => `parameterTable_${Id}`
const projectAssetDetailsKey = (assetId) => `projectAssetDetails_${assetId}`

export { compareDataType, compareActions, parameterTableType, pillTabs, navbarHeight, dateFormats, dateFormatOptions, operationTypes, functionExamplesDictionary, AddNewSimDataActionMode, AddNewSimDataActionOptions}
export { parameterTableKey, projectAssetDetailsKey }