import AuthLayout from './AuthLayout';
import { Card } from 'react-bootstrap';
const VerifyAccountPage = () => {
  return (
    <AuthLayout>
      <Card className="p-3">
        <div className="text-dark">
          <h3 className="text-center">Thanks for registering!</h3>
          <p className="lead">
            An email with verification link has been sent to your email address.
            Please click the link to verify your account.
          </p>
        </div>
      </Card>
    </AuthLayout>
  );
};

export default VerifyAccountPage;
