import { useEffect, useState, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { Card } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { HorizontalFormField } from '../common/FormFields';
import {
  QuantityControl,
  SelectControl,
  CheckboxControl,
  Unit,
} from '../common/FormControls';
import {
  Table,
  TableHeader,
  TextCell,
  SelectCell,
  QuantityCell,
} from '../common/Table';

const fluidTypeOptions = [
  { label: 'Newtonian', value: 'Newtonian' },
  { label: 'Power Law', value: 'powerLaw' },
  { label: 'Cross Power Law', value: 'CrossPowerLaw' },
  { label: 'Bird Carreau', value: 'BirdCarreau' },
  { label: 'Herschel Bulkley', value: 'HerschelBulkley' },
];

const typeOptions = ['Diptube', 'Inlet', 'Outlet'];

const directionOptions = ['Clockwise', 'Anti-Clockwise'];

const volumeRectangular = (tankWidth, tankLength, liquidlevel) => {
  return tankWidth * tankLength * liquidlevel;
};

const calVolumeForTorisphericalBottom = (d, hl, k, f) => {
  // d = tankDia;
  // hl = liquidLevel;
  // a = botDepth;
  let vol_temp = 0;
  const fact = (1 - 2 * k) / (2 * (f - k));
  const alpha = Math.asin(fact); //angle in radians
  const a1 = f * d * (1 - Math.cos(alpha)); //tori_a1
  const a2 = k * d * Math.cos(alpha); //tori_a2
  const D1 = 2 * f * d * Math.sin(alpha);
  const s = Math.pow(k * d * Math.sin(alpha), 2);
  const t = 2 * a2;
  const u = hl - f * d * (1 - Math.cos(alpha));
  if (hl <= a1)
    vol_temp =
      ((Math.PI * Math.pow(hl, 2)) / 4) *
      (2 * a1 + Math.pow(D1, 2) / (2 * a1) - (4 * hl) / 3);
  if ((hl > a1) & (hl <= a1 + a2)) {
    const A1 =
      (Math.PI / 4) * ((2 * Math.pow(a1, 3)) / 3 + (a1 * Math.pow(D1, 2)) / 2);
    const A2 = Math.PI * u * (Math.pow(d / 2 - k * d, 2) + s);
    const A3 =
      (Math.PI * t * Math.pow(u, 2)) / 2 - (Math.PI * Math.pow(u, 3)) / 3;
    const A4 = Math.PI * d * (1 - 2 * k);
    const A5 = ((2 * u - t) / 4) * Math.sqrt(s + t * u - Math.pow(u, 2));
    const A6 = (t * Math.sqrt(s)) / 4;
    const A7 =
      (Math.pow(k * d, 2) / 2) * (Math.acos((t - 2 * u) / (2 * k * d)) - alpha);
    vol_temp = A1 + A2 + A3 + A4 * (A5 + A6 + A7);
  }
  if (hl > a1 + a2) {
    const B1 =
      (Math.PI / 4) * ((2 * Math.pow(a1, 3)) / 3 + (a1 * Math.pow(D1, 2)) / 2);
    const B2 = ((Math.PI * t) / 2) * (Math.pow(d / 2 - k * d, 2) + s);
    const B3 = (Math.PI * Math.pow(t, 3)) / 12;
    const B4 = Math.PI * d * (1 - 2 * k);
    const B5 = (t * Math.sqrt(s)) / 4;
    const B6 = (Math.pow(k * d, 2) / 2) * Math.asin(Math.cos(alpha));
    const B7 = ((Math.PI * Math.pow(d, 2)) / 4) * (hl - (a1 + a2));
    vol_temp = B1 + B2 + B3 + B4 * (B5 + B6) + B7;
  }
  return vol_temp;
};

const volumeCylindrical = (tankDia, liquidLevel, botStyle, botDepth) => {
  let d = tankDia;
  let hl = liquidLevel;
  let a = botDepth;
  let vol = 0;
  const f = 1; //Dish Radius Factor

  if (botStyle === 'Conical') {
    if (hl < a)
      vol =
        (Math.PI * Math.pow(d, 2) * Math.pow(hl, 3)) / (12 * Math.pow(a, 2));
    if (hl >= a) vol = ((Math.PI * Math.pow(d, 2)) / 12) * (3 * hl - 2 * a);
  } else if (botStyle === '2:1Elliptical') {
    if (hl < a) vol = (Math.PI / 4) * Math.pow((d * hl) / a, 2) * (a - hl / 3);
    if (hl >= a) vol = ((Math.PI * Math.pow(d, 2)) / 4) * (hl - a / 3);
  } else if (botStyle === 'Flat') {
    vol = (Math.PI / 4) * Math.pow(d, 2) * hl;
  } else if (botStyle === 'Hemispherical') {
    if (hl < a)
      vol =
        ((Math.PI * Math.pow(hl, 2)) / 4) *
        (2 * a + Math.pow(d, 2) / (2 * a) - (4 * hl) / 3);
    if (hl >= a)
      vol =
        (Math.PI / 4) *
        ((2 * Math.pow(a, 3)) / 3 -
          (a * Math.pow(d, 2)) / 2 +
          hl * Math.pow(d, 2));
  } else if (botStyle === '10%Torispherical') {
    const k = 0.1; //Knuckle Radius Factor for 10% torispherical
    vol = calVolumeForTorisphericalBottom(d, hl, k, f);
  } else {
    const k = 0.06; //Knuckle Radius Factor for 6% torispherical
    vol = calVolumeForTorisphericalBottom(d, hl, k, f);
  }

  return vol;
};

const levelCylindrical = (tankDia, botStyle, botDepth, operatingVolume) => {
  const d = tankDia;
  const a = botDepth;
  let p, q;
  let h = 0,
    v = operatingVolume;

  if (botStyle == 'Conical') {
    const v_cone = (Math.PI / 12.0) * Math.pow(d, 2.0) * a;
    if (v < v_cone) {
      p = 12.0 * v * Math.pow(a, 2.0);
      q = Math.PI * Math.pow(d, 2.0);
      h = Math.pow(p / q, 0.333);
    } else if (v >= v_cone) {
      p = v + (Math.PI * Math.pow(d, 2.0) * a) / 6.0;
      q = (Math.PI * Math.pow(d, 2)) / 4.0;
      h = p / q;
    }
  } else if (botStyle == '2:1Elliptical') {
    const v_ellip = (Math.PI / 6.0) * Math.pow(d, 2.0) * a;
    if (v < v_ellip) h = heightElliptical(v, a, d);
    else if (v >= v_ellip) {
      p = (4.0 * v) / (Math.PI * Math.pow(d, 2));
      q = a / 3.0;
      h = p + q;
    }
  } else if (botStyle == 'Flat') h = (4.0 * v) / (Math.PI * Math.pow(d, 2));
  else if (botStyle == 'Hemispherical') {
    const p1 = (Math.PI * a * Math.pow(d, 2)) / 8.0;
    const p2 = (Math.PI * Math.pow(a, 3)) / 6.0;
    const v_spr = p1 + p2;
    if (v < v_spr) h = heightSpherical(v, a, d);
    else if (v >= v_spr) {
      const p11 = (4.0 * v) / Math.PI;
      const p21 = (2.0 * Math.pow(a, 3)) / 3.0;
      const p31 = (a * Math.pow(d, 2)) / 2.0;
      h = (p11 - (p21 - p31)) / Math.pow(d, 2);
    }
  } else if (botStyle == '10%Torispherical') {
    const head_volume = 0.09896 * Math.pow(d, 3);
    const knuckle_radius_fact = 0.1;
    const dish_radius_fact = 1.0;
    if (v > head_volume) {
      const straight_side_vol = v - head_volume;
      const straight_side_liqlevel =
        (4 * straight_side_vol) / (Math.PI * Math.pow(d, 2));
      h = a + straight_side_liqlevel;
    } else
      h = liquidLevelForSphericalSectionOfToriHead(
        v,
        knuckle_radius_fact,
        dish_radius_fact,
        d
      );
  }
  if (botStyle == '6%Torispherical') {
    const head_volume = 0.081 * Math.pow(d, 3);
    const knuckle_radius_fact = 0.06;
    const dish_radius_fact = 1.0;
    if (v > head_volume) {
      const straight_side_vol = v - head_volume;
      const straight_side_liqlevel =
        (4 * straight_side_vol) / (Math.PI * Math.pow(d, 2));
      h = a + straight_side_liqlevel;
    } else
      h = liquidLevelForSphericalSectionOfToriHead(
        v,
        knuckle_radius_fact,
        dish_radius_fact,
        d
      );
  }
  return h;
};

const heightElliptical = (v, a, d) => {
  const y = v;
  const dep = a;
  let x = a;
  const tol = 1e-8;
  let dx = 0;
  let xnew;
  let count = 0;
  while (true) {
    const f = functionElliptical(x, y, dep, d);
    const fd = functionDerivativeElliptical(x, dep);
    dx = f / fd;
    xnew = x - dx;
    const error = ((xnew - x) * 100) / xnew;
    if (error < tol) return xnew;
    else x = xnew;
    count = count + 1;
  }
};

const functionElliptical = (z, y, dep, d) => {
  const r = (12.0 * Math.pow(dep, 2)) / (Math.PI * Math.pow(d, 2));
  const fc = Math.pow(z, 3) - 3.0 * dep * Math.pow(z, 2) + y * r;
  return fc;
};

const functionDerivativeElliptical = (z, dep) => {
  const fcd = 3.0 * Math.pow(z, 2) - 6.0 * z * dep;
  return fcd;
};

const heightSpherical = (y, dep, d) => {
  let x = dep;
  const tol = 1e-8;
  let count = 0;
  let dx;
  let xnew;
  while (true) {
    const f = functionSpherical(x, y, dep, d);
    const fd = functionDerivativeSpherical(x, dep, d);
    dx = f / fd;
    xnew = x - dx;
    const error = ((xnew - x) * 100) / xnew;
    if (error < tol) return xnew;
    else x = xnew;
    count = count + 1;
  }
};

const functionSpherical = (z, y, dep, d) => {
  const r = (4.0 * Math.pow(dep, 2) + Math.pow(d, 2)) / (2.0 * dep);
  const s = (4.0 * y) / Math.PI;
  const fc = 1.3333 * Math.pow(z, 3) - r * Math.pow(z, 2) + s;
  return fc;
};

const functionDerivativeSpherical = (z, dep, d) => {
  const r = (4.0 * Math.pow(dep, 2) + Math.pow(d, 2)) / (2.0 * dep);
  const fcd = 4.0 * Math.pow(z, 2) - 2.0 * z * r;
  return fcd;
};

const liquidLevelForSphericalSectionOfToriHead = (y, k, f, d) => {
  const fact = (1 - 2.0 * k) / (2.0 * (f - k));
  const alpha = Math.asin(fact); //#angle in radians
  const a1 = f * d * (1.0 - Math.cos(alpha));
  const D1 = 2.0 * f * d * Math.sin(alpha);
  let x = a1;
  const tol = 1e-8;
  let count = 0;
  let dx;
  let fc = functionSphericalSectionOfTori(x, y, a1, D1);
  let fd = functionDerivativeSphericalSectionOfTori(x, a1, D1);
  while (true) {
    dx = fc / fd;
    if (Math.abs(dx) < tol * (1 + Math.abs(x))) return x - dx;
    x = x - dx;
    fc = functionSphericalSectionOfTori(x, y, a1, D1);
    fd = functionDerivativeSphericalSectionOfTori(x, a1, D1);
    count = count + 1;
  }
};

const functionSphericalSectionOfTori = (z, y, a1, D1) => {
  const r = (4.0 * Math.pow(a1, 2) + Math.pow(D1, 2)) / (2.0 * a1);
  const s = (4.0 * y) / Math.PI;
  const fc = 1.3333 * Math.pow(z, 3) - r * Math.pow(z, 2) + s;
  return fc;
};

const functionDerivativeSphericalSectionOfTori = (z, a1, D1) => {
  const r = (4.0 * Math.pow(a1, 2) + Math.pow(D1, 2)) / (2.0 * a1);
  const fcd = 4.0 * Math.pow(z, 2) - 2.0 * z * r;
  return fcd;
};

const levelRectangular = (width, length, vol) => {
  return vol / (width * length);
};

const InletOutletTypeCell = (props) => {
  const { setFieldValue } = useFormikContext();
  const {
    row: { index },
    tableName,
  } = props;
  return (
    <SelectCell
      {...props}
      onChange={(newValue) => {
        const flowRate = `${tableName}.${index}.flowRate`;
        if (newValue !== 'Inlet') setFieldValue(flowRate, 0);
      }}
    />
  );
};

const FlowRateCell = (props) => {
  const { row } = props;
  return <QuantityCell {...props} disabled={row.original.type !== 'Inlet'} />;
};

const OperatingConditionsPanel = ({
  data,
  disabled,
  setFieldValue,
  onGeometryChange = () => {},
}) => {
  const queryClient = useQueryClient();
  const userImpellers = queryClient.getQueryData('user-impellers') || [];
  const [levelOrLiquidUpdated, setLevelOrLiquidUpdated] = useState();
  const geometryData = data.config.geometry;
  const operatingConditions = data.process.operatingConditions;

  const impellers = data.config.geometry.impellers || [];
  const { sameSpeedEnabled, operatingSpeed, impellerSpeeds } = data.process;
  const [impellerSpeedData, setImpellerSpeedData] = useState(); //Added one extra state to render the value of impeller type

  const dipTubeData = data.config.internals.dipTubes || [];
  const noOfDipTubes = dipTubeData.length;
  const {inletOutletFlowRates = [], inletOutletConditions} = data.process

  const fluidType = [data.process.processFluid.type];

  useEffect(() => {
    if (noOfDipTubes > inletOutletFlowRates.length) {
      for (let i = inletOutletFlowRates.length + 1; i <= noOfDipTubes; i++) {
        const inletOutletObj = {
          id: inletOutletConditions[i-1].id,
          name: inletOutletConditions[i-1].name,
          type: inletOutletConditions[i-1].type,
          flowRate: inletOutletConditions[i-1].flowRate,
        };
        inletOutletFlowRates.push(inletOutletObj);
      }
      setFieldValue('process.inletOutletFlowRates', [...inletOutletFlowRates]);
    } else if (noOfDipTubes < inletOutletFlowRates.length) {
      setFieldValue(
        'process.inletOutletFlowRates',
        inletOutletFlowRates.slice(0, noOfDipTubes)
      );
    }
  }, [noOfDipTubes]);

  useEffect(() => {
    const noOfImpellers = impellers.length;
    if (noOfImpellers > impellerSpeeds.length) {
      for (let i = impellerSpeeds.length + 1; i <= noOfImpellers; i++) {
        impellerSpeeds.push({
          id: i,
          speed: operatingSpeed,
          direction: 'Clockwise',
        });
      }
    } else if (noOfImpellers <= impellerSpeeds.length) {
      impellerSpeeds.splice(noOfImpellers);
    }
    impellers.forEach((i, index) => {
      let impellerType = impellers[index].type; // type-name for built-in impellers and unique name for user-defined impellers
      // MOW-137: For user-defined impeller, show type-name in dropdown instead of unique name
      const userImpeller = userImpellers.find(
        (i) => i.fileName.split('.')[0] === impellerType
      );
      if (userImpeller)
        impellerType = `${userImpeller.type}-${userImpeller.name}`;
      impellerSpeeds[index].type = impellerType;
      setImpellerSpeedData((impellerSpeeds) => ({
        ...impellerSpeeds,
        type: impellerType,
      }));
    });
  }, [impellers]);

  useEffect(() => {
    if (levelOrLiquidUpdated === 'level') {
      const tankType = geometryData.tank.type;
      const tankDia = geometryData.tank.diameter;
      const tankLength = geometryData.tank.length;
      const tankWidth = geometryData.tank.width;
      const botDepth = geometryData.tank.bottomDepth;
      const botStyle = geometryData.tank.bottomStyle;
      const liquidLevel = operatingConditions.liquidLevel;
      let opVolume = 0;
      if (tankType === 'Cylindrical')
        opVolume = volumeCylindrical(tankDia, liquidLevel, botStyle, botDepth);
      if (tankType == 'Rectangular')
        opVolume = volumeRectangular(tankWidth, tankLength, liquidLevel);
      setFieldValue('process.operatingConditions.operatingVolume', opVolume);
    }
  }, [
    geometryData.tank.type,
    geometryData.tank.diameter,
    geometryData.tank.length,
    geometryData.tank.width,
    geometryData.tank.bottomStyle,
    geometryData.tank.bottomDepth,
    operatingConditions.liquidLevel,
  ]);

  useEffect(() => {
    if (levelOrLiquidUpdated === 'volume') {
      const tankType = geometryData.tank.type;
      const tankDia = geometryData.tank.diameter;
      const tankLength = geometryData.tank.length;
      const tankWidth = geometryData.tank.width;
      const botDepth = geometryData.tank.bottomDepth;
      const botStyle = geometryData.tank.bottomStyle;
      const operatingVolume = operatingConditions.operatingVolume;
      let level;
      if (tankType === 'Cylindrical')
        level = levelCylindrical(tankDia, botStyle, botDepth, operatingVolume);
      if (tankType == 'Rectangular')
        level = levelRectangular(tankWidth, tankLength, operatingVolume);
      setFieldValue('process.operatingConditions.liquidLevel', level);
    }
  }, [
    geometryData.tank.type,
    geometryData.tank.diameter,
    geometryData.tank.length,
    geometryData.tank.width,
    geometryData.tank.bottomStyle,
    geometryData.tank.bottomDepth,
    operatingConditions.operatingVolume,
  ]);

  useEffect(() => {
    if (sameSpeedEnabled) {
      impellerSpeeds.forEach((i) => {
        i.speed = operatingSpeed;
      });
      setFieldValue('process.impellerSpeeds', [...impellerSpeeds]);
    }
  }, [sameSpeedEnabled, operatingSpeed]);

  useEffect(() => {
    onGeometryChange(data);
  }, [
    data,
    operatingConditions.liquidLevel,
    operatingConditions.operatingVolume,
  ]);

  const inletOutletTableColumns = useMemo(
    () => [
      {
        title: 'Sr. No.',
        accessor: 'id',
        dataType: 'number',
        disabled: true,
        style: {
          width: '15%',
        },
      },
      {
        title: 'Name',
        accessor: 'name',
        Cell: TextCell,
        style: {
          width: '30%',
        },
      },
      {
        title: 'Type',
        accessor: 'type',
        Cell: InletOutletTypeCell,
        options: typeOptions,
        style: {
          width: '30%',
        },
      },
      {
        title: 'Flow Rate',
        accessor: 'flowRate',
        qty: 'volFlowRate',
        dataType: 'number',
        Cell: FlowRateCell,
        style: {
          width: '25%',
        },
      },
    ],
    []
  );

  const impellerSpeedsTableDefaultColumn = useMemo(() => {
    return {
      Header: TableHeader,
      Cell: QuantityCell,
      disabled,
    };
  }, [disabled]);

  const impellerSpeedsTableColumns = useMemo(
    () => [
      {
        title: 'Sr. No.',
        accessor: 'id',
        dataType: 'number',
        disabled: true,
        style: {
          width: '10%',
        },
      },
      {
        title: 'Type',
        accessor: 'type',
        Cell: TextCell,
        style: {
          width: '35%',
        },
      },
      {
        title: 'Speed',
        accessor: 'speed',
        qty: 'angularSpeed',
        dataType: 'number',
        disabled: true,
        style: {
          width: '20%',
        },
      },
      {
        title: 'Direction',
        accessor: 'direction',
        Cell: SelectCell,
        options: directionOptions,
        style: {
          width: '35%',
        },
      },
    ],
    [impellerSpeedData]
  );

  const processFluid = data.process.processFluid;

  const layoutProps = { w1: 5, w2: 5, rowClass: 'mb-1' };
  return (
    <div>
      <div className="row">
        <div className="col-sm-6">
          <Card>
            <Card.Body>
              <Card.Title className="h6">Operating Conditions</Card.Title>
              <HorizontalFormField label="Temperature" {...layoutProps}>
                <QuantityControl
                  name="process.operatingConditions.temperature"
                  qty="temperature"
                  disabled={disabled}
                />
                <Unit qty="temperature" />
              </HorizontalFormField>

              <HorizontalFormField label="Gauge Pressure" {...layoutProps}>
                <QuantityControl
                  name="process.operatingConditions.gaugePressure"
                  qty="pressure"
                  disabled={disabled}
                />
                <Unit qty="pressure" />
              </HorizontalFormField>

              <HorizontalFormField label="Liquid Level" {...layoutProps}>
                <QuantityControl
                  name="process.operatingConditions.liquidLevel"
                  qty="length"
                  onBlur={(e) => {
                    setLevelOrLiquidUpdated('level');
                  }}
                  disabled={disabled}
                />
                <Unit qty="length" />
              </HorizontalFormField>

              <HorizontalFormField label="Op. Volume" {...layoutProps}>
                <QuantityControl
                  name="process.operatingConditions.operatingVolume"
                  value={Number(operatingConditions.operatingVolume).toExponential(3)}
                  qty="volume"
                  onBlur={(e) => {
                    setLevelOrLiquidUpdated('volume');
                  }}
                  disabled={disabled}
                />
                <Unit qty="volume" />
              </HorizontalFormField>
            </Card.Body>
          </Card>
        </div>
        <div className="col-sm-6">
          {dipTubeData.length > 0 && (
            <Card>
              <Card.Body>
                <Card.Title className="h6">Inlet/Outlet/FlowRates</Card.Title>
                <Table
                  name="process.inletOutletFlowRates"
                  columns={inletOutletTableColumns}
                  data={inletOutletFlowRates}
                  defaultColumn={{
                    Header: TableHeader,
                    Cell: QuantityCell,
                    disabled: disabled,
                  }}
                  tableClass="table-borderless"
                />
              </Card.Body>
            </Card>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-sm-6">
          <Card className="mt-3">
            <Card.Body>
              <Card.Title className="h6">Fluid Properties</Card.Title>
              <HorizontalFormField label="Density" {...layoutProps}>
                <QuantityControl
                  name="process.processFluid.density"
                  qty="density"
                  disabled={disabled}
                />
                <Unit qty="density" />
              </HorizontalFormField>

              <HorizontalFormField label="Fluid Type" {...layoutProps}>
                <SelectControl
                  name="process.processFluid.type"
                  options={fluidType}
                  disabled={disabled}
                  onChange={(e) => {
                    if (
                      e.target.value === 'Newtonian' ||
                      e.target.value === 'powerLaw' ||
                      e.target.value === 'CrossPowerLaw' ||
                      e.target.value === 'BirdCarreau'
                    ) {
                      setFieldValue('process.processFluid.yieldStress', 0);
                    }
                  }}
                />
              </HorizontalFormField>
              {processFluid.type === 'Newtonian' && (
                <HorizontalFormField label="Viscosity" {...layoutProps}>
                  <QuantityControl
                    name="process.processFluid.viscosity"
                    qty="viscosity"
                    disabled={disabled}
                  />
                  <Unit qty="viscosity" />
                </HorizontalFormField>
              )}
              {processFluid.type !== 'Newtonian' && (
                <HorizontalFormField w1={5} w2={7} label="K" {...layoutProps}>
                  <QuantityControl
                    // Here for simsight we are refering value of viscosity for parameter 'k' because in mixit XML value of 'k' is getting dumped in viscosity.
                    // FIXME: Add value of 'k' seperately in mixit XML dump.
                    name="process.processFluid.viscosity"
                    disabled={disabled}
                  />
                </HorizontalFormField>
              )}
              <HorizontalFormField label="Flow Index" {...layoutProps}>
                <QuantityControl
                  name="process.processFluid.flowIndex"
                  disabled={disabled || processFluid.type === 'Newtonian'}
                />
              </HorizontalFormField>

              <HorizontalFormField label="Yield Stress" {...layoutProps}>
                <QuantityControl
                  name="process.processFluid.yieldStress"
                  qty="pressure"
                  disabled={
                    disabled ||
                    processFluid.type === 'Newtonian' ||
                    processFluid.type === 'powerLaw' ||
                    processFluid.type === 'CrossPowerLaw' ||
                    processFluid.type === 'BirdCarreau'
                  }
                />
                <Unit qty="pressure" />
              </HorizontalFormField>

              {processFluid.type === 'Newtonian' && (
                <HorizontalFormField label="Mu0" {...layoutProps}>
                  <QuantityControl
                    name="process.processFluid.muZero"
                    qty="viscosity"
                    disabled={disabled || processFluid.type === 'Newtonian'}
                  />
                  <Unit qty="viscosity" />
                </HorizontalFormField>
              )}

              {processFluid.type === 'Newtonian' && (
                <HorizontalFormField label="Mu-Inf" {...layoutProps}>
                  <QuantityControl
                    name="process.processFluid.muInfinity"
                    qty="viscosity"
                    disabled={disabled || processFluid.type === 'Newtonian'}
                  />
                  <Unit qty="viscosity" />
                </HorizontalFormField>
              )}

              {processFluid.type !== 'Newtonian' && (
                <HorizontalFormField label="Min. Viscosity" {...layoutProps}>
                  <QuantityControl
                    name="process.processFluid.muZero"
                    qty="viscosity"
                    disabled={disabled || processFluid.type === 'Newtonian'}
                  />
                  <Unit qty="viscosity" />
                </HorizontalFormField>
              )}

              {processFluid.type !== 'Newtonian' && (
                <HorizontalFormField label="Max. Viscosity" {...layoutProps}>
                  <QuantityControl
                    name="process.processFluid.muInfinity"
                    qty="viscosity"
                    disabled={disabled || processFluid.type === 'Newtonian'}
                  />
                  <Unit qty="viscosity" />
                </HorizontalFormField>
              )}

              <HorizontalFormField label="Surface Tension" {...layoutProps}>
                <QuantityControl
                  name="process.processFluid.surfaceTension"
                  qty="surfaceTension"
                  disabled={disabled}
                />
                <Unit qty="surfaceTension" />
              </HorizontalFormField>
            </Card.Body>
          </Card>
        </div>

        <div className="col-sm-6">
          <Card className="mt-3">
            <Card.Body>
              <Card.Title className="h6">Impeller Speeds</Card.Title>
              {/* <HorizontalFormField
                w1={5}
                w2={7}
                label="Operating Speed"
                rowClass="mb-1"
              >
                <QuantityControl
                  name="process.operatingSpeed"
                  qty="angularSpeed"
                  disabled={disabled || !sameSpeedEnabled}
                />
              </HorizontalFormField>

              <CheckboxControl
                name="process.sameSpeedEnabled"
                label="Same speed for all impellers"
                disabled={disabled}
              /> */}

              <Table
                name="process.impellerSpeeds"
                columns={impellerSpeedsTableColumns}
                data={impellerSpeeds}
                defaultColumn={impellerSpeedsTableDefaultColumn}
                tableClass="table-borderless"
              />
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

const OperatingConditions = {
  id: 'operating-conditions',
  label: 'Operating Conditions',
  container: 'process',
  Renderer: OperatingConditionsPanel,
  tabEnabled: (activeNodeType) => activeNodeType === 'process',
};

export default OperatingConditions;
