import { Card, Col, Form, Image, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Edit2 } from 'react-feather';
import { GetSimcaseAnimation } from '../sim-data-explorer/services/business-logic/animations.businesslogic';
import { useContext } from 'react';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Dialog, UserContext } from '@tri/ui-shared';
import { GetSimcaseImage } from '../sim-data-explorer/services/business-logic/images.businesslogic';
import loadingGif from '../../assets/loading.gif'

const ImageModalView = ({imageModal, setImageModal}) => {
    return <Dialog 
        show={imageModal.show} 
        title={imageModal.title} 
        onHide={() => {
            setImageModal((modalData) => ({...modalData, "show": false}))
        }} 
        showOk={false}
        scrollable={true}
        size='lg'
    >
        <center>
            <Image src={imageModal.url} fluid></Image>
        </center>
    </Dialog>
}

const ImageCardView = ({simCaseId, image, setImageModal, handleDataChange, handleIsDisabled, SaveImageData, type = "image"}) => {
    const { user } = useContext(UserContext);
    const url = type === "image" ? GetSimcaseImage(simCaseId, image.id) : GetSimcaseAnimation(simCaseId, image.id);

    return <Card className='mt-3'>
        <Card.Body className="d-flex flex-column p-1">
            {
                url ? <Image 
                    src={url}
                    thumbnail
                    style={{
                        height: '250px',
                        border: 'none',
                        textAlign: 'center',
                        cursor: 'pointer',
                    }}
                    title="Click to open expanded view"
                    onClick={() => {
                        setImageModal({"show": true, "title": image.filename, "url": url})
                    }}
                /> : <center className="mt-5 mb-5">
                    <img alt="Loading..." src={loadingGif} height="50px"/>
                </center>
            }
            <div className="m-auto">
                <OverlayTrigger 
                    placement='bottom'
                    overlay={<Tooltip>{image.filename}</Tooltip>}
                >
                    <Form.Control
                        type="text"
                        value={ 
                            image.filename.lastIndexOf('.') === -1 ? image.filename 
                                : image.filename?.substring(0, image.filename.lastIndexOf('.'))
                        }
                        style={{textOverflow: 'ellipsis'}}
                        onChange={(e) => handleDataChange(e, image.id)}
                        disabled={image.isTitleDisabled ?? true}
                    />                    
                </OverlayTrigger>
            </div>
        </Card.Body>
        <Form.Group className='mb-3 p-2'>
            <Form.Label column className='mt-2'> { !image.description && user.is_staff ? '' : 'Description' }
            {
                !user.is_staff && <Edit2 className='text-link ms-2' size={15} style={{float: 'right'}} onClick={() => handleIsDisabled(image.id)}></Edit2> 
            }
            </Form.Label>
            <Col>
                {
                    (!image?.isDescDisabled || image.description) && <Form.Control
                        className="mb-2"
                        as="textarea"
                        rows={2}
                        value={image.description}
                        onChange={(e) => handleDataChange(e, image.id, 'description')}
                        disabled={image.isDescDisabled ?? true}
                    />
                }
                { !user.is_staff && !image?.isDescDisabled && <Button disabled={image?.isButtonDisabled} onClick={() => SaveImageData(image.id)}>Save</Button> }
            </Col>
        </Form.Group>
    </Card>
}

export { ImageCardView, ImageModalView }