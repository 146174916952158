import { useState, useEffect } from 'react';

import ModelViewer from './ModelViewer.js';

function ImpellerViewer({ selectedImpeller }) {
  const [transforms, setTransforms] = useState([]);

  useEffect(() => {
    const { name, type, fileName: _fileName } = selectedImpeller;
    const fileName = _fileName
      ? _fileName
      : name && type
      ? `${type}-${name}.stl`
      : null;
    if (!fileName) return;
    const url = selectedImpeller.custom
      ? `/api/files/users/impellers/${fileName}`
      : `/api/files/system/impellers/${fileName}`;

    const transform = {
      geometry: [
        {
          shape: 'STL',
          dim: { url: url },
          res: { theta: 20, phi: 20 },
          options: { capping: true, base: true },
          origin: [0, 0, 0],
          pos: [0, 0, 0],
          rotate: { x: 0, y: 0, z: 0 },
          scale: [1, 1, 1],
          opacity: 1,
          color: [1, 1, 1],
        },
      ],
      clip: [],
      cut: [],
      closedClip: [],
      color: [1, 1, 1],
      opacity: 1,
      label: '',
    };
    setTransforms([transform]);
  }, [selectedImpeller]);

  return <ModelViewer transforms={transforms} />;
}

export default ImpellerViewer;
