// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { UserContext } from '@tri/ui-shared';
import axios from 'axios';
import { Formik } from 'formik';
import { useContext } from 'react';
import { Breadcrumb, Button, Col, Form, Row } from 'react-bootstrap';
import { Save } from 'react-feather';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import { getCookie } from '../utils';
import ToastContext from '../context';
import MandatorySymbol from '../common/MandatorySymbol';

const ChangePassword = () => {
  const { setUser } = useContext(UserContext);
  const { user } = useContext(UserContext);
  const setToast = useContext(ToastContext)
  const history = useHistory();
  const initialValues = {
    // oldPassword: '',
    newPassword: '',
    // confirmPassword: '',
  };

  const ValidationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required('No password provided.') 
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
  });

  const submitPassword = async (values) => {
    let fd = new FormData();
    const changePasswordData = {
      id: user._id,
      // old_password: values.oldPassword,
      new_password1: values.newPassword,
      // new_password2: values.confirmPassword,
    };
    fd.append('data', JSON.stringify(changePasswordData));
    try{
      await axios.put('/api/users/profiles/passwords/', fd, {
        headers: {
          'X-CSRF-TOKEN': getCookie('csrf_token'),
          'X-CSRFToken': 'csrftoken',
      },
      });
      setUser(undefined);
      history.push('/');
    } catch (err) {
      setToast({
        statusCode: 500,
        message: err
      })
    }
  };

  return (
    <div style={{marginTop: '3.3rem'}}>
      <Breadcrumb>
        <Breadcrumb.Item active={true}>Change Password</Breadcrumb.Item>
      </Breadcrumb>
      <div className="px-3" style={{ width: '32em' }}>
        <Formik
          initialValues={initialValues}
          validationSchema={ValidationSchema}
          onSubmit={submitPassword}
        >
          {({ handleSubmit, errors, handleChange }) => (
            <Form>
              <div style={{ height: '3em' }}>
                <Button
                  size="sm"
                  variant="outline-primary"
                  className="mr-1 mb-1"
                  title="Update profile"
                  onClick={handleSubmit}
                >
                  <Save size={20} />
                </Button>
              </div>
              {/* <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="4">
                  Old Password
                </Form.Label>
                <Col sm="8">
                  <Field
                    type="password"
                    name="oldPassword"
                    placeholder="Enter Old Password"
                    value={values.oldPassword}
                    onChange={handleChange}
                    className="form-control"
                  />
                </Col>
              </Form.Group> */}
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="4">
                  New Password <MandatorySymbol />
                </Form.Label>
                <Col sm="8">
                  <Form.Control 
                    name="newPassword"
                    type="password" 
                    placeholder="Enter New Password"
                    onChange={handleChange}
                    isInvalid={errors.newPassword}
                  />
                  <Form.Control.Feedback type="invalid">
                      {errors.newPassword}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              {/* <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="4">
                  Confirm Password
                </Form.Label>
                <Col sm="8">
                  <Field
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    className="form-control"
                  />
                </Col>
              </Form.Group> */}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ChangePassword;
