import { useQuery } from "react-query";
import { executeApiCall, useHeaderConfiguration } from "../../../utils"

const useGetSimcaseDocumentURL = (simcaseId, fileName) => {
    const config = useHeaderConfiguration();
    return useQuery(`simcaseDocuments${fileName}`, async () => {
        const { data } = await executeApiCall('get', `api/v3/projects/sim_cases/results/Documents/${simcaseId}/${fileName}`, {}, config, true)
        return data
    })
}

//get list of all documents
const useSimCaseDocumentsList = (simcaseIds) => {
    const config = useHeaderConfiguration();
    let useQueryKey = simcaseIds.toString().replace(/, /g,'');
    return useQuery(`simcaseDocuments${useQueryKey}`, async () => {
        const { data } = await executeApiCall('get', `/api/v3/projects/sim_cases/results/documents/?c_ids=${simcaseIds}`, {}, config)
        return data
    })
}

export { useGetSimcaseDocumentURL, useSimCaseDocumentsList }