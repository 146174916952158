import '@kitware/vtk.js/Rendering/Profiles/Geometry';
import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper';
import vtkSTLReader from '@kitware/vtk.js/IO/Geometry/STLReader';
import vtkMath from '@kitware/vtk.js/Common/Core/Math';
import matrix from '@kitware/vtk.js/Common/Core/MatrixBuilder';
import vtkPlane from '@kitware/vtk.js/Common/DataModel/Plane';
import vtkPolyData from '@kitware/vtk.js/Common/DataModel/PolyData';
import vtkCutter from '@kitware/vtk.js/Filters/Core/Cutter';
import vtkAppendPolyData from '@kitware/vtk.js/Filters/General/AppendPolyData';
import vtkClosedPolyLine from '@kitware/vtk.js/Filters/General/ClosedPolyLineToSurfaceFilter';
import vtkTubeFilter from '@kitware/vtk.js/Filters/General/TubeFilter';
import vtkCircleSource from '@kitware/vtk.js/Filters/Sources/CircleSource';
import vtkConeSource from '@kitware/vtk.js/Filters/Sources/ConeSource';
import vtkCubeSource from '@kitware/vtk.js/Filters/Sources/CubeSource';
import vtkCylinderSource from '@kitware/vtk.js/Filters/Sources/CylinderSource';
import vtkSphereSource from '@kitware/vtk.js/Filters/Sources/SphereSource';

function cutWithPlane(polyData, plane) {
  const cutter = vtkCutter.newInstance();
  cutter.setCutFunction(plane);
  cutter.setInputData(polyData);
  const polyLine = cutter.getOutputData();
  const cpltsFilter = vtkClosedPolyLine.newInstance();
  cpltsFilter.setInputData(polyLine);
  return cpltsFilter.getOutputData();
}

function clipWithPlane(polyData, plane) {
  var EPSILON = 1e-6;
  var newPolyData = vtkPolyData.newInstance();
  newPolyData.shallowCopy(polyData);
  var points = polyData.getPoints().getData();
  var polys = polyData.getPolys().getData();
  var newPoints = [];
  var newPolys = [];
  var o = plane.getOrigin();
  var n = plane.getNormal();
  for (var i = 0; i < polys.length; i += polys[i] + 1) {
    var pointIdx = newPoints.length / 3;
    var polyLen = polys[i];
    var polygon = polys.slice(i, i + polyLen + 1);
    var abovePlane = new Float32Array(polyLen).fill(-1);
    for (var j = 0; j < polyLen; j++) {
      var idx = polygon[j + 1];
      var dp =
        vtkMath.dot(points.slice(idx * 3, (idx + 1) * 3), n) -
        vtkMath.dot(o, n);
      if (dp > -EPSILON && dp < EPSILON) {
        abovePlane[j] = 0;
      } else {
        abovePlane[j] = dp;
      }
    }
    if (
      abovePlane.every(function (v) {
        return v >= 0;
      })
    ) {
      continue;
    } else if (
      abovePlane.every(function (v) {
        return v <= 0;
      })
    ) {
      newPolys.push(polyLen);
      for (var k = 1; k < polygon.length; k++) {
        var index = polygon[k];
        newPoints.push(
          points[index * 3],
          points[index * 3 + 1],
          points[index * 3 + 2]
        );
        newPolys.push(pointIdx);
        pointIdx++;
      }
    } else {
      var noOfNewPts = 0;
      var pts = [];
      for (index = 0; index < polyLen; index++) {
        var nextIndex = index === polyLen - 1 ? 0 : index + 1;
        var p1 = points.slice(
          polygon[index + 1] * 3,
          (polygon[index + 1] + 1) * 3
        );
        var p2 = points.slice(
          polygon[nextIndex + 1] * 3,
          (polygon[nextIndex + 1] + 1) * 3
        );
        var p1D = abovePlane[index];
        var p2D = abovePlane[nextIndex];
        if (p1D * p2D < 0) {
          var K = -p1D / (p2D - p1D);
          pts.push([
            K * (p2[0] - p1[0]) + p1[0],
            K * (p2[1] - p1[1]) + p1[1],
            K * (p2[2] - p1[2]) + p1[2],
          ]);
          noOfNewPts++;
        }
        if (p2D <= 0) {
          pts.push(p2);
        }
      }
      if (noOfNewPts <= 2) {
        newPolys.push(pts.length);
        for (var l = 0; l < pts.length; l++) {
          newPoints.push(pts[l][0], pts[l][1], pts[l][2]);
          newPolys.push(pointIdx);
          pointIdx++;
        }
      }
    }
  }
  newPolyData.getPoints().setData(newPoints);
  newPolyData.getPolys().setData(newPolys);
  return newPolyData;
}

function transformPolyData(polyData, position, rotation, scale) {
  let newPolyData = vtkPolyData.newInstance();
  newPolyData.shallowCopy(polyData);
  let points = newPolyData.getPoints().getData();
  const transform = matrix.buildFromDegree();
  transform
    .translate(...position)
    .rotateZ(rotation.z)
    .rotateY(rotation.y)
    .rotateX(rotation.x)
    .scale(...scale)
    .apply(points);
  return newPolyData;
}

function createSource(geometryObj) {
  switch (geometryObj.shape) {
    case 'STL': {
      const url = geometryObj.dim.url;
      const stlReader = vtkSTLReader.newInstance({
        url: url,
      });
      return stlReader;
    }
    case 'Cylinder': {
      const cylinderSource = vtkCylinderSource.newInstance({
        radius: geometryObj.dim.radius,
        height: geometryObj.dim.height,
        resolution: geometryObj.res.theta,
        capping: geometryObj.options.capping,
        direction: [0, 0, 1],
      });
      return cylinderSource;
    }
    case 'Cube': {
      const cubeSource = vtkCubeSource.newInstance({
        xLength: geometryObj.dim.x,
        yLength: geometryObj.dim.y,
        zLength: geometryObj.dim.z,
      });
      return cubeSource;
    }
    case 'Cone': {
      let coneSource = vtkAppendPolyData.newInstance();
      if (geometryObj.options.base === false) {
        const polyData = vtkPolyData.newInstance();
        const unitArc = (2 * Math.PI) / geometryObj.res.theta;
        const points = [];
        const polys = [];
        points.push(0, 0, geometryObj.dim.height / 2);
        points.push(0, geometryObj.dim.radius, -geometryObj.dim.height / 2);
        for (let i = 1; i < geometryObj.res.theta; i++) {
          const angle = i * unitArc;
          points.push(
            geometryObj.dim.radius * Math.sin(angle),
            geometryObj.dim.radius * Math.cos(angle),
            -geometryObj.dim.height / 2
          );
          polys.push(3, i - 1 === 0 ? geometryObj.res.theta - 1 : i - 1, 0, i);
        }
        polyData.getPoints().setData(Float32Array.from(points), 3);
        polyData.getPolys().setData(Uint32Array.from(polys), 1);
        coneSource.setInputData(polyData);
      } else {
        coneSource = vtkConeSource.newInstance({
          radius: geometryObj.dim.radius,
          height: geometryObj.dim.height,
          resolution: geometryObj.res.theta,
          direction: [0, 0, 1],
        });
      }
      return coneSource;
    }
    case 'Sphere': {
      const sphereSource = vtkSphereSource.newInstance({
        radius: geometryObj.dim.radius,
        thetaResolution: geometryObj.res.theta,
        phiResolution: geometryObj.res.phi,
      });
      return sphereSource;
    }
    case 'Torus': {
      const torusOutline = vtkCircleSource.newInstance({
        radius: geometryObj.dim.majorRadius,
        resolution: geometryObj.res.theta,
      });
      const torusSource = vtkTubeFilter.newInstance({
        radius: geometryObj.dim.minorRadius,
        numberOfSides: geometryObj.res.phi,
        capping: false,
      });
      const numberOfPoints = torusOutline.getOutputData().getNumberOfPoints();
      const lines = [numberOfPoints + 1];
      for (let i = 0; i < numberOfPoints; i++) lines.push(i);
      lines.push(0);
      torusOutline.getOutputData().getLines().setData(lines);
      torusSource.setInputData(torusOutline.getOutputData());
      return torusSource;
    }
    case 'Tube': {
      const polyData = vtkPolyData.newInstance();
      const tubeSource = vtkTubeFilter.newInstance({
        radius: geometryObj.dim.tubeRadius,
        numberOfSides: geometryObj.res.phi,
        capping: geometryObj.options.capping,
      });
      polyData.getPoints().setData(geometryObj.dim.points);
      polyData.getLines().setData(geometryObj.dim.lines);
      tubeSource.setInputData(polyData);
      return tubeSource;
    }
    case 'Arrow': {
      const shaftLength =
        geometryObj.dim.arrowLength - geometryObj.dim.tipLength;
      const cone = vtkConeSource.newInstance({
        radius: geometryObj.dim.tipRadius,
        height: geometryObj.dim.tipLength,
        resolution: geometryObj.res.phi,
      });
      const conePoints = cone.getOutputData().getPoints().getData();
      matrix
        .buildFromDegree()
        .translate(
          geometryObj.dim.arrowLength - geometryObj.dim.tipLength / 2,
          0,
          0
        )
        .apply(conePoints);
      const cylinder = vtkCylinderSource.newInstance({
        radius: geometryObj.dim.shaftRadius,
        height: shaftLength,
        resolution: geometryObj.res.theta,
      });
      const cylinderPoints = cylinder.getOutputData().getPoints().getData();
      const cylinderNormals = cylinder
        .getOutputData()
        .getPointData()
        .getNormals()
        .getData();
      matrix
        .buildFromDegree()
        .rotateZ(-90)
        .translate(0, shaftLength / 2, 0)
        .apply(cylinderPoints)
        .apply(cylinderNormals);
      const arrowSource = vtkAppendPolyData.newInstance();
      arrowSource.setInputConnection(cone.getOutputPort());
      arrowSource.addInputConnection(cylinder.getOutputPort());
      const arrowPoints = arrowSource.getOutputData().getPoints().getData();
      matrix
        .buildFromDegree()
        .rotateFromDirections([1, 0, 0], geometryObj.dim.direction)
        .apply(arrowPoints);
      return arrowSource;
    }
    default: {
      console.error('Cannot render the given shape : ', geometryObj.shape);
    }
  }
}

async function promise_createActor(transform) {
  const polyData = await getTransformedPolyData(transform);
  const actor = vtkActor.newInstance();
  const mapper = vtkMapper.newInstance();
  mapper.setInputData(polyData);
  actor.setMapper(mapper);
  actor.getProperty().setOpacity(transform.opacity);
  actor.getProperty().setColor(transform.color);
  return actor;
}

function createActor(geometryObj) {
  const source = createSource(geometryObj);
  const actor = vtkActor.newInstance();
  const mapper = vtkMapper.newInstance();
  mapper.setInputConnection(source.getOutputPort());
  actor.setMapper(mapper);
  actor.getProperty().setOpacity(geometryObj.opacity);
  actor.getProperty().setColor(geometryObj.color);
  actor.rotateZ(geometryObj.rotate.z);
  actor.rotateY(geometryObj.rotate.y);
  actor.rotateX(geometryObj.rotate.x);
  actor.setPosition(geometryObj.pos);
  actor.setScale(geometryObj.scale);
  actor.setOrigin(geometryObj.origin);
  return actor;
}

async function getTransformedPolyData(transform) {
  if (!transform.geometry || transform.geometry.length === 0) {
    return vtkPolyData.newInstance();
  }
  const arr_source = transform.geometry.map(createSource);
  const arr_pd_promise = arr_source.map(async (source, index, array) => {
    if (source.isA('vtkSTLReader')) {
      await source.loadData({ binary: true });
    }
    const pd = source.getOutputData();
    const { pos, rotate, scale } = transform.geometry[index];
    return transformPolyData(pd, pos, rotate, scale);
  });
  const arr_pd = await Promise.all(arr_pd_promise);
  const append = vtkAppendPolyData.newInstance();
  append.setInputData(arr_pd[0]);
  arr_pd.slice(1).forEach((pd) => {
    append.addInputData(pd);
  });
  let polyData = append.getOutputData();
  transform.clip
    .map((x) => vtkPlane.newInstance(x))
    .forEach((plane) => {
      polyData = clipWithPlane(polyData, plane);
    });
  transform.cut
    .map((x) => vtkPlane.newInstance(x))
    .forEach((plane) => {
      polyData = cutWithPlane(polyData, plane);
    });
  transform.closedClip
    .map((x) => vtkPlane.newInstance(x))
    .forEach((plane) => {
      const apd = vtkAppendPolyData.newInstance();
      const clip = clipWithPlane(polyData, plane);
      const cut = cutWithPlane(polyData, plane);
      apd.setInputData(clip);
      apd.addInputData(cut);
      polyData = apd.getOutputData();
    });
  return polyData;
}

export {
  cutWithPlane,
  clipWithPlane,
  transformPolyData,
  createSource,
  createActor,
  getTransformedPolyData,
  promise_createActor,
};
