// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { UserContext, CenteredSpinner } from '@tri/ui-shared';
import axios from 'axios';
import { useContext, useMemo, useState } from 'react';
import { Card, Dropdown, Image, Spinner } from 'react-bootstrap';
import { AlignJustify, Plus } from 'react-feather';
import { useQuery, useQueryClient } from 'react-query';
import { getCookie } from '../utils';
import NewAppDialog from './NewAppDialog';
import ToastContext from '../context';

const newAppItem = [
  {
    id: 0,
    icon: '../../assets/plus-square.svg',
    name: 'Add New',
  },
];

const AppGrid = ({ url = '/api/v3/app/' }) => {
  const { user } = useContext(UserContext);
  const setToast = useContext(ToastContext)

  const queryClient = useQueryClient();
  const [openNewAppDialogFlag, setOpenNewAppDialogFlag] = useState(false);
  const [editAppFlag, setEditAppFlag] = useState(false);
  const [selectedAppData, setSelectedAppData] = useState([]);
  const [apiResponse, setApiResponse] = useState(false);
  const [clickedAppName, setClickedAppName] = useState('');

  const [appList, setAppList] = useState([]);

  const config = {
    headers: { 
      'X-CSRF-TOKEN': getCookie('csrf_token')
    },
  };

  const appListData = useQuery('appList', async () => {
    const { data } = await axios.get(`${url}`, config);
    return data;
  });


  useMemo(() => {
    const appData = appListData?.data 
    if(appData) {
      setAppList([...newAppItem, ...appData]);
      if (user.is_superuser || user.is_poweruser) {
        setAppList([...newAppItem, ...appData]);
      } else {
      setAppList([...appData]);
      }
    }
  }, [appListData?.data])

  const deleteApp = async (appId) => {
    const confirm = window.confirm('Please confirm to proceed with deletion..');
    if (confirm) {
      const app_del_resp = await axios.delete(`${url}${appId}/`, config);
      queryClient.invalidateQueries('appList');

      if(app_del_resp.data.status_code === 200)
      {
        setToast({
          statusCode: app_del_resp.data.status_code,
          message: app_del_resp.data.msg
        })
      }
    }
  };
  
  const getUrl = async (appId) => {
    const { data, status } = await axios.get(`/api/v3/ssapp?id=${appId}`, config);
    if (status === 200) {
      setApiResponse(false);
    }
    window.open(data.launch_url, '_blank');
  };

  return (
    <>
      {openNewAppDialogFlag && (
        <NewAppDialog
          onHide={() => {
            setOpenNewAppDialogFlag(false);
          }}
          onApply={() => {
            queryClient.invalidateQueries('appList');
          }}
          editAppFlag={editAppFlag}
          selectedAppData={selectedAppData}
        ></NewAppDialog>
      )}
      {appListData.status === 'loading' && <CenteredSpinner />}
      {appListData.status === 'error' && <h5>Something went wrong ...</h5>}
      {appListData.status === 'success' && (
        <div className="d-flex flex-wrap p-3">
          {appList &&
            appList.map((app) => (
              <Card
                className="shadow"
                style={{
                  margin: '8px 16px',
                  minHeight: '12rem',
                  width: '12rem',
                  borderRadius: '1rem',
                }}
                key={app.name}
              >
                {app.name !== 'Add New' && (
                  <div className="float-right">
                    {(user.is_superuser || user.is_poweruser) && (
                      <div style={{ textAlign: 'end' }}>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="default"
                            id="dropdown-basic"
                            style={{ color: '#23395d' }}
                          >
                            <AlignJustify></AlignJustify>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            { <Dropdown.Item
                              onClick={() => {
                                setOpenNewAppDialogFlag(true);
                                setEditAppFlag(true);
                                setSelectedAppData([
                                  {
                                    id: app._id,
                                    name: app.name,
                                    description: app.description,
                                    file: app.file,
                                    icon: app.icon,
                                    iconFileName: app.icon_file_name,
                                    ipynbFileName: app.ipynb_file_name,
                                  },
                                ]);
                              }}
                            >
                              Edit
                            </Dropdown.Item> }
                            <Dropdown.Item onClick={() => deleteApp(app._id)}>
                              Remove
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    )}
                  </div>
                )}
                {app.name === 'Add New' ?<Card.Body
                    className="text-center d-flex flex-column p-1"
                    onClick={() => {
                      setOpenNewAppDialogFlag(true);
                      setEditAppFlag(false);
                      setSelectedAppData([]);
                    }}
                    style={{
                      cursor: 'pointer',
                      borderRadius: '6rem',
                    }}
                    title="Add new app"
                  >
                    <div
                      style={{
                        textAlign: 'center',
                        alignItems: 'center',
                        marginTop: '32px',
                      }}
                    >
                      <Plus size={120} color={'#fd7702'} />
                    </div>

                    <div
                      title="Add new App"
                      className="mr-1 py-2 mt-auto font-weight-bold"
                      style={{ color: '#23395d' }}
                    >
                      Add New App
                    </div>
                  </Card.Body>
                 : <Card.Body
                    className="text-center d-flex flex-column align-items-center p-1"
                    onClick={() => {
                      getUrl(app._id);
                      setApiResponse(true);
                      setClickedAppName(app.name);
                    }}
                    style={
                      clickedAppName === app.name && apiResponse
                        ? {
                            cursor: 'not-allowed',
                            pointerEvents: 'none',
                            opacity: '0.4',
                          }
                        : { cursor: 'pointer', borderRadius: 16 }
                    }
                    title="Open app"
                  >                    
                    <Image
                      src={`/api/v3/app-icon/?app_dir_name=${app.app_dir_name}&app_icon_file=${app.icon_file_name}`}
                      thumbnail
                      style={{
                        width: '8rem',
                        height: '8rem',
                        border: 'none',
                        textAlign: 'center',
                      }}
                    ></Image>
                    <div
                      bg="primary"
                      className="py-2 mt-auto font-weight-bold"
                      style={{ color: '#23395d' }}
                    >
                      <div className="flex">
                        {clickedAppName === app.name && apiResponse && (
                          <Spinner
                            size="sm"
                            animation="border"
                            variant="secondary"
                          />
                        )}
                        <> {app.name}</>
                      </div>
                    </div>
                  </Card.Body>
                }
              </Card>
            ))}
        </div>
      )}
    </>
  );
};

export default AppGrid;
