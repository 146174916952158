export const quantities = {
  /* Quantities whose name is same as dimension, don't require dimension property.
     Its dimension is assumed to be same as its key. For example,
     quantity 'mass' has dimension also of 'mass'. */
  mass: {
    label: 'Mass',
  },
  time: {
    label: 'Time',
  },
  length: {
    label: 'Length',
  },
  area: {
    label: 'Area',
  },
  volume: {
    label: 'Volume',
  },
  temperature: {
    label: 'Temperature',
  },
  linearSpeed: {
    label: 'Linear Speed',
  },
  angularSpeed: {
    label: 'Angular Speed',
  },
  volFlowRate: {
    label: 'Volumetric Flow Rate',
  },
  density: {
    label: 'Density',
  },
  viscosity: {
    label: 'Viscosity',
  },
  pressure: {
    label: 'Pressure',
  },
  energy: {
    label: 'Energy',
  },
  power: {
    label: 'Power',
  },
  powerPerUnitVolume: {
    label: 'Power Per Unit Volume',
  },
  surfaceTension: {
    label: 'Surface Tension',
  },
  specificHeatCapacity: {
    label: 'Specific Heat Capacity',
  },
  thermalConductivity: {
    label: 'Thermal Conductivity',
  },
  turbulentDissipationRate: {
    label: 'Turbulent Dissipation Rate',
  },
  molecularWeight: {
    label: 'Molecular Weight',
  },
  heatTransferCoefficient: {
    label: 'Heat Transfer Coefficient',
  },
  diffusivity: {
    label: 'Diffusivity',
  },
  /* Quantities whose name can be different from dimension key requires the 'dimenion' property.
     This is because multiple quantites can have same dimension. For example, heatTransferRate
     and power both have dimension of 'power'. */
  distance: {
    label: 'Distance',
    dimension: 'length',
  },
  bubbleDropletParticleSize: {
    label: 'Bubble Droplet Particle Size',
    dimension: 'length',
  },
  massTransferCoefficient: {
    label: 'Mass Transfer Coefficient',
    dimension: 'linearSpeed',
  },
  heatTransferRate: {
    label: 'Heat Transfer Rate',
    dimension: 'power',
  },
  strainRate: {
    label: 'Strain Rate',
    dimension: 'timeInverse',
  },
  gasFlowRate: {
    label: 'Gas Flow Rate',
    dimension: 'volFlowRate',
  },
  volMTC: {
    label: 'Vol. MTC',
    dimension: 'timeInverse',
  },
  solidParticleSize: {
    label: 'Particle Size',
    dimension: 'length',
  },
};

export const dimensions = {
  mass: {
    units: ['kg', 'g', 'mg', 'lb'],
  },
  time: {
    units: ['s', 'min', 'hr'],
  },
  length: {
    units: ['m', 'ft', 'cm', 'mm', 'in', 'microm'],
  },
  area: {
    units: ['m2', 'ft2', 'cm2', 'mm2', 'in2'],
  },
  volume: {
    units: ['m3', 'cm3', 'mm3', 'in3', 'ft3', 'gallon', 'gallon-imp', 'liter'],
  },
  temperature: {
    units: ['temp-C', 'temp-K', 'temp-F', 'temp-R'],
  },
  linearSpeed: {
    units: ['m/s', 'ft/s', 'cm/s', 'mm/s', 'm/min', 'ft/min'],
  },
  angularSpeed: {
    units: ['rpm', 'rotation/s', 'rad/s', 'rad/min'],
  },
  volFlowRate: {
    units: [
      'm3/s',
      'cm3/s',
      'ft3/s',
      'gallon/s',
      'm3/hr',
      'ft3/hr',
      'gallon/hr',
      'ft3/min',
      'gallon-imp/s',
      'm3/min',
    ],
  },
  density: {
    units: ['kg/m3', 'lb/ft3', 'g/cm3', 'g/L', 'lb/gallon', 'lb/gallon-imp'],
  },
  viscosity: {
    units: ['cP', 'poise', 'kg/m s', 'lb/ft s', 'Pa s'],
  },
  pressure: {
    units: [
      'bar',
      'atm',
      'Pa',
      'kPa',
      'mmHg',
      'N/m2',
      'dyne/cm2',
      'lbf/ft2',
      'lbf/in2',
      'psi',
    ],
  },
  energy: {
    units: ['Joule', 'kcal', 'cal', 'BTU', 'MBTU', 'kWh', 'kJ'],
  },
  power: {
    units: ['W', 'J/s', 'BTU/s', 'BTU/hr', 'MBTU/hr', 'kW', 'MW', 'hp'],
  },
  powerPerUnitVolume: {
    units: ['kW/m3', 'W/m3', 'hp/gallon', 'hp/gallon-imp'],
  },
  surfaceTension: {
    units: ['N/m', 'dyne/cm', 'nN/m', 'lbf/in'],
  },
  specificHeatCapacity: {
    units: [
      'J/kg degK',
      'J/kg degC',
      'kJ/kg degK',
      'kJ/kg degC',
      'BTU/lb degF',
      'kcal/kg degK',
      'cal/g degC',
    ],
  },
  thermalConductivity: {
    units: ['W/m degK', 'kW/m degK', 'kcal/h m degC', 'BTU ft/hr ft2 degF'],
  },
  turbulentDissipationRate: {
    units: ['m2/s3', 'ft2/s3', 'cm2/s3', 'mm2/s3', 'in2/s3'],
  },
  molecularWeight: {
    units: ['kg/kmol', 'g/mol'], // TODO lb/lbmol
  },
  heatTransferCoefficient: {
    units: ['W/m2 degK', 'W/m2 degC', 'kcal/hr m2 degC', 'BTU/hr ft2 degF'],
  },
  diffusivity: {
    units: ['m2/s', 'ft2/s', 'cm2/s', 'mm2/s', 'in2/s'],
  },
  timeInverse: {
    units: ['1/s', '1/min', '1/hr'],
  },
};

export const unitSystems = {
  SI: {
    id: 'SI',
    name: 'SI',
    units: {
      mass: 'kg',
      time: 's',
      length: 'm',
      area: 'm2',
      volume: 'm3',
      temperature: 'temp-K',
      linearSpeed: 'm/s',
      angularSpeed: 'rpm',
      volFlowRate: 'm3/s',
      density: 'kg/m3',
      viscosity: 'Pa s',
      pressure: 'Pa',
      energy: 'J',
      power: 'kW',
      powerPerUnitVolume: 'kW/m3',
      surfaceTension: 'N/m',
      specificHeatCapacity: 'J/kg degK',
      thermalConductivity: 'W/m degK',
      turbulentDissipationRate: 'm2/s3',
      molecularWeight: 'kg/kmol',
      heatTransferCoefficient: 'W/m2 degK',
      diffusivity: 'm2/s',
      angle: 'degree',
      strainRate: '1/s',
      bubbleDropletParticleSize: 'm',
      heatTransferRate: 'W',
      massTransferCoefficient: 'm/s',
      gasFlowRate: 'm3/s',
      volMTC: '1/s',
      solidParticleSize: 'micron',
    },
  },
  Imperial: {
    id: 'Imperial',
    name: 'Imperial',
    units: {
      mass: 'lb',
      time: 's',
      length: 'in',
      area: 'in2',
      volume: 'gallon',
      temperature: 'temp-F',
      linearSpeed: 'ft/s',
      angularSpeed: 'rad/s',
      volFlowRate: 'ft3/s',
      density: 'lb/ft3',
      viscosity: 'lb/ft s',
      pressure: 'psi',
      energy: 'BTU',
      power: 'BTU/s',
      powerPerUnitVolume: 'hp/gallon',
      surfaceTension: 'lbf/inch',
      specificHeatCapacity: 'BTU/lb degF',
      thermalConductivity: 'BTU/h ft degF',
      turbulentDissipationRate: 'in2/s3',
      molecularWeight: 'g/mol',
      heatTransferCoefficient: 'BTU/h m2 degK',
      diffusivity: 'ft2/s',
      angle: 'degree',
      strainRate: '1/s',
      bubbleDropletParticleSize: 'in',
      heatTransferRate: 'BTU/h',
      massTransferCoefficient: 'ft/s',
      gasFlowRate: 'm3/s',
      volMTC: '1/h',
      solidParticleSize: 'in',
    },
  },
};
