import AuthLayout from './AuthLayout';
import { Card } from 'react-bootstrap';

const SentResetLinkPage = () => {
  return (
    <AuthLayout>
      <Card className="p-3">
        <div className="text-dark">
          <p className="lead">
            An email with link to reset password has been sent to your email
            address.
          </p>
        </div>
      </Card>
    </AuthLayout>
  );
};

export default SentResetLinkPage;
