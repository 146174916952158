import { useState, useLayoutEffect, memo } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { UserContext } from '@tri/ui-shared';
import AppPage from './AppPage';
import SigninPage from './pages/SigninPage';
import ToastContext, { CompareCombinationContext } from '../app/context';
import { getRefreshData } from './services/auth.dataservice';
import Toast from './common/Toast';
import { queryClient } from './utils';

const SigninPageMemorise = memo(SigninPage)
const AppPageMemorise = memo(AppPage)

const App = () => {
  const [user, setUser] = useState(null);
  const [toast, setToast] = useState({})
  const [selectedSimCasesForComparision, setSelectedSimCasesForComparision] = useState({})

  //return user data if user have valid token 
  useLayoutEffect(() => {
    (() => {
      const userData = getRefreshData();
      userData.then(res => {
        setUser(res.data ?? undefined)
      })
    })()
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
     <ToastContext.Provider value={setToast}>
      <CompareCombinationContext.Provider value={{selectedSimCasesForComparision, setSelectedSimCasesForComparision}}>
        <Router>
          <Switch>
            <Route
              exact
              path="/"
              render={(routeProps) => {
                if (user === null) return

                const { activeView, activeSubView, activeSubSubView, type } =
                  routeProps.location.state || {};

                if(user) {
                  let redirectTo = '/app'
                  if(activeView) {
                    redirectTo += `/${activeView}`
                    if(activeSubView) {
                      redirectTo += `/${activeSubView}`
                      if(activeSubSubView) {
                        redirectTo += `/${activeSubSubView}`
                      }
                    }
                  } else {
                    redirectTo += `/home`
                  }
                  if(type) {
                    redirectTo += `/?type=${type}`
                  }
                  return <Redirect to={redirectTo} />
                }
                else {
                  return <SigninPageMemorise
                    onSignin={setUser}
                    activeView={activeView}
                    activeSubView={activeSubView}
                    activeSubSubView={activeSubSubView}
                    type={type}
                  />
                }
              }}
            ></Route>

            <Route
              path="/app/:viewPath*"
              render={(routeProps) => {
                let serachParams = routeProps.location?.search?.substring(1)?.split('&')
                let typeParam = serachParams?.find(element => element.includes("type="));
                const type = typeParam?.substring(typeParam.indexOf('=') + 1)?.replaceAll('%20', ' ')
                const viewPath = routeProps.match.params.viewPath;
                
                const [activeView, activeSubView, activeSubSubView] =
                  viewPath?.split('/') || [];
                if (!user)
                  return (
                    <Redirect
                      to={{
                        pathname: '/',
                        state: { activeView, activeSubView, activeSubSubView, type },
                      }}
                    />
                  );

                return (
                  <QueryClientProvider client={queryClient}>
                    <AppPageMemorise
                      activeView={activeView}
                      activeSubView={activeSubView}
                      activeSubSubView={activeSubSubView}
                      type={type}
                    />
                  </QueryClientProvider>
                );
              }}
            />
          </Switch>
        </Router>
        <Toast toastObj={toast} />
      </CompareCombinationContext.Provider>
     </ToastContext.Provider>
    </UserContext.Provider>
  );
};

export default App;
