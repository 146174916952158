import { Nav, Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import AppGrid from '../ssapps/AppGrid';
import assetExplorerIcon from '../../assets/Asset_Explorer.svg';
import simulationExplorerIcon from '../../assets/Simulation_Explorer.svg';
import '../common/Common.css';

const views = [
  {
    key: 'asset',
    label: 'Asset Explorer',
    img: <img src={assetExplorerIcon} height={120} />,
  },
  {
    key: 'simulationData',
    label: 'Simulation Explorer',
    img: <img src={simulationExplorerIcon} height={120} />,
  },
];
const HomePage = () => {
  const history = useHistory();
  const activeView = 'home';
  return (
    <>
      <Nav 
        className="mr-auto" 
        style={{ marginTop: '4rem' }}
        onSelect={(key) => {
          history.push(`/app/${key}`);
        }}
      >
        {views.map(({ key, label, img }) => (
          <Nav.Link
            key={key}
            eventKey={key}
            active={activeView === key}
            className="text-center"
          >
            <Card
              className="shadow"
              style={{
                margin: '8px 16px',
                height: '12rem',
                width: '16rem',
                borderRadius: '1rem',
              }}
            >
              <Card.Body className="text-center d-flex flex-column justify-content-center align-items-center p-1">
                <div>{img}</div>
                <div
                  className="py-2 font-weight-bold"
                  style={{ color: '#23395d' }}
                >
                  {label}
                </div>
              </Card.Body>
            </Card>
          </Nav.Link>
        ))}
      </Nav>
      <hr />
      <AppGrid />
    </>
  );
};

export default HomePage;
