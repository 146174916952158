import { useContext } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import UserContext from '../UserContext';

const Signout = () => {
  const { setUser } = useContext(UserContext);
  const history = useHistory();

  return (
    <div
      color="primary"
      size="sm"
      onClick={async () => {
        try {
          await axios.post('/api/auth/signout');
          setUser(undefined);
          history.push('/auth/signin');
        } catch (err) {
          alert(err.response.data);
        }
      }}
    >
      Sign out
    </div>
  );
};

export default Signout;
