import { UserContext } from '@tri/ui-shared';
import { useContext } from 'react';
import { NavDropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { User } from 'react-feather';
import { useHistory } from 'react-router';
import SimSightSignoutPage from '../pages/SignoutPage';

const UserDropdownMenu = () => {
  const history = useHistory();
  const { user } = useContext(UserContext);
  
  return (<NavDropdown
      title={
        <OverlayTrigger 
          placement="bottom-start"
          overlay={<Tooltip>{user.username}</Tooltip>}
        >
          <User className="text-light" style={{color: '#fff'}} />
        </OverlayTrigger>
      }
      id="basic-nav-dropdown"
      align="end"
    >
      {user.is_superuser && (
        <NavDropdown.Item
          active={history.location.pathname.includes('/administration')}
          onClick={() => {
            history.push(`/app/administration`);
          }}
        >
          Administration
        </NavDropdown.Item>
      )}

      <NavDropdown.Item
        active={history.location.pathname.includes('/userProfile')}
        onClick={() => {
          history.push(`/app/userProfile`);
        }}
      >
        Profile
      </NavDropdown.Item>
      <NavDropdown.Item
        active={history.location.pathname.includes('/changePassword')}
        onClick={() => {
          history.push(`/app/changePassword`);
        }}
      >
        Change Password
      </NavDropdown.Item>
      <NavDropdown.Item>
        <SimSightSignoutPage />
      </NavDropdown.Item>
    </NavDropdown>
  );
};

export default UserDropdownMenu;
