import { useContext, useEffect, useMemo, useRef, useReducer, useState } from 'react';
import { executeApiCall, useHeaderConfiguration } from '../../../utils';
import { useSimcaseImagesList } from '../data-service/images.dataservice';
import { useGetProjectDetails } from '../data-service/simulation.dataservice';
import { GetSimcaseImage } from './images.businesslogic';
import { useQuery } from 'react-query';
import { GetSimcaseAnimation } from './animations.businesslogic';
import { compareDataType, compareActions } from '../../../utils/constants';
import { useProjectGroupingDetailsData } from './grouping.businesslogic';
import ToastContext, { CompareCombinationContext } from '../../../context';

const GetSimCaseImagesList = (selectedCompareCaseIds) => useSimcaseImagesList(selectedCompareCaseIds)

const compareCombinationReducer = (state, action) => {
    switch(action.type) {
        case 'CompareAction': {
            return {
                ...state, 
                selectedCompareAction: action.value,
                compareCases: state.compareCases?.map((simCase, index) => {
                    simCase["plotType"] = {}
                    return simCase
                })
            }
        }
        case 'all': {
            return action.value
        }
        case 'delete': {
            return {
                ...state,
                compareCases: state.compareCases?.filter((simCase, index) => index !== action.index)
            }
        }
        default:
            return {
                ...state, compareCases: [
                    ...(state.compareCases?.length > 0 ? state.compareCases.find((simCase, index) => index === action.index) ? 
                        state.compareCases.map((simCase, index) => {
                            if(index === action.index) {
                                simCase[action.type] = action.value
                            }
                            return simCase
                        }) : [...state.compareCases, {[action.type]: action.value}]
                        : [{
                            [action.type]: action.value
                        }])
                ]
            }
    }
} 

const GetProjectCompareDetailsData = (projectId) => {
    const bottomOfPage = useRef()
    const topOfPage = useRef()
    const projectDetails = useGetProjectDetails(projectId)
    const config = useHeaderConfiguration()
    const [allSimCasesData, setAllSimCasesData] = useState([])
    const [compareActionOptions, ] = useState(compareActions)
    const [simCasesOptions, setProjectCaseOptions] = useState([])
    const [compareCases, setCompareCases] = useState([])
    const [selectedCompareCasesCount, setSelectedCompareCasesCount] = useState(0)
    const [selectedCompareCaseIds, setSelectedCompareCaseIds] = useState([]) 
    const [plotTypesOptions, setPlotTypesOptions] = useState({})
    const [allSimCasesDocumentsDictionary, setAllSimCasesDocumentsDictionary] = useState({})
    const [columnGroupsDictionary, setColumnGroupsDictionary] = useState({})
    const { existingColumnGroups } = useProjectGroupingDetailsData(projectId)
    const [compareCombination, setCompareCombination] = useReducer(compareCombinationReducer, [])   

    const setToast = useContext(ToastContext)
    const { selectedSimCasesForComparision, setSelectedSimCasesForComparision } = useContext(CompareCombinationContext)
    let compareCombinationSavedData = localStorage.getItem('compareCombination')

    const [imageModal, setImageModal] = useState({
        show: false,
        title: "",
        url: ""
    })
        
    //if sim cases selection for the comparision gets changed
    useMemo(() => {
        let savedCompareCombination = {
            selectedCompareAction: {},
            compareCases: []
        }
        //check for preselected sim cases from simulation details page
        let selectedSimCasesFromSimDetailsTable = []
        if(Object.keys(selectedSimCasesForComparision).length > 0) {
            let projectCompareCases = selectedSimCasesForComparision[projectId]
            selectedSimCasesFromSimDetailsTable = projectCompareCases?.compareCases ?? []
        }

        //if preselected cases are there, then show only those
        if(selectedSimCasesFromSimDetailsTable.length > 0) {
            savedCompareCombination = selectedSimCasesForComparision[projectId]
        }
        // if no preselected cases and save data there, then show saved compare cases
        else if(compareCombinationSavedData) {
            savedCompareCombination = JSON.parse(compareCombinationSavedData)?.[projectId] ?? {
                selectedCompareAction: {},
                compareCases: []
            }
        }

        setCompareCombination({
            type: "all",
            value: savedCompareCombination,
        })
    }, [selectedSimCasesForComparision])

    //remove all selected sim cases that was selected from sim details page on component unmount
    useEffect(() => {
        return () => {
            let selectedSimCasesForComparisionTemp = selectedSimCasesForComparision
            // eslint-disable-next-line no-prototype-builtins
            if(selectedSimCasesForComparisionTemp.hasOwnProperty(projectId)) {
                delete selectedSimCasesForComparisionTemp[projectId]
            }
            setSelectedSimCasesForComparision(selectedSimCasesForComparisionTemp)
        }
    }, [])

    useMemo(() => {
        let newCompareCases = compareCases
        compareCombination?.compareCases?.forEach((compareCase, index) => {
            if(newCompareCases.length > 0 && newCompareCases[index]) {
                newCompareCases[index].value = compareCase?.caseId ?? ""
                newCompareCases[index].plotTypeValue = compareCase?.plotType ?? ""
            }
        })
        setCompareCases([...newCompareCases])
    }, [compareCombination])
    
    useQuery(`simcase${compareCombination?.selectedCompareAction?.value}${selectedCompareCaseIds.toString().replace(/, /g,'')}`, async () => {
            if(selectedCompareCasesCount > 0 && compareCases.length === selectedCompareCasesCount) {
                const { data } = await executeApiCall('get', `/api/v3/projects/sim_cases/results/${compareCombination.selectedCompareAction.value.toLowerCase()}/?${ selectedCompareCaseIds.map(caseId => `c_ids=${caseId}`).join('&')}`, {}, config)
                setPlotTypesOptions(data)
                return data
            }
            return []
        }, {
        notifyOnChangeProps: [compareCombination?.selectedCompareAction, selectedCompareCaseIds]
    })

    const appendGroupingPlotTypeOption = (compareCases) => {
        if(compareCombination.selectedCompareAction?.value === compareDataType.Groups) {
            let plotTypeOptions = []
            let group_column_names_list = {}
            existingColumnGroups?.forEach(group => {
                plotTypeOptions.push({"label": group.group_name, "value": group.group_name})
                group_column_names_list[group.group_name] = {
                    color: group.color_label,
                    columns: group.column_names_list 
                }
            }) 
            setColumnGroupsDictionary(group_column_names_list)
            let cases = compareCases.map(compareCase => {
                compareCase.plotTypeOptions = plotTypeOptions
                if(compareCombination?.selectedCompareAction?.value !== compareDataType.Geometry && selectedCompareCasesCount > 0 && compareCases.length === selectedCompareCasesCount) {
                    compareCase.isPlotTypeDisabled = false
                }
                return compareCase
            })
            setCompareCases(cases)
        }
    }

    //Generate plot type options for grouping
    useEffect(() => {
        appendGroupingPlotTypeOption(compareCases)
    }, [existingColumnGroups, compareCases.length, compareCombination?.selectedCompareAction])

    //Generate plot type options
    useEffect(() => {
        if(plotTypesOptions && Object.keys(plotTypesOptions)) {
            let updatedCompareCases = compareCases.map(compareCase => {
                let allDocuments = {}
                let caseId = compareCase.value?.value
                if(caseId && plotTypesOptions[caseId]) {
                    let caseImageOrDocumentData = plotTypesOptions[caseId][1].file_info
                    let plotTypeOptions = []

                    if(compareCombination.selectedCompareAction?.value === compareDataType.Documents) {
                        caseImageOrDocumentData.forEach(file => {
                            let fileNameAndExtension = file.split('.')
                            allDocuments[fileNameAndExtension[1]] = allDocuments[fileNameAndExtension[1]] ? 
                                [...allDocuments[fileNameAndExtension[1]], fileNameAndExtension[0] ]
                                : [fileNameAndExtension[0]]
                        })
                        //Creating dictionary of sim case and their docs for displaying compare result data
                        setAllSimCasesDocumentsDictionary(docs => {
                            let simcaseDoc = {}
                            simcaseDoc[caseId] = allDocuments
                            return {...docs, ...simcaseDoc }
                        })
                        Object.keys(allDocuments).forEach(doc => plotTypeOptions.push({"label": doc, "value": doc}))
                    }
                    else {
                        caseImageOrDocumentData.forEach((image) => {
                            plotTypeOptions.push({"label": image.filename, "value": image.id})
                        })
                    }
                    compareCase.plotTypeOptions = plotTypeOptions
                }
                return compareCase
            })
            setCompareCases(updatedCompareCases)
        }
    }, [plotTypesOptions])

    //Add dropdown for comparasion
    useEffect(() => {
        if(compareCombination.selectedCompareAction) {
            let cases = [];
            let comparisionDDCount = compareCombination?.compareCases?.length || 2;
            [...Array(comparisionDDCount).keys()].forEach(index =>{ cases.push(createCompareCaseObject(cases)) }) 
            setCompareCases(cases)
            appendGroupingPlotTypeOption(cases)
            setCompareCombination({
                "type": "all",
                "value": {...compareCombination, selectedCompareAction: compareCombination.selectedCompareAction}
            })
        }
    }, [compareCombination?.selectedCompareAction])

    //count selected compare cases and update selected compare case ids
    useEffect(() => {
        let selectedCasesCount = 0
        if(compareCases.length > 0) {
            let selectedCompareCaseIds = []
            compareCases.forEach(compareCase => {
                if(compareCase.value !== '') {
                    selectedCasesCount++;
                    selectedCompareCaseIds.push(compareCase.value?.value)
                }
            })
            setSelectedCompareCaseIds(selectedCompareCaseIds)
        }
        setSelectedCompareCasesCount(selectedCasesCount)
        //Enable Save button if compare options changed
    } , [compareCases])

    //Enable plot type option once select all compare cases
    useEffect(() => {
        //if compare action is geometry then skip plot type options
        if(compareCombination?.selectedCompareAction?.value !== compareDataType.Geometry && selectedCompareCasesCount > 0 && compareCases.length === selectedCompareCasesCount) {
            setCompareCases((existingCases) => existingCases.map(compareCase => ({...compareCase, isPlotTypeDisabled: false })))

            let selectedCompareCaseIds = []
            compareCases.forEach(compareCase => {
                selectedCompareCaseIds.push(compareCase.value?.value)
            })
            
            selectedCompareCaseIds.length > 0 && setSelectedCompareCaseIds(selectedCompareCaseIds)
        }
    }, [plotTypesOptions])

    //Creating sim cases dropdown options
    useMemo(() => { 
        setAllSimCasesData(projectDetails.data?.project_case_data || [])
        let simCaseNameColumnName
        const projectCases = projectDetails.data?.project_case_data?.filter(projectCase => {
            //here we have to find column name where type is equal to CASENAME
            if(projectCase?.isColumnType) {
                Object.keys(projectCase.column_type).forEach(key => {
                    if(projectCase.column_type[key] && projectCase.column_type[key].toLowerCase() === 'casename') {
                        simCaseNameColumnName = key
                    }
                })
            }
            else if(projectCase?.isColumnUnit);
            else return projectCase
        })
        let simCasesOptions = []
        simCaseNameColumnName && projectCases.forEach(projectCase => {
            simCasesOptions.push({label: projectCase[simCaseNameColumnName], value: projectCase._id})
        })
        setProjectCaseOptions(simCasesOptions)
    }, [projectDetails.data])

    const createCompareCaseObject = (existingCompareCases, selectedCaseId= '', plotTypeValue= '', isPlotTypeDisabled= true) => {
        let currentObjIndex = existingCompareCases.length
        return {
            index: currentObjIndex,
            value: selectedCaseId,
            plotTypeValue: plotTypeValue,
            isPlotTypeDisabled: isPlotTypeDisabled,
            handleChange: (value) => { 
                setCompareCases(compareCases => {
                    compareCases[currentObjIndex].value = value
                    return [...compareCases]
                })
            },
            handlePlotTypeChange: (plotTypeValue) => {
                setCompareCases(compareCases => {
                    compareCases[currentObjIndex].plotTypeValue = plotTypeValue
                    return [...compareCases]
                })
            }
        }
    }

    //Add new compare case
    const addNewCaseToCompare = () => {
        setCompareCases(existingCases => {
            existingCases[existingCases.length] = createCompareCaseObject(existingCases)
            return [...existingCases]
        })
    }

    //Remove compare case
    const removeCompareCase = (index) => {
        let updatedCompareCases = compareCases.filter(compareCase => index !== compareCase.index)
        setCompareCases(updatedCompareCases)
        setCompareCombination({
            type: "delete",
            index: index
        })
    }

    //Save compare combinations
    const saveCombination = () => {
        let compareCombinationSavedData = localStorage.getItem('compareCombination')
        let newCompareData = compareCombinationSavedData ? JSON.parse(compareCombinationSavedData) : {}
        newCompareData[projectId] = compareCombination
        localStorage.setItem('compareCombination', JSON.stringify(newCompareData))
        setToast({
            statusCode: 200,
            message: "Saved!"
        })
    }

    const addNewCaseToCompareAndScrollToBottom = () => {
        bottomOfPage.current?.scrollIntoView({behavior: "smooth"})
        addNewCaseToCompare()
    }
 
    const scrollToTop = () => window.scrollTo(topOfPage.current?.offsetTop - 100, 0)

    return { allSimCasesData, allSimCasesDocumentsDictionary, columnGroupsDictionary, compareDataType, compareActionOptions, simCasesOptions,
        compareCases, setCompareCases, topOfPage, bottomOfPage, scrollToTop, addNewCaseToCompareAndScrollToBottom, selectedCompareCaseIds, removeCompareCase, imageModal, setImageModal, 
        compareCombination, setCompareCombination, saveCombination }
}

const GetImageOrAnimationURL = (selectedCompareAction, caseId, plotTypeValue) => {
    switch(selectedCompareAction) {
        case compareDataType.Animations: 
            return GetSimcaseAnimation(caseId, plotTypeValue)
        case compareDataType.Images: 
            return GetSimcaseImage(caseId, plotTypeValue)
    }
}

const GetSimcaseRowMetaDataForGroupsComparision = (allSimCasesData, selectedCaseId) => {
    let simcaseGroupComparisionData = {}
    allSimCasesData?.forEach(simcase => {
        // eslint-disable-next-line no-prototype-builtins
        if(simcase.hasOwnProperty('isColumnUnit')) {
            simcaseGroupComparisionData["columnUnits"] = simcase.column_unit
        }
        // eslint-disable-next-line no-prototype-builtins
        else if (simcase.hasOwnProperty('isColumnType')) {
            simcaseGroupComparisionData["columnTypes"] = simcase.column_type
        }
        // eslint-disable-next-line no-prototype-builtins
        else if(simcase._id === selectedCaseId) {
            simcaseGroupComparisionData["simCaseRowData"] = simcase
        }
    })
    return { simcaseGroupComparisionData }
}

const SaveCompareCombinationToLocalStorage = (projectId, compareCombination) => {
    let compareCombinationSavedData = localStorage.getItem('compareCombination')
    let newCompareData = compareCombinationSavedData ? JSON.parse(compareCombinationSavedData) : {}
    newCompareData[projectId] = compareCombination
    localStorage.setItem('compareCombination', JSON.stringify(newCompareData))
}

export { GetSimCaseImagesList, GetProjectCompareDetailsData, GetImageOrAnimationURL, GetSimcaseRowMetaDataForGroupsComparision, SaveCompareCombinationToLocalStorage }