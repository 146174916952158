import { useContext, useRef, useEffect, forwardRef } from 'react';
import { Form, Table as BTable } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { useTable, useRowSelect, useSortBy } from 'react-table';
import { ArrowDown, ArrowUp, Check, X } from 'react-feather';
import { SelectOptions } from './FormFields';
import { QuantityControl, TextControl } from './FormControls';
import { UnitSystemContext, Utils } from '@tri/ui-shared';

const IndeterminateCheckbox = forwardRef((props, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  const { indeterminate, id, ...rest } = props;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <div className="custom-control custom-checkbox">
      <input
        type="checkbox"
        className="custom-control-input"
        ref={resolvedRef}
        {...rest}
        id={`ccb-${id}`}
      ></input>
      <label
        className="custom-control-label"
        ref={resolvedRef}
        {...rest}
        htmlFor={`ccb-${id}`}
      ></label>
    </div>
  );
});

const DownIcon = () => <ArrowDown size={12}></ArrowDown>;
const UpIcon = () => <ArrowUp size={12}></ArrowUp>;

export const FormattedDateCell = ({ value }) => {
  return Utils.formatDate(value);
};

export const BooleanCell = ({ value }) => {
  return value === true ? <Check size={16} /> : <X size={16} />;
};

export const QuantityCell = ({ row, column, tableName, disabled, onBlur }) => {
  const { qty, id, disabled: columnDisabled } = column;
  const { index } = row;
  const name = `${tableName}.${index}.${id}`;
  return (
    <QuantityControl
      name={name}
      qty={qty}
      disabled={disabled || columnDisabled}
      className="number-input-no-spinner"
      onBlur={onBlur}
    />
  );
};

export const TextCell = ({ row, column, value, tableName, disabled }) => {
  const { setFieldValue } = useFormikContext();
  const { id, disabled: columnDisabled } = column;
  const { index } = row;
  const name = `${tableName}.${index}.${id}`;
  return (
    <TextControl
      initialValue={value}
      disabled={columnDisabled || disabled}
      onBlur={(e) => {
        setFieldValue(name, e.target.value);
      }}
    />
  );
};

export const SelectCell = ({
  row,
  column,
  value,
  tableName,
  onChange = () => {},
}) => {
  const { setFieldValue } = useFormikContext();
  const { index } = row;
  const { id, options, disabled } = column;
  const name = `${tableName}.${index}.${id}`;
  return (
    <Form.Control
      as="select"
      value={value}
      onChange={(e) => {
        setFieldValue(name, e.target.value);
        onChange(e.target.value);
      }}
      disabled={disabled}
    >
      <SelectOptions options={options} />
    </Form.Control>
  );
};

// Create a default prop getter
const defaultPropGetter = () => ({});

export const Table = ({
  name: tableName,
  columns,
  data,
  defaultColumn,
  initialState = {},
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  tableClass = {},
  selectionEnabled = false,
  onSelect = () => {},
  disableSortBy = true,
  ...rest
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      tableName,
      initialState,
      disableSortBy,
      ...rest,
    },
    useSortBy,
    useRowSelect,
    (hooks) => {
      if (!selectionEnabled) return hooks;
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps, column }) => (
            <div>
              <IndeterminateCheckbox
                {...getToggleAllRowsSelectedProps()}
                disabled={column.disabled}
              />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row, column }) => (
            <div>
              <IndeterminateCheckbox
                id={row.id}
                {...row.getToggleRowSelectedProps()}
                disabled={column.disabled}
              />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  useEffect(() => {
    onSelect(selectedFlatRows);
  }, [selectedFlatRows]);

  return (
    <BTable {...getTableProps()} className={tableClass} size="sm">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps([
                  getColumnProps(column),
                  getHeaderProps(column),
                  column.getSortByToggleProps(),
                  {
                    className: column.className,
                    style: column.style,
                  },
                ])}
              >
                {column.render('Header')}
                {/* Add a sort direction indicator */}
                <span>
                  {column.canSort && column.isSorted ? (
                    column.isSortedDesc ? (
                      <DownIcon />
                    ) : (
                      <UpIcon />
                    )
                  ) : (
                    ''
                  )}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td
                    // Based on: https://react-table.tanstack.com/docs/examples/data-driven-classes-and-styles
                    // Return an array of prop objects and react-table will merge them appropriately
                    {...cell.getCellProps([
                      {
                        className: cell.column.className,
                        style: cell.column.style,
                      },
                      getColumnProps(cell.column),
                      getCellProps(cell),
                    ])}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </BTable>
  );
};

export const TableHeader = ({ column: { qty, title } }) => {
  const { displayUnitSystemId, allSystems } = useContext(UnitSystemContext);
  const displayUnit = qty
    ? allSystems[displayUnitSystemId].units[qty]
    : undefined;
  const unitLabel = displayUnit ? `[${displayUnit}]` : '';
  return (
    <div className="d-flex flex-column align-items-center">
      <div>{title}</div>
      <div>{unitLabel}</div>
    </div>
  );
};
