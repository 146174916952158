import { MyProjects, SharedProjects } from './sim-data-view';
import Pills from '../common/Pills';
import { useContext, useMemo } from 'react';
import { UserContext } from '@tri/ui-shared';
import { getActiveTabIndex } from '../utils';

const simulationTabs = (user) => {
  return user.is_superuser ? 
    [MyProjects()]
    : user.is_staff ? 
      [SharedProjects()] 
      : [ MyProjects(), SharedProjects()]
} 

const SimDataList = ({ projectType }) => {
  const { user } = useContext(UserContext);
  const tabs = useMemo(() => simulationTabs(user), [user])

  return <Pills tabs={tabs} activeTabIndex={getActiveTabIndex(tabs, projectType)} />
}

export default SimDataList;
