import { useQuery } from "react-query";
import { executeApiCall, useHeaderConfiguration } from "../../../utils"

//get list of sim case images
const useSimcaseImagesList = (simcaseIds) => {
    const config = useHeaderConfiguration()
    let useQueryKey = simcaseIds.toString().replace(/, /g,'');
    simcaseIds = simcaseIds.map(caseId => `c_ids=${caseId}`).join('&');
    return useQuery(`simcaseImages${useQueryKey}`, async () => {
        const { data } = await executeApiCall('get', `/api/v3/projects/sim_cases/results/images/?${simcaseIds}`, {}, config)
        return data
    })
}

const useGetSimcaseImageURL = (simCaseId, fileId) => {
    const config = useHeaderConfiguration();
    return useQuery(`simcaseImages${fileId}`, async () => {
        const { data } = await executeApiCall('get', `api/v3/projects/sim_cases/results/Images/${simCaseId}/${fileId}`, {}, config, true)
        return data
    })
}

const useSaveImageData = (simcaseId, image) => {
    const config = useHeaderConfiguration()
    let formData = new FormData();
    formData.append('filename', image.filename)
    formData.append('description', image.description)
    return executeApiCall('put', `/api/v3/projects/sim_cases/results/${simcaseId}/Images/${image.id}`, formData, config)
}

export { useSimcaseImagesList, useGetSimcaseImageURL, useSaveImageData }