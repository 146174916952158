import { Card } from 'react-bootstrap';
import { HorizontalFormField } from '../common/FormFields';
import {
  QuantityControl,
  SelectControl,
  CheckboxControl,
  Unit,
  TextControl,
} from '../common/FormControls';

const gasTypes = [
  { name: 'Air', molwt: 28.966 },
  { name: 'Acetylene', molwt: 26.04 },
  { name: 'Ammonia', molwt: 17.02 },
  { name: 'Argon', molwt: 39.948 },
  { name: 'Benzene', molwt: 78.11 },
  { name: 'N-Butane', molwt: 58.12 },
  { name: 'Iso-Butane', molwt: 58.12 },
  { name: 'Butadiene', molwt: 54.09 },
  { name: '1-Butene', molwt: 56.108 },
  { name: 'cis-2-Butene', molwt: 56.108 },
  { name: 'trans-2-Butene', molwt: 56.108 },
  { name: 'Isobutene', molwt: 56.108 },
  { name: 'Carbon Dioxide', molwt: 44.01 },
  { name: 'Carbon Disulphide', molwt: 76.13 },
  { name: 'Carbon Monoxide', molwt: 28.011 },
  { name: 'Chlorine', molwt: 70.906 },
  { name: 'Cyclohexane', molwt: 84.16 },
  { name: 'Deuterium', molwt: 2.04 },
  { name: 'Ethane', molwt: 30.07 },
  { name: 'Ethyl Alcohol', molwt: 46.07 },
  { name: 'Ethyl Chloride', molwt: 64.515 },
  { name: 'Ethylene', molwt: 28.054 },
  { name: 'Fluorine', molwt: 37.996 },
  { name: 'Helium', molwt: 4.02 },
  { name: 'N-Heptane', molwt: 100.02 },
  { name: 'Hexane', molwt: 86.17 },
  { name: 'Hydrochloric Acid', molwt: 36.47 },
  { name: 'Hydrogen', molwt: 2.016 },
  { name: 'Hydrogen Chloride', molwt: 36.461 },
  { name: 'Hydrogen Sulphide', molwt: 34.076 },
  { name: 'Hydroxyl', molwt: 17.01 },
  { name: 'Krypton', molwt: 83.8 },
  { name: 'Methane', molwt: 16.044 },
  { name: 'Methyl Alcohol', molwt: 32.04 },
  { name: 'Methyl Butane', molwt: 72.15 },
  { name: 'Methyl Chloride', molwt: 50.488 },
  { name: 'Natural Gas', molwt: 19 },
  { name: 'Neon', molwt: 20.179 },
  { name: 'Nitric Oxide', molwt: 30.006 },
  { name: 'Nitrogen', molwt: 28.0134 },
  { name: 'Nitrous Oxide', molwt: 44.012 },
  { name: 'N-Octane', molwt: 114.22 },
  { name: 'Oxygen', molwt: 31.9988 },
  { name: 'Ozone', molwt: 47.998 },
  { name: 'N-Pentane', molwt: 72.15 },
  { name: 'Iso-Pentane', molwt: 72.15 },
  { name: 'Propane', molwt: 44.097 },
  { name: 'Propylene', molwt: 42.08 },
  { name: 'R-11', molwt: 137.37 },
  { name: 'R-12', molwt: 120.92 },
  { name: 'R-22', molwt: 86.48 },
  { name: 'R-114', molwt: 170.93 },
  { name: 'R-123', molwt: 152.93 },
  { name: 'R-134a', molwt: 102.03 },
  { name: 'R-611', molwt: 60.05 },
  { name: 'Sulfur', molwt: 32.02 },
  { name: 'Sulfur Dioxide', molwt: 64.06 },
  { name: 'Sulfuric Oxide', molwt: 48.1 },
  { name: 'Toluene', molwt: 92.13 },
  { name: 'Xenon', molwt: 131.3 },
  { name: 'Steam', molwt: 18.02 },
];

const gasTypeOptions = gasTypes.map((o) => o.name);

const DispersedPhaseConditionsPanel = ({
  data,
  onChange,
  setFieldValue,
  disabled,
}) => {
  const dpData = data.process.dispersedPhase;
  const isGasEnabled = dpData.gas.enabled;
  const isSolidEnabled = dpData.solid.enabled;
  const isLiquidEnabled = dpData.liquid.enabled;
  const isRingSpargerPresent = data.config.internals.ringSpargers?.length > 0;
  const isDipTubesPresent = data.config.internals.dipTubes?.length > 0;

  const layoutProps = { w1: 6, w2: 4, rowClass: 'mb-1' };
  return (
    <div className="pl-0">
      <CheckboxControl
        name="process.dispersedPhase.gas.enabled"
        label="Enable Gas"
        disabled={disabled || (!isRingSpargerPresent && !isDipTubesPresent)}
      />

      {isGasEnabled && (
        <Card className="mt-2">
          <Card.Body className="width-32">
            <HorizontalFormField label="Type" {...layoutProps}>
              <SelectControl
                name="process.dispersedPhase.gas.material"
                options={gasTypeOptions}
                onChange={(e) => {
                  onChange(e);
                  const obj = gasTypes.find((o) => o.name === e.target.value);
                  setFieldValue(
                    'process.dispersedPhase.gas.molecularWeight',
                    obj.molwt
                  );
                }}
                disabled={disabled}
              />
            </HorizontalFormField>

            <HorizontalFormField label="Molecular Weight" {...layoutProps}>
              <QuantityControl
                name="process.dispersedPhase.gas.molecularWeight"
                disabled={disabled}
              />
              <Unit qty="molecularWeight" />
            </HorizontalFormField>

            <HorizontalFormField label="Volumetric Flow Rate" {...layoutProps}>
              <QuantityControl
                name="process.dispersedPhase.gas.volFlowRate"
                qty="volFlowRate"
                disabled={disabled}
              />
              <Unit qty="volFlowRate" />
            </HorizontalFormField>
          </Card.Body>
        </Card>
      )}

      <div className="mt-3">
        <CheckboxControl
          name="process.dispersedPhase.solid.enabled"
          label="Enable Solids"
          disabled={disabled}
        />

        {isSolidEnabled && (
          <Card className="mt-2">
            <Card.Body className="width-32">
              <HorizontalFormField label="Density" {...layoutProps}>
                <QuantityControl
                  name="process.dispersedPhase.solid.density"
                  qty="density"
                  disabled={disabled}
                />
                <Unit qty="density" />
              </HorizontalFormField>

              <HorizontalFormField label="Particle Size" {...layoutProps}>
                {/* Changed the component from <QuantityControl/> to <TextControl/> so that adding unit as 'microns' will not throw error. */}
                <TextControl
                  name="process.dispersedPhase.solid.particleSize"
                  qty="solidParticleSize"
                  disabled={disabled}
                />
                <Unit qty="solidParticleSize" />
              </HorizontalFormField>

              <HorizontalFormField label="Loading %" {...layoutProps}>
                <QuantityControl
                  name="process.dispersedPhase.solid.loading"
                  disabled={disabled}
                />
              </HorizontalFormField>
            </Card.Body>
          </Card>
        )}
      </div>

      <div className="mt-3">
        <CheckboxControl
          name="process.dispersedPhase.liquid.enabled"
          label="Enable Liquids"
          disabled={disabled}
        />

        {isLiquidEnabled && (
          <Card className="mt-2">
            <Card.Body className="width-32">
              <HorizontalFormField label="Density" {...layoutProps}>
                <QuantityControl
                  name="process.dispersedPhase.liquid.density"
                  qty="density"
                  disabled={disabled}
                />
                <Unit qty="density" />
              </HorizontalFormField>

              <HorizontalFormField label="Viscosity" {...layoutProps}>
                <QuantityControl
                  name="process.dispersedPhase.liquid.viscosity"
                  qty="viscosity"
                  disabled={disabled}
                />
                <Unit qty="viscosity" />
              </HorizontalFormField>

              <HorizontalFormField label="Surface Tension" {...layoutProps}>
                <QuantityControl
                  name="process.dispersedPhase.liquid.surfaceTension"
                  qty="surfaceTension"
                  disabled={disabled}
                />
                <Unit qty="surfaceTension" />
              </HorizontalFormField>
            </Card.Body>
          </Card>
        )}
      </div>
    </div>
  );
};

const DispersedPhaseConditions = {
  id: 'dispersed-phase-conditions',
  label: 'Dispersed Phase',
  container: 'process',
  Renderer: DispersedPhaseConditionsPanel,
  tabEnabled: (activeNodeType) => activeNodeType === 'process',
};

export default DispersedPhaseConditions;
