// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Dialog } from '@tri/ui-shared';
import axios from 'axios';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { Col, Form, Row } from 'react-bootstrap';
import { useContext, useState } from 'react';
import { getCookie } from '../../utils';
import ToastContext from '../../context';

const NewScriptDialog = ({
  onHide,
  onApply,
  selectedAssetId,
  geometryId,
  editScriptFlag,
  selectedScriptData,
}) => {
  const [scriptFile, setScriptFile] = useState(undefined);
  const setToast = useContext(ToastContext)
  
  let initialValues = {
    name: '',
    description: '',
    // scriptFile: undefined,
    scriptFileName: '',
  };
  
  let title = 'Add New Script';
  if (editScriptFlag) {
    initialValues = {
      name: selectedScriptData[0].values.name,
      description: selectedScriptData[0].values.description,
      scriptFileName: selectedScriptData[0].values.file_name,
    };
    title = 'Edit Script';
  }

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is Required'),
    description: Yup.string().required('Description is Required'),
    // scriptFile: Yup.mixed().required('File is Required'),
    scriptFileName: Yup.string().required('File is Required'),
  });

  const submitAssetData = async (values) => {
    let fd = new FormData();
    const data = {
      name: values.name,
      description: values.description,
      asset: { id: selectedAssetId, geometry: { id: geometryId } },
      file_name: values.scriptFileName,
    };
    const finalData = JSON.stringify(data);
    fd.append('data', finalData);
    if (scriptFile) fd.append('script_file', scriptFile);
    try {
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-CSRF-TOKEN': getCookie('csrf_token')
        },
      }
      if (editScriptFlag) {
        await axios.put(`/api/script/${selectedScriptData[0].values.id}`, fd, config);
      } else {
        await axios.post('/api/script/', fd, config);
      }
      onApply();
      onHide();
    } catch (err) {
      setToast({
        statusCode: 500,
        message: err
      }) 
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={submitAssetData}
    >
      {({
        handleSubmit,
        values,
        setFieldValue,
        handleChange,
        errors,
        touched,
        isValidating,
      }) => (
        <Dialog show={true} title={title} onHide={onHide} onOk={handleSubmit}>
          <Form>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="3">
                Name
              </Form.Label>
              <Col sm="9">
                <Field
                  type="text"
                  name="name"
                  placeholder="Enter Name"
                  value={values.name}
                  onChange={handleChange}
                  className="form-control"
                />

                {errors.name && touched.name ? (
                  <div className="text-danger" style={{ fontSize: '12px' }}>
                    {errors.name}
                  </div>
                ) : null}
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="3">
                Description
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="description"
                  placeholder="Enter Description"
                  value={values.description}
                  onChange={handleChange}
                />
                {errors.description && touched.description ? (
                  <div className="text-danger" style={{ fontSize: '12px' }}>
                    {errors.description}
                  </div>
                ) : null}
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="3">
                Import Script
              </Form.Label>
              <Col cm="9">
                <Form.Control
                  type="file"
                  custom
                  id="file"
                  name="scriptFileName"
                  accept=".py"
                  title={values.scriptFileName || 'Select File'}
                  onChange={(e) => {
                    setScriptFile(e.currentTarget.files[0]);
                    setFieldValue(
                      'scriptFileName',
                      e.currentTarget.files[0].name
                    );
                  }}
                />
                {errors.scriptFileName && touched.scriptFileName ? (
                  <div className="text-danger" style={{ fontSize: '12px' }}>
                    {errors.scriptFileName}
                  </div>
                ) : null}
              </Col>
            </Form.Group>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

export default NewScriptDialog;
