import { useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import UserContext from './UserContext';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const PageDoesNotExist = (props) => {
  return (
    <div className="alert alert-danger" role="alert">
      {`Route does not exist`}
    </div>
  );
};

const App = ({
  LandingPage = PageDoesNotExist,
  SignupPage = PageDoesNotExist,
  renderSigninPage,
  AppPage = PageDoesNotExist,
  VerifyAccountPage = PageDoesNotExist,
  SentResetLinkPage = PageDoesNotExist,
  ResetPasswordPage = PageDoesNotExist,
}) => {
  const [user, setUser] = useState();
  return (
    <UserContext.Provider value={{ user, setUser }}>
      <Router>
        <Switch>
          <Route
            exact
            path="/auth/signin"
            render={(routeProps) => {
              const { activeView, activeSubView, activeSubSubView } =
                routeProps.location.state || {};
              return renderSigninPage({
                onSignin: setUser,
                activeView,
                activeSubView,
                activeSubSubView,
              });
            }}
          ></Route>

          <Route exact path="/auth/signup">
            <SignupPage onSignup={setUser} />
          </Route>

          <Route
            path="/app/:viewPath*"
            render={(routeProps) => {
              const viewPath = routeProps.match.params.viewPath;
              const [activeView, activeSubView, activeSubSubView] =
                viewPath?.split('/') || [];
              if (!user)
                return (
                  <Redirect
                    to={{
                      pathname: '/auth/signin',
                      state: { activeView, activeSubView, activeSubSubView },
                    }}
                  />
                );

              return (
                <QueryClientProvider client={queryClient}>
                  <AppPage
                    activeView={activeView}
                    activeSubView={activeSubView}
                    activeSubSubView={activeSubSubView}
                  ></AppPage>
                </QueryClientProvider>
              );
            }}
          />

          <Route
            exact
            path="/"
            render={(routeProps) => {
              const searchString = routeProps.location.search;
              const searchParams = new URLSearchParams(searchString);
              const initViewPath = searchParams.get('initViewPath');
              if (initViewPath) {
                if (
                  initViewPath.startsWith('database') ||
                  initViewPath.startsWith('jobs') ||
                  initViewPath.startsWith('impellers') ||
                  initViewPath.startsWith('admin')
                ) {
                  // When user opens a shared object by clicking its link in email or by entering its address in browser
                  const [activeView, activeSubView, activeSubSubView] =
                    initViewPath?.split('/') || [];
                  return (
                    <Redirect
                      to={{
                        pathname: '/auth/signin',
                        state: { activeView, activeSubView, activeSubSubView },
                      }}
                    />
                  );
                } else if (initViewPath.startsWith('/reset-password/')) {
                  const token = initViewPath.substring(
                    '/reset-password/'.length
                  );
                  return <ResetPasswordPage token={token}></ResetPasswordPage>;
                }
              } else {
                return <LandingPage></LandingPage>;
              }
            }}
          ></Route>

          <Route exact path="/verify-account">
            <VerifyAccountPage></VerifyAccountPage>
          </Route>

          <Route exact path="/sent-reset-link">
            <SentResetLinkPage></SentResetLinkPage>
          </Route>

          <Route
            path="/reset-password/:resetPasswordToken"
            render={(routeProps) => {
              const token = routeProps.match.params.resetPasswordToken;
              return <ResetPasswordPage token={token}></ResetPasswordPage>;
            }}
          />
        </Switch>
      </Router>
    </UserContext.Provider>
  );
};

export default App;
