import { useMemo, useState } from 'react';
import Pills from '../common/Pills';
import { getActiveTabIndex } from '../utils';
import { SimulationDetails, AssetDetails, Compare } from './project-data-view'
import { Geometry, Grouping, Images, Animations, Documents } from './sim-case-view'

const projectTabs = (projectId, activeSubSubView, selectedSimcase, setSelectedSimcase) => {
  return activeSubSubView ? [
    Geometry(activeSubSubView),
    Grouping(projectId, selectedSimcase),
    Images(activeSubSubView),
    Animations(activeSubSubView),
    Documents(activeSubSubView)
  ] : [
    SimulationDetails(projectId, setSelectedSimcase),
    AssetDetails(projectId),
    Compare(projectId)
  ]
}

const SimData = ({ simDataId, activeSubSubView, projectActiveTabName }) => {
  const [selectedSimcase, setSelectedSimcase] = useState(null)

  const tabs = useMemo(() => {
     return projectTabs(simDataId, activeSubSubView, selectedSimcase, setSelectedSimcase)
  }, [simDataId, activeSubSubView, selectedSimcase])

  return <Pills tabs={tabs} activeTabIndex={getActiveTabIndex(tabs, projectActiveTabName)}/>
}

export default SimData;
