import { useState, useMemo } from 'react';
import { useAddNewParameter, useDeleteParams, useGetParams, useUpdateParameter } from '../data-service/parametertable.dataservice';
import { useContext } from 'react';
import ToastContext from '../../../context';
import { useQueryClient } from 'react-query';
import { parameterTableType, parameterTableKey } from '../../../utils/constants';

const GetParameterDetailsData = (Id, type = parameterTableType.asset_param) => {

  const setToast = useContext(ToastContext)
  const queryClient = useQueryClient()
  const paramData = useGetParams(Id, type)
  const [isTableLock, setIsTableLock] = useState(true)
  const [isAddingNewParam, setIsAddingNewParam] = useState(false)
  const [selectedParams, setSelectedParams] = useState([])
  const lockUnlockToastId = "parameter-table"

  const paramDetails = useMemo(() => {
    if(paramData.status === 'success') {
      return type === parameterTableType.asset_param ? paramData.data?.param_data : paramData.data
    }
    return []
  }, [paramData])

  const filteredParamTableData = useMemo(() => {
    let tempParamData = paramDetails === undefined || Object.keys(paramDetails).length <= 0 ? [] : paramDetails
    if(isTableLock) {
      return tempParamData.filter(param => param.g_para_id !== 1)
    }
    else if(isAddingNewParam && tempParamData.every(param => param.g_para_id !== 1)) {
      tempParamData.push({
        "g_para_id": 1,
        "parameter": "",
        "value": "",
        "unit": "",
        "disableRowSelection": true
      })
    }
    return tempParamData
  }, [paramDetails, isAddingNewParam, isTableLock])

  const DeleteParams = async () => {
      const confirm = window.confirm('Please confirm to proceed with deletion..');
      if (!confirm) return

      let selectedParamIds = []
      selectedParams.forEach(param => {
        selectedParamIds.push(param.values?.g_para_id)
      })

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const deleteParamsResponse = await useDeleteParams(selectedParamIds, type, Id)
      if([200, 201].includes(deleteParamsResponse.statusCode)) {
        queryClient.invalidateQueries(parameterTableKey(Id))
        selectedParamIds.length === filteredParamTableData.length && setSelectedParams([])
      }
      else {
        setToast({
          statusCode: deleteParamsResponse.statusCode,
          message: deleteParamsResponse.message
        })
      }
  }

  const EditableCell = ({
    value: initialValue,
    row: { original },
    column: { id: columnName },
    updateMyData,
    paramTableData= filteredParamTableData
  }) => {
    const [value, setValue] = useState(initialValue)

    const onChange = e => {
        let cellValue = e.target.value
        setValue(cellValue)
    }

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        if(initialValue !== value) {
          //this is for adding new parameter 
          if(original.g_para_id === 1) {
            let lastRowIndex = paramTableData.length - 1
            paramTableData[lastRowIndex][columnName] = value
            let newParam = paramTableData[lastRowIndex]
            if(newParam.parameter.trim() !== '' && newParam.unit.trim() !== '' && newParam.value.trim() !== '') {
              addNewParam(newParam)
            }
          }
          else {
            updateMyData(original.g_para_id, columnName.toLowerCase(), value)
          }
        }
    }
    return original.g_para_id === 1 ? <input type="text" onChange={onChange} onBlur={onBlur} value={value}/> :
    <input type="text" onChange={onChange} onBlur={onBlur} value={value ?? ''} style={{border: 0, width: '100%', margin: 0, padding: 0, background: 'transparent'}}/> 
  }

  const addNewParam = async (parameterValues) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const addParamResponse = await useAddNewParameter(Id, parameterValues, type)
    if([200, 201].includes(addParamResponse.statusCode)) {
      queryClient.invalidateQueries(parameterTableKey(Id))
      setIsAddingNewParam(false)
    }
    else {
      setToast({
          statusCode: addParamResponse.statusCode,
          message: addParamResponse.message
      })
    }
  }

  const updateMyData = async (paramId, columnId, value) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const updateParamResponse = await useUpdateParameter(paramId, columnId, value, type)
    if([200, 201].includes(updateParamResponse.statusCode)) {
      queryClient.invalidateQueries(parameterTableKey(Id))
    }
    else {
      setToast({
        statusCode: updateParamResponse.statusCode,
        message: updateParamResponse.message
      })
    }
  }

  const listOfAssetsParamTableColumn = useMemo(() => [
      {
        Header: 'Id',
        accessor: 'g_para_id',
        disableGlobalFilter: true,
      },
      {
        Header: 'Parameter',
        accessor: 'parameter',
      },
      {
        Header: 'Value',
        accessor: 'value',
      },
      {
        Header: 'Unit',
        accessor: 'unit',
      }
  ])

  // Set our editable cell renderer as the default Cell renderer
  const defaultColumns = useMemo(() => {
    !isTableLock && setToast({
        id: lockUnlockToastId,
        removeDuplicate: true,
        statusCode: 300,
        message: "Any modification after the unlock the parameter details table, that will directly save into database"
    })
    return isTableLock ? {} : {
        Cell: EditableCell,
    }
  }, [isTableLock])

  return { selectedParams, setSelectedParams, DeleteParams, filteredParamTableData, listOfAssetsParamTableColumn, defaultColumns, isTableLock, setIsTableLock, setIsAddingNewParam, updateMyData }
}

export { GetParameterDetailsData }