import { NavDropdown } from 'react-bootstrap';

const DropdownMenu = ({title, dropdownMenus, ...rest}) => {
  return (
    <NavDropdown
      title={title ?? ''}
      id="basic-nav-dropdown"
      align="start"
      {...rest}
    >
      {
        dropdownMenus.map((item, index) => <NavDropdown.Item
            key={index}
            active={item.isActive}
            onClick={item.onSelect}
          >
            {item.title}
          </NavDropdown.Item>)
      }
    </NavDropdown>
  )
}

export default DropdownMenu;
