import { Formik } from 'formik';
import { Form, Col, Row, Collapse } from 'react-bootstrap';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { RadioGroupField2 } from 'apps/mixit/src/app/common/FormFields';
import { useProjectFormikData, useProjectDetailsData } from '../services/business-logic/addorupdateproject.businesslogic';
import RSDropdown from '../../common/RSDropdown';
import ToolbarButton from '../../common/ToolbarButton';
import _ from 'lodash';
import MandatorySymbol from '../../common/MandatorySymbol';
import { RBOffcanvas } from '../../common/RBOffcanvas';
import { useEffect, useState } from 'react';
import NewAssetDialog from '../../asset-explorer/NewAsset';
import { useQueryClient } from 'react-query';

function AddOrUpdateSimulationProject({user, selectedProject, isSharedProject = false}) {
    const { initialValues, setInitialValues, projectValidationSchema, isEditMode } = useProjectFormikData(user, selectedProject)
    const { dialogueFlag, setDialogueFlag, setToast, fileDataActionMode, selectedSharedUsers, setSelectedSharedUsers, 
        userListOptions, selectedAsset, setSelectedAsset, assetListOptions, addOrUpdateProject, deleteProjects,
        isCreateProjectInstructionOpen, setIsCreateProjectInstructionOpen
    } = useProjectDetailsData(user, initialValues, setInitialValues, selectedProject)

    const [openCreateAssetDialog, setOpenCreateAssetDialog] = useState(false)
    const queryClient = useQueryClient();

    useEffect(() => {
        selectedAsset?.value === 'link_new_asset' && setOpenCreateAssetDialog(true)
    }, [selectedAsset?.value])

    return !user.is_staff &&  <>
        <div style={{ width: '12rem', marginBottom: '16px' }}>
            {
                !isSharedProject && selectedProject.length === 0 && 
                <ToolbarButton iconType='add' title="Add New Project" onClickAction={() => setDialogueFlag(true)}/>
            }
            {
                isEditMode && 
                <ToolbarButton iconType='edit' title="Edit Selected Project" onClickAction={() => setDialogueFlag(true)} buttomWithSpinner={true}/>
            }
            {
                selectedProject.length > 0 && 
                <ToolbarButton iconType='delete' title="Delete Selected Project" onClickAction={deleteProjects.mutateAsync} buttomWithSpinner={true}/>
            }
        </div>
        {
            dialogueFlag && <Formik
                initialValues={initialValues}
                validationSchema={projectValidationSchema}
                onSubmit={addOrUpdateProject.mutateAsync}
            > 
            {({values, errors, handleChange, handleSubmit, setFieldValue, isSubmitting}) => <>
                <RBOffcanvas 
                    show={dialogueFlag} 
                    onHide={() => setDialogueFlag(false)}
                    title={selectedProject.length !== 1 ? "Add New Project" : "Edit Project"}
                    onOk={handleSubmit}
                    isOkBtnDisabled={_.isEqual(values, initialValues) || isSubmitting}
                >
                <Form>
                    <Form.Group as={Row} className="mb-3" >
                    <Form.Label column sm="3">
                        Input Data File{
                            !isEditMode && <MandatorySymbol />
                        } 
                    </Form.Label>
                    <Col sm="9">
                        <Form.Control
                            type="file"
                            custom
                            accept=".csv"
                            excludeAcceptAllOption={true}
                            title={values.projectFile?.name || 'Select .csv file'}
                            onChange={(event) => { 
                                let file = event.currentTarget.files[0]
                                if (file === undefined || file.name.match(/.csv$/) === null) {
                                setToast({
                                    statusCode: 500,
                                    message: "Please select .csv file only"
                                })
                                return;
                                }
                                setFieldValue('projectFile', file);
                            }}
                            isInvalid={errors.projectFile}
                            aria-describedby="downloadTemplateFile"
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.projectFile}
                        </Form.Control.Feedback>
                        <Form.Text id="downloadTemplateFile"> 
                            <a style={{color: 'blue'}} href="./assets/Sim_3_0_Template.xlsx" download={true}>Download a project template</a>
                            <br/><a style={{color: 'blue'}} href="./assets/Geometry_Parameter.csv" download={true}>Download a geometry data template</a>
                        </Form.Text>
                    </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Col>
                            <Form.Text style={{fontSize: '87%'}}>
                            <span style={{color: '#e93535cc'}}> Instructions: </span>
                            <span className='text-link' style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => setIsCreateProjectInstructionOpen(!isCreateProjectInstructionOpen)} aria-controls="create-project-instruction" aria-expanded={isCreateProjectInstructionOpen}>
                                Please check the following things before you upload the files.
                            </span>
                            <Collapse in={isCreateProjectInstructionOpen}>
                                <div id="create-project-instruction">
                                    <Form.Text as="p" muted>
                                        {
                                            isEditMode && values.fileDataAction === fileDataActionMode.append &&
                                            <li>
                                                <span style={{color: '#e93535cc'}}>For append: </span>
                                                <ul style={{listStyleType: 'circle'}}>
                                                    <li>Make sure the no of columns in .csv and simulation details should be same</li>
                                                    <li>Column header names in .csv should exactly match with that in simulation details</li>
                                                </ul>
                                            </li>
                                        }
                                        <li>
                                        { 
                                            isEditMode && <span style={{color: '#e93535cc'}}>For overwrite: </span>
                                        }
                                        Make sure .csv file follows a simsight template
                                        </li> 
                                        <li>Make sure geometry parameter .csv file follows a geometry data template</li>
                                        <li>Geometry parameter .csv file & .stl file should have the name same as that of case name</li>
                                    </Form.Text>
                                </div>
                            </Collapse>   
                            </Form.Text>                            
                        </Col>
                    </Form.Group>
                    {
                    isEditMode && values.projectFile && <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="3">
                        File Action
                        </Form.Label>
                        <Col sm="9">
                        <RadioGroupField2
                            w1={9}
                            w2={3}
                            onChange={(actionType) => setFieldValue("fileDataAction", actionType)}
                            options={[
                            { name: 'Append Data', value: fileDataActionMode.append },
                            { name: 'Overwrite Data', value: fileDataActionMode.overwrite },
                            ]}
                            value={values.fileDataAction}
                            className="mb-2"
                        />
                        </Col>
                    </Form.Group>
                    }
                    <Form.Group as={Row} className="mb-3" >
                    <Form.Label column sm="3">
                        Project Name<MandatorySymbol />
                    </Form.Label>
                    <Col sm="9">
                        <Form.Control
                        name="projectName"
                        value={values.projectName}
                        onChange={handleChange}
                        isInvalid={errors.projectName}
                        />
                        <Form.Control.Feedback type="invalid">
                        {errors.projectName}
                        </Form.Control.Feedback>
                    </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                    <Form.Label column sm="3">
                        Project Owner
                    </Form.Label>
                    <Col sm="9">
                        <Form.Control
                        name="projectOwner"
                        value={values.projectOwner}
                        disabled={true}
                        />
                    </Col>
                    </Form.Group>
                    
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="3">
                        Mapped Asset
                        </Form.Label>
                        <Col sm="9">
                        <RSDropdown
                            placeholder="Search"
                            value={selectedAsset}
                            options={assetListOptions}
                            handleChange={(filterSelectedItem) => { 
                                setSelectedAsset(filterSelectedItem)
                                setFieldValue('mappedAssets', filterSelectedItem.value);
                            }}
                        />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="3">
                        Shared With
                        </Form.Label>
                        <Col sm="9">
                        <RSDropdown
                            placeholder="Search"
                            value={selectedSharedUsers}
                            options={userListOptions}
                            handleChange={(filterSelectedItems) => {
                                setSelectedSharedUsers(filterSelectedItems)
                                let sharedUsers = []
                                filterSelectedItems.forEach(sharedUser => {
                                    sharedUsers.push(sharedUser.value)
                                })
                                setFieldValue('sharedUsers', sharedUsers)
                            }} 
                            isMulti={true}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                    <Form.Label column sm="3">
                        Comments/Notes
                    </Form.Label>
                    <Col sm={9}>
                        <Form.Control
                        as="textarea"
                        rows={3}
                        name="comment"
                        value={values.comment}
                        onChange={handleChange}
                        />
                    </Col>
                    </Form.Group>
                </Form>
                </RBOffcanvas>
                {
                    (selectedAsset?.value === 'link_new_asset' && openCreateAssetDialog) && (
                       <NewAssetDialog
                         linkAsset={true}
                         assetData={null}
                         onHide={() => {
                           setOpenCreateAssetDialog(false);
                           let resetSelectedOption = {
                               label: "None",
                               value: ''
                             }
                           setSelectedAsset(resetSelectedOption)
                           setFieldValue('mappedAssets', resetSelectedOption.value);
                         }}
                         onApply={(asset) => {
                            queryClient.invalidateQueries('allAssetsList');
                            queryClient.invalidateQueries('assets');
                            setSelectedAsset(asset)
                            setFieldValue('mappedAssets', asset.value);
                            setOpenCreateAssetDialog(false);
                         }}
                       />
                     )
               }
               </>
            }           
            </Formik> 
        }
    </>
}

export { AddOrUpdateSimulationProject }
