import { UserContext } from '@tri/ui-shared';
import { useContext } from 'react';
import ToolbarButton from '../common/ToolbarButton';
import { parameterTableType } from '../utils/constants';
import { Table } from './../common/SimsightTable';
import { GetParameterDetailsData } from './services/business-logic/parametertable.businesslogic';

//Id props is depends on what type of parameter table going to be render
const ParameterTable = ({ Id, type = parameterTableType.asset_param, readOnly = false }) => {
  const { user } = useContext(UserContext);
  const { selectedParams, setSelectedParams, DeleteParams, filteredParamTableData, listOfAssetsParamTableColumn, defaultColumns, isTableLock, setIsTableLock, setIsAddingNewParam, updateMyData } = GetParameterDetailsData(Id, type)
  
  return <div style={{minWidth: '320px'}}>
    {
        !user.is_staff && !readOnly && <div style={{ marginBottom: '1em' }}>
            <ToolbarButton
                title="Add parameter"
                onClickAction={() => {setIsTableLock(false);setIsAddingNewParam(true)}}
                iconType="add"
            />
            {
                selectedParams.length > 0 && <ToolbarButton
                    title="Delete parameter"
                    onClickAction={DeleteParams}
                    iconType="delete"
                />
            }
            { 
                filteredParamTableData?.length > 0 && <ToolbarButton
                    title="Lock/Unlock"
                    onClickAction={() => setIsTableLock((isTableLock) => !isTableLock)}
                    iconType={isTableLock ? "lock" : "unlock"}
                />
            }
        </div>
    }
    {
        filteredParamTableData?.length > 0 ?
        <Table
            name="parameterTable"
            defaultColumn={defaultColumns}
            data={filteredParamTableData}
            initialState={{ hiddenColumns: ['g_para_id'] }}
            columns={listOfAssetsParamTableColumn}
            showGlobalFilter={false}
            selectionEnabled={!readOnly}
            onSelect={setSelectedParams} 
            updateMyData={updateMyData}
        /> : <h5>No parameters found</h5>
    }
  </div>
}

export { ParameterTable };
