import { useEffect, useState } from 'react';
import ModelViewer from './ModelViewer';
import {
  getTransformTankHead,
  getTransformTankMiddle,
  getTransformTankBottom,
  getTransformImpellers,
  getTransformShafts,
  getTransformBaffles,
  getTransformRingSpargers,
  getTransformDipTubes,
  getTransformHelicalCoils,
  getTransformLiquidLevel,
  getTransformTracer,
  getTransformSpeciesMonitors,
  getTransformHeatTransferMonitors,
} from './reactorGeometry.js';

function ReactorViewer({ reactor }) {
  const [transforms, setTransforms] = useState([]);

  useEffect(() => {
    if (reactor) {
      setTransforms(
        [
          getTransformTankHead(reactor),
          getTransformTankMiddle(reactor),
          getTransformTankBottom(reactor),
          getTransformBaffles(reactor),
          getTransformImpellers(reactor),
          getTransformShafts(reactor),
          getTransformRingSpargers(reactor),
          getTransformDipTubes(reactor),
          getTransformHelicalCoils(reactor),
          getTransformLiquidLevel(reactor),
          getTransformTracer(reactor),
          getTransformSpeciesMonitors(reactor),
          getTransformHeatTransferMonitors(reactor),
        ].filter((t) => t.geometry.length > 0)
      );
    } else {
      setTransforms([]);
    }
    return () => {
      setTransforms([]);
    };
  }, [reactor]);

  return <ModelViewer transforms={transforms}></ModelViewer>;
}

export default ReactorViewer;
