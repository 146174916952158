import { Row, Col, Card } from 'react-bootstrap';
import { pillTabs } from '../../utils/constants';
import { useSimCaseImagesData } from '../services/business-logic/images.businesslogic';
import { ImageCardView, ImageModalView } from '../../common/ImageCardView';
import loadingGif from '../../../assets/loading.gif'

const ImagesPanel = ({simcaseId}) => {
  const { imageModal, setImageModal, isGettingImages, images, SaveImageData, handleDataChange, handleIsDisabled } = useSimCaseImagesData(simcaseId)
  
  return isGettingImages ? <center className="mt-5"><img alt="Loading..." src={loadingGif} height="50px"/></center> :
    <Row xs={1} md={4} className="g-4">
    { 
      images.length > 0 ? 
        images.map((image, index) => <Col id={index}>
        <ImageCardView simCaseId={simcaseId} image={image} SaveImageData={SaveImageData} handleDataChange={handleDataChange} handleIsDisabled={handleIsDisabled} setImageModal={setImageModal} />
      </Col>) : <Col><h5> Images not Found</h5></Col>
    }
    <ImageModalView imageModal={imageModal} setImageModal={setImageModal} />
  </Row>
}
  
const Images = (simcaseId) => ({
  label: pillTabs.Images,
  Panel: <ImagesPanel simcaseId={simcaseId}/>
})
  
export { Images }   