// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Dialog} from '@tri/ui-shared';
import axios from 'axios';
import about from '../../assets/About_simsight.svg';
import { useQuery } from 'react-query';

const AboutMenu = ({ onHide }) => {
  let version1 = ''
  let date = ''
  let timeStamp = ''
  let buildDate1 = ''
  const getBuildInfoQuery = useQuery('build-info', async () => {
    const {data} = await axios.get(`/api/build-info/`);
    return{data};
  });

  console.log('getBuildInfoQuery---',getBuildInfoQuery.data);
  if(getBuildInfoQuery.data){
    console.log('test', getBuildInfoQuery.data.data.build_data)
    version1 = getBuildInfoQuery.data.data.build_data.version
    date = getBuildInfoQuery.data.data.build_data.date[0]
    timeStamp = getBuildInfoQuery.data.data.build_data.date[1]
    buildDate1 = date + ' ' +  timeStamp
  }

  return (
    <Dialog
      show={true}
      title="About SimSight"
      showOk={false}
      cancelLabel="Close"
      onHide={onHide}
      dialogClassName="w-90"
    >
      <div className="container">
        <div className="mx-auto mb-3 w-50">
          <img src={about} className="w-100" />
        </div>
        
        <p>Version: {version1}</p>

        <p>Build Date: {buildDate1}</p>

        <p>
          <a
            href="/api/files/system/help/Copyright_Notices.pdf"
            target="_blank"
          >
            Copyright Notices
          </a>
        </p>
      </div>
    </Dialog>
  );
};

export default AboutMenu;
