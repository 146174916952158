// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  ButtonWithSpinner,
  CenteredSpinner,
  UserContext,
} from '@tri/ui-shared';
import axios from 'axios';
import { useContext, useState } from 'react';
import { Breadcrumb, Button } from 'react-bootstrap';
import { Plus, Trash2, Edit2 } from 'react-feather';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Table } from '../common/SimsightTable';
import { executeApiCall, getCookie, sortTableColumn } from '../utils';
import NewAssetDialog from './NewAsset';
import ToastContext from '../context';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

const LinkCell = ({ value, onClickLink, row }) => {
  return (
    <span
      onClick={(e) => {
        //row.values['id'] = row.values['_id'];
        onClickLink(row.values);
      }}
      className="text-primary text-link"
    >
      {value}
    </span>
  );
};

const columns = [
  {
    Header: 'Id',
    accessor: '_id',
  },
  {
    Header: 'Asset',
    accessor: 'asset_name',
    sortType: sortTableColumn,
    Cell: LinkCell,
    style: {
      cursor: 'pointer',
    },
  },
  {
    Header: 'Model Name',
    sortType: sortTableColumn,
    accessor: 'model_name',
  },
  {
    Header: 'Scale',
    sortType: sortTableColumn,
    accessor: 'scale',
  },
  {
    Header: 'Vendor',
    sortType: sortTableColumn,
    accessor: 'vendor',
  },
  {
    Header: 'Comments/Notes',
    sortType: sortTableColumn,
    accessor: 'comments',
  },
];

const AssetList = ({ onNew, onClickLink }) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { user } = useContext(UserContext);
  const setToast = useContext(ToastContext)

  const [selectedAsset, setselectedAsset] = useState([]);
  const [openAddNewAssetDialogFlag, setOpenAddNewAssetDialogFlag] =
    useState(false);

  const config = {
    'X-CSRF-TOKEN': getCookie('csrf_token')
  };
  //Get Asset List
  const query = useQuery('assets', async () => {
    const { data } = await axios.get('/api/v3/assets/', config);
    const tableData = [];
    for (const key in data.data) {
      data.data[key]['id'] = data.data[key]['_id'];
      tableData.push(data.data[key]);
    }
    return { data: tableData };
  });

  const deleteSelectedAsset = useMutation(
    async () => {
      const confirm = window.confirm(
        'Please confirm to proceed with deletion..'
      );
      if (confirm) {
        let toastId = toast.loading(`Deleting assets is in under process`)  
        try {
          let asset_lists = {}
          selectedAsset.forEach(asset => {
            let assetId = asset?.original?._id
            asset_lists[assetId] = asset.original.asset_name
          })

          let asset_list_details = {
            "asset_lists": Object.keys(asset_lists)
          }
          
          let assetDeleteResponse = await executeApiCall('delete', `/api/v3/assets/`, asset_list_details, { headers: config})
          if(assetDeleteResponse.statusCode !== 200) {
            let finalMessage = ''
            let failedDeleteAssets = ''
            assetDeleteResponse?.data?.failedDeleteAssets?.forEach(failedAssetId => {
              failedDeleteAssets += (failedDeleteAssets ? ', ': '') + asset_lists?.[failedAssetId]
            })

            if(failedDeleteAssets) {
              finalMessage = `These ${failedDeleteAssets} assets are failed to delete`
            }

            let mappedAssetToProject = ''
            const mappedAssetToProjectData = assetDeleteResponse?.data?.mappedAssetToProject
            Object.keys(mappedAssetToProjectData).forEach(assetId => {
              mappedAssetToProject += (mappedAssetToProject ? ', ': '') + asset_lists?.[assetId] + ` is mapped to ${mappedAssetToProjectData[assetId]}`
            })

            if(mappedAssetToProject) {
              finalMessage += (finalMessage ? 'and ': '') + ` ${mappedAssetToProject}. Please unmap first and retry to delete these assets.`
            }

            const isAllAssetDeletionIntrupt = selectedAsset.length === (assetDeleteResponse?.data?.failedDeleteAssets?.length + Object.keys(mappedAssetToProjectData).length)
            
            finalMessage && setToast({
              id: isAllAssetDeletionIntrupt ? toastId : '',
              statusCode: 199, 
              message: finalMessage
            }) 

            if(!isAllAssetDeletionIntrupt) {
              setToast({
                id: toastId,
                statusCode: assetDeleteResponse.statusCode,
                message: finalMessage ? ' Rest all the asset deleted successfully ': 'All assets deleted successfully'
              }) 
            }            
          }
          else {
            setToast({
              id: toastId,
              statusCode: assetDeleteResponse.statusCode,
              message: assetDeleteResponse.message
            }) 
          }
        }
        catch (error) {
          setToast({
            id: toastId,
            statusCode: 500,
            message: "Failed to delete assets"
          })
        }
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('assets');
        queryClient.invalidateQueries('allAssetsList');
        setselectedAsset([])
      },
    }
  );

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item
          onClick={() => {
            history.push('/app/home');
          }}
          active={false}
        >
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item active={true}>Asset Explorer</Breadcrumb.Item>
      </Breadcrumb>
      <div className="px-3">
        <div style={{ marginBottom: '17px', height: '47px' }}>
          {(user.is_superuser || user.is_poweruser) && (
            <div>
              {
                selectedAsset.length === 0 && <Button
                  size="sm"
                  variant="outline-primary"
                  className="mr-1"
                  title="Add new asset"
                  onClick={() => {
                    setOpenAddNewAssetDialogFlag(true);
                  }}
                >
                  <Plus size={20} />
                </Button>
              }
              {selectedAsset.length === 1 && (
                <ButtonWithSpinner
                  size="sm"
                  variant="outline-primary"
                  title="Edit selected asset"
                  onClick={() => {
                    setOpenAddNewAssetDialogFlag(true);
                  }}
                  content={<Edit2 size={20} />}
                  buttonClass="mr-1"
                ></ButtonWithSpinner>
              )}
              {selectedAsset.length > 0 && (
                <ButtonWithSpinner
                  size="sm"
                  variant="outline-primary"
                  title="Delete selected assets"
                  onClick={deleteSelectedAsset.mutateAsync}
                  content={<Trash2 size={20} />}
                  buttonClass="mr-1"
                ></ButtonWithSpinner>
              )}
            </div>
          )}
        </div>
        {openAddNewAssetDialogFlag && (
          <NewAssetDialog
            assetData={
              selectedAsset.length > 0 ? selectedAsset[0].original : null
            }
            onHide={() => {
              setOpenAddNewAssetDialogFlag(false);
            }}
            onApply={() => {
              queryClient.invalidateQueries('assets');
              queryClient.invalidateQueries('allAssetsList');
              setOpenAddNewAssetDialogFlag(false);
            }}
          />
        )}

        {query.status === 'loading' && <CenteredSpinner />}
        {query.status === 'error' && <h5>Something went wrong ...</h5>}
        {query.status === 'success' && query.data.data.length > 0 && (
          <Table
            data={query.data.data}
            columns={columns}
            initialState={{ hiddenColumns: ['_id'] }}
            selectionEnabled={!user.is_staff}
            onSelect={setselectedAsset}
            onClickLink={onClickLink}
            tableClass={`table skip-table-scroll skip-table-scroll-col1 ${user.is_staff ? '' : 'skip-table-scroll-col2'}} `}
            searchBoxPlaceholder="Search in Asset Table"
            disableSortBy={false}
          />
        )}
      </div>
    </div>
  );
};

export default AssetList;
