import React from 'react'
import loading from '../../assets/loading.gif'

function STLViewerSkeleton({height = '70vh', background= 'black'}) {
  return <div style={{background: background, height: height, textAlign: 'center'}}>
    <img 
        height="15%" 
        style={{transform: 'translateY(-50%)', marginTop: '50%'}} 
        src={loading} 
        alt="Loading..."
    /> 
</div> 
}

export default STLViewerSkeleton