// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Dialog } from '@tri/ui-shared';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Col, Form, Row } from 'react-bootstrap';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { SelectOptions } from 'apps/mixit/src/app/common/FormFields';
import { getCookie } from '../utils';
import { toast } from 'react-toastify';
import { useContext } from 'react';
import ToastContext from '../context';
import MandatorySymbol from '../common/MandatorySymbol';
import { RBOffcanvas } from '../common/RBOffcanvas';

// Currently THREE User Types are supported.
// 'Admin' will be having all the privilages
// 'Power User' will be having only Add Case/Asset privilage.
// 'Normal User' will be having read only privilage for all the data.

const userTypeList = [
  {
    userType: 'Normal User',
    value: 'Normal User',
  },
  {
    userType: 'Power User',
    value: 'Power User',
  },
  {
    userType: 'Admin',
    value: 'Admin',
  },
];

const CreateUserDialog = ({
  onHide,
  onApply,
  editUserFlag,
  selectedUserData,
}) => {

  const setToast = useContext(ToastContext)

  let initialValues = {
    userName: '',
    password: '',
    emailId: '',
    userType: 'Normal User',
  };
  let title = 'Add New User';
  if (editUserFlag) {
    let selectedUser = selectedUserData[0]
    initialValues = {
      userName: selectedUser?.values.username,
      emailId: selectedUser?.values.email,
      userType: selectedUser?.values.user_type,
    };
    title = 'Edit User Data';
  }

  const passwordValidation =  Yup.string()
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
  )

  const ValidationSchema = Yup.object().shape({
    userName: Yup.string().required('Username is required')
      .matches(
        /^[A-Za-z]+([_.\-@]*[A-Za-z0-9]+)*$/,
        "Username must start with character and end with characters or numbers, special characters . _ - @ are allowed in between."
      ),
    password: editUserFlag ? passwordValidation : passwordValidation.required('No password provided.'),
    emailId: Yup.string().email().required('Email is Required'),
  });

  //Create & Edit User
  const createUser = async (values) => {
    let fd = new FormData();
    let userData = {
      username: values.userName,
      password: values.password,
      email: values.emailId,
      user_type: values.userType,
    };

    if (editUserFlag) {
      userData['user_id'] = selectedUserData[0].values.user_id;
      let editUserConfirmMsg = window.confirm(`Please confirm to proceed with edit the user data`)
      if(!editUserConfirmMsg) {
          return
      }
    }
    let toastId = toast.loading(`${editUserFlag ? 'Modifying' : 'Creating'} user: ${userData.username}`)
    let userCreateOrEditResponse

    fd.append('data', JSON.stringify(userData));
    try {
      let headers = {
        'X-CSRF-TOKEN': getCookie('csrf_token')
      }
      if (editUserFlag) {
        let { data } = await axios.patch('/api/users/', fd, {
          headers: {
            'content-type': 'multipart/form-data',
            ...headers
          },
        });
        userCreateOrEditResponse = data
      } else {
        let { data } = await axios.post('/api/users/', fd, {
          headers: headers,
        });
        userCreateOrEditResponse = data
      }
      onApply();
      userCreateOrEditResponse.status_code !== 409 && onHide();
    } catch (err) {
      userCreateOrEditResponse = {
        status_code: 500,
        msg: "Whoops, something went wrong!!!"
      }
    }
    setToast({
        id: toastId,
        statusCode: userCreateOrEditResponse.status_code,
        message: userCreateOrEditResponse.msg
    })
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={createUser}
    >
      {({
        handleSubmit,
        values,
        handleChange,
        errors,
        isSubmitting
      }) => (
        <RBOffcanvas 
          show={true} 
          title={title} 
          onHide={onHide} 
          onOk={handleSubmit} 
          isOkBtnDisabled={_.isEqual(values,  initialValues) || isSubmitting}
        >
          <Form>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                Username {!editUserFlag && <MandatorySymbol />}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  name="userName"
                  placeholder="Enter Username"
                  value={values.userName}
                  onChange={handleChange}
                  className="form-control"
                  disabled={editUserFlag}
                  isInvalid={errors.userName}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.userName}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                {editUserFlag ? 'Change Password': 'Password'}
                {!editUserFlag && <MandatorySymbol />}
              </Form.Label>
              <Col sm="8">
                <Form.Control 
                  name="password"
                  type="password" 
                  placeholder={editUserFlag ? "Enter New Password" : "Enter Password"}
                  onChange={handleChange}
                  value={values.password}
                  isInvalid={errors.password}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.password}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                Email {<MandatorySymbol />}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="email"
                  name="emailId"
                  placeholder="Enter Email"
                  value={values.emailId}
                  onChange={handleChange}
                  className="form-control"
                  isInvalid={errors.emailId}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.emailId}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                User Type
              </Form.Label>
              <Col cm="8">
                <Form.Control
                  className="form-control"
                  as="select"
                  name="userType"
                  onChange={handleChange}
                  value={values.userType}
                >
                  <SelectOptions
                    options={
                      userTypeList.map((userType) => {
                        return {
                          label: userType.userType,
                          value: userType.value,
                        };
                      })
                    }
                  />
                </Form.Control>
              </Col>
            </Form.Group>
          </Form>
        </RBOffcanvas>

      )}
    </Formik>
  );
};

export default CreateUserDialog;