import { useMemo } from 'react';
import { useProjectList } from '../data-service/project.dataservice';
import { Form } from 'react-bootstrap';
import { useEffect } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { BreadcrumbContext } from '../../../context';
import { removeRestBreadcrumbsOrAppendNew, sortTableColumn } from '../../../utils';
import { LinkCell } from '../../../common/LinkCell';
import { useHistory } from 'react-router';
import { pillTabs } from '../../../utils/constants';
import { useAssetList } from '../../../asset-explorer/services/data-service/asset.dataservice';

//Creating asset id along with its name dictionary
const createAssetIdAndNameDictionary = (assetData) => {
  let assetIdsWithNames = {}
  assetData?.forEach((asset) => assetIdsWithNames[asset._id] = asset.asset_name)
  return assetIdsWithNames
}
const useMyProjectDetailsData = (user) => {
  const projectList = useProjectList()
  const assetList = useAssetList()
  const history = useHistory()
  const { isBreadcrumbsFromCache, setBreadCrumbItmes } = useContext(BreadcrumbContext)
  const [selectedProject, setSelectedProject] = useState([]);
  
  const assetIdAndNameDictionary = useMemo(() => createAssetIdAndNameDictionary(assetList.data), [assetList.data])

  //creating breadcrumbs
  useEffect(() => {
    !isBreadcrumbsFromCache && removeRestBreadcrumbsOrAppendNew(pillTabs.MyProjects, [{ 
      title: pillTabs.MyProjects,
      redirectTo: `/app/simulationData/?type=${pillTabs.MyProjects}`
    }], setBreadCrumbItmes, pillTabs.SharedProjects, history)
  }, [])

  const onClickOnProject = (project) => {
    history.push(`/app/simulationData/${project.values._id}`);
  }

  const allMyProjectTableData = useMemo(() => {
    let projectdata = []
    projectList.data?.forEach(project => {
      project["is_project_shared"] = <Form.Check
        custom
        type="switch"
        id={`is_project_shared-${project._id}`}
        checked={project.shared_with?.length}
        disabled={true}
      />
      project['project_owner'] = project.project_info?.owner
      project['project_name'] = project.project_info?.name
      project['project_mapped_asset'] = assetIdAndNameDictionary[project.mapped_asset?.asset_id]
      if(user.is_superuser || project.project_info?.owner === user.username)  {
        projectdata.push(project)
      } 
    })

    if(projectdata.length < 1) {
      setSelectedProject([])
    }
    
    return projectdata
  }, [projectList.data])

  const listOfAllProjectTableColumn = useMemo(() => [
    {
      Header: 'Id',
      accessor: '_id',
      disableGlobalFilter: true
    },
    {
      Header: 'Project Name',
      accessor: 'project_name',
      Cell: LinkCell,
      sortType: sortTableColumn
    },
    {
      Header: 'Project Owner',
      accessor: 'project_owner',
      sortType: sortTableColumn
    },
    {
      Header: 'Mapped Asset',
      accessor: 'project_mapped_asset',
      sortType: sortTableColumn
    },
    {
      Header: 'Date',
      accessor: 'date_added',
      sortType: sortTableColumn
    },
    {
      Header: 'Comments/Notes',
      accessor: 'comments',
      sortType: sortTableColumn
    },
    {
      Header: 'Shared With Others',
      accessor: 'is_project_shared',
      sortType: sortTableColumn
    },
  ], [])
  
  return { 
    selectedProject, setSelectedProject, projectList, allMyProjectTableData, listOfAllProjectTableColumn, onClickOnProject
  }
}

const useSharedProjectDetailsData = (user) => {
    const projectList = useProjectList()
    const assetList = useAssetList()
    const history = useHistory()
    const { isBreadcrumbsFromCache, setBreadCrumbItmes } = useContext(BreadcrumbContext)
    const [selectedProject, setSelectedProject] = useState([]);
    
    const assetIdAndNameDictionary = useMemo(() => createAssetIdAndNameDictionary(assetList.data), [assetList.data])

    //creating breadcrumbs
    useEffect(() => {
      !isBreadcrumbsFromCache && removeRestBreadcrumbsOrAppendNew(pillTabs.SharedProjects, [{ 
        title: pillTabs.SharedProjects,
        redirectTo: `/app/simulationData/?type=${pillTabs.SharedProjects}`
      }], setBreadCrumbItmes, pillTabs.MyProjects, history)
    }, [])

    const onClickOnProject = (project) => {
      history.push(`/app/simulationData/${project.values._id}`);
    }

    const allSharedProjectTableData = useMemo(() => {
        let projectdata = []
        projectList.data?.forEach(project => {
            project['project_owner'] = project.project_info?.owner
            project['project_name'] = project.project_info?.name
            project['project_mapped_asset'] = assetIdAndNameDictionary[project.mapped_asset?.asset_id]
            if(project.shared_with?.includes(user._id))  projectdata.push(project)
        });
        return projectdata
    }, [projectList.data])
  
    const listOfAllProjectTableColumn = useMemo(() => [
        {
            Header: 'Id',
            accessor: '_id',
            disableGlobalFilter: true
        },
        {
            Header: 'Project Name',
            accessor: 'project_name',
            sortType: sortTableColumn,
            Cell: LinkCell,
            style: {
              cursor: 'pointer',
            }
        },
        {
            Header: 'Project Owner',
            accessor: 'project_owner',
            sortType: sortTableColumn
        },
        {
          Header: 'Mapped Asset',
          accessor: 'project_mapped_asset',
          sortType: sortTableColumn
        },
        {
            Header: 'Date',
            accessor: 'date_added',
            sortType: sortTableColumn
        },
        {
            Header: 'Comments/Notes',
            accessor: 'comments',
            sortType: sortTableColumn
        }
    ], [])
    
    return { selectedProject, setSelectedProject, projectList, allSharedProjectTableData, listOfAllProjectTableColumn, onClickOnProject }
}

export { useMyProjectDetailsData, useSharedProjectDetailsData }