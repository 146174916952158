import { useDeleteProjects, useCreateProject, useUpdateProject } from '../data-service/project.dataservice';
import { useState, useEffect, useContext } from 'react';
import * as Yup from 'yup'
import { useUserList } from '../../../user/services/data-service/user.dataservice';
import { useAssetList } from '../../../asset-explorer/services/data-service/asset.dataservice';
import { useMutation, useQueryClient } from 'react-query';
import ToastContext from '../../../context';
import { toast } from 'react-toastify';

const useFileDataActionMode = () => ({
    append: "append",
    overwrite: "overwrite" 
})

//delete projects
const DeleteSelectedProjects = (selectedProject) => {
    const selectedProjectIds = []
    selectedProject.forEach(project => {
        selectedProjectIds.push(project.original?._id)
    })

    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useDeleteProjects(selectedProjectIds)
}

//create or update project
const CreateOrUpdateProjects = async (projectValues, isEditMode) => {
    const fileDataActionMode = useFileDataActionMode()
    let toastId;
    if(!isEditMode) {
        toastId = toast.loading(`Creating project: ${projectValues.projectName}`)
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return {...(await useCreateProject(projectValues)), toastId: toastId}
    }
    else {
        let isOverwrite = projectValues.fileDataAction === fileDataActionMode.overwrite
        let confirm = true;
        // eslint-disable-next-line no-prototype-builtins
        confirm = window.confirm(`Please confirm to proceed with ${projectValues.hasOwnProperty("projectFile") ? projectValues.fileDataAction : "edit"} the data..`)
        if(!confirm) {
            return
        }
        toastId = toast.loading(`Modifying project: ${projectValues.projectName}`)
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return {...(await useUpdateProject(projectValues, isOverwrite)), toastId: toastId }
    }
}

const useProjectDetailsData = (user, initialValues, setInitialValues, selectedProject) => {
  const [userListOptions, setUsersListOptions] = useState([])
  const [selectedSharedUsers, setSelectedSharedUsers] = useState([])
  const [assetListOptions, setAsstesListOptions] = useState([])
  const [selectedAsset, setSelectedAsset] = useState('')
  const [dialogueFlag, setDialogueFlag] = useState(false)
  const userList = useUserList()
  const assetList = useAssetList()
  const setToast = useContext(ToastContext)
  const queryClient = useQueryClient();
  const fileDataActionMode = useFileDataActionMode()
  const [isCreateProjectInstructionOpen, setIsCreateProjectInstructionOpen] = useState(false)

  useEffect(() => {
    //close instruction div once dialogue is open
    if(dialogueFlag) {
      setIsCreateProjectInstructionOpen(false)
    }
    //reset form values on dialog close
    else if(selectedProject.length !== 1) {
      let preSelectedValues = {
        "fileDataAction": fileDataActionMode.append,
        "projectName": '',
        "projectOwner": user.username,
        "mappedAssets": '',
        "sharedUsers": [],
        "comment": ''
      }
      setInitialValues(preSelectedValues)
    }
  }, [dialogueFlag])

  //set preselected user list
  useEffect(() => {
    let sharedUsers = userListOptions.filter(option => initialValues.sharedUsers?.includes(option.value))
    setSelectedSharedUsers(sharedUsers || [])
  }, [userListOptions, initialValues])

  //set preselected asset
  useEffect(() => {
    let asset = assetListOptions.filter(option => initialValues.mappedAssets === option.value)
    setSelectedAsset(asset)
  }, [initialValues])
  
  //Generate user list options
  useEffect(() => {
    let userOptions = []
    userList && userList.data?.forEach((userItem) => {
      if(userItem.user_type !== "Admin" && userItem.username !== user.username) {
        userOptions.push({ 
          value: userItem._id, 
          label: userItem.username
        })
      }
    })
    setUsersListOptions(userOptions)
  }, [userList.data])

  //Generate assets list options
  useEffect(() => {
    let assetOptions = [{
      label: "Link new asset",
      value: 'link_new_asset'
    }]
    assetList && assetList.data?.forEach((asset) => 
      assetOptions.push({
        label: asset.asset_name,
        value: asset.asset_name === 'None' ? '' : asset._id
      })
    )
    setAsstesListOptions(assetOptions)
  }, [assetList.data])
  
  const addOrUpdateProject = useMutation(
    async (values) => {
      let addProjectResponse = await CreateOrUpdateProjects(values, selectedProject.length === 1)
      if([200, 201, 406].includes(addProjectResponse?.statusCode)) {
        queryClient.invalidateQueries('projects');
        if(selectedProject.length === 1)  {
          queryClient.invalidateQueries(`projectDetail_${values.projectId}`);
          if(values.mappedAssets) {
            queryClient.invalidateQueries(`allAssetDetails`);
          }
        }
        setSelectedAsset('')
        setSelectedSharedUsers([])
        setDialogueFlag(false)
      }  

      addProjectResponse?.toastId && setToast({
        id: addProjectResponse.toastId,
        statusCode: addProjectResponse.statusCode,
        message: addProjectResponse.message
      })   
    }
  );

  const deleteProjects = useMutation(
    async () => {
      const confirm = window.confirm('Please confirm to proceed with deletion..');
      if (!confirm) return
  
      let deleteProjectResponse = await DeleteSelectedProjects(selectedProject)
      if([200, 201].includes(deleteProjectResponse.statusCode)) {
        queryClient.invalidateQueries('projects')
      }
      else {
        setToast({
          statusCode: deleteProjectResponse.statusCode,
          message: deleteProjectResponse.message
        })
      }
    }
  )

  return { dialogueFlag, setDialogueFlag, setToast, fileDataActionMode, 
    selectedSharedUsers, setSelectedSharedUsers, userListOptions, selectedAsset, setSelectedAsset, assetListOptions, addOrUpdateProject, deleteProjects,
    isCreateProjectInstructionOpen, setIsCreateProjectInstructionOpen
  }
}

const useProjectFormikData = (user, selectedProject) => {
    const [initialValues, setInitialValues] = useState()
    const fileDataActionMode = useFileDataActionMode()
    const [isEditMode, setIsEditMode] = useState(false)

    //update preselected values
    useEffect(() => {
        let preSelectedValues = {
          "fileDataAction": fileDataActionMode.append,
          "projectName": '',
          "projectOwner": user.username,
          "mappedAssets": '',
          "sharedUsers": [],
          "comment": ''
        }
        
        if(selectedProject.length === 1) {
          preSelectedValues["projectId"] = selectedProject[0].original?._id
          preSelectedValues["projectName"] = selectedProject[0].original?.project_name
          preSelectedValues["projectOwner"] = selectedProject[0].original?.project_owner
          preSelectedValues["mappedAssets"] = selectedProject[0].original?.mapped_asset?.asset_id
          preSelectedValues["sharedUsers"] = selectedProject[0].original?.shared_with
          preSelectedValues["comment"] = selectedProject[0].original?.comments
          setIsEditMode(true)
        }
        else {
          setIsEditMode(false)
        }
        setInitialValues(preSelectedValues)
    }, [selectedProject])

    //add new or edit project validation schema
    const projectValidationSchema = Yup.object().shape({
        projectFile: selectedProject.length !== 1 ? Yup.mixed().required('Required') : Yup.mixed(),
        projectName: Yup.string().required('Required')
    })

    return { initialValues, setInitialValues, projectValidationSchema, isEditMode }
}

export { CreateOrUpdateProjects, DeleteSelectedProjects, useProjectDetailsData, useFileDataActionMode, useProjectFormikData }