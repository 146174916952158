import { Nav, Navbar } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

const Header = ({
  appLogo,
  appTitle,
  views = [],
  activeView,
  userMenu,
  helpMenu,
}) => {
  const history = useHistory();

  return (
    <Navbar bg="primary" variant="dark" expand="lg" className="header-panel">
      {appLogo && <img src={appLogo} />}
      {appTitle && <Navbar.Brand>{appTitle}</Navbar.Brand>}
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav 
          className="mr-auto" 
          onSelect={(key) => {
            history.push(`/app/${key}`);
          }}
        >
          {views.map(({ key, label }) => (
            <Nav.Link
              key={key}
              eventKey={key}
              active={activeView === key}
            >
              {label}
            </Nav.Link>
          ))}
        </Nav>
        {helpMenu}
        {userMenu}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
