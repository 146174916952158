import { useQuery } from "react-query";
import { executeApiCall, useHeaderConfiguration } from "../../../utils"

//get list of all users
const useSimcaseAnimationsList = (simcaseIds) => {
    const config = useHeaderConfiguration();
    let useQueryKey = simcaseIds.toString().replace(/, /g,'');
    return useQuery(`simcaseAnimations${useQueryKey}`, async () => {
        const { data } = await executeApiCall('get', `/api/v3/projects/sim_cases/results/animations/?c_ids=${simcaseIds}`, {}, config)
        return data
    })
}

const useGetSimcaseAnimationURL = (simCaseId, fileId) => {
    const config = useHeaderConfiguration();
    return useQuery(`simcaseAnimation${fileId}`, async () => {
        const { data } = await executeApiCall('get', `api/v3/projects/sim_cases/results/Animations/${simCaseId}/${fileId}`, {}, config, true)
        return data
    })
}

const useSaveAnimationData = (simcaseId, animation, accessToken) => {
    const config = useHeaderConfiguration()
    let formData = new FormData();
    formData.append('filename', animation.filename)
    formData.append('description', animation.description)
    return executeApiCall('put', `/api/v3/projects/sim_cases/results/${simcaseId}/Animations/${animation.id}`, formData, config)
}

export { useSimcaseAnimationsList, useSaveAnimationData, useGetSimcaseAnimationURL }
