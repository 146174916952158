import Select from "react-select";
import { reactSelectCustomTheme } from "../utils";
import { useState } from 'react';

export default function RSDropdown({options, placeholder, isMulti, value, handleChange, extraOption= true, hideSelectedOptions = true, allowDuplicate = true, ...rest}) {
    const [isMenuOpen, setIsMenuOpen] = useState(undefined)

    if(extraOption) {
        options = isMulti
            ? [{ label: "Select All", value: "all" }, ...options]
            : [{ label: "None", value: "" }, ...options]
    }

    const onMenuOpen = () => {
        if(isMenuOpen !== undefined) setIsMenuOpen(undefined)
    }

    const onOptionChange = (selected) => {
        let filterSelectedItems = []
        if(isMulti && selected.length) {
            let remainingOptions = options.filter(option => !selected.includes(option))
            if(selected.find(option => option.value === "all") || remainingOptions.length === 1 && remainingOptions[0].value === 'all') {
                filterSelectedItems = options.slice(1)
                setIsMenuOpen(false)
            }
            else {
                filterSelectedItems = !isMulti ? selected || null
                : selected
                setIsMenuOpen(true)
            }
        } 
        else { 
            filterSelectedItems = !isMulti ? selected || null
            : selected
        }   
        handleChange(filterSelectedItems)
    }

    return (
        <div className={`react-select-wrapper ${isMulti ? "multi" : ""}`} style={rest?.containerStyle}>
            <Select
                menuIsOpen={isMenuOpen}
                onMenuOpen={onMenuOpen}
                theme={(theme) => (reactSelectCustomTheme(theme))}
                placeholder={placeholder}
                options={options}
                isMulti={isMulti}
                value={value ?? null}
                onChange={onOptionChange} 
                onBlur={() => setIsMenuOpen(false)}
                hideSelectedOptions={hideSelectedOptions}
                styles={{
                    control: (baseStyles) => ({
                        ...baseStyles, ...(rest?.style)
                    }),
                }}
                {...rest}
            />
        </div>
    )
}