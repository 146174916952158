import { Button, Modal } from 'react-bootstrap';

const Dialog = ({
  show,
  title,
  children,
  okLabel = 'Apply',
  cancelLabel = 'Cancel',
  showCancel = true,
  showOk = true,
  isOkBtnDisabled = false,
  onOk,
  onHide,
  size = 'md',
}) => {
  return (
    <Modal show={show} size={size} onHide={onHide} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title className="text-break">{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{children}</Modal.Body>

      <Modal.Footer>
        {showOk && (
          <Button disabled={isOkBtnDisabled} variant="primary" onClick={onOk}>
            {okLabel}
          </Button>
        )}
        {showCancel && (
          <Button variant="secondary" onClick={onHide}>
            {cancelLabel}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default Dialog;
