// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { StlViewer } from '@tri/ui-shared';
import { GetSimCaseGeometry } from '../services/business-logic/geometry.businesslogic';
import { parameterTableType, pillTabs } from '../../utils/constants';
import { ParameterTable } from '../ParameterTable';
import STLViewerSkeleton from './../../common/STLViewerSkeleton';

const GeometryPanel = ({selectedSimCaseId}) => {
  const url = GetSimCaseGeometry(selectedSimCaseId)

  return (
    <div className='d-flex flex-wrap justify-content-between'>
      <ParameterTable Id={selectedSimCaseId} type={parameterTableType.geometry_param} />
      <div style={{width: '480px'}}>
        {
          url ? <StlViewer url={url} /> : 
            url === '' ? <h5 className='mt-5'>Geometry not found or failed to load</h5> : <STLViewerSkeleton />
        }
      </div>
    </div>
  )
}
  
const Geometry = (selectedSimCaseId) =>  ({
  label: pillTabs.Geometry,
  Panel: <GeometryPanel selectedSimCaseId={selectedSimCaseId} />,
  tabEnabled: true
})

export { Geometry }